import React, { Fragment } from 'react'

import './App.css'
import { Route } from 'react-router-dom'

import Switch from 'react-router-dom/es/Switch'

import ModalsPortal from '../../common/ModalsPortal/ModalsPortal'
import Login from '../Login/Login'
import theme from 'reapop-theme-bootstrap'
import NotificationsSystem from 'reapop'
import HomeRouter from '../Home/HomeRouter'
import Signup from '../Signup/Signup'
import TestSigner from './TestSigner'
import Test from './Test'

const authenticated = true

const App = () => {
  return <Fragment>
    <NotificationsSystem theme={theme} />
    <Switch>
      <Route
        exact
        path='/sign'
        render={() => <TestSigner />} />
      {/* render={() => <div>Test is empty now <span role='img' aria-label='Sleeping'>😴</span></div>} /> */}
      <Route
        exact
        path='/test'
        render={() => <Test />} />
      <Route
        exact
        path={'/signup'}
        component={Signup} />
      <Route
        path='/'
        render={props => {
          if (authenticated) {
            return <HomeRouter {...props} />
          } else {
            return <Login {...props} />
          }
        }} />
    </Switch>
    <ModalsPortal />
  </Fragment>
}

export default App
