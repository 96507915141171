import moment from 'moment'

const today = new Date()
// const testReceipts = [
//   {
//     number: '3011000041468888005',
//     date: moment(new Date(today)).subtract(10, 'days').toDate().toString(),
//     patient: 'Valentin Mirchev',
//     accessAndWaiverCode: '239605',
//     optionCode: '9961',
//     location: 'Hospital Cruz Vermelha',
//     prescriber: 'R. Pimenta-Araújo',
//     contact: '217714000',
//     medicines: [
//       {
//         id: '813f6dd0-a2bc-46d0-98f2-682d4710b97d',
//         name: 'Omeprazol',
//         dose: '20mg',
//         type: 'Comprimidos',
//         pack: 'blister',
//         units: '60',
//         dosage: 'sos',
//         quantity: 2,
//         renewable: 1,
//         validation: '2019-03-30',
//         price: 5.12
//       }
//     ]
//   },
//   {
//     number: '3011000041468888006',
//     date: moment(new Date(today)).subtract(10, 'days').toDate().toString(),
//     patient: 'Valentin Mirchev',
//     accessAndWaiverCode: '239605',
//     optionCode: '9961',
//     location: 'Hospital Cruz Vermelha',
//     prescriber: 'R. Pimenta-Araújo',
//     contact: '217714000',
//     medicines: [
//       {
//         id: 'becae097-f6e7-408d-be88-5c60fec35fdc',
//         name: 'Ácido Acetilsalicílico',
//         dose: '100mg',
//         type: 'Comprimidos',
//         pack: 'blister',
//         units: '30',
//         dosage: '2cp/dia',
//         quantity: 6,
//         renewable: 1,
//         validation: '2019-03-30',
//         price: 2.34
//       },
//       {
//         id: 'a21a8de7-ec28-44b9-a0d7-c76ebea07926',
//         name: 'Paracetamol',
//         dose: '500mg',
//         type: 'Comprimidos',
//         pack: 'blister',
//         units: '20',
//         dosage: '2cp/dia',
//         quantity: 1,
//         renewable: 1,
//         validation: '2019-03-30',
//         price: 2.34
//       },
//       {
//         id: '813f6dd0-a2bc-46d0-98f2-682d4710b97d',
//         name: 'Omeprazol',
//         dose: '20mg',
//         type: 'Comprimidos',
//         pack: 'blister',
//         units: '60',
//         dosage: 'sos',
//         quantity: 2,
//         renewable: 1,
//         validation: '2019-03-30',
//         price: 5.12
//       }
//     ]
//   },
//   {
//     number: '3011000041468888007',
//     date: moment(new Date(today)).subtract(1, 'month').toDate().toString(),
//     patient: 'Valentin Mirchev',
//     accessAndWaiverCode: '239605',
//     optionCode: '9961',
//     location: 'Hospital Cruz Vermelha',
//     prescriber: 'R. Pimenta-Araújo',
//     contact: '217714000',
//     medicines: [
//       {
//         id: 'becae097-f6e7-408d-be88-5c60fec35fdc',
//         name: 'Ácido Acetilsalicílico',
//         dose: '100mg',
//         type: 'Comprimidos',
//         pack: 'blister',
//         units: '30',
//         dosage: '2cp/dia',
//         quantity: 6,
//         renewable: 1,
//         validation: '2019-03-30',
//         price: 2.34
//       },
//       {
//         id: 'a21a8de7-ec28-44b9-a0d7-c76ebea07926',
//         name: 'Paracetamol',
//         dose: '500mg',
//         type: 'Comprimidos',
//         pack: 'blister',
//         units: '20',
//         dosage: '2cp/dia',
//         quantity: 1,
//         renewable: 1,
//         validation: '2019-03-30',
//         price: 2.34
//       },
//       {
//         id: '72a438a3-539a-47b9-98ae-6bf67e74efa3',
//         name: 'Dol-u-Ron',
//         dose: '1000mg',
//         type: 'Comprimidos',
//         pack: 'blister',
//         units: '10',
//         dosage: '2cp/dia',
//         quantity: 2,
//         renewable: 0,
//         validation: '2019-03-30',
//         price: 2.34
//       }
//     ]
//   }
// ]

const testReceipts = [
  {
    number: '3011000041468888005',
    date: moment(new Date(today)).subtract(10, 'days').toDate().toString(),
    patient: 'Carlos Santos',
    accessAndWaiverCode: '239605',
    optionCode: '9961',
    location: 'Hospital Cruz Vermelha',
    prescriber: 'R. Pimenta-Araújo',
    contact: '217714000',
    medicines: [
      {
        id: 11593,
        name: 'Omeprazol Almus',
        dose: '20mg',
        isGeneric: true,
        medicineType: { id: 31, name: 'Cápsula gastrorresistente' },
        packageType: { id: 7, name: 'Blister' },
        administrationType: { id: 48, name: 'Via oral' },
        units: 30,
        dosage: 'de 8 em 8 horas',
        quantity: 2,
        renewable: 1,
        validation: '2019-03-30',
        price: 5.12
      },
      {
        id: 2027561,
        name: 'Antigrippine C',
        dose: '500 mg + 25 mg + 200 mg',
        units: 6,
        medicineType: { id: 968, name: 'Granulado para solução oral em saqueta' },
        administrationType: { id: 48, name: 'Via oral' },
        packageType: { id: 45, name: 'Saqueta' },
        isGeneric: true,
        dosage: '2x por dia',
        quantity: 1,
        renewable: 0,
        validation: '2019-03-30',
        price: 5.12
      },
      {
        id: 198399,
        name: 'Ben-u-ron Caff',
        dose: '500 mg + 65 mg',
        units: 12,
        medicineType: { id: 271, name: 'Comprimido' },
        administrationType: { id: 48, name: 'Via oral' },
        isGeneric: false,
        packageType: { id: 7, name: 'Blister' },
        dosage: 'sos',
        quantity: 1,
        renewable: 0,
        validation: '2019-03-30',
        price: 5.12
      },
      {
        id: 2035190,
        name: 'Brometo de Vecurónio Bradex',
        dose: '10 mg',
        units: 1,
        medicineType: { id: 210, name: 'Pó para solução injetável' },
        administrationType: { id: 48, name: 'Via oral' },
        isGeneric: true,
        packageType: { id: 60, name: 'Frasco para injetáveis' },
        dosage: '1x dia',
        quantity: 1,
        renewable: 0,
        validation: '2019-03-30',
        price: 5.12
      }
    ]
  },
  {
    number: '3011000041468888007',
    date: moment(new Date(today)).subtract(90, 'days').toDate().toString(),
    patient: 'Valentin Mirchev',
    accessAndWaiverCode: '239605',
    optionCode: '9961',
    location: 'Hospital Cruz Vermelha',
    prescriber: 'Jorge Costa',
    contact: '217714000',
    medicines: [
      {
        id: 198399,
        name: 'Ben-u-ron Caff',
        dose: '500 mg + 65 mg',
        units: 12,
        medicineType: { id: 271, name: 'Comprimido' },
        administrationType: { id: 48, name: 'Via oral' },
        isGeneric: false,
        packageType: { id: 7, name: 'Blister' },
        dosage: 'sos',
        quantity: 1,
        renewable: 0,
        validation: '2019-03-30',
        price: 5.12
      }
    ]
  },
  {
    number: '3011000041468888006',
    date: moment(new Date(today)).subtract(20, 'days').toDate().toString(),
    patient: 'Tiago Rordigues',
    accessAndWaiverCode: '239605',
    optionCode: '9961',
    location: 'Hospital Cruz Vermelha',
    prescriber: 'R. Pimenta-Araújo',
    contact: '217714000',
    medicines: [
      {
        id: 2035190,
        name: 'Brometo de Vecurónio Bradex',
        dose: '10 mg',
        units: 1,
        medicineType: { id: 210, name: 'Pó para solução injetável' },
        administrationType: { id: 48, name: 'Via oral' },
        isGeneric: true,
        packageType: { id: 60, name: 'Frasco para injetáveis' },
        dosage: '1x dia',
        quantity: 1,
        renewable: 0,
        validation: '2019-03-30',
        price: 5.12
      }
    ]
  },
  {
    number: '3011000041468888007',
    date: moment(new Date(today)).subtract(2, 'years').toDate().toString(),
    patient: 'Jorge Neves',
    accessAndWaiverCode: '239605',
    optionCode: '9961',
    location: 'Hospital Cruz Vermelha',
    prescriber: 'R. Pimenta-Araújo',
    contact: '217714000',
    medicines: [
      {
        id: 198399,
        name: 'Ben-u-ron Caff',
        dose: '500 mg + 65 mg',
        units: 12,
        medicineType: { id: 271, name: 'Comprimido' },
        administrationType: { id: 48, name: 'Via oral' },
        isGeneric: false,
        packageType: { id: 7, name: 'Blister' },
        dosage: 'sos',
        quantity: 1,
        renewable: 0,
        validation: '2019-03-30',
        price: 5.12
      }
    ]
  }
]

// const testDoctorReceipts = testReceipts.slice(0, testReceipts.length - 1)
const testDoctorReceipts = testReceipts

export { testDoctorReceipts }

export default testReceipts
