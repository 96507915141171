import React from 'react'
import PropTypes from 'prop-types'
// import { polyglot } from '../../../index'

export class Renderer extends React.Component {
  constructor (props) {
    super(props)
    // let noDataMessage = props.noDataMessage
    // if (polyglot) {
    //   noDataMessage = polyglot.t('staticFields.commonFields.noInformation')
    // }
    let noDataMessage = 'No Information'
    this.state = {
      noDataMessage: noDataMessage
    }
  }
  render () {
    if (this.props.data) {
      return this.props.children
    } else {
      if (!this.props.noMessage) {
        return <h2>{this.props.noDataMessage}</h2>
      }
      return null
    }
  }
}

Renderer.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  noMessage: PropTypes.bool.isRequired,
  noDataMessage: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
}

Renderer.defaultProps = {
  noMessage: false,
  noDataMessage: 'No Data'
}
