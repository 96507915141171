import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import './style.css'

class TabsContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeTab: props.defaultActive
    }
  }

  handleClick (index) {
    this.setState({activeTab: index})
  }

  getContent (index) {
    return this.props.content.map((element, i) => {
      if (index === i) {
        return <div key={element.label}>{element.content}</div>
      } else {
        return false
      }
    })
  }

  render () {
    const { content, type, justifiedLabels, marginBody, rightButton } = this.props
    const { activeTab } = this.state
    const labels = content.map(element => element['label'])
    const hints = content.map(element => element['hint'])
    const marginTop = marginBody === 'normal' ? '15px' : marginBody === 'small' ? '5px' : '0'
    return <Fragment>
      <Tabs rightButton={rightButton} justifiedLabels={justifiedLabels} type={type} activeTab={activeTab} labels={labels} hints={hints} changeTab={this.handleClick.bind(this)} />
      <div style={{marginTop}} className='tab-content'>
        {this.getContent(activeTab)}
      </div>
    </Fragment>
  }
}

TabsContainer.propTypes = {
  type: PropTypes.oneOf(['tabs', 'pills']),
  content: PropTypes.array.isRequired,
  defaultActive: PropTypes.number,
  justifiedLabels: PropTypes.bool,
  marginBody: PropTypes.string
}

TabsContainer.defaultProps = {
  defaultActive: 0,
  marginBody: 'normal',
  type: 'tabs',
  justifiedLabels: false
}

const Tabs = ({labels, type, activeTab, changeTab, justifiedLabels, rightButton, hints}) => {
  return (
    <Fragment>
      <ul className={`nav nav-${type} ${justifiedLabels ? 'nav-justified' : ''}`}>
        {labels.map(function (tab, index) {
          if (index === labels.length - 1) {
            return <Fragment key={index}>
              <Tab key={index} name={tab} hint={hints[index]} isActive={activeTab === index} handleClick={changeTab.bind(this, index)} />
              {rightButton ? <div className='pull-right'>{rightButton}</div> : null}
            </Fragment>
          } else {
            return (
              <Tab key={index} name={tab} hint={hints[index]} isActive={activeTab === index} handleClick={changeTab.bind(this, index)} />
            )
          }
        }.bind(this))}
      </ul>
    </Fragment>

  )
}

Tabs.propTypes = {
  type: PropTypes.oneOf(['tabs', 'pills']),
  labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  activeTab: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired,
  justifiedLabels: PropTypes.bool.isRequired
}

const Tab = (props) => {
  return (
    <li data-tip={props.hint} onClick={props.handleClick} className={`${props.isActive ? 'active' : ''} ${props.last ? 'pull-right' : ''}`}>
      <button className='no-focus'>{props.name}</button>
    </li>
  )
}

Tab.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  hint: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
}

export {TabsContainer}
