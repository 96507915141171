const SET_DOCTOR = 'doctor//SET_DOCTOR'
const REMOVE_DOCTOR = 'doctor//REMOVE_DOCTOR'
const ADD_FAVORITE = 'doctor//ADD_FAVORITE'
const CHANGE_FAVORITE = 'doctor//CHANGE_FAVORITE'
const REMOVE_FAVORITE = 'doctor//REMOVE_FAVORITE'

export {
  SET_DOCTOR,
  REMOVE_DOCTOR,
  ADD_FAVORITE,
  CHANGE_FAVORITE,
  REMOVE_FAVORITE
}
