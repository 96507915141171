import React from 'react'
import PatientData from './PatientData'
import { manyPatients } from '../../../../../../test/testPatient'

const PatientDataContainer = ({patientHealthcareNumber}) => {
  const patient = manyPatients.find(pat => pat.healthcareNumber === patientHealthcareNumber)
  if (patient) {
    console.log(patient)
    return <PatientData person={patient} />
  } else {
    return <div>Loading</div>
  }
}

export default PatientDataContainer
