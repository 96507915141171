import React, { Fragment } from 'react'

import './style.css'
import { Button } from '../../../../../common/Button/Button'
import ReceiptVisualizerContainer from '../Receipts/ReceiptVisualizer/ReceiptVisualizerContainer'
import ReceiptListContainer from '../Receipts/ReceiptList/ReceiptListContainer'

const RepeatReceiptButton = ({error, patientNumber, handleChange, handleSubmit}) => {
  return <div className='patient-select form-inline'>
    <div className={`input-group ${error ? 'has-error' : ''}`}>
      <input
        type='text'
        className={`form-control ${error ? 'has-error' : ''}`}
        onChange={handleChange}
        placeholder='Nº SNS' />
      <div className='input-group-btn'>
        <Button
          onClick={() => handleSubmit(patientNumber)}
          text={<Fragment>
            <span className='hidden-sm'>Prescrever</span>
            <span className='visible-sm fa fa-prescription button-fa' />
          </Fragment>}
          buttonType={`btn btn-${error ? 'warning' : 'success'}`}
          type={'button'} />
      </div>
    </div>
  </div>
}

class PatientHistory extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      openedReceiptNumber: null
    }
  }

  openReceipt (number) {
    this.setState({
      openedReceiptNumber: number
    })
    this.toggleModal()
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  render () {
    const { openedReceiptNumber, modalIsOpen } = this.state
    return <div className='full-height patient-history-container'>
      <div className='col-md-12 full-height no-side-padding-for-container history-panel'>
        <ReceiptListContainer
          itemClickHandle={this.openReceipt.bind(this)}
          repeatButton={<RepeatReceiptButton />} />
      </div>
      {openedReceiptNumber
        ? <ReceiptVisualizerContainer
          history={this.props.history}
          receiptNumber={openedReceiptNumber}
          modalIsOpen={modalIsOpen}
          toggleModal={this.toggleModal.bind(this)} />
        : null
      }
    </div>
  }
}

export default PatientHistory
