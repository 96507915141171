import {
  SET_DOCTOR,
  REMOVE_DOCTOR,
  ADD_FAVORITE,
  CHANGE_FAVORITE,
  REMOVE_FAVORITE
} from './types'

export function setDoctor (doctor) {
  return {
    type: SET_DOCTOR,
    payload: doctor
  }
}

export function removeDoctor () {
  return {
    type: REMOVE_DOCTOR
  }
}

export function addFavorite (item, index) {
  return {
    type: ADD_FAVORITE,
    payload: {
      item,
      index
    }
  }
}

export function changeFavorite (index, data) {
  return {
    type: CHANGE_FAVORITE,
    payload: {
      index,
      data
    }
  }
}

export function removeFavorite (index) {
  return {
    type: REMOVE_FAVORITE,
    payload: {
      index
    }
  }
}
