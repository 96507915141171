import React, { Component } from 'react'

import SearchFormContainer from '../../../Patient/Prescription/Prescribe/Search/SearchForm/SearchFormContainer'
import List from '../../../Patient/Prescription/Prescribe/List/List'
import PrescriptionMedicineViewContainer
  from '../../../Patient/Prescription/MedicineView/PrescriptionMedicineView/PrescriptionMedicineViewContainer'
import BasketContainer from '../../../Patient/Prescription/Prescribe/Basket/BasketContainer'

import './style.css'
import PropTypes from 'prop-types'
import { ReactModal } from '../../../../../common/ReactModal/ReactModal'
import { Button } from '../../../../../common/Button/Button'
import FreeformMedicineViewContainer
  from '../../../Patient/Prescription/MedicineView/FreeformMedicineView/FreeformMedicineViewContainer'

const EmptyMedicine = () => {
  return <FreeformMedicineViewContainer />
}

class FavoritesAddition extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }
  render () {
    let { searching, searchResults, handleSubmitClick, freeFormPrescription } = this.props
    return <div className='container-large no-side-padding-for-container favorites-container'>
      <div className={`col-xs-12 no-side-padding`}>
        <Button onClick={this.toggleModal.bind(this)} type={'button'} buttonType={'btn-default margin-bottom btn-xs-block pull-left'} text={<div> <span className='fas fa-plus' /> Medicamento</div>} />
        <Button onClick={handleSubmitClick} type={'button'} buttonType={'btn-success margin-bottom btn-xs-block pull-right'} text={'Guardar Favorito'} />
      </div>
      <ReactModal customClass={'favorite-modal'} fullWidth toggleModal={this.toggleModal.bind(this)} name={'Seleção de Medicamentos'} modalIsOpen={this.state.modalIsOpen}>
        <div className='container-fluid prescribe-container'>
          <SearchFormContainer favorites />
          <div className='col-xs-12 top-margin no-side-padding-for-container'>
            {searching
              ? <div className='loading'><span className='fas fa-spinner fa-4x fa-pulse' /></div>
              : searchResults.length > 0
                ? <List>
                  {
                    searchResults.map((item, index) => {
                      return <PrescriptionMedicineViewContainer
                        favorite
                        key={index}
                        medicine={item} />
                    })
                  }
                </List>
                : freeFormPrescription
                  ? <EmptyMedicine />
                  : null
            }
          </div>
        </div>
      </ReactModal>
      <BasketContainer submit={handleSubmitClick} />
    </div>
  }
}

FavoritesAddition.propTypes = {
  searching: PropTypes.any,
  searchResults: PropTypes.any,
  handleSubmitClick: PropTypes.any
}

export default FavoritesAddition
