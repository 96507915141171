import { OPEN, CLOSE } from './types'

const reducer = (state = [], action) => {
  switch (action.type) {
    case OPEN:
      return state.concat({
        id: action.payload.id,
        title: action.payload.title,
        fullWidth: action.payload.fullWidth,
        content: action.payload.content
      })
    case CLOSE:
      return state.filter(modal => modal.id !== action.payload)
    default:
      return state
  }
}

export default reducer
