import React, { Component } from 'react'
import { Panel } from '../../../../../common/Panel/Panel'
import { Button } from '../../../../../common/Button/Button'
import PropTypes from 'prop-types'
import ProfessionalForm from './Form'
import { ReactModal } from '../../../../../common/ReactModal/ReactModal'
import { DisabledInput } from '../../../../../common/formComponents/Input'
import ProfileSectionsHeader from '../ProfileSectionsHeader'

class View extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  render () {
    let { person } = this.props
    return <div className='container-fluid doctor-profile'>
      <ProfileSectionsHeader person={person} />
      <Panel
        heading={'Dados Profissionais'}
        panelType={'panel-default'}
        rightHeader={<Button onClick={this.toggleModal.bind(this)} type={'submit'}
          text={<i className='fas fa-edit' />} buttonType={'btn-info btn-xs'} />}>
        <div className='margin-bottom'>
          <form>
            <div className='form-group col-sm-12'>
              <label className='control-label' style={{ textAlign: 'right' }}>Nome Clínico</label>
              <div className=''>
                <DisabledInput content={person.name} />
              </div>
            </div>
            <div className='form-group col-sm-6'>
              <label className='control-label' style={{ textAlign: 'right' }}>Ordem Profissional</label>
              <div>
                <DisabledInput content={''} />
              </div>
            </div>
            <div className='form-group col-sm-6'>
              <label className='control-label' style={{ textAlign: 'right' }}>Nº Cédula</label>
              <div className=''>
                <DisabledInput content={person.professionalNumber} />
              </div>
            </div>
            <div className='form-group col-sm-12'>
              <label className='control-label' style={{ textAlign: 'right' }}>Local de Trabalho</label>
              <div className=''>
                <DisabledInput content={''} />
              </div>
            </div>
            <div className='form-group col-sm-12'>
              <label className='control-label' style={{ textAlign: 'right' }}>Morada Profissional</label>
              <div className=''>
                <DisabledInput content={''} />
              </div>
            </div>
            <div className='form-group col-sm-6'>
              <label className='control-label' style={{ textAlign: 'right' }}>Código Postal</label>
              <div className=''>
                <DisabledInput content={''} />
              </div>
            </div>
            <div className='form-group col-sm-6'>
              <label className='control-label' style={{ textAlign: 'right' }}>Localidade</label>
              <div className=''>
                <DisabledInput content={''} />
              </div>
            </div>
            <div className='form-group col-sm-6'>
              <label className='control-label' style={{ textAlign: 'right' }}>Telefone</label>
              <div className=''>
                <DisabledInput content={''} />
              </div>
            </div>
            <div className='form-group col-sm-6'>
              <label className='control-label' style={{ textAlign: 'right' }}>Email</label>
              <div className=''>
                <DisabledInput content={''} />
              </div>
            </div>
          </form>
          {/*<Form handleSubmit={() => {}} type={'horizontal'}>*/}
          {/*  <FormElement disabled={!editable} inputSize={9} type={'text'} label={'Nome Clínico'} defaultValue={person.name} name={'professionalName'} />*/}
          {/*  <div className='form-group'>*/}
          {/*    <label className='control-label col-sm-3' style={{ textAlign: 'right' }}>Ordem Profissional</label>*/}
          {/*    <div className='col-sm-3'>*/}
          {/*      <DisabledInput content={''} />*/}
          {/*    </div>*/}
          {/*    <label className='control-label col-sm-2' style={{ textAlign: 'right' }}>Nº Cédula</label>*/}
          {/*    <div className='col-sm-4'>*/}
          {/*      <DisabledInput content={person.professionalNumber} />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <FormElement disabled inputSize={9} type={'text'} label={'Local de Trabalho'} name={'locationType'} placeholder={'Hospital, Centro de Saúde, Consultório, Clínica'} />*/}
          {/*  <FormElement disabled={!editable} inputSize={9} type={'text'} label={'Morada Profissional'} name={'professionalAddress'} />*/}
          {/*  <div className='form-group'>*/}
          {/*    <label className='control-label col-sm-3' style={{ textAlign: 'right' }}>Código Postal</label>*/}
          {/*    <div className='col-sm-3'>*/}
          {/*      <DisabledInput content={''} />*/}
          {/*    </div>*/}
          {/*    <label className='control-label col-sm-2' style={{ textAlign: 'right' }}>Localidade</label>*/}
          {/*    <div className='col-sm-4'>*/}
          {/*      <DisabledInput content={''} />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className='form-group'>*/}
          {/*    <label className='control-label col-sm-3' style={{ textAlign: 'right' }}>Telefone</label>*/}
          {/*    <div className='col-sm-3'>*/}
          {/*      <DisabledInput content={''} />*/}
          {/*    </div>*/}
          {/*    <label className='control-label col-sm-2' style={{ textAlign: 'right' }}>Email</label>*/}
          {/*    <div className='col-sm-4'>*/}
          {/*      <DisabledInput content={''} />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</Form>*/}
        </div>
      </Panel>
      <ReactModal
        footer={<Button onClick={this.toggleModal.bind(this)} type={'submit'} text={'Guardar'} buttonType={'btn-success pull-right'} />}
        name={'Editar Dados Profissionais'}
        customClass={'modal-lg'}
        toggleModal={this.toggleModal.bind(this)}
        modalIsOpen={this.state.modalIsOpen}>
        <ProfessionalForm person={person} editable />
      </ReactModal>
    </div>
  }
}

View.propTypes = {
  editable: PropTypes.bool,
  person: PropTypes.shape({})
}

View.defaultProps = { person: {} }

export default View
