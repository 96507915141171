import React from 'react'
import PropTypes from 'prop-types'

import './style.css'
import BasketContainer from './Basket/BasketContainer'
import { Button } from '../../../../../common/Button/Button'
import { ReactModal } from '../../../../../common/ReactModal/ReactModal'
import SearchFormContainer from './Search/SearchForm/SearchFormContainer'
import List from './List/List'
import PrescriptionMedicineViewContainer
  from '../MedicineView/PrescriptionMedicineView/PrescriptionMedicineViewContainer'
import FavoritesViewContainer from '../../../Profile/Favorites/FavoritesViewContainer'
import FreeformMedicineViewContainer from '../MedicineView/FreeformMedicineView/FreeformMedicineViewContainer'
import FavoriteCreationContainer from './FavoriteCreation/FavoriteCreationContainer'

const EmptyMedicine = () => {
  return <FreeformMedicineViewContainer />
}

class Prescribe extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      favoriteModalIsOpen: false,
    }
  }

  componentDidMount () {
    if (this.props.emptyBasket) {
      this.setState({modalIsOpen: true})
    }
  }

  toggleModal () {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }

  toggleModalFavorite () {
    if (!this.props.emptyBasket) {
      this.setState({ favoriteModalIsOpen: !this.state.favoriteModalIsOpen })
    }
  }

  render () {
    const { handleSubmitClick, searching, searchResults, freeFormPrescription } = this.props
    return <div className='container-fluid'>
      <div className={`col-xs-12 no-side-padding buttons-container`}>
        <Button onClick={this.toggleModal.bind(this)} type={'button'} buttonType={'btn-default margin-bottom btn-xs-block pull-left'} text={<div> <span className='fas fa-plus' /> Medicamento</div>} />
        <Button onClick={handleSubmitClick} type={'button'} buttonType={'btn-success margin-bottom pull-right'} text={'Confirmar prescrição'} />
        <Button disabled={this.props.emptyBasket} onClick={this.toggleModalFavorite.bind(this)} type={'button'} buttonType='btn-default pull-right' text={<i className='fa fa-star yellow-icon' />} />
      </div>
      <ReactModal customClass={'modal-lg prescribe-modal'} customHeaderClass={'prescribe-modal-header'} fullWidth toggleModal={this.toggleModal.bind(this)} name={'Seleção de Medicamentos'} modalIsOpen={this.state.modalIsOpen}>
        <div className='container-fluid prescribe-container'>
          <SearchFormContainer />
          <div className='col-xs-12 top-margin no-side-padding-for-container'>
            {searching
              ? <div className='loading'><span className='fas fa-spinner fa-4x fa-pulse' /></div>
              : searchResults.length > 0
                ? <List>
                  {
                    searchResults.map((item, index) => {
                      return <PrescriptionMedicineViewContainer
                        key={index}
                        medicine={item} />
                    })
                  }
                </List>
                : freeFormPrescription
                  ? <EmptyMedicine />
                  : <div className='favorites-panel'>
                    <FavoritesViewContainer editable={false} />
                  </div>
            }
          </div>
        </div>
      </ReactModal>
      <FavoriteCreationContainer toggleModal={this.toggleModalFavorite.bind(this)} modalIsOpen={this.state.favoriteModalIsOpen} />
      <BasketContainer submit={handleSubmitClick} />
    </div>
  }
}

Prescribe.propTypes = {
  searchHasValue: PropTypes.bool.isRequired,
  searching: PropTypes.bool.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
  emptyBasket: PropTypes.bool,
  freeFormPrescription: PropTypes.bool,
  notify: PropTypes.func
}

export default Prescribe
