import React, { Component, Fragment } from 'react'

import moment from 'moment'

import { withRouter } from 'react-router-dom'

import PropTypes from 'prop-types'

import { ReactModal } from '../../../../../common/ReactModal/ReactModal'
import SectionTitle from '../../../../../common/SectionTitle/SectionTitle'
import testReceipts from '../../../../../../test/testReceipt'
import testGuides from '../../../../../../test/testGuides'
import { UNITS_POSITION } from './PrescriptionGuideContainer/GuideCreator/GuideEntryComponent'
import { MealsViewer } from './PrescriptionGuideContainer/GuideCreator/MealPeriod'
import { SpecificPeriodViewer } from './PrescriptionGuideContainer/GuideCreator/Period'
import { SpecificTimePeriodViewer } from './PrescriptionGuideContainer/GuideCreator/TimePeriod'
import { SOSViewer } from './PrescriptionGuideContainer/GuideCreator/SOS'
import GuideCreatorContainer from './PrescriptionGuideContainer/GuideCreator/GuideCreatorContainer'
import { WeaningViewer } from './PrescriptionGuideContainer/GuideCreator/Weaning'
import { sortByDateDesc } from '../../../../../../resources/utilities'

const getDurationString = (value, type) => {
  const duration = moment.duration(parseInt(value), type)
  return `${duration.humanize()}`
}

const getEndDate = (start, duration) => {
  return moment(new Date(start)).add(duration.value, duration.type).format('L')
}

const GuideCompleteViewer = ({entries}) => {
  return <div>
    {entries.map((entry, i) => {
      console.log(entry)
      return <div key={i} className='medicine-item col-xs-12'>
        <div className='name'>{entry.designation}</div>
        <div className='col-xs-12 no-side-padding start-end'>
          <div className='beginning'>
            Início - <strong>{moment(new Date(entry.start)).format('L')}</strong>
            <span> Fim - <strong>{getEndDate(entry.start, entry.duration)}</strong> | Duração <strong>{getDurationString(entry.duration.value, entry.duration.type)}</strong></span>
          </div>
        </div>
        <div className='col-xs-12 no-side-padding'>
          <hr className='no-margin-hr' />
        </div>
        {entry.periods.map((period, i) => {
          if (period.type === 'meal') {
            return <MealsViewer key={i} unitsPosition={UNITS_POSITION} {...period.data} />
          }
          if (period.type === 'specificPeriod') {
            return <SpecificPeriodViewer key={i} unitsPosition={UNITS_POSITION} {...period.data} />
          }
          if (period.type === 'specificTime') {
            return <SpecificTimePeriodViewer key={i} unitsPosition={UNITS_POSITION} {...period.data} />
          }
          if (period.type === 'sos') {
            return <SOSViewer key={i} unitsPosition={UNITS_POSITION} {...period.data} />
          }
          if (period.type === 'weaning') {
            return <WeaningViewer key={i} unitsPosition={UNITS_POSITION} {...period.data} />
          }
          return null
        })}
      </div>
    })}
  </div>
}

class GuideItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }
  render () {
    const { guide } = this.props
    const guideDate = moment(new Date(guide.date))
    return <Fragment>
      <div className='guides-list'>
        <div onClick={this.toggleModal.bind(this)} className='list-group-item guide-item clickable hover-effect'>
          Guia da Receita nº <strong>*{guide.receiptNumber}*</strong>
          <div className='pull-right'>{guide.prescriber} | <strong>{guideDate.format('L')}</strong></div>
        </div>
      </div>
      <ReactModal
        name={`Receita nº *${guide.receiptNumber}* | Criada em ${guideDate.format('L')} | ${guide.prescriber}`}
        customClass={'modal-lg'}
        toggleModal={this.toggleModal.bind(this)}
        modalIsOpen={this.state.modalIsOpen}>
        <Fragment>
          <GuideCompleteViewer entries={guide.entries} />
        </Fragment>
      </ReactModal>
    </Fragment>
  }
}

GuideItem.propTypes = {
  guide: PropTypes.object.isRequired
}

class PrescriptionGuideContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      receiptId: null
    }
  }

  render () {
    return <Fragment>
      <SectionTitle
        title={'Receitas sem Guia de Tratamento'}
        size={'h4'}
        smallMargins />
      <ul className='list-group receipt-choice-list'>
        {testReceipts.sort(sortByDateDesc).map((receipt, i) => {
          return <div key={i} onClick={() => { this.setState({receiptId: receipt.number}) }}>
            <li className='list-group-item receipt-item clickable hover-effect'>
              <span>
                Receita nº *{receipt.number}* | {moment(new Date(receipt.date)).format('L')}
              </span>
              <div className='pull-right'>
                {receipt.prescriber}
              </div>
            </li>
          </div>
        })}
      </ul>
      <GuideCreatorContainer close={() => { this.setState({receiptId: undefined}) }} receiptId={this.state.receiptId} />
      <SectionTitle
        title={'Guias de Tratamento'}
        size={'h4'}
        smallMargins />
      <ul className='list-group guides-list'>
        {testGuides.sort(sortByDateDesc).map((guide, i) => {
          return <GuideItem guide={guide} key={i} />
        })}
      </ul>
    </Fragment>
  }
}

export default withRouter(PrescriptionGuideContainer)
