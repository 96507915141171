import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { searchActions } from '../../../../../../../redux/search'
import { basketActions } from '../../../../../../../redux/basket'
import { convertSearchResultsToMedicinesList } from '../../../../../../../resources/utilities'
import MedicineChangeView from './MedicineChangeView'
import Medicine from '../../../../../../../models/Medicine'

class MedicineChangeContainer extends Component {
  constructor (props) {
    super(props)
    this.handleItemClick = this.handleItemClick.bind(this)
  }

  componentDidMount () {
    const { handleChange, medicine } = this.props
    console.log(!medicine.isGeneric)
    const generic = medicine.isGeneric ? 0 : 1
    handleChange('generic', generic)
    handleChange('pharmForm', medicine.medicineType.id)
    handleChange('presentationForm', medicine.packageType.id)
    handleChange('adminWay', medicine.administrationType.id)
    handleChange('dosage', medicine.dose)
    handleChange('current', medicine.activeComponent)
  }

  handleItemClick (item) {
    const { basketIndex, removeItem, addItem, onItemClick } = this.props
    removeItem(basketIndex)
    addItem({
      medicineId: item.id,
      medicine: item,
      quantity: 1
    })
    onItemClick()
  }

  render () {
    return <MedicineChangeView handleItemClick={this.handleItemClick} medicines={this.props.results} />
  }
}

const mapStateToProps = ({search}) => {
  const results = convertSearchResultsToMedicinesList(search.results)
  return {
    results
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...searchActions, ...basketActions }, dispatch)
}

MedicineChangeContainer.propTypes = {
  basketIndex: PropTypes.number.isRequired,
  onItemClick: PropTypes.func.isRequired,
  medicine: PropTypes.instanceOf(Medicine).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicineChangeContainer)
