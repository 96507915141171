import {
  ADD_ITEM,
  CHANGE_ITEM,
  REMOVE_ITEM,
  REMOVE_ALL
} from './types'

export function addItem (item, index) {
  return {
    type: ADD_ITEM,
    payload: {
      item,
      index
    }
  }
}

export function addItems (items) {
  return dispatch => {
    items.forEach(item => {
      dispatch({
        type: ADD_ITEM,
        payload: {
          item
        }
      })
    })
  }
}

export function changeItem (index, data) {
  return dispatch => {
    if (data.quantity < 1) {
      dispatch({
        type: REMOVE_ITEM,
        payload: index
      })
    } else {
      dispatch({
        type: CHANGE_ITEM,
        payload: {
          index,
          data
        }
      })
    }
  }
}

export function removeItem (index) {
  return {
    type: REMOVE_ITEM,
    payload: {
      index
    }
  }
}

export function removeAll () {
  return {
    type: REMOVE_ALL
  }
}
