import React, { Component } from 'react'
import PrescriptionMedicineViewContainer
  from '../../../Patient/Prescription/MedicineView/PrescriptionMedicineView/PrescriptionMedicineViewContainer'
import SearchFormContainer from '../../../Patient/Prescription/Prescribe/Search/SearchForm/SearchFormContainer'
import List from '../../../Patient/Prescription/Prescribe/List/List'
import BasketContainer from '../../../Patient/Prescription/Prescribe/Basket/BasketContainer'
import PropTypes from 'prop-types'
import { Button } from '../../../../../common/Button/Button'
import { ReactModal } from '../../../../../common/ReactModal/ReactModal'
import FreeformMedicineViewContainer
  from '../../../Patient/Prescription/MedicineView/FreeformMedicineView/FreeformMedicineViewContainer'

const EmptyMedicine = () => {
  return <FreeformMedicineViewContainer />
}

class FavoriteEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }
  render () {
    let { items, handleSubmitClick, searching, freeFormPrescription } = this.props
    return <div>
      <div className={`col-xs-12 no-side-padding`}>
        <Button onClick={this.toggleModal.bind(this)} type={'button'} buttonType={'btn-default margin-bottom btn-xs-block pull-left'} text={<div> <span className='fas fa-plus' /> Medicamento</div>} />
        <Button onClick={handleSubmitClick} type={'button'} buttonType={'btn-success margin-bottom btn-xs-block pull-right'} text={'Confirmar Favorito'} />
      </div>
      <ReactModal fullWidth customClass={'favorite-modal'} toggleModal={this.toggleModal.bind(this)} name={'Seleção de Medicamentos'} modalIsOpen={this.state.modalIsOpen}>
        <div className='container-fluid prescribe-container'>
          <SearchFormContainer />
          <div className='col-xs-12 top-margin no-side-padding-for-container'>
            {searching
              ? <div className='loading'><span className='fas fa-spinner fa-4x fa-pulse' /></div>
              : items.length > 0
                ? <List>
                  {
                    items.map((item, index) => {
                      return <PrescriptionMedicineViewContainer
                        favorite
                        key={index}
                        medicine={item} />
                    })
                  }
                </List>
                : freeFormPrescription
                  ? <EmptyMedicine />
                  : null
            }
          </div>
        </div>
      </ReactModal>
      <BasketContainer submit={handleSubmitClick} noConfirmation />
    </div>
  }
}

FavoriteEdit.propTypes = {
  items: PropTypes.any,
  handleSubmitClick: PropTypes.any,
  name: PropTypes.any
}

export default FavoriteEdit
