import React, { Fragment } from 'react'
import EnhancedSearch from './EnhancedSearch'
import { manyPatients } from '../../../../../test/testPatient'
import { NavLink } from 'react-router-dom'
import defaultUser from '../../../../../resources/images/user-icon-green-2.svg'
import { getAgeString } from '../../../../../resources/utilities'
import moment from 'moment'
import Fuse from 'fuse.js'
import { Button } from '../../../../common/Button/Button'

class SearchResults extends React.Component {
  constructor (props) {
    super(props)
    this.state = {expanded: false}
  }

  render () {
    const results = this.props.results.slice(0, this.state.expanded ? this.props.results.length : 3)
    return <div className='col-xs-12 no-side-padding margin-bottom top-margin'>
      {results.map((result, i) => <ResultListElement key={i} currentUrl={this.props.match.url} patient={result} />)}
      {this.props.results.length > 3
        ? <Button
          onClick={() => this.setState({expanded: !this.state.expanded})}
          text={this.state.expanded ? 'Menos' : 'Todos'}
          buttonType={'btn-info btn-block'}
          type={'button'} />
        : null
      }
    </div>
  }
}

const ResultListElement = ({currentUrl, patient}) => {
  const {name, birth, photo, gender, healthcareNumber} = patient
  const birthDate = moment(new Date(birth))
  console.log(currentUrl)
  console.log(healthcareNumber)
  return <NavLink activeClassName={'active'} to={`${currentUrl}${healthcareNumber}/prescription/prescribe`}>
    <div className={`contact-card`}>
      <div className='content'>
        <div className='photo-container'>
          <img className='photo' src={photo || defaultUser} alt='User Profile' />
        </div>
        <div className='information'>
          <div className='name'>{name}</div>
          <div className='birthDate'>
            <span className={`gender fa fa-${gender === 'male' ? 'mars' : 'venus'}`} />
            <span className='text-muted'>Idade:</span> {getAgeString(birth)} ({birthDate.format('L')})
          </div>
        </div>
      </div>
    </div>
  </NavLink>
}

class EnhancedSearchContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      results: [],
      noMatch: false
    }
    this.searchForResults = this.searchForResults.bind(this)
  }

  searchForResults (value) {
    const options = {
      caseSensitive: false,
      shouldSort: true,
      threshold: 0,
      location: 0,
      tokenize: true,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: [
        'name',
        'birth'
      ]
    }
    const fuse = new Fuse(manyPatients, options)
    const results = fuse.search(value)
    this.setState({ results, noMatch: results.length === 0 })
  }

  searchPatient (params) {
    this.searchForResults(Object.values(params).join(' '))
  }
  render () {
    const { results } = this.state
    const { history, match, handleSubmit, focus } = this.props
    return <Fragment>
      <EnhancedSearch search={this.searchPatient.bind(this)} handleSubmit={handleSubmit} focus={focus} />
      {results.length
        ? <SearchResults match={match} history={history} results={results} />
        : null
      }
    </Fragment>
  }
}

export default EnhancedSearchContainer
