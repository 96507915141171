import React, { Component } from 'react'
import ExampleComponent from '@kenetto/scribo-signer'
import v4 from 'uuid/v4'
import { ReactModal } from '../../common/ReactModal/ReactModal'

import '@kenetto/scribo-signer/dist/styles.css'

const testReceipts = [
  {
    id: v4(),
    elements: [
      {
        name: 'Ácido fólico, 5 mg, Comprimido, Blister - 60 unidade(s)',
        units: 2
      },
      {
        name: 'Paracetamol, 1000 mg, Comprimido efervescente, Fita termossoldada - 32 unidade(s)',
        units: 1
      },
      {
        name: 'Zolmitriptano, 2.5 mg, Comprimido orodispersível, Blister - 6 unidade(s)',
        units: 2
      }
    ]
  },
  {
    id: v4(),
    elements: [
      {
        name: 'Zolmitriptano, 2.5 mg, Comprimido orodispersível, Blister - 6 unidade(s)',
        units: 1
      }
    ]
  }
]

class Test extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: true
    }
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  render () {
    return <div>
      <button onClick={this.toggleModal.bind(this)}>Abrir</button>
      <ReactModal fullWidth toggleModal={this.toggleModal.bind(this)} name={'Assinatura do Receituário'} modalIsOpen={this.state.modalIsOpen}>
        <div className='clearfix'>
          <ExampleComponent defaultPhone={'919603742'} handleConfirmationCompletion={() => console.log('confirmado tudo')} receipts={testReceipts} />
        </div>
      </ReactModal>
    </div>
  }
}

export default Test
