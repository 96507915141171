import React, { Component } from 'react'
import v4 from 'uuid/v4'
import { LATERALITY, STATUS } from '../../../../../models/Diagnosis'
import { Button } from '../../../../common/Button/Button'
import * as PropTypes from 'prop-types'

class PersonalBackgroundsForm extends Component {
  constructor (props) {
    super(props)
    const defaultData = {
      diagnosis: props.data.diagnosis || '',
      laterality: props.data.laterality || '',
      id: props.data.id || v4(),
      date: props.data.date || '',
      status: props.data.status || '',
      endDate: props.data.endDate || ''
    }
    this.state = {
      ...defaultData
    }
  }

  handleChange (name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    this.props.handleSubmit(this.state)
  }
  render () {
    let { handleSubmit, enableDelete } = this.props
    const { diagnosis, laterality, id, date, status, endDate } = this.state
    return <form onSubmit={(e) => this.handleSubmit(e)}>
      <div className='modal-body'>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Diagnóstico</label>
            <div className=''>
              <input
                required
                type='text'
                className='form-control'
                name={'diagnosis'}
                value={diagnosis}
                onChange={({target}) => this.handleChange(target.name, target.value)} />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Data</label>
            <div className=''>
              <input
                required
                type='date'
                className='form-control'
                name={'date'}
                value={date}
                onChange={({target}) => this.handleChange(target.name, target.value)} />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Lateralidade</label>
            <div className=''>
              <select required className='form-control' name={'laterality'} value={laterality} onChange={({target}) => this.handleChange(target.name, target.value)}>
                <option value={''} hidden disabled />
                <option value={LATERALITY.LEFT}>{LATERALITY.getDescription(LATERALITY.LEFT)}</option>
                <option value={LATERALITY.RIGHT}>{LATERALITY.getDescription(LATERALITY.RIGHT)}</option>
                <option value={LATERALITY.NOT_APPLICABLE}>{LATERALITY.getDescription(LATERALITY.NOT_APPLICABLE)}</option>
              </select>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Estado</label>
            <div className=''>
              <select required className='form-control' name={'status'} value={status} onChange={({target}) => this.handleChange(target.name, target.value)}>
                <option value={''} hidden disabled />
                <option value={STATUS.RESOLVED}>{STATUS.getDescription(STATUS.RESOLVED)}</option>
                <option value={STATUS.UNRESOLVED}>{STATUS.getDescription(STATUS.UNRESOLVED)}</option>
                <option value={STATUS.STABLE_DISEASE}>{STATUS.getDescription(STATUS.STABLE_DISEASE)}</option>
                <option value={STATUS.PARTIAL_REMISSION}>{STATUS.getDescription(STATUS.PARTIAL_REMISSION)}</option>
                <option value={STATUS.DISEASE_PROGRESSION}>{STATUS.getDescription(STATUS.DISEASE_PROGRESSION)}</option>
              </select>
            </div>
          </div>
        </div>
        {status === STATUS.RESOLVED
          ? <div className='form-group'>
            <div className='col-sm-12 margin-bottom-form-input'>
              <label className='control-label' style={{ textAlign: 'right' }}>Data Fim</label>
              <div className=''>
                <input
                  type='date'
                  className='form-control'
                  name={'endDate'}
                  value={endDate}
                  onChange={({target}) => this.handleChange(target.name, target.value)} />
              </div>
            </div>
          </div>
          : null
        }
      </div>
      <div className='modal-footer'>
        {enableDelete
          ? <Button
            type={'button'}
            onClick={() => handleSubmit({ id: id }, true)}
            text={<span className='fas fa-trash-alt' />}
            buttonType={'btn-danger pull-left'} />
          : <div />
        }
        <Button type={'submit'} text={'Guardar'} buttonType={'btn-success pull-right'} />
      </div>
    </form>
  }
}

PersonalBackgroundsForm.propTypes = {
  handleSubmit: PropTypes.any,
  data: PropTypes.shape({}),
  enableDelete: PropTypes.bool
}

PersonalBackgroundsForm.defaultProps = { data: {}, enableDelete: false }

export default PersonalBackgroundsForm
