import { OPEN, CLOSE } from './types'
import v4 from 'uuid/v4'
export function open (id = v4(), title, content, fullWidth) {
  return {
    type: OPEN,
    payload: {
      id, title, fullWidth, content
    }
  }
}
export function close (id) {
  return {
    type: CLOSE,
    payload: id
  }
}
