import { REMOVE_PATIENT, SET_PATIENT, FETCH_PATIENT, ERROR_FETCHING_PATIENT } from './types'

const patient = (state = {
  fetching: false,
  patientHealthcareNumber: null,
  data: undefined
}, action) => {
  switch (action.type) {
    case FETCH_PATIENT:
      return Object.assign({}, state, {
        fetching: true,
        error: null,
        patientHealthcareNumber: action.payload
      })
    case SET_PATIENT:
      return Object.assign({}, state, {
        fetching: false,
        error: null,
        data: action.payload
      })
    case ERROR_FETCHING_PATIENT:
      return Object.assign({}, state, {
        data: undefined,
        patientHealthcareNumber: null,
        fetching: false,
        error: action.payload
      })
    case REMOVE_PATIENT:
      return {
        error: null,
        fetching: false,
        data: undefined
      }
    default:
      return state
  }
}

export default patient
