import React from 'react'
import PropTypes from 'prop-types'
import FormElement from './FormElement'

export default class Form extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  internalChange (name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    this.props.handleSubmit(this.state)
  }

  submitForm () {
    this.refs[`form-${this.props.legend}`].submit()
  }

  render () {
    const {type, children, legend} = this.props
    return <form ref={`form-${legend}`} className={type !== '' ? `form-${type}` : ''} onSubmit={this.handleSubmit.bind(this)}>
      <fieldset>
        {legend ? <legend content={legend} /> : null}
        {React.Children.map(children, child => {
          if (child.type === FormElement) {
            return React.cloneElement(child, {onChange: this.internalChange.bind(this), static: false})
          } else {
            return child
          }
        }, {})}
      </fieldset>
    </form>
  }
}
Form.propTypes = {
  type: PropTypes.oneOf(['horizontal', 'inline', '']).isRequired,
  legend: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired
}

Form.defaultProps = {
  type: 'horizontal',
  topBottomMargin: ''
}
