import React, { Fragment } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import Home from './Home'
// import logo from '../../../resources/images/logo.svg'
import logoBeta from '../../../resources/images/logo-beta.svg'
import { CustomLink } from '../../common/CustomLink/CustomLink'
import { Navigation } from '../../common/Navigation/Navigation'
import Contacts from './Contacts/Contacts'
import Messages from './Messages/Messages'
import ProfileRouting from './Profile/ProfileRouting'
import PatientSearchContainer from './PatientSearchContainer/PatientSearchContainer'
import PureBreadcrumbs from './Breadcrumbs/Breadcrumbs'

import './style.css'
import PatientRouting from './Patient/PatientRouting'

const HomeRouter = ({match, history}) => {
  const handlePatientFound = (patientHealthcareNumber) => {
    history.push(`/${patientHealthcareNumber}/prescription/prescribe`)
  }
  const navContent = () => {
    return [<CustomLink
      key={'contactos'}
      label={'Contactos'}
      to={`/contacts`} />,
      <CustomLink
        key={'messages'}
        label={'Mensagens'}
        to={`/messages`} />,
      <PatientSearchContainer patientFound={handlePatientFound} key={'patientSearch'} history={history} match={match} navbarVersion />]
  }
  return <Fragment>
    <Route path={`${match.url}`} children={({match}) => {
      return <Navigation
        brand={
          <Link className={'navbar-brand'} to={'/'}>
            <img className='navigation-logo' src={logoBeta} alt='Logo' />
          </Link>
        }
        rightContent={
          <Fragment>
            <div className='double-line-navigation navbar-text'>
              <div className='primary'>
                <CustomLink
                  key={'profile'}
                  label={'Dr. Ricardo Pimenta-Araújo'}
                  to={`/profile`} />
              </div>
              <div className='secondary-info' key={'bar'}>HCVP</div>
            </div>
            <CustomLink
              key={'logout'}
              exact
              label={<p>
                <span className='visible-xs'>Logout</span>
                <i className='fas fa-power-off fa-lg hidden-xs' />
              </p>}
              to={`/logout`} />
          </Fragment>
        }>
        {
          match.isExact
            ? []
            : navContent()
        }
      </Navigation>
    }} />
    <div className='breadcrumbs'>
      <PureBreadcrumbs />
    </div>
    <div className='home-container container-fluid full-height no-side-padding-for-container'>
      <Switch>
        <Route exact path={'/'} render={(props) => <Home {...props} />} />
        <Route path={'/contacts'} component={Contacts} />
        <Route path={'/messages'} component={Messages} />
        <Route path={'/profile'} component={ProfileRouting} />
        <Route path={'/:id'} render={(props) => {
          return <PatientRouting {...props} />
        }} />
      </Switch>
    </div>
  </Fragment>
}

export default HomeRouter
