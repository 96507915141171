import moment from 'moment'

// RNU

// utente - Identificacao, EntidadeResponsavel (array), Beneficios

// Identificacao - NumeroSNS, NomeCompleto, NomesProprios, Apelidos, DataNascimento, Sexo, PaisNacionalidade, Obito, Duplicado

// Entidade Responsavel - Codigo, Descricao, NumeroBenefEntidade, DataValidade, Pais, Cartao --> NumeroCartao, TipoCartao

// Beneficios - RECM, IsencaoTaxa, Outros
//   RECM - Motivo, Descricao, DataInicio, DataFim
//   IsencaoTaxa - Motivo, Descricao, DataInicio, DataFim
//   Outros - Descricao, DataInicio, DataFim

export default class Patient {
  constructor (id, healthcareNumber, name, birth, gender, country, contacts, email, photo) {
    this._id = id
    this._name = name
    this._birth = birth
    this._gender = gender
    this._healthcareNumber = healthcareNumber
    this._country = country
    this._contacts = contacts
    this._email = email
    this._photo = photo
  }

  get id () {
    return this._id
  }

  get healthcareNumber () {
    return this._healthcareNumber
  }

  safeLengthName (chars) {
    const name = this._name.split('')
    if (name.length > chars) {
      const names = this._name.split(' ')
      const first = names[0]
      const last = names[names.length - 1]
      const middleNames = names
        .filter((name, index) => index !== names.length - 1)
        .filter((name, index) => index !== 0)
        .filter(name => name.split('')[0].toUpperCase() === name.split('')[0])
      const initials = middleNames.map((name) => name.split('')[0]).join('. ')
      return `${first} ${initials} ${last}`
    } else {
      return this._name
    }
  }

  getFirstLastName () {
    const names = this._name.split(' ')
    const first = names[0]
    const last = names[names.length - 1]
    return `${first} ${last}`
  }

  get name () {
    return this._name
  }

  get birth () {
    return this._birth
  }

  get gender () {
    return this._gender
  }

  get country () {
    return this._country
  }

  get contacts () {
    return this._contacts
  }

  get email () {
    return this._email
  }

  getAge () {
    return moment().diff(this._birth, 'years')
  }

  get photo () {
    return this._photo
  }
}
