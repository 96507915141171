import React from 'react'
import PropTypes from 'prop-types'

import './style.css'
import FormElement from '../../../../../../../common/formComponents/FormElement'
import { AutoComplete, AutoCompleteOptions } from '@kenetto/infarmed-sdk'

const structureListForAutocomplete = (arr = []) => {
  return Object.values(arr)
    .filter(element => element.name)
    .map(element => {
      return {
        value: element.id,
        displayValue: element.name
      }
    })
}

export const SEARCH = {
  ALL: 'all',
  INN_COMMERCIAL: 'innAndCommercial',
  INN: 'inn',
  FREE_FORM: 'freeForm',
  COMMERCIAL: 'commercial',
  MEDICAL_DEVICES: 'medicalDevices',
  MANIPULATED: 'manipulated',
  DIETARY: 'dietary',
  OTHER: 'other',
  FAVOURITES: 'favourites',
  getDesignationForType: (type = '') => {
    switch (type) {
      case SEARCH.ALL:
        return 'Todos'
      case SEARCH.INN_COMMERCIAL:
        return 'DCI/NC'
      case SEARCH.INN:
        return 'DCI'
      case SEARCH.COMMERCIAL:
        return 'Nome Comercial'
      case SEARCH.MEDICAL_DEVICES:
        return 'Dispositivos Médicos'
      case SEARCH.MANIPULATED:
        return 'Manipulados'
      case SEARCH.DIETARY:
        return 'Dietéticos'
      case SEARCH.FREE_FORM:
        return 'Prescrição de Texto Livre'
      case SEARCH.OTHER:
        return 'Outros'
      case SEARCH.FAVOURITES:
        return 'Favoritos'
      default:
        return 'DCI'
    }
  }
}

class SearchForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      search: '',
      adminWays: [],
      formSubmitted: false,
      selectedItem: { name: 'Via oral' }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.formSubmitted && this.props.formSubmitted !== prevProps.formSubmitted) {
      this.setState({
        formSubmitted: true,
        selectedItem: { name: 'Via oral' }
      })
    } else if (!this.props.formSubmitted && this.props.formSubmitted !== prevProps.formSubmitted) {
      this.setState({
        formSubmitted: false
      })
    }
  }

  handleAdminWayChange (name, value) {
    if (value === '') {
      this.props.handleChange('adminWay')
      this.setState({
        adminWays: [],
        selectedItem: null
      })
    } else {
      const option = new AutoCompleteOptions(AutoCompleteOptions.AdministrationWay, value)
      AutoComplete(option).then(res => {
        this.setState({
          adminWays: res
        })
      })
    }
  }

  handleAdminWaySelect (item) {
    this.setState({ selectedItem: item })
    this.props.handleChange('adminWay', item.id)
  }

  render () {
    const { searchType, searchTypes, currentSearch, currentPharmForm, pharmaceuticalForms, packagingTypes, dcis, handleChange } = this.props
    return <form className='form-horizontal no-side-padding col-xs-12' data-autocomplete='off' autoComplete={'off'}>
      <div className='col-xs-12 col-md-6 no-side-padding col-md-offset-3'>
        <div className='input-group'>
          <span className='input-group-btn'>
            <button
              className='btn btn-default dropdown-toggle'
              type='button'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'>
              {SEARCH.getDesignationForType(searchType)}
              <span className='caret' />
            </button>
            <ul className='dropdown-menu'>
              {
                searchTypes.map((type, index) => {
                  return <li
                    key={index}
                    className={searchType === type ? 'active' : null}
                    onClick={() => handleChange('searchType', type)}>
                    {SEARCH.getDesignationForType(type)}
                  </li>
                })
              }
            </ul>
          </span>
          <FormElement
            resetField={this.state.formSubmitted}
            inGroup
            handleChange={(value) => value === '' ? handleChange('current', value) : this.setState({ search: value })}
            defaultValue={currentSearch}
            disabled={searchType === SEARCH.FREE_FORM}
            searchBeyondResults={(value) => handleChange('current', value)}
            type={'autocomplete'}
            label={''}
            options={structureListForAutocomplete(dcis)}
            name={'search'}
            onSuggestionSelected={({ displayValue }) => handleChange('current', displayValue)} />
          <span className='input-group-btn'>
            <button className='btn btn-default' onClick={() => { handleChange('current', this.state.search) }}
              type='button'><span className='glyphicon glyphicon-search' /></button>
          </span>
        </div>
      </div>
      <div className={`col-xs-12 no-side-padding top-margin`}
        style={{ display: searchType === 'freeForm' ? 'none' : 'block ' }}>
        <div className='form-group no-margin-form-group'>
          <div className='col-md-3 small-side-padding'>
            <FormElement
              resetField={this.state.formSubmitted}
              defaultValue={currentPharmForm}
              onSuggestionSelected={({ value }) => handleChange('pharmForm', value)}
              disabled={searchType === SEARCH.FREE_FORM}
              type={'autocomplete'}
              placeholder={'Forma Farmacêutica'}
              label={''}
              handleChange={(value) => value === '' ? handleChange('pharmForm') : null}
              name={'pharmaceuticalForm'}
              options={structureListForAutocomplete(pharmaceuticalForms)} />
          </div>
          <div className='col-xs-12 col-md-2 small-side-padding margin-bottom-form-input'>
            <input
              disabled={searchType === SEARCH.FREE_FORM}
              type='text'
              value={this.props.currentDosage}
              onChange={(e => handleChange('dosage', e.target.value))}
              className='form-control' placeholder='Dosagem' />
          </div>
          <div className='col-md-3 small-side-padding'>
            <FormElement
              resetField={this.state.formSubmitted}
              disabled={searchType === SEARCH.FREE_FORM}
              type={'autocomplete'}
              placeholder={'Tipo Embalagem'}
              label={''}
              name={'presentationForm'}
              handleChange={(value) => value === '' ? handleChange('presentationForm') : null}
              onSuggestionSelected={({ value }) => handleChange('presentationForm', value)}
              options={structureListForAutocomplete(packagingTypes)} />
          </div>
          <div className='col-md-2 small-side-padding margin-bottom-form-input'>
            <input
              disabled={searchType === SEARCH.FREE_FORM}
              type='text'
              className='form-control' placeholder='Nº Unidades' />
          </div>
          <div className='col-md-2 small-side-padding admin-ways'>
            <FormElement
              resetField={this.state.formSubmitted}
              disabled={searchType === SEARCH.FREE_FORM}
              type={'text'}
              placeholder={'Via Administração'}
              defaultValue={(this.state.selectedItem && this.state.selectedItem.name) || ''}
              label={''}
              handleChange={this.handleAdminWayChange.bind(this)}
              name={'administrationWays'} />
            {this.state.selectedItem
              ? null
              : <div className='autocomplete-dropdown-container'>
                <div className='list-group autocomplete-list popped-list'>
                  {this.state.adminWays.map((item, i) => {
                    return <div key={i} className='autocomplete-list-suggestion list-group-item clickable' onClick={() => this.handleAdminWaySelect(item)}>
                      {item.name}
                    </div>
                  })}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </form>
  }
}

SearchForm.propTypes = {
  dcis: PropTypes.array.isRequired,
  searchType: PropTypes.string.isRequired,
  searchTypes: PropTypes.array.isRequired,
  currentSearch: PropTypes.string.isRequired,
  currentSearchType: PropTypes.string.isRequired,
  currentAdminWay: PropTypes.string.isRequired,
  currentPharmForm: PropTypes.string.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  inFocus: PropTypes.bool.isRequired
}

export default SearchForm
