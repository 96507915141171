import React from 'react'
import { Link } from 'react-router-dom'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import EnhancedBreadcrumb from './EnhancedBreadcrumb'

import './style.css'

const routes = [
  { path: '/', breadcrumb: <span>Inicio</span> },
  { path: '/contacts', breadcrumb: <span>Contactos</span> },
  { path: '/contacts/professional/:id', breadcrumb: null },
  { path: '/contacts/:id', breadcrumb: null },
  { path: '/messages', breadcrumb: <span>Mensagens</span> },
  { path: '/messages/:id', breadcrumb: null },
  { path: '/profile', breadcrumb: <span>Perfil</span> },
  { path: '/profile/data', breadcrumb: <span>Dados do Utilizador</span>, noRoute: true },
  { path: '/profile/data/personal', breadcrumb: <span>Pessoais</span> },
  { path: '/profile/data/professional', breadcrumb: <span>Profissionais</span> },
  { path: '/profile/data/documents', breadcrumb: <span>Documentos</span> },
  { path: '/profile/billing', breadcrumb: <span>Pagamentos</span>, noRoute: true },
  { path: '/profile/billing/payments', breadcrumb: <span>Cartões</span> },
  { path: '/profile/notifications', breadcrumb: <span>Notificações</span> },
  { path: '/profile/prescription/favorites', breadcrumb: <span>Favoritos</span> },
  { path: '/profile/prescription/favorites/edit', breadcrumb: <span>Editar Favorito </span>, noRoute: true },
  { path: '/profile/prescription/favorites/add', breadcrumb: <span>Adicionar Favorito </span>, noRoute: true },
  { path: '/profile/prescription/favorites/edit/:id', breadcrumb: null },
  { path: '/profile/prescription/history', breadcrumb: <span>Histórico Receitas</span> },
  { path: '/:id', breadcrumb: EnhancedBreadcrumb, addToRouteBefore: '/contacts' },
  { path: '/:id/prescription', breadcrumb: <span>Prescrição</span>, noRoute: true },
  { path: '/:id/allergies', breadcrumb: <span>Alergias</span> },
  { path: '/:id/diagnostics', breadcrumb: <span>Diagnósticos</span> },
  { path: '/:id/medication', breadcrumb: <span>Medicação</span> },
  { path: '/:id/prescription/messages', breadcrumb: <span>Mensagens</span> },
  { path: '/:id/prescription/prescribe', breadcrumb: <span>Prescrever</span> },
  { path: '/:id/prescription/history', breadcrumb: <span>Histórico Receitas</span> },
  { path: '/:id/prescription/guides', breadcrumb: <span>Guias de Tratamento</span> },
  { path: '/:id/prescription/guides/:id', breadcrumb: <span>Criação de Guia</span>}
]

const PureBreadcrumbs = ({ breadcrumbs, rightField }) => {
  return <ul className='breadcrumb'>
    {rightField || null}
    {breadcrumbs.map(({ breadcrumb, match, noRoute, addToRouteBefore = '', addToRouteAfter = '' }) => {
      return <li key={match.url}>
        {noRoute
          ? breadcrumb
          : <Link to={`${addToRouteBefore}${match.url}${addToRouteAfter}` || (match.url || '')}>{breadcrumb}</Link>
        }
      </li>
    })}
  </ul>
}

export default withBreadcrumbs(routes)(PureBreadcrumbs)
