import React, { Fragment } from 'react'
import { Panel } from '../../../../../common/Panel/Panel'
import { SEVERITY, THERAPEUTICS_TYPE } from '../../../../../../models/Allergy'
import moment from 'moment'

const MedicalAllergiesPanel = ({allergies}) => {
  return <Panel
    heading={'Alergias Medicamentosas'}
    panelType={'panel-warning-attention'}>
    <Fragment>
      {allergies.length
        ? null
        : <div>Sem Alergias</div>
      }
      <table className='table table-striped'>
        <thead>
          <tr>
            <th>DCI</th>
            <th>Data Diagnóstico</th>
            <th>Gravidade</th>
            <th>Terapêutica</th>
          </tr>
        </thead>
        <tbody>
          {allergies.map((allergy, i) => <tr key={i}>
            <td>{allergy.substance}</td>
            <td>{moment(new Date(allergy.discoveryDate)).format('L')}</td>
            <td>{SEVERITY.getDescription(allergy.severity)}</td>
            <td>{THERAPEUTICS_TYPE.getDescription(allergy.therapeutics)}</td>
          </tr>)}
        </tbody>
      </table>
    </Fragment>
  </Panel>
}

export default MedicalAllergiesPanel
