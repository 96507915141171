import { FETCH_SUCCESS, FETCHING_RESULTS, ERROR_FETCHING, TYPE_CHANGE, RESET } from './types'
import getInfarmed, { DEFAULT_ADMIN_WAY, getSearchOptions } from '../infarmed'

// const DEBOUNCE_VALUE = 300
//
// const searchByDCI = debounce((dispatch, dci) => {
//   console.log('searching dci')
//   dispatch({
//     type: FETCHING_RESULTS
//   })
//   const infarmed = getInfarmed()
//   getSearchOptions().textValue = dci
//   infarmed.search2(getSearchOptions())
//     .then(results => {
//       dispatch({
//         type: FETCH_SUCCESS,
//         payload: {
//           text: dci,
//           results
//         }
//       })
//     })
//     .catch(error => {
//       console.log(error)
//       dispatch({
//         type: ERROR_FETCHING,
//         payload: {
//           error: error.toString()
//         }
//       })
//     })
// }, DEBOUNCE_VALUE)

// export function onChange (text, type) {
//   console.log('this is still used')
//   return dispatch => {
//     dispatch({
//       type: ON_CHANGE,
//       payload: {
//         text
//       }
//     })
//     if (text.split('').length > 2) {
//       switch (type) {
//         case 'inn':
//         case 'commercial':
//           searchByDCI(dispatch, text)
//           break
//         case 'medicalDevices':
//         case 'manipulated':
//         case 'dietary':
//         case 'other':
//         case 'favourites':
//           return []
//         default:
//           searchByDCI(dispatch, text)
//           break
//       }
//     }
//   }
// }

export function onTypeChange (data) {
  return dispatch => {
    dispatch({
      type: TYPE_CHANGE,
      payload: data
    })
  }
}

function dispatchResults (results) {
  return {
    type: FETCH_SUCCESS,
    payload: {
      results
    }
  }
}

function errorFetching (error) {
  return {
    type: ERROR_FETCHING,
    payload: {
      error: error.toString()
    }
  }
}

export function handleChange (type, value) {
  const searchOpts = getSearchOptions()
  return dispatch => {
    dispatch({
      type: TYPE_CHANGE,
      payload: { name: type, value }
    })
    const infarmed = getInfarmed()
    if (type === 'current') {
      if (value.split('').length > 2) {
        searchOpts.textValue = value
        if (searchOpts.textValue !== '') {
          dispatch({
            type: FETCHING_RESULTS
          })
          infarmed.search2(searchOpts)
            .then((results) => {
              dispatch(dispatchResults(results))
            })
            .catch((error) => dispatch(errorFetching(error)))
        }
      } else {
        searchOpts.textValue = ''
        dispatch({
          type: RESET,
          payload: {}
        })
      }
    }
    if (type === 'pharmForm') {
      console.log(value)
      if (value !== searchOpts.pharmFormId) {
        searchOpts.pharmFormId = value
        if (searchOpts.textValue !== '') {
          infarmed.search2(searchOpts)
            .then((results) => {
              dispatch({
                type: FETCHING_RESULTS
              })
              dispatch(dispatchResults(results))
            })
            .catch((error) => dispatch(errorFetching(error)))
        }
      }
    }
    if (type === 'presentationForm') {
      if (value !== searchOpts.adminWayId) {
        searchOpts.packageTypeId = value
        if (searchOpts.textValue !== '') {
          infarmed.search2(searchOpts)
            .then((results) => {
              dispatch({
                type: FETCHING_RESULTS
              })
              dispatch(dispatchResults(results))
            })
            .catch((error) => dispatch(errorFetching(error)))
        }
      }
    }
    if (type === 'adminWay') {
      console.log(value)
      if (value !== searchOpts.adminWayId) {
        searchOpts.adminWayId = value
        if (searchOpts.textValue !== '') {
          infarmed.search2(searchOpts)
            .then((results) => {
              dispatch({
                type: FETCHING_RESULTS
              })
              dispatch(dispatchResults(results))
            })
            .catch((error) => dispatch(errorFetching(error)))
        }
      }
    }
    if (type === 'dosage') {
      if (value !== searchOpts.adminWayId) {
        searchOpts.dosage = value
        if (searchOpts.textValue !== '') {
          infarmed.search2(searchOpts)
            .then((results) => {
              dispatch({
                type: FETCHING_RESULTS
              })
              dispatch(dispatchResults(results))
            })
            .catch((error) => dispatch(errorFetching(error)))
        }
      }
    }
    if (type === 'generic') {
      if (value !== searchOpts) {
        searchOpts.generic = value
        if (searchOpts.textValue !== '') {
          infarmed.search2(searchOpts)
            .then((results) => {
              dispatch({
                type: FETCHING_RESULTS
              })
              dispatch(dispatchResults(results))
            })
            .catch((error) => dispatch(errorFetching(error)))
        }
      }
    }
  }
}

export function reset (focus = false) {
  const searchOpts = getSearchOptions()
  searchOpts.textValue = ''
  searchOpts.packageTypeId = ''
  searchOpts.dosage = ''
  searchOpts.generic = null
  searchOpts.adminWayId = DEFAULT_ADMIN_WAY
  return {
    type: RESET,
    payload: {
      focus
    }
  }
}
