import { Button } from '../../../../../../../common/Button/Button'
import React, { Fragment } from 'react'
import { Panel } from '../../../../../../../common/Panel/Panel'
import { UnitsInsertion } from './GuideEntryComponent'
import { SpecificPeriod } from '../../../../../../../../models/Guides/Periods'
import GuideEntry from '../../../../../../../../models/Guides/GuideEntry'

const SpecificPeriodViewer = ({units, unitsPosition = 'right', removeItem, entries}) => {
  const { periodType, startAt, interval } = entries[0]
  const description = SpecificPeriod.getTypes(periodType).filter(item => item.value === periodType)[0]
  const startTime = SpecificPeriod.getValues(periodType).filter(item => item.value === startAt)[0]
  return <div className='col-xs-12 no-side-padding guide-entry-line'>
    <div className='col-sm-11 no-side-padding'>
      {unitsPosition === 'right'
        ? <div className='periods'>
          <div className='period-item'>{`A cada ${interval} ${description.displayValue}`}</div>
          { startTime ? <div className='period-item'>{`A começar ${description.value === 'hours' ? 'às' : 'a'} ${startTime.displayValue} ${description.value === 'hours' ? description.displayValue : ''}`}</div> : null }
          <div className='line'><i className='fas fa-long-arrow-alt-right' /></div>
          <div className='units'>{`${GuideEntry.getUnit(units).displayValue} un.`}</div>
        </div>
        : <div className='periods'>
          <div className='units'>{`${GuideEntry.getUnit(units).displayValue} un.`}</div>
          <div className='line'><i className='fas fa-long-arrow-alt-right' /></div>
          <div className='period-item'>{`A cada ${interval} ${description.displayValue}`}</div>
          { startTime ? <div className='period-item'>{`A começar ${description.value === 'hours' ? 'às' : 'a'} ${startTime.displayValue} ${description.value === 'hours' ? description.displayValue : ''}`}</div> : null }
        </div>
      }
    </div>
    {removeItem
      ? <div className='col-sm-1 no-side-padding' style={{marginTop: '4px'}}>
        <Button type={'button'} text={<i className='fas fa-trash-alt' />} buttonType={'btn-danger btn-sm pull-right'} onClick={removeItem} />
      </div>
      : null
    }
  </div>
}

class SpecificPeriodsComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      units: '1'
    }
  }

  handleChange (name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    const { type, startAt, interval, units } = this.state
    const data = {
      type: 'specificPeriod',
      entries: [
        {
          startAt,
          interval,
          periodType: type
        }
      ],
      units
    }
    console.log(data)
    this.props.handleSubmit(data)
  }
  render () {
    const { type, startAt, interval } = this.state
    const { unitType } = this.props
    return <Fragment>
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Panel
          heading={'Medicação Periódica'}
          footer={<Fragment>
            <Button type={'button'} text={<span className='fas fa-trash-alt' />} buttonType={'btn-danger'} onClick={this.props.remove} />
            <Button type={'submit'} text={<span className='fas fa-plus' />} buttonType={'btn-success pull-right'} />
          </Fragment>}>
          <Fragment>
            <div className='form-group col-sm-4 no-side-padding'>
              <label className='control-label' style={{ textAlign: 'right' }}>A cada</label>
              <div className=''>
                <div className='input-group input-select-group'>
                  <input
                    required
                    onChange={e => this.handleChange(e.target.name, e.target.value)}
                    value={interval || ''}
                    type='number'
                    min={1}
                    className='form-control'
                    name='interval' />
                  <select
                    required
                    onChange={e => this.handleChange(e.target.name, e.target.value)}
                    value={type || ''}
                    name='type'
                    className='form-control btn-right'>
                    <option value='' hidden disabled />
                    {SpecificPeriod.getTypes().map(({ value, displayValue }, i) => {
                      return <option key={i} value={value}>{displayValue}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            {type
              ? <div className='form-group col-sm-3'>
                <label
                  className='control-label'
                  style={{ textAlign: 'right' }}>A Começar a</label>
                <div className=''>
                  <select
                    onChange={e => this.handleChange(e.target.name, e.target.value)}
                    value={startAt}
                    name='startAt'
                    className='form-control'>
                    <option value='' hidden disabled />
                    {SpecificPeriod.getValues(type).map(({value, displayValue}, i) => {
                      return <option key={i} value={value}>{displayValue}</option>
                    })}
                  </select>
                </div>
              </div>
              : null
            }
            {type
              ? <UnitsInsertion defaultValue={'1'} handleChange={units => this.setState({ units })} unitType={unitType} />
              : null
            }
          </Fragment>
        </Panel>
      </form>
    </Fragment>
  }
}

export { SpecificPeriodViewer, SpecificPeriodsComponent }
