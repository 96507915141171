import React, { Fragment } from 'react'
import FavoritesAddition from './FavoritesAddition'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { basketActions } from '../../../../../../redux/basket'
import { doctorActions } from '../../../../../../redux/doctor'
import { searchActions } from '../../../../../../redux/search'
import { modalsActions } from '../../../../../../redux/modals'
import {notify} from 'reapop'

import v4 from 'uuid/v4'
import { withRouter } from 'react-router-dom'
import FormElement from '../../../../../common/formComponents/FormElement'
import Form from '../../../../../common/formComponents/Form'
import { convertBasketToPrescribedMedicines, convertSearchResultsToMedicinesList } from '../../../../../../resources/utilities'
import { SEARCH } from '../../../Patient/Prescription/Prescribe/Search/SearchForm/SearchForm'

class FavoritesAdditionContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: ''
    }
  }

  componentWillUnmount () {
    this.props.reset()
  }

  componentDidMount () {
    this.props.removeAll()
  }
  handlePlusClick (medicineId) {
    this.props.addItem(medicineId)
  }

  handleMinusClick (medicineId) {
    this.props.removeItem(medicineId)
  }
  handleDosageChange (medicineId, dosage) {
    this.props.changeDosage(medicineId, dosage)
  }

  handleRenewableChange (medicineId, times) {
    const {changeRenewable, basketItems} = this.props
    if (!basketItems.find(item => item === medicineId)) {
      this.props.addItem(medicineId)
    }
    changeRenewable(medicineId, times)
  }
  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  addToFavorites () {
    const { basketItems } = this.props
    const name = this.state.name || 'Sem Nome'
    const items = convertBasketToPrescribedMedicines(basketItems)
    this.props.addFavorite({
      id: v4(),
      name,
      items
    })
    this.props.removeAll()
    this.props.history.replace('/profile/prescription/favorites')
    this.props.notify({
      message: `Favorito criado com sucesso.`,
      status: 'info',
      dismissible: true,
      dismissAfter: 3000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
  }
  render () {
    return <Fragment>
      <div className='margin-bottom'>
        <Form handleSubmit={() => {}}>
          <FormElement
            inputSize={9}
            type={'text'}
            label={'Nome do Favorito'}
            name={'favName'}
            defaultValue={this.state.name}
            handleChange={(_, value) => this.setState({ name: value })} />
        </Form>
      </div>
      <FavoritesAddition
        freeFormPrescription={this.props.freeFormSearch}
        searching={this.props.loading}
        searchHasValue={this.props.searchHasValue}
        handleSubmitClick={this.addToFavorites.bind(this)}
        searchResults={this.props.searchResults} />
    </Fragment>
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...basketActions, ...doctorActions, ...searchActions, ...modalsActions, notify }, dispatch)
}

function mapStateToProps ({basket, search}) {
  return {
    freeFormSearch: search.type === SEARCH.FREE_FORM,
    searchResults: convertSearchResultsToMedicinesList(search.results),
    searchHasValue: false,
    loading: search.loading,
    basketItems: basket,
    basketHasItems: !!basket.length
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FavoritesAdditionContainer))
