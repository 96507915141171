import React from 'react'

const Form = ({editable}) => {
  return <div className='margin-bottom'>
    <form>
      <div className='form-group'>
        <label className='control-label' style={{ textAlign: 'right' }}>Nº CC</label>
        <div className=''>
          <input disabled={!editable} type='number' min={1000000} max={999999999} className='form-control' name={'identityCard'} />
        </div>
      </div>
      <div className='form-group'>
        <label className='control-label' style={{ textAlign: 'right' }}>NIF</label>
        <div className=''>
          <input disabled={!editable} type='number' className='form-control' name={'vat'} minLength={9} maxLength={9} min={100000000} max={999999999} />
        </div>
      </div>
      <div className='form-group'>
        <label className='control-label' style={{ textAlign: 'right' }}>Nº Local de Prescrição</label>
        <div className=''>
          <input disabled={!editable} type='number' className='form-control' name={'identityCard'} />
        </div>
      </div>
    </form>
  </div>
}

export default Form
