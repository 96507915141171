import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

class Navigation extends React.Component {
  render () {
    return <nav className='navbar navbar-default navbar-fixed-top'>
      <div className='container-fluid'>
        <div className='navbar-header'>
          {this.props.brand}
          <button type='button' className='navbar-toggle collapsed' data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1' aria-expanded='false'>
            <span className='sr-only'>{'navigationBar.toggleNavigation'}</span>
            <span className='icon-bar' />
            <span className='icon-bar' />
            <span className='icon-bar' />
          </button>
        </div>
        <div className='collapse navbar-collapse' id='bs-example-navbar-collapse-1'>
          <ul className='nav navbar-nav navbar-left'>
            {this.props.children}
          </ul>
          <ul className='nav navbar-nav navbar-right'>
            {this.props.rightContent}
          </ul>
        </div>
      </div>
    </nav>
  }
}

Navigation.propTypes = {
  children: PropTypes.array,
  brand: PropTypes.object,
  rightContent: PropTypes.object
}

export {Navigation}
