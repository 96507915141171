import React from 'react'
import testReceipts from '../../../../../../../test/testReceipt'
import ReceiptList from './ReceiptList'

const ReceiptListContainer = ({itemClickHandle, repeatButton}) => {
  const receipts = testReceipts
  return <ReceiptList
    itemClickHandle={itemClickHandle}
    receipts={receipts}
    repeatButton={repeatButton} />
}

export default ReceiptListContainer
