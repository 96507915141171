import React, { Fragment } from 'react'
import SectionTitle from '../../../../../common/SectionTitle/SectionTitle'
import { Button } from '../../../../../common/Button/Button'
import PropTypes from 'prop-types'

import './style.css'

class AdditionalInput extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedOpt: ''
    }
  }

  renderOptInputs (inputs) {
    return inputs.map((input, i) => {
      return <input className='form-control' key={i} {...input} />
    })
  }

  render () {
    const { additionalFields } = this.props
    const options = additionalFields.map(item => ({ value: item.type, displayValue: item.label }))
    const { selectedOpt } = this.state
    const hasInputSelected = Object.values(additionalFields).filter(item => item.type === selectedOpt)[0]
    const selectedTypeInputs = hasInputSelected ? hasInputSelected.inputs : []
    return <div className='col-sm-12 form-group'>
      <label className='control-label' style={{ textAlign: 'right' }}>Tipo</label>
      <div className=''>
        <div className='form-inline'>
          <select
            name='type'
            defaultValue={''}
            onChange={e => this.setState({ selectedOpt: e.target.value })}
            className='form-control'>
            <option value='' disabled hidden />
            {options.map((item, i) => {
              return <option key={i} value={item.value}>{item.displayValue}</option>
            })}
          </select>
          {selectedOpt
            ? this.renderOptInputs(selectedTypeInputs)
            : null
          }
        </div>
      </div>
    </div>
  }
}

AdditionalInput.propTypes = {
  additionalFields: PropTypes.array.isRequired
}

export class Section extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      additionalFields: []
    }
  }

  addField () {
    const additionalFields = this.state.additionalFields.slice()
    additionalFields.push({})
    this.setState({
      additionalFields
    })
  }

  render () {
    const { additionalFields } = this.state
    const { title, titleSize, buttonText, buttonType, children, additionalFieldSelectorOptions } = this.props
    return <Fragment>
      <SectionTitle
        smallMargins
        title={title}
        size={titleSize}
        rightField={<Button
          onClick={this.addField.bind(this)}
          type={'button'}
          text={buttonText}
          buttonType={buttonType} />} />
      {children}
      {additionalFields.map((_, index) => {
        return <AdditionalInput additionalFields={additionalFieldSelectorOptions} key={index} />
      })}
    </Fragment>
  }
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  titleSize: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']).isRequired,
  buttonText: PropTypes.string,
  additionalFieldSelectorOptions: PropTypes.array,
  buttonType: PropTypes.string
}

Section.defaultProps = {
  buttonType: 'btn-info btn-sm',
  buttonText: 'Adicionar'
}

export default SectionTitle
