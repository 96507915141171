import React from 'react'
import PropTypes from 'prop-types'
import MedicationListItem from './MedicationListItem'
import { DisabledInput } from '../../../../common/formComponents/Input'
import moment from 'moment'

// name, dosage, date, prescriber, medType

const MedicationItem = ({medicine}) => {
  return <div>
    <form>
      <div className='form-group col-sm-12'>
        <label className='control-label' style={{ textAlign: 'right' }}>Posologia</label>
        <div className=''>
          <DisabledInput content={medicine.dosage} />
        </div>
      </div>
      <div className='form-group col-sm-12'>
        <label className='control-label' style={{ textAlign: 'right' }}>Data Prescrição</label>
        <div className=''>
          <DisabledInput content={moment(new Date(medicine.date)).format('L')} />
        </div>
      </div>
      <div className='form-group col-sm-12'>
        <label className='control-label' style={{ textAlign: 'right' }}>Prescritor</label>
        <div className=''>
          <DisabledInput content={medicine.prescriber} />
        </div>
      </div>
    </form>
  </div>
}

MedicationItem.propTypes = {
  medicine: PropTypes.oneOfType([PropTypes.instanceOf(MedicationListItem), PropTypes.object])
}

export default MedicationItem
