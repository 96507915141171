import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../common/Button/Button'
import { Panel } from '../../../../../../common/Panel/Panel'
import { bindActionCreators } from 'redux'
import { basketActions } from '../../../../../../../redux/basket'
import connect from 'react-redux/es/connect/connect'

import commercialIcon from '../../../../../../../resources/images/medicine-icons/icon_commercial.svg'
import genericIcon from '../../../../../../../resources/images/medicine-icons/icon_generic.svg'

import './style.css'
import MedicineTypeIcon from '../MedicineTypeIcon/MedicineTypeIcon'
import moment from 'moment'
import { ReactModal } from '../../../../../../common/ReactModal/ReactModal'
import MedicineChangeContainer from '../MedicineChangeView/MedicineChangeContainer'
import { searchActions } from '../../../../../../../redux/search'
import ReactTooltip from 'react-tooltip'

class DosageInsertion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isActive: !props.disable || props.dosage === '',
      completeInsertion: false
    }
  }

  render () {
    const { dosage, handleDosageChange } = this.props
    if (this.state.isActive) {
      return <div>
        <div className={`form-group dosage-container ${dosage === '' ? 'has-warning' : ''}`}>
          <input
            type='text'
            className='form-control flex-inner-item dosage-input'
            placeholder='Posologia'
            value={dosage}
            onChange={e => { handleDosageChange(e.target.value) }} />
        </div>
      </div>
    } else {
      return <div onClick={() => { this.setState({ isActive: true }) }}>
        <span className='content'>{dosage || 'Sem Posologia'}</span>
      </div>
    }
  }
}

DosageInsertion.propTypes = {
  medicineType: PropTypes.shape({ ID: PropTypes.number, name: PropTypes.string }).isRequired,
  dosage: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  handleDosageChange: PropTypes.func.isRequired
}

DosageInsertion.defaultProps = {
  disable: false,
  dosage: ''
}

class SimpleMedicineView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }
  }

  toggleModal () {
    console.log('toggled')
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    }, () => {
      if (!this.state.modalIsOpen) {
        this.props.clearSearch()
      }
    })
  }

  addOne () {
    const { changeItem, itemIndex, quantity } = this.props
    changeItem(itemIndex, {
      quantity: quantity + 1
    })
  }

  removeOne () {
    const { changeItem, itemIndex, quantity } = this.props
    changeItem(itemIndex, {
      quantity: quantity - 1
    })
  }

  changeDosage (dosage) {
    const { changeItem, itemIndex } = this.props
    changeItem(itemIndex, {
      dosage
    })
  }

  changeJustification (justification) {
    const { changeItem, itemIndex } = this.props
    changeItem(itemIndex, {
      justification
    })
  }

  changeJustificationAdditionalData (data) {
    const { changeItem, itemIndex } = this.props
    changeItem(itemIndex, {
      justificationAdditionalData: data
    })
  }

  handleRenewChange (renewable) {
    const { changeItem, itemIndex } = this.props
    changeItem(itemIndex, {
      renewable
    })
  }

  render () {
    const {
      medicine,
      dosage,
      quantity,
      renewable,
      removeItem,
      itemIndex,
      repeated,
      justificationAdditionalData,
      justification
    } = this.props
    const {
      activeComponent: name,
      commercialName,
      medicineType,
      administrationType,
      packageType,
      units,
      isGeneric: generic,
      dose
    } = medicine
    return <div className='medicine-view simple-medicine-view'>
      <Panel
        panelType={'white'}
        disableCollapse
        heading={
          <div className='clearfix'>
            <div className={'short-medicine-title-container'}>
              <div className={'text'}>
                {generic
                  ? <img
                    className={`custom-icon commercial-icon center-with-text`}
                    src={genericIcon}
                    alt='Generic Medicine' />
                  : <img
                    className={`custom-icon commercial-icon center-with-text`}
                    src={commercialIcon}
                    alt='Commercial Medicine' />
                }
                <span style={{ color: repeated ? 'var(--attention-color)' : 'unset' }}>
                  <strong>{name}</strong> [{commercialName}]
                </span> {dose ? '|' : ''} {dose} {medicineType.name ? '|' : ''} {medicineType.name} {medicineType.id
                ? <MedicineTypeIcon color='black' type={medicineType.id} />
                : null }
                {packageType.name ? '|' : ''} {packageType.name} {units ? '|' : ''} {units && (units + ' un.')} {administrationType ? '|' : null} {administrationType.name}
              </div>

            </div>
          </div>
        }>
        <div className='short-medicine-info'>
          <div className='col-xs-12 no-side-padding number-editing-line'>
            <div className='flex-item'>
              <Button
                disabled={!quantity}
                text={'DCI'}
                buttonType={`btn btn-${generic ? 'primary' : 'default'} btn-xs btn-left`}
                type={'button'}
                onClick={this.toggleModal.bind(this)} />
              <Button
                disabled={!quantity}
                text={'NC'}
                buttonType={`btn btn-${!generic ? 'primary' : 'default'} btn-xs btn-right`}
                type={'button'}
                onClick={this.toggleModal.bind(this)} />
            </div>
            <div className='flex-item' data-tip={'Renovável'}>
              <Button
                disabled
                text={<i className='fas fa-recycle' />}
                buttonType={`btn btn-default btn-xs btn-left`}
                type={'button'} />
              <Button
                disabled={!quantity}
                text={'Não'}
                buttonType={`btn btn-${renewable === 0 ? 'primary' : 'default'} btn-xs btn-middle`}
                type={'button'}
                onClick={() => this.handleRenewChange(0)} />
              <Button
                disabled={!quantity}
                text={'1'}
                buttonType={`btn btn-${renewable === 1 ? 'primary' : 'default'} btn-xs btn-middle`}
                type={'button'}
                onClick={() => this.handleRenewChange(1)} />
              <Button
                disabled={!quantity}
                text={'2'}
                buttonType={`btn btn-${renewable === 2 ? 'primary' : 'default'} btn-xs btn-middle`}
                type={'button'}
                onClick={() => this.handleRenewChange(2)} />
              <Button
                disabled={!quantity}
                text={'3'}
                buttonType={`btn btn-${renewable === 3 ? 'primary' : 'default'} btn-xs btn-right`}
                type={'button'}
                onClick={() => this.handleRenewChange(3)} />
            </div>
            <div className='flex-item' data-tip={'Unidades'}>
              <Button
                text={<span className='fa fa-minus fa-xs' />}
                buttonType={'btn minus-button btn-xs btn-left'}
                type={'button'}
                onClick={this.removeOne.bind(this)} />
              <div className='text-input-static'>{quantity}</div>
              <Button
                text={<span className='fa fa-plus fa-xs' />}
                buttonType={'btn plus-button btn-xs btn-right'}
                type={'button'}
                onClick={this.addOne.bind(this)} />
            </div>
            <div className='flex-item dosage'>
              <DosageInsertion
                medicineType={medicineType}
                date={moment().format('YYYY-MM-DD')}
                handleDosageChange={this.changeDosage.bind(this)}
                dosage={dosage} />
            </div>
            <div className='flex-item'>
              <Button
                text={<span className='fa fa-trash-alt' />}
                buttonType={'btn btn-danger btn-xs'}
                type={'button'}
                onClick={() => { removeItem(itemIndex) }} />
            </div>
          </div>
          {generic
            ? null
            : <Fragment>
              <div className='col-xs-2' style={{ marginTop: '15px' }}>
                <label className='pull-right'>Justificação</label>
              </div>
              <div className='col-xs-7 small-side-padding margin-top'>
                <select
                  className='form-control input'
                  value={justification || ''}
                  onChange={(e) => this.changeJustification(e.target.value)}>
                  <option value='' hidden disabled />
                  <option value='a'>a) Medicamento de margem ou índice terapêutico estreito
                  </option>
                  <option value='b'>b) Reação adversa prévia</option>
                  <option value='c'>c) Continuidade de tratamento superior a 28 dias</option>
                </select>
              </div>
              {justification === 'c'
                ? <Fragment>
                  <div className={`col-xs-2 margin-top small-side-padding ${justificationAdditionalData < 29 ? 'has-warning' : ''}`}>
                    <input
                      type='number'
                      value={justificationAdditionalData || 29}
                      onChange={(e) => this.changeJustificationAdditionalData(e.target.value)}
                      className='form-control input' placeholder={'Dias'} min={29} />
                  </div>
                  <div className='col-xs-1 margin-top small-side-padding' style={{marginTop: '15px'}}>
                    <label>Dias</label>
                  </div>
                </Fragment>
                : null
              }
            </Fragment>
          }
        </div>
      </Panel>
      <ReactModal
        toggleModal={this.toggleModal.bind(this)}
        customClass={'modal-lg'}
        modalIsOpen={this.state.modalIsOpen}
        name={name}>
        <MedicineChangeContainer onItemClick={this.toggleModal.bind(this)} medicine={medicine} basketIndex={itemIndex} />
      </ReactModal>
      <ReactTooltip delayShow={300} effect='solid' place='bottom' />
    </div>
  }
}

SimpleMedicineView.propTypes = {
  dosage: PropTypes.string,
  generic: PropTypes.bool,
  medicineType: PropTypes.shape({ ID: PropTypes.number, name: PropTypes.string }).isRequired,
  justification: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  changeItem: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
  repeated: PropTypes.bool
}

SimpleMedicineView.defaultProps = {
  renewable: 0
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    changeItem: basketActions.changeItem,
    removeItem: basketActions.removeItem,
    clearSearch: searchActions.reset
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(SimpleMedicineView)
