import React from 'react'
import PropTypes from 'prop-types'

import PrescriptionMedicineView from './PrescriptionMedicineView'
import { bindActionCreators } from 'redux'
import { basketActions } from '../../../../../../../redux/basket'
import { modalsActions } from '../../../../../../../redux/modals'
import { searchActions } from '../../../../../../../redux/search'
import { connect } from 'react-redux'
import { notify, removeNotification } from 'reapop'

const currentBasketHasSameDCI = (items, dci) => {
  return !!items.filter(item => item.medicine.activeComponent === dci).length
}

class PrescriptionMedicineViewContainer extends React.Component {
  addMedicine (data) {
    const { addItem, medicine, clearSearch, notify, handleAddition } = this.props
    console.log(data)
    addItem(data)
    const content = this.props.favorite ? 'ao favorito' : 'à receita'
    notify({
      id: 'med-add',
      message: `${medicine.activeComponent} adicionado ${content}.`,
      status: 'info',
      dismissible: true,
      dismissAfter: 5000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
    clearSearch()
    handleAddition && handleAddition()
  }

  addItem (data) {
    const { basket, medicine, notify, removeNotification } = this.props
    if (currentBasketHasSameDCI(basket, medicine.activeComponent)) {
      notify({
        id: 'med-already-exist',
        title: 'Este Medicamento já foi prescrito.',
        message: `Confirme a repetição do ${medicine.activeComponent}.`,
        status: 'error',
        dismissible: false,
        buttons: [{
          name: 'Confirmar',
          primary: true,
          onClick: () => {
            this.addMedicine(data)
          }
        }, {
          name: 'Cancelar',
          primary: true,
          onClick: () => {
            removeNotification('med-already-exist')
          }
        }],
        dismissAfter: 0,
        position: 'br',
        allowHTML: false
      })
    } else {
      this.addMedicine(data)
    }
  }
  handleAdditionClick (id, data) {
    const { medicine, quantity, itemIndex } = this.props
    if (id) {
      if (itemIndex === -1) {
        this.addItem({ medicineId: medicine.id, medicine: medicine, quantity: quantity + 1 })
      } else {
        this.props.changeItem(itemIndex, {quantity: quantity + 1})
      }
    } else {
      const { description, prescriptionType, presentationType, pharmaceuticalType, dosage, id } = data
      this.addItem({ medicineId: id, attributes: {description, prescriptionType, presentationType, pharmaceuticalType}, dosage, quantity: 1, renewable: 0 })
    }
  }

  handleSubtractionClick () {
    const {quantity, itemIndex} = this.props
    if (quantity === 1) {
      this.props.removeItem(itemIndex)
    } else {
      this.props.changeItem(itemIndex, {quantity: quantity - 1})
    }
  }

  render () {
    const { editable, medicine, dosage, renewable, quantity, currentSearch, itemIndex, changeItem, hasAdminWay, hasPharmaceuticalForm, hasPresentationForm } = this.props
    return <PrescriptionMedicineView
      hasAdminWay={hasAdminWay}
      hasPharmaceuticalForm={hasPharmaceuticalForm}
      hasPresentationForm={hasPresentationForm}
      quantity={quantity}
      description={currentSearch}
      editable={editable}
      handleRenewChange={(renewable) => { changeItem(itemIndex, { renewable }) }}
      handleDosageChange={(dosage) => { changeItem(itemIndex, { dosage }) }}
      addItem={this.handleAdditionClick.bind(this)}
      remove={this.handleSubtractionClick.bind(this)}
      renewable={renewable}
      dosage={dosage}
      medicine={medicine} />
  }
}

PrescriptionMedicineViewContainer.propTypes = {
  editable: PropTypes.bool.isRequired,
  handleAddition: PropTypes.func,
  itemIndex: PropTypes.number,
  medicine: PropTypes.object
}

PrescriptionMedicineViewContainer.defaultProps = {
  editable: false
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    notify,
    removeNotification,
    clearSearch: searchActions.reset,
    addItem: basketActions.addItem,
    removeItem: basketActions.removeItem,
    changeItem: basketActions.changeItem,
    open: modalsActions.open
  }, dispatch)
}

function mapStateToProps ({basket, search, medicineData}, {medicine}) {
  const item = medicine && basket.find(item => {
    return item.medicine.id === medicine.id
  })
  const quantity = (item && item.quantity) || 0
  const dosage = (item && item.dosage) || ''
  const renewable = (item && item.renewable) || 0
  const itemIndex = medicine && basket.findIndex(item => item.medicine.id === medicine.id)
  return {
    currentSearch: search.current,
    hasPresentationForm: !!search.presentationForm,
    hasAdminWay: !!search.adminWay,
    hasPharmaceuticalForm: !!search.pharmForm,
    itemIndex,
    basket,
    dosage,
    renewable,
    quantity
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionMedicineViewContainer)
