import React from 'react'
import Patient from '../../../../models/Patient'
import connect from 'react-redux/es/connect/connect'

const EnhancedBreadcrumb = ({ patientName }) => {
  return <span>{patientName}</span>
}

const mapStateToProps = ({patient}) => {
  if (patient.data) {
    const { id, healthcareNumber, name, birth, gender, country, contacts, email, photo } = patient.data
    const patientObject = new Patient(id, healthcareNumber, name, birth, gender, country, contacts, email, photo)
    return {
      patientName: `${patientObject.getFirstLastName()}`
    }
  }
  return {
    patientName: patient.data && patient.data.name
  }
}

export default connect(mapStateToProps)(EnhancedBreadcrumb)
