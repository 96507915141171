import React from 'react'
import Diagnosis, { LATERALITY, SITUATION, STATUS, TYPE } from '../../../../../../models/Diagnosis'
import testDiagnostics from '../../../../../../test/testDiagnostics'
import { Panel } from '../../../../../common/Panel/Panel'
import moment from 'moment'

function getPrimaryDiagnostics (diagnostics) {
  return diagnostics.filter(diagnostic => diagnostic.type === TYPE.PRIMARY)
    .map(({ id, type, disease, startDate, laterality, situation, status, endDate }) => (
      new Diagnosis(id, type, disease, startDate, laterality, situation, status, endDate)
    ))
}

function getSecondaryDiagnostics (diagnostics) {
  return diagnostics.filter(diagnostic => diagnostic.type === TYPE.SECONDARY)
    .map(({ id, type, disease, startDate, laterality, situation, status, endDate }) => (
      new Diagnosis(id, type, disease, startDate, laterality, situation, status, endDate)
    ))
}

const DiagnosisTable = ({ diagnostics }) => {
  return <table className='table table-striped'>
    <thead>
      <tr>
        <th>Descrição</th>
        <th>Data Início</th>
        <th>Lateralidade</th>
        <th>Situação</th>
        <th>Estado</th>
        <th>Data Resolução</th>
      </tr>
    </thead>
    <tbody>
      {diagnostics.map((diagnostic) => {
        return <tr key={diagnostic.id}>
          <td>{diagnostic.disease}</td>
          <td>{moment(new Date(diagnostic.startDate)).format('MM/YYYY')}</td>
          <td>{LATERALITY.getDescription(diagnostic.laterality)}</td>
          <td>{SITUATION.getDescription(diagnostic.situation)}</td>
          <td>{STATUS.getDescription(diagnostic.status)}</td>
          <td>{diagnostic.endDate ? moment(new Date(diagnostic.endDate)).format('L') : '-----'}</td>
        </tr>
      })}
    </tbody>
  </table>
}

const Diagnostics = () => {
  return <div className='clearfix'>
    <Panel heading={'Diagnósticos Principais'} panelType={'panel-info'}>
      <DiagnosisTable
        editable={false}
        diagnostics={getPrimaryDiagnostics(testDiagnostics)} />
    </Panel>
    <Panel heading={'Diagnósticos Secundários'} panelType={'panel-info'}>
      <DiagnosisTable
        editable={false}
        diagnostics={getSecondaryDiagnostics(testDiagnostics)} />
    </Panel>
  </div>
}

export default Diagnostics
