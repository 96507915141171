import React, { Fragment } from 'react'
import { Panel } from '../../../../common/Panel/Panel'
import { ALLERGY_TYPES, SEVERITY, THERAPEUTICS_TYPE } from '../../../../../models/Allergy'
import moment from 'moment'

const DiverseAllergiesPanel = ({allergies, openInsertion}) => {
  return <Panel
    heading={'Outras Alergias'}
    panelType={`panel-warning`}>
    <Fragment>
      {allergies.length
        ? null
        : <div>Sem Alergias</div>
      }
      <table className='table table-hover table-striped'>
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Data Diagnóstico</th>
            <th>Gravidade</th>
            <th>Terapêutica</th>
          </tr>
        </thead>
        <tbody>
          {allergies.map((allergy) => <tr key={allergy.id} className={'clickable'} onClick={() => openInsertion(allergy)}>
            <td>{`${ALLERGY_TYPES.getDescription(allergy.type)} ${allergy.substance ? '-' : ''} ${allergy.substance || ''}`}</td>
            <td>{moment(new Date(allergy.discoveryDate)).format('MM/YYYY')}</td>
            <td>{SEVERITY.getDescription(allergy.severity)}</td>
            <td>{THERAPEUTICS_TYPE.getDescription(allergy.therapeutics)}</td>
          </tr>)}
        </tbody>
      </table>
    </Fragment>
  </Panel>
}

export default DiverseAllergiesPanel
