import { Infarmed, SearchOptions } from '@kenetto/infarmed-sdk'

import fetch from 'isomorphic-fetch'
import { UserAPI } from '@kenetto/user-sdk'

const DEFAULT_PHARM_FORM_ID = null
const DEFAULT_ADMIN_WAY = 48

let infarmed

const getInfarmed = () => {
  if (!infarmed) {
    infarmed = new Infarmed(fetch)
  }
  return infarmed
}

let search

const getSearchOptions = () => {
  if (!search) {
    search = new SearchOptions()
    search.adminWayId = DEFAULT_ADMIN_WAY
  }
  return search
}

const userAPI = new UserAPI(fetch)

export { DEFAULT_PHARM_FORM_ID, DEFAULT_ADMIN_WAY, getSearchOptions, userAPI }

export default getInfarmed
