import React, { Component } from 'react'
import v4 from 'uuid/v4'
import { RELATIVES } from '../../../../../models/FamilyBackground'
import { Button } from '../../../../common/Button/Button'
import * as PropTypes from 'prop-types'

class FamilyBackgroundsForm extends Component {
  constructor (props) {
    super(props)
    const defaultData = {
      id: props.data.id || v4(),
      diagnosis: props.data.diagnosis || '',
      observations: props.data.observations || '',
      relative: props.data.relative || ''
    }
    this.state = {
      ...defaultData
    }
  }

  handleChange (name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    this.props.handleSubmit(this.state)
  }
  render () {
    let { handleSubmit, enableDelete } = this.props
    const { diagnosis, observations, relative, id } = this.state
    return <form onSubmit={(e) => this.handleSubmit(e)}>
      <div className='modal-body'>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Diagnóstico</label>
            <div className=''>
              <input
                required
                type='text'
                className='form-control'
                name={'diagnosis'}
                value={diagnosis}
                onChange={({target}) => this.handleChange(target.name, target.value)} />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Parentesco</label>
            <div className=''>
              <select required className='form-control' name={'relative'} value={relative} onChange={({target}) => this.handleChange(target.name, target.value)}>
                <option value={''} hidden disabled />
                <option value={RELATIVES.MOTHER}>{RELATIVES.getDescription(RELATIVES.MOTHER)}</option>
                <option value={RELATIVES.FATHER}>{RELATIVES.getDescription(RELATIVES.FATHER)}</option>
                <option value={RELATIVES.GRAND_MOTHER_MOTHER_LINE}>{RELATIVES.getDescription(RELATIVES.GRAND_MOTHER_MOTHER_LINE)}</option>
                <option value={RELATIVES.GRAND_MOTHER_FATHER_LINE}>{RELATIVES.getDescription(RELATIVES.GRAND_MOTHER_FATHER_LINE)}</option>
                <option value={RELATIVES.GRAND_FATHER_MOTHER_LINE}>{RELATIVES.getDescription(RELATIVES.GRAND_FATHER_MOTHER_LINE)}</option>
                <option value={RELATIVES.GRAND_FATHER_FATHER_LINE}>{RELATIVES.getDescription(RELATIVES.GRAND_FATHER_FATHER_LINE)}</option>
                <option value={RELATIVES.UNCLE_MOTHER_LINE}>{RELATIVES.getDescription(RELATIVES.UNCLE_MOTHER_LINE)}</option>
                <option value={RELATIVES.UNCLE_FATHER_LINE}>{RELATIVES.getDescription(RELATIVES.UNCLE_FATHER_LINE)}</option>
                <option value={RELATIVES.AUNT_MOTHER_LINE}>{RELATIVES.getDescription(RELATIVES.AUNT_MOTHER_LINE)}</option>
                <option value={RELATIVES.AUNT_FATHER_LINE}>{RELATIVES.getDescription(RELATIVES.AUNT_FATHER_LINE)}</option>
                <option value={RELATIVES.BROTHER}>{RELATIVES.getDescription(RELATIVES.BROTHER)}</option>
                <option value={RELATIVES.SISTER}>{RELATIVES.getDescription(RELATIVES.SISTER)}</option>
                <option value={RELATIVES.COUSIN}>{RELATIVES.getDescription(RELATIVES.COUSIN)}</option>
                <option value={RELATIVES.HALF_BROTHER}>{RELATIVES.getDescription(RELATIVES.HALF_BROTHER)}</option>
                <option value={RELATIVES.HALF_SISTER}>{RELATIVES.getDescription(RELATIVES.HALF_SISTER)}</option>
              </select>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Observações</label>
            <div className=''>
              <input
                required
                type='text'
                className='form-control'
                name={'observations'}
                value={observations}
                onChange={({target}) => this.handleChange(target.name, target.value)} />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        {enableDelete ? <Button type={'button'} onClick={() => handleSubmit({ id: id }, true)}
                                text={<span className='fas fa-trash-alt' />} buttonType={'btn-danger pull-left'} />
          : <div />}
        <Button type={'submit'} text={'Guardar'} buttonType={'btn-success pull-right'} />
      </div>
    </form>
  }
}

FamilyBackgroundsForm.propTypes = {
  handleSubmit: PropTypes.any,
  data: PropTypes.shape({}),
  enableDelete: PropTypes.bool
}

FamilyBackgroundsForm.defaultProps = { data: {}, enableDelete: false }

export default FamilyBackgroundsForm
