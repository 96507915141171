import { SET_DOCTOR, REMOVE_DOCTOR, REMOVE_FAVORITE, ADD_FAVORITE, CHANGE_FAVORITE } from './types'
import PrescribedMedicine from '../../models/NewPrescribedMedicine'
import Medicine from '../../models/Medicine'

const doctor = (state = {
  fetching: false,
  data: {},
  preferences: null,
  editingFavorite: null,
  favorites: [
    {
      id: '7305bc62-10fd-4519-b019-31591bb73f63',
      name: 'Teste Omeprazol com excepção',
      items: [
        new PrescribedMedicine(new Medicine(11593, 'Omeprazol Almus', 'Omeprazol', '20 mg', 30, {id: 31, name: 'Cápsula gastrorresistente'}, {id: 7, name: 'Blister'}, { id: 48, name: 'Via oral' }, null, true), 2, 'Posologia', 1, 'c')
      ]
    }
  ]
}, action) => {
  switch (action.type) {
    case SET_DOCTOR:
      return Object.assign({}, state, {
        data: action.payload.data,
        preferences: action.payload.preferences,
        favorites: action.payload.favorites
      })
    case REMOVE_DOCTOR:
      return {
        fetching: false,
        data: undefined
      }
    case ADD_FAVORITE:
      let newArr = state.favorites.slice()
      const itemIndex = action.payload.index || state.favorites.length
      newArr.splice(itemIndex, 0, action.payload.item)
      return Object.assign({}, state, {
        favorites: newArr
      })
    case CHANGE_FAVORITE:
      const newFav = state.favorites.map((item, index) => {
        if (index !== action.payload.index) {
          return item
        } else {
          return {
            ...item,
            ...action.payload.data
          }
        }
      })
      return Object.assign({}, state, {
        favorites: newFav
      })
    case REMOVE_FAVORITE:
      return Object.assign({}, state, {
        favorites: state.favorites.filter((item, index) => index !== action.payload.index)
      })
    default:
      return state
  }
}

export default doctor
