import React  from 'react'
import SectionTitle from '../../../../common/SectionTitle/SectionTitle'

import './style.css'

class PatientInsertion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleChange (name, value) {
    this.setState({ [name]: value })
  }

  handleSubmit () {
    console.log(this.state)
  }

  render () {
    return <div className='container-fluid no-side-padding'>
      <div className='margin-bottom'>
        <form>
          <div className='form-group col-sm-12'>
            <label className='control-label' style={{ textAlign: 'right' }}>Nome Completo</label>
            <div className=''>
              <input type='text' className='form-control' name={'fullName'} />
            </div>
          </div>
          <div className='form-group col-sm-12'>
            <label className='control-label' style={{ textAlign: 'right' }}>Nome do Pai</label>
            <div className=''>
              <input type='text' className='form-control' name={'fatherName'} />
            </div>
          </div>
          <div className='form-group col-sm-12'>
            <label className='control-label' style={{ textAlign: 'right' }}>Nome da Mãe</label>
            <div className=''>
              <input type='text' className='form-control' name={'motherName'} />
            </div>
          </div>
          <div className='form-group col-sm-4'>
            <label className='control-label' style={{ textAlign: 'right' }}>Sexo</label>
            <div className='form-control' style={{border: 'none', boxShadow: 'none'}}>
              <div className='radio-inline'>
                <label className=''>
                  <input type='radio' name='genderSelect' value='male' onChange={() => {}} />
                  Masculino
                </label>
              </div>
              <div className='radio-inline'>
                <label className=''>
                  <input type='radio' name='genderSelect' value='female' onChange={() => {}} />
                  Feminino
                </label>
              </div>
            </div>
          </div>
          <div className='form-group col-sm-4'>
            <label className='control-label' style={{ textAlign: 'right' }}>Data Nascimento</label>
            <div className=''>
              <input type='date' className='form-control' />
            </div>
          </div>
          <div className='form-group col-sm-4'>
            <label className='control-label' style={{ textAlign: 'right' }}>Estado Civil</label>
            <div className=''>
              <select name='maritalState' className='form-control' defaultValue={''}>
                <option value='' hidden disabled />
                <option value='single'>Solteiro/a</option>
                <option value='maried'>Casado/a</option>
                <option value='divorced'>Divorciado/a</option>
                <option value='widow'>Viúvo/a</option>
              </select>
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Naturalidade</label>
            <div className=''>
              <input type='text' className='form-control' name={'nationality'} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Nacionalidade</label>
            <div className=''>
              <input type='text' className='form-control' name={'naturality'} />
            </div>
          </div>
          <div className='form-group col-sm-12'>
            <label className='control-label' style={{ textAlign: 'right' }}>Profissão</label>
            <div className=''>
              <input type='text' className='form-control' name={'profession'} />
            </div>
          </div>
          <div className='form-group col-sm-12'>
            <label className='control-label' style={{ textAlign: 'right' }}>Morada Pessoal</label>
            <div className=''>
              <input type='text' className='form-control' name={'personalAddress'} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Código Postal</label>
            <div className=''>
              <input type='text' className='form-control' name={'postalCode'} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Localidade</label>
            <div className=''>
              <input type='text' className='form-control' name={'location'} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Telefone</label>
            <div className=''>
              <input type='tel' className='form-control' name={'phoneNumber'} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Email</label>
            <div className=''>
              <input type='email' className='form-control' name={'email'} />
            </div>
          </div>
          <div className='col-sm-12'>
            <SectionTitle title={'Documentos'} size={'h4'} smallMargins />
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Nº CC</label>
            <div className=''>
              <input type='number' min={1000000} max={999999999} className='form-control' name={'identityCard'} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>NIF</label>
            <div className=''>
              <input
                type='number'
                className='form-control'
                name={'sns'}
                minLength={9}
                maxLength={9}
                min={100000000}
                max={999999999} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Nº SNS</label>
            <div className=''>
              <input
                type='number'
                className='form-control'
                name={'fiscalNumber'}
                minLength={9}
                maxLength={9}
                min={100000000}
                max={999999999} />
            </div>
          </div>
        </form>
      </div>
    </div>
  }
}

export default PatientInsertion
