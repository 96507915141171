import v4 from 'uuid/v4'
import { LATERALITY, SITUATION, STATUS, TYPE } from '../models/Diagnosis'

const testDiagnostics = [
  {
    id: v4(),
    type: TYPE.PRIMARY,
    disease: 'Hepatite C',
    startDate: '2019-02-02',
    laterality: LATERALITY.LEFT,
    situation: SITUATION.CONFIRMED,
    status: STATUS.RESOLVED,
    endDate: '2019-03-01'
  },
  {
    id: v4(),
    type: TYPE.PRIMARY,
    disease: 'Cancro',
    startDate: '2019-02-02',
    laterality: LATERALITY.NOT_APPLICABLE,
    situation: SITUATION.SUSPECT,
    status: STATUS.UNRESOLVED,
  },
  {
    id: v4(),
    type: TYPE.SECONDARY,
    disease: 'Colesterol Alto',
    startDate: '2019-02-02',
    laterality: LATERALITY.RIGHT,
    situation: SITUATION.CONFIRMED,
    status: STATUS.STABLE_DISEASE
  }
]

export default testDiagnostics
