import React, { Fragment } from 'react'

import '@kenetto/scribo-signer/dist/styles.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Medicine from '../../../../../../../models/Medicine'

import { basketActions } from '../../../../../../../redux/basket'
import TreatmentGuideGenerator from '../../../../../PDFCreators/treatmentGuideGenerator'
import moment from 'moment/moment'
import { convertBasketToPrescribedMedicines } from '../../../../../../../resources/utilities'
import ReceiptConfirmation from './ReceiptConfirmation'
import { ReactModal } from '../../../../../../common/ReactModal/ReactModal'

class ReceiptConfirmationContainer extends React.Component {
  handleDosageChange (id, dosage) {
    this.props.changeDosage(id, dosage)
  }

  generatePDF () {
    const medicines = this.props.basketItems.map(item => {
      const { medicine } = item
      const medicineObject = new Medicine(
        medicine.id,
        medicine.commercialName,
        medicine.activeComponent,
        medicine.dose,
        medicine.units,
        medicine.medicineType,
        medicine.packageType,
        medicine.administrationType,
        medicine.price,
        medicine.isGeneric,
        medicine.hasGeneric
      )
      return {
        fullDesignation: medicineObject.getFullDesignation(),
        dosage: item.dosage,
        quantity: item.quantity,
        expiryDate: moment(new Date()).add(1, 'month').format('YYYY-MM-DD'),
        costs: medicineObject.price
      }
    })
    const doc = new TreatmentGuideGenerator('3011000041468888005', '2019-01-01', 'Valentin Angelov Mirchev', 239605, 9961, 'H. CRUZ VERMELHA P.', 'R. PIMENTA-ARAÚJO', 217714000, medicines)
    const blob = doc.getBlobUrl()
    window.open(blob, '_blank')
  }

  handleConfirmationClick () {
    if (this.props.handleConfirmationClick) {
      this.props.handleConfirmationClick()
    } else {
      this.generatePDF()
    }
  }

  render () {
    const prescribedMedicines = convertBasketToPrescribedMedicines(this.props.basketItems)
    const receipt = prescribedMedicines.map(item => ({
      name: item.medicine.getFullDesignation(),
      units: item.quantity
    }))
    return <ReactModal customClass={'modal-lg receipt-signer-modal'} toggleModal={this.props.toggleModal} name={'Assinatura do Receituário (2)'} modalIsOpen={this.props.modalIsOpen}>
      <Fragment>
        <ReceiptConfirmation
          defaultPhone={'919603742'}
          handleConfirmationClick={this.handleConfirmationClick.bind(this)}
          handleDosageChange={this.handleDosageChange.bind(this)}
          items={[{id: '1', elements: receipt}, {id: '2', elements: receipt}]} />
      </Fragment>
    </ReactModal>
  }
}

const mapStateToProps = ({ basket }) => {
  return {
    basketItems: basket
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(basketActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptConfirmationContainer)
