class MedicationListItem {
  constructor (name, dosage, date, prescriber, medType) {
    this._name = name
    this._dosage = dosage
    this._date = date
    this._prescriber = prescriber
    this._medType = medType
  }

  get name () {
    return this._name
  }

  get dosage () {
    return this._dosage
  }

  get date () {
    return this._date
  }

  get prescriber () {
    return this._prescriber
  }

  get medType () {
    return this._medType
  }

  set medType (value) {
    this._medType = value
  }
}

export default MedicationListItem
