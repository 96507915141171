import React, { Fragment } from 'react'
import FavoriteEdit from './FavoriteEdit'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { basketActions } from '../../../../../../redux/basket'
import { doctorActions } from '../../../../../../redux/doctor'
import { searchActions } from '../../../../../../redux/search'
import { modalsActions } from '../../../../../../redux/modals'
import {notify} from 'reapop'

import { withRouter } from 'react-router-dom'
import Form from '../../../../../common/formComponents/Form'
import FormElement from '../../../../../common/formComponents/FormElement'
import {
  convertBasketToPrescribedMedicines, convertFavoriteToBasket,
  convertSearchResultsToMedicinesList
} from '../../../../../../resources/utilities'
import { SEARCH } from '../../../Patient/Prescription/Prescribe/Search/SearchForm/SearchForm'

class FavoriteEditContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: props.favorite.name || ''
    }
  }

  componentWillUnmount () {
    this.props.reset()
  }

  componentDidMount () {
    const { removeAll, favorite, addItems } = this.props
    removeAll()
    if (favorite) {
      addItems(convertFavoriteToBasket(favorite.items))
    }
  }
  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  saveChanges () {
    const name = this.state.name
    this.props.changeFavorite(this.props.favoriteIndex, {
      name,
      id: this.props.match.params.favoriteId,
      items: convertBasketToPrescribedMedicines(this.props.basketItems)
    })
    this.props.notify({
      message: `Favorito modificado com sucesso.`,
      status: 'info',
      dismissible: true,
      dismissAfter: 3000,
      position: 'br',
      closeButton: true,
      allowHTML: false,
      buttons: [
        {
          name: 'Voltar',
          primary: true,
          onClick: () => { this.props.history.goBack() }
        }
      ]
    })
  }
  render () {
    return <Fragment>
      <div className='margin-bottom'>
        <Form handleSubmit={() => {}}>
          <FormElement
            inputSize={9}
            type={'text'}
            label={'Nome do Favorito'}
            name={'favName'}
            defaultValue={this.state.name}
            handleChange={(_, value) => this.setState({ name: value })} />
        </Form>
      </div>
      <FavoriteEdit
        freeFormPrescription={this.props.freeFormSearch}
        name={this.props.favorite.name}
        handleSubmitClick={this.saveChanges.bind(this)}
        basketHasItems={this.props.basketHasItems}
        items={this.props.searchResults} />
    </Fragment>
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...basketActions, ...doctorActions, ...searchActions, ...modalsActions, notify }, dispatch)
}

const mapStateToProps = ({basket, search, doctor}, {match}) => {
  return {
    freeFormSearch: search.type === SEARCH.FREE_FORM,
    favorite: doctor.favorites.find(fav => fav.id === match.params.favoriteId),
    favoriteIndex: doctor.favorites.findIndex(fav => fav.id === match.params.favoriteId),
    searchResults: convertSearchResultsToMedicinesList(search.results),
    basketItems: basket,
    basketHasItems: !!basket.length
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FavoriteEditContainer))
