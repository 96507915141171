import React, { Component, Fragment } from 'react'

import './style.css'
// import ReceiptSigner from '@kenetto/scribo-signer'
import '@kenetto/scribo-signer/dist/styles.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion'
import ConfirmationBox from './ConfirmationBox/ConfirmationBox'
import PropTypes from 'prop-types'

import shieldIcon from '../../../../../../../resources/images/prescription/shield.svg'

class ReceiptConfirmation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      signedReceipts: [],
      expanded: [1]
    }
  }

  signReceipt (receiptId) {
    const curr = [...this.state.signedReceipts]
    curr.push(receiptId)
    this.setState({
      signedReceipts: curr
    }, () => {
      this.setState({
        expandState: [this.state.signedReceipts.length + 1]
      })
    })
  }

  handleExpand (expanded) {
    console.log('expanding')
    this.setState({
      expandState: expanded
    })
  }

  // changeAccordion (ordinal) {
  //   this.setState((oldState) => {
  //     if (oldState.expanded === ordinal) {
  //       return { expanded: null }  // user closed accordion
  //     } else {
  //       return { expanded: ordinal }
  //     }
  //   })
  // }

  render () {
    let { items, defaultPhone } = this.props
    const { expanded, signedReceipts } = this.state
    return <div className='receipt-signer-window'>
      <Accordion allowZeroExpanded preExpanded={expanded}>
        {items.map((item, i) => {
          return <AccordionItem key={i}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <Fragment>
                  <div className='receipt-line-title'>
                    <div className='text-center'>{`Receita nº${i + 1}`}</div>
                    <div className='pull-right'>
                      {signedReceipts.findIndex(receipt => receipt === item.id) !== -1
                        ? <img src={shieldIcon} alt='' style={{width: '31px', height: '28px', marginTop: '-4px'}} />
                        : null
                      }
                    </div>
                  </div>
                </Fragment>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className='clearfix'>
                <ul className='list-group medicine-list'>
                  {item.elements.map((element, i) => {
                    return <li key={i} className='list-group-item'>
                      <div className='item-line'>
                        <div>
                          {element.name}
                        </div>
                        <div>
                          {element.units} emb.
                        </div>
                      </div>
                    </li>
                  })}
                </ul>
                <div className='sign-box'>
                  <div>
                    <ConfirmationBox confirmReceipt={() => this.signReceipt(item.id)} defaultPhone={defaultPhone} />
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        })}
      </Accordion>
    </div>
  }
}

ReceiptConfirmation.propTypes = {
  items: PropTypes.array,
  handleConfirmationClick: PropTypes.func,
  defaultPhone: PropTypes.string
}

ReceiptConfirmation.defaultProps = {
  btnText: 'Confirmar'
}

export default ReceiptConfirmation
