import React, { Component, Fragment } from 'react'
import v4 from 'uuid/v4'
import testReceipts from '../../../../../../../../test/testReceipt'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion'

import GuideEntryComponent from './GuideEntryComponent'
import commercialIcon from '../../../../../../../../resources/images/medicine-icons/icon_commercial.svg'
import { convertReceiptToPrescribedMedicine } from '../../../../../../../../resources/utilities'

import './style.css'
import * as PropTypes from 'prop-types'
import { Button } from '../../../../../../../common/Button/Button'
import { ReactModal } from '../../../../../../../common/ReactModal/ReactModal'
import Guide from '../../../../../../../../models/Guides/Guide'
import moment from 'moment'
import GuideEntry from '../../../../../../../../models/Guides/GuideEntry'
import testGuides from '../../../../../../../../test/testGuides'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { notify } from 'reapop'

class GuideCreatorContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: !!props.receiptId
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.handleGuideChange = this.handleGuideChange.bind(this)
    this.handleGuideChange = this.handleGuideChange.bind(this)
  }

  componentDidUpdate (prevProps) {
    const { receiptId } = this.props
    if (receiptId && prevProps.receiptId !== receiptId) {
      const receipt = testReceipts.find(rec => rec.number === receiptId)
      const prescribedMedicines = convertReceiptToPrescribedMedicine(receipt.medicines)
      const entries = prescribedMedicines.map((entry, i) => {
        const { medicineType, units, commercialName, dose, isGeneric, administrationType } = entry.medicine
        const designation = `${commercialName} |  ${dose} | ${medicineType.name} | ${units} Unidade(s) | ${administrationType.name}`
        return new GuideEntry(i, designation, entry.dosage, null, medicineType, isGeneric)
      })
      const guide = new Guide(v4(), 'Ricardo Pimenta-Araújo', moment(new Date()).format('YYYY/MM/DD'), receiptId, entries)
      this.setState({
        guide
      })
    }
    if (prevProps.receiptId !== receiptId) {
      this.setState({
        modalIsOpen: !!receiptId
      })
    }
  }

  toggleModal () {
    this.props.close()
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  handleGuideSubmit () {
    testGuides.push(this.state.guide)
    this.toggleModal()
    this.props.notify({
      message: 'Guia Criada com sucesso.',
      status: 'info',
      dismissible: true,
      dismissAfter: 5000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
  }

  handleGuideChange (entry) {
    const newGuide = this.state.guide
    const index = newGuide.entries.findIndex(item => item.id === entry.id)
    newGuide.entries[index] = entry
    this.setState({guide: newGuide})
  }

  render () {
    const { receiptId } = this.props
    const { guide } = this.state
    let noEntriesForMedicines = false
    guide && guide.entries.forEach(entry => {
      if (entry.periods.length === 0) {
        noEntriesForMedicines = true
      }
    })
    return <ReactModal
      name={'Gerar Guia de Tratamento'}
      toggleModal={() => this.toggleModal()} modalIsOpen={!!this.state.modalIsOpen} customClass={'modal-lg guide-modal'}
      footer={<Button disabled={noEntriesForMedicines} type={'button'} text={'Gerar Guia'} buttonType={'btn-success'} onClick={this.handleGuideSubmit.bind(this)} />}>
      { receiptId ? <GuideCreator guide={guide} handleGuideChange={this.handleGuideChange} receiptId={receiptId} /> : <div /> }
    </ReactModal>
  }
}

class GuideCreator extends Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: [0]
    }
  }

  renderHeading (designation, isGeneric) {
    return <Fragment>
      {isGeneric
        ? null
        : <img className={`custom-icon small center-with-text`} src={commercialIcon} alt='Commercial Medicine' />}
      {designation}
    </Fragment>
  }

  handleEntryChange (entryItem, newData) {
    if (newData.type === 'beginningOfTreatment') {
      entryItem.start = newData.value
    }
    if (newData.type === 'duration') {
      entryItem.duration = newData.value
    }
    if (newData.type === 'entry') {
      entryItem.periods.push(newData.value)
    }
    this.props.handleGuideChange(entryItem)
  }

  render () {
    let { guide } = this.props
    return <div style={{ marginBottom: '100px' }}>
      <Accordion allowZeroExpanded preExpanded={this.state.expanded}>
        {guide.entries.map((item, i) => {
          return <AccordionItem key={i}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className='guide-title'>
                  <div>{this.renderHeading(item.designation, item.generic, item.unitType)}</div>
                  <div>{item.dosage}</div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className='clearfix'>
                <GuideEntryComponent
                  handleChange={(newData) => this.handleEntryChange(item, newData)}
                  medicineType={item.unitType.name} />
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        })}
      </Accordion>
    </div>
  }
}

GuideCreator.propTypes = {
  handleGuideChange: PropTypes.any,
  guide: PropTypes.any
}

GuideCreatorContainer.propTypes = { receiptId: PropTypes.any }

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({notify}, dispatch)
}

export default connect(null, mapDispatchToProps)(GuideCreatorContainer)
