import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './reapop-style.css'
import { createBrowserHistory } from 'history'
import '@fortawesome/fontawesome-free/css/all.css'
import Provider from 'react-redux/es/components/Provider'
import { ConnectedRouter } from 'connected-react-router'
import createStore from '@kenetto/kenetto-redux'
import { basePathConfiguration } from './config'
import reducers from './redux'
import App from './components/general/App/App'
import moment from 'moment'
import LOCALE_PT_PT from './resources/locales/pt-PT'

moment.defineLocale('pt', LOCALE_PT_PT)

const reduxStore = createStore(basePathConfiguration, reducers)
// import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'

const history = createBrowserHistory({ basename: process.env.REACT_APP_ROUTER_BASE_PATH })

export default history

ReactDOM.render(<Provider store={reduxStore.store}>
  <ConnectedRouter history={reduxStore.history}>
    <App />
  </ConnectedRouter>
</Provider>, document.getElementById('root'))
