import React, { Fragment } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import PrescribeContainer from './PrescribeContainer'
import ReceiptConfirmationContainer from './ReceiptConfirmation/ReceiptConfirmationContainer'
import { connect } from 'react-redux'

const PrescribeRouter = ({ match, basketHasItems }) => {
  return <Fragment>
    <Switch>
      <Route exact path={`${match.url}/`} render={(props) => <PrescribeContainer {...props} />} />
      <Route path={`${match.url}/confirmation`} children={props => {
        if ((props.location && props.location.state && props.location.state.isValid) && basketHasItems) {
          return <ReceiptConfirmationContainer {...props} />
        } else {
          return <Redirect to={match.url} replace />
        }
      }} />
    </Switch>
  </Fragment>
}

const mapStateToProps = ({basket}) => {
  return {
    basketHasItems: basket.length
  }
}
export default connect(mapStateToProps)(PrescribeRouter)
