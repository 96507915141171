import React, { Fragment } from 'react'
import { Button } from '../../../common/Button/Button'
import { bindActionCreators } from 'redux'
import { patientActions } from '../../../../redux/patient'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { notify } from 'reapop'
import EnhancedSearchContainer from './EnhancedSearchContainer/EnhancedSearchContainer'

import './style.css'

const NavBar = ({ patientNumber, handleChange, handleSubmit, handleKeyPress, error }) => {
  return <div key={'patientSearch'} className='navbar-form navbar-left' role='search'>
    <div className={`input-group ${error ? 'has-error' : ''}`}>
      <input
        onKeyPress={patientNumber ? handleKeyPress : null}
        type='text'
        className={`form-control ${error ? 'has-error' : ''}`}
        value={patientNumber}
        onChange={handleChange}
        placeholder='Nº SNS' />
      <div className='input-group-btn'>
        <Button
          onClick={() => handleSubmit(patientNumber)}
          text={<Fragment>
            <span className='hidden-sm'>Prescrever</span>
            <span className='visible-sm fas fa-prescription button-fa' />
          </Fragment>}
          buttonType={`btn btn-${error ? 'warning' : 'success'}`}
          type={'button'} />
      </div>
    </div>
  </div>
}

class Normal extends React.Component {
  render () {
    const { handleSubmit, error, renderErrorMessage, history, match } = this.props
    return <Fragment>
      {error
        ? renderErrorMessage(error)
        : null
      }
      <EnhancedSearchContainer
        history={history}
        match={match}
        focus
        handleSubmit={handleSubmit} />
    </Fragment>
  }
}

class PatientSearchContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      patientNumber: '',
      error: undefined
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange ({target}) {
    this.setState({
      patientNumber: target.value
    })
  }

  handleKeyPress (e) {
    if (e.key === 'Enter') {
      this.handleSubmit(this.state.patientNumber)
    }
  }

  handleSubmit (patientNumber) {
    const { setPatient, patientFound, patientHealthNumber, navbarVersion, notify , patientError } = this.props
    if (patientHealthNumber) {
      this.setState({oldPatient: patientHealthNumber})
    }
    setPatient(patientNumber)
      .then(() => {
        notify({
          id: 'patient-not-found',
          message: `Utente Selecionado.`,
          status: 'info',
          dismissible: true,
          dismissAfter: 5000,
          position: 'br',
          closeButton: true,
          allowHTML: false
        })
        this.setState({
          patientNumber: ''
        })
        patientFound(patientNumber)
      })
      .catch((error) => {
        console.log(error)
        const oldNumber = this.state.oldPatient
        this.setState({error: patientError, oldPatient: undefined})
        if (navbarVersion) {
          notify({
            id: 'patient-not-found',
            message: `Utente Não Encontrado.`,
            status: 'error',
            dismissible: true,
            dismissAfter: 5000,
            position: 'br',
            closeButton: true,
            allowHTML: false
          })
          setPatient(oldNumber).then().catch(console.log)
        }
      })
  }

  renderErrorMessage (error) {
    return <div className='col-xs-12 margin-top'>
      <div className='alert alert-danger' role='alert'>
        {console.log(error)}
        {error.type === 'NOT_FOUND' ? 'Utente Não Encontrado' : 'Ocorreu um erro, por favor tente de novo.' }
      </div>
    </div>
  }
  render () {
    const { navbarVersion, notify, patientError, history, match } = this.props
    const { error, patientNumber } = this.state
    if (navbarVersion) {
      return <NavBar
        notify={notify}
        error={error}
        handleChange={this.handleChange.bind(this)}
        handleSubmit={this.handleSubmit.bind(this)}
        handleKeyPress={this.handleKeyPress.bind(this)}
        patientNumber={patientNumber} />
    } else {
      return <Normal
        history={history}
        match={match}
        patientNumber={patientNumber}
        error={patientError}
        renderErrorMessage={this.renderErrorMessage.bind(this)}
        handleKeyPress={this.handleKeyPress.bind(this)}
        handleChange={this.handleChange.bind(this)}
        handleSubmit={this.handleSubmit.bind(this)} />
    }
  }
}

PatientSearchContainer.propTypes = {
  patientFound: PropTypes.func.isRequired,
  navbarVersion: PropTypes.bool.isRequired
}
PatientSearchContainer.defaultProps = {
  navbarVersion: false
}

function mapStateToProps ({patient}) {
  return {
    patientError: patient.error,
    patientHealthNumber: patient.data && patient.data.healthcareNumber,
    patientExists: !!patient.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...patientActions, notify}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientSearchContainer)
