import React from 'react'
import v4 from 'uuid/v4'
import { Legend } from '../../../../../../common/formComponents/utilities'
import { Button } from '../../../../../../common/Button/Button'
import { connect } from 'react-redux'
import Medicine from '../../../../../../../models/Medicine'
import { bindActionCreators } from 'redux'
import { basketActions } from '../../../../../../../redux/basket'
import { searchActions } from '../../../../../../../redux/search'
import { notify } from 'reapop'
import AutoCompleter from '../../../../../../common/formComponents/AutoCompleter'
import { AutoComplete, AutoCompleteOptions } from '@kenetto/infarmed-sdk'

const structureListForAutocomplete = (arr) => {
  return Object.values(arr)
    .filter(element => element.name)
    .map(element => {
      return {
        value: element.id,
        displayValue: element.name
      }
    })
}

class FreeformMedicineViewContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      id: null,
      selectedItem: { name: 'Via oral' },
      currentAdminWayText: '',
      adminWays: [],
      pharmaceuticalForm: props.currentPharmForm
    }
  }

  handleAutocompleteSelect (name, value) {
    this.setState({
      [name]: {
        id: value.value,
        name: value.displayValue
      }
    })
  }

  handleChange (name, value) {
    this.setState({
      [name]: value,
      id: null
    })
  }

  add () {
    const id = v4()
    const { description, administrationWay, pharmaceuticalForm, dosage, dose } = this.state
    const med = new Medicine(id, null, description, dose, null, pharmaceuticalForm, null, administrationWay, false, false, null)
    this.props.addItem({
      medicine: med,
      medicineId: id,
      dosage,
      quantity: 1,
      renewable: 0
    })
    this.props.notify({
      message: `${description} adicionado à receita.`,
      status: 'info',
      dismissible: true,
      dismissAfter: 5000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
    this.props.clearSearch()
  }

  handleAdminWayChange (name, value) {
    if (value === '') {
      this.handleChange('administrationWays', value)
      this.setState({
        adminWays: [],
        selectedItem: null
      })
    } else {
      this.setState({
        currentAdminWayText: value
      })
      const option = new AutoCompleteOptions(AutoCompleteOptions.AdministrationWay, value)
      AutoComplete(option).then(res => {
        this.setState({
          adminWays: res
        })
      })
    }
  }

  handleAdminWaySelect (item) {
    this.setState({ selectedItem: item })
    this.handleChange('administrationWay', item)
  }

  render () {
    const { dosage, description, pharmaceuticalForms } = this.props
    const currPharmaceuticalForm = (this.state.pharmaceuticalForm && this.state.pharmaceuticalForm.name) || ''
    return <div className='clearfix medication'>
      <div className='col-xs-12'>
        <div style={{lineHeight: '42px'}}>
          <Legend text={'Prescrição em Texto Livre'} />
        </div>
      </div>
      <div className='col-xs-12 no-side-padding top-margin'>
        <form>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Tipo de Prescrição</label>
            <div>
              <select defaultValue={''} name='prescriptionType' className='form-control' onChange={({target}) => this.handleChange(target.name, target.value)}>
                <option value='' disabled hidden />
                <option value='therapeutic'>{'Produtos dietéticos com caráter terapêutico'}</option>
                <option value='manipulated'>{'Medicamentos manipulados'}</option>
                <option value='other'>{'Outros Produtos'}</option>
              </select>
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Descrição</label>
            <div className=''>
              <input
                placeholder=''
                value={description}
                type='text'
                className='form-control'
                name={'description'}
                onChange={({target}) => this.handleChange(target.name, target.value)} />
            </div>
          </div>
          <div className='form-group col-sm-4'>
            <label className='control-label' style={{ textAlign: 'right' }}>Dosagem</label>
            <div>
              <input
                placeholder=''
                value={description}
                type='text'
                className='form-control'
                name={'dose'}
                onChange={({target}) => this.handleChange(target.name, target.value)} />
            </div>
          </div>
          <div className='form-group col-sm-4'>
            <label className='control-label' style={{ textAlign: 'right' }}>Forma Farmacêutica</label>
            <div>
              <AutoCompleter
                defaultValue={currPharmaceuticalForm}
                name={'pharmaceuticalForm'}
                onSuggestionSelected={value => this.handleAutocompleteSelect('pharmaceuticalForm', value)}
                options={structureListForAutocomplete(pharmaceuticalForms)} />
            </div>
          </div>
          {/*<div className='form-group col-sm-4'>*/}
          {/*  <label className='control-label' style={{ textAlign: 'right' }}>Via de Administração</label>*/}
          {/*  <div>*/}
          {/*    <AutoCompleter*/}
          {/*      defaultValue={currAdminWay}*/}
          {/*      name={'adminWay'}*/}
          {/*      onSuggestionSelected={value => this.handleAutocompleteSelect('adminWay', value)}*/}
          {/*      options={structureListForAutocomplete(adminWays)} />*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className='form-group col-sm-4 admin-ways'>
            <label className='control-label' style={{ textAlign: 'right' }}>Via de Administração</label>
            <div>
              <input
                placeholder=''
                value={(this.state.selectedItem && this.state.selectedItem.name) || this.state.currentAdminWayText}
                type='text'
                className='form-control'
                name={'administrationWay'}
                onChange={({target}) => this.handleAdminWayChange(target.name, target.value)} />
            </div>
            {this.state.selectedItem
              ? null
              : <div className='autocomplete-dropdown-container'>
                <div className='list-group autocomplete-list popped-list'>
                  {this.state.adminWays.map((item, i) => {
                    return <div
                      key={i}
                      className='autocomplete-list-suggestion list-group-item clickable'
                      onClick={() => this.handleAdminWaySelect(item)}>
                      {item.name}
                    </div>
                  })}
                </div>
              </div>
            }
          </div>
          <div className='form-group col-sm-12'>
            <div>
              <input
                placeholder='Posologia'
                value={dosage}
                type='text'
                className='form-control'
                name={'dosage'}
                onChange={({target}) => this.handleChange(target.name, target.value)} />
            </div>
          </div>
        </form>
        {/*<div className='col-xs-12'>*/}
        {/*  <Form ref={'form'} type={'horizontal'} handleSubmit={() => {}}>*/}
        {/*    <FormElement*/}
        {/*      inputSize={8}*/}
        {/*      required*/}
        {/*      type={'text'}*/}
        {/*      defaultValue={description}*/}
        {/*      label={''}*/}
        {/*      name={'description'}*/}
        {/*      placeholder='Descrição'*/}
        {/*      handleChange={this.handleChange.bind(this)} />*/}
        {/*    <FormElement*/}
        {/*      required*/}
        {/*      inputSize={7}*/}
        {/*      type={'select'}*/}
        {/*      label={'Tipo de Prescrição'}*/}
        {/*      name={'prescriptionType'}*/}
        {/*      options={[*/}
        {/*        {value: 'therapeutic', displayValue: 'Produtos dietéticos com caráter terapêutico'},*/}
        {/*        {value: 'manipulated', displayValue: 'Medicamentos manipulados'},*/}
        {/*        {value: 'other', displayValue: 'Outros Produtos'}*/}
        {/*      ]}*/}
        {/*      handleChange={this.handleChange.bind(this)} />*/}
        {/*    <FormElement*/}
        {/*      required*/}
        {/*      type={'autocomplete'}*/}
        {/*      inputSize={7} onSuggestionSelected={(value) => this.handleAutocompleteSelect('pharmaceuticalForm', value)}*/}
        {/*      label={'Forma de Apresentação'}*/}
        {/*      name={'pharmaceuticalForms'}*/}
        {/*      options={structureListForAutocomplete(pharmaceuticalForms)}*/}
        {/*      handleChange={this.handleChange.bind(this)} />*/}
        {/*    <FormElement*/}
        {/*      required*/}
        {/*      type={'autocomplete'}*/}
        {/*      inputSize={7}*/}
        {/*      label={'Embalagem'}*/}
        {/*      name={'packagingTypes'}*/}
        {/*      options={structureListForAutocomplete(packagingTypes)}*/}
        {/*      onSuggestionSelected={(value) => this.handleAutocompleteSelect('packagingType', value)} />*/}
        {/*    <FormElement*/}
        {/*      required*/}
        {/*      type={'text'}*/}
        {/*      defaultValue={dosage}*/}
        {/*      label={''}*/}
        {/*      name={'dosage'}*/}
        {/*      placeholder='Posologia'*/}
        {/*      handleChange={this.handleChange.bind(this)} />*/}
        {/*  </Form>*/}
        {/*</div>*/}
      </div>
      <div className='col-xs-12 margin-bottom'>
        <div className='pull-right'>
          <Button
            text={'Adicionar'}
            buttonType={'btn btn-info btn-sm'}
            type={'button'} onClick={this.add.bind(this)} />
        </div>

      </div>
    </div>
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    addItem: basketActions.addItem,
    clearSearch: searchActions.reset,
    notify: notify
  }, dispatch)
}

function mapStateToProps ({medicineData, search}) {
  const currentAdminWay = medicineData.administrationWays.filter(item => item.id === search.adminWay)[0]
  const currentPharmForm = medicineData.pharmaceuticalForms.filter(item => item.id === search.pharmForm)[0]
  return {
    currentAdminWay: currentAdminWay,
    currentPharmForm: currentPharmForm,
    currentSearch: search,
    adminWays: medicineData.administrationWays,
    pharmaceuticalForms: medicineData.pharmaceuticalForms
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeformMedicineViewContainer)
