import React from 'react'
import PropTypes from 'prop-types'

import LabelWrapper from './LabelWrapper'
import Input from './Input'
import Selector from './Selector'
import Range from './Range'
// import SelectionList from './SelectionList'

import './style.css'
import AutoCompleter from './AutoCompleter'
// import AutoCompleter from './AutoCompleter'

export function getChecked (currentState, name, value, checked) {
  let arr = currentState || []
  if (checked) {
    arr.push(value)
  } else {
    const index = arr.findIndex(element => element === value)
    if (index >= 0) {
      arr = [
        ...arr.slice(0, index),
        ...arr.slice(index + 1)
      ]
    }
  }
  return arr
}

function selectInputType (type, props) {
  switch (type) {
    case 'text':
    case 'date':
    case 'email':
    case 'tel':
    case 'phone':
    case 'number':
    case 'password':
    case 'textarea':
      return <Input type={type} {...props} />
    case 'autocomplete':
      // return <div>Not yet supported</div>
      return <AutoCompleter {...props} />
    case 'select':
    case 'radio':
    case 'checkbox':
      return <Selector type={type} {...props} />
    case 'range':
      return <Range {...props} />
    case 'list':
      return <div>Not yet supported</div>
      // return <SelectionList {...props} />
    default:
      console.error(new Error('Wrong data type'))
      return <div>Error</div>
  }
}

const FormElement = ({type, inGroup, ...props}) => {
  if (props.label) {
    return <LabelWrapper formType={props.formType} inputSize={props.inputSize} name={props.name} label={props.label} input={selectInputType(type, props)} />
  } else {
    return <div className={`form-group ${props.hidden ? 'hidden' : ''} ${inGroup ? 'grouped-item' : ''}`}>
      <div className='col-xs-12'>
        {selectInputType(type, props)}
      </div>
    </div>
  }
}

FormElement.propTypes = {
  formType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  groupName: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  step: PropTypes.number,
  inputSize: PropTypes.number,
  withEdit: PropTypes.bool,
  removeElement: PropTypes.func,
  isInputInFocus: PropTypes.bool,
  hidden: PropTypes.bool,
  focusInput: PropTypes.func,
  reset: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
  type: PropTypes.oneOf(['text', 'date', 'email', 'tel', 'password', 'phone', 'list', 'number', 'select', 'radio', 'checkbox', 'range', 'textarea', 'autocomplete']).isRequired
}

FormElement.defaultProps = {
  formType: 'horizontal'
}

export default FormElement
