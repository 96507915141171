import React from 'react'
import testAllergies from '../../../../../../test/testAllergies'
import Allergy, { ALLERGY_TYPES } from '../../../../../../models/Allergy'

import DiverseAllergiesPanel from './DiverseAllergiesPanel'
import MedicalAllergiesPanel from './MedicalAllergiesPanel'

function getDiverseAllergies (allAllergies) {
  return allAllergies.filter(allergy => allergy.type !== ALLERGY_TYPES.MEDICATION)
    .map(({ id, type, discoveryDate, substance, severity, therapeutics }) => (
      new Allergy(id, type, discoveryDate, substance, severity, therapeutics))
    )
}

function getMedicalAllergies (allAllergies) {
  return allAllergies.filter(allergy => allergy.type === ALLERGY_TYPES.MEDICATION)
    .map(({ id, type, discoveryDate, substance, severity, therapeutics }) => (
      new Allergy(id, type, discoveryDate, substance, severity, therapeutics))
    )
}
const Allergies = () => {
  const medicalAllergies = getMedicalAllergies(testAllergies)
  const otherAllergies = getDiverseAllergies(testAllergies)
  return <div className='clearfix'>
    <div className='col-xs-12 small-side-padding'>
      <MedicalAllergiesPanel allergies={medicalAllergies} />
    </div>
    <div className='col-xs-12 small-side-padding'>
      <DiverseAllergiesPanel allergies={otherAllergies} />
    </div>
  </div>
}

export default Allergies
