import React from 'react'
import PropTypes from 'prop-types'

const DisabledInput = ({ content = 'Sem Dados', withWarning, placeholder }) => {
  return <div className={`disabled-input ${withWarning ? 'warning' : ''}`}>{content !== '' ? content : <span className='text-muted'>{placeholder}</span>}</div>
}

class Input extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    const { name, defaultValue } = this.props
    if (defaultValue) {
      this.handleChange(name, defaultValue)
    }
  }

  componentDidUpdate (prevProps) {
    const { name, defaultValue } = this.props
    if (prevProps.defaultValue !== defaultValue) {
      this.handleChange(name, defaultValue)
    }
  }

  handleChange (name, value) {
    this.setState({
      value: value
    })
    if (this.props.onChange) {
      this.props.onChange(name, value)
    }
    this.props.handleChange(name, value)
  }

  render () {
    const { type, disabled, placeholder, name, rows, required, minValue, maxValue, hidden } = this.props
    return type === 'textarea'
      ? disabled
        ? <DisabledInput content={this.state.value} />
        : <textarea
          required={required}
          disabled={disabled}
          onChange={event => { this.handleChange(event.target.name, event.target.value) }}
          value={this.state.value}
          className='form-control'
          rows={rows}
          placeholder={placeholder}
          name={name} />
      : disabled
        ? <DisabledInput content={this.state.value} />
        : <input
          required={required}
          disabled={disabled}
          onChange={event => { this.handleChange(event.target.name, event.target.value) }}
          className='form-control'
          min={minValue}
          max={maxValue}
          name={name}
          id={name}
          value={this.state.value}
          type={hidden ? 'hidden' : type}
          placeholder={placeholder} />
  }
}

Input.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'phone', 'tel', 'number', 'email', 'textarea', 'password', 'date']).isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  rows: PropTypes.number,
  hidden: PropTypes.bool,
  handleChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minValue: PropTypes.string,
  maxValue: PropTypes.string
}

Input.defaultProps = {
  handleChange: () => {},
  rows: 5
}

export { DisabledInput }

export default Input
