import { GENDER } from '../resources/utilities'
import v4 from 'uuid/v4'

import testUser from '../resources/images/userIdPhoto.jpg'

const manyPatients = [
  {
    id: v4(),
    name: 'Felipe Gonçalves Santos',
    birth: '1986-02-10',
    photo: testUser,
    healthcareNumber: '223456789',
    gender: GENDER.MALE,
    country: 'Portugal',
    contacts: [
      963483059
    ],
    email: 'paulina.jorge@gmail.com'
  },
  {
    id: v4(),
    name: 'Mariana Melo Sousa',
    birth: '1994-11-11',
    healthcareNumber: '123456789',
    gender: GENDER.FEMALE,
    country: 'Portugal',
    contacts: [
      919603742
    ],
    vat: '269557610',
    email: 'valyo.mir@gmail.com'
  },
  {
    id: v4(),
    name: 'Miguel Alves Gonçalves',
    birth: '1994-11-11',
    healthcareNumber: '323456789',
    gender: GENDER.MALE,
    country: 'Portugal',
    contacts: [
      919603742
    ],
    vat: '269557610',
    email: 'valyo.mir@gmail.com'
  },
  {
    id: v4(),
    name: 'João Cunha Castro',
    birth: '1986-02-10',
    healthcareNumber: '423456789',
    gender: GENDER.MALE,
    country: 'Portugal',
    contacts: [
      963483059
    ],
    email: 'paulina.jorge@gmail.com'
  },
  {
    id: v4(),
    name: 'Vitória Martins Melo',
    birth: '1994-11-11',
    healthcareNumber: '523456789',
    gender: GENDER.FEMALE,
    country: 'Portugal',
    contacts: [
      919603742
    ],
    vat: '269557610',
    email: 'valyo.mir@gmail.com'
  },
  {
    id: v4(),
    name: 'Livia Martins Fernandes',
    birth: '1986-02-10',
    healthcareNumber: '623456789',
    gender: GENDER.FEMALE,
    country: 'Portugal',
    contacts: [
      963483059
    ],
    email: 'paulina.jorge@gmail.com'
  },
  {
    id: v4(),
    name: 'Nicole Martins Correia',
    birth: '1994-11-11',
    healthcareNumber: '723456789',
    gender: GENDER.FEMALE,
    country: 'Portugal',
    contacts: [
      919603742
    ],
    vat: '269557610',
    email: 'valyo.mir@gmail.com'
  },
  {
    id: v4(),
    name: 'António Fernandes Rodrigues',
    birth: '1986-02-10',
    healthcareNumber: '823456789',
    gender: GENDER.MALE,
    country: 'Portugal',
    contacts: [
      963483059
    ],
    email: 'paulina.jorge@gmail.com'
  },
  {
    id: v4(),
    name: 'Gabriel Alves Cunha',
    birth: '1994-11-11',
    healthcareNumber: '923456789',
    gender: GENDER.MALE,
    country: 'Portugal',
    contacts: [
      919603742
    ],
    vat: '269557610',
    email: 'valyo.mir@gmail.com'
  },
  {
    id: v4(),
    name: 'Beatriz Dias Alves',
    birth: '1986-02-10',
    healthcareNumber: '133456789',
    gender: GENDER.FEMALE,
    country: 'Portugal',
    contacts: [
      963483059
    ],
    email: 'paulina.jorge@gmail.com'
  },
  {
    id: v4(),
    name: 'Isabelle Barros Araújo',
    birth: '1994-11-11',
    healthcareNumber: '233456789',
    gender: GENDER.FEMALE,
    country: 'Portugal',
    contacts: [
      919603742
    ],
    vat: '269557610',
    email: 'valyo.mir@gmail.com'
  },
  {
    id: v4(),
    name: 'Matilde Alves Azevedo',
    birth: '1986-02-10',
    healthcareNumber: '333456789',
    gender: GENDER.FEMALE,
    country: 'Portugal',
    contacts: [
      963483059
    ],
    email: 'paulina.jorge@gmail.com'
  },
  {
    id: v4(),
    name: 'Alex Barros Lima',
    birth: '1994-11-11',
    healthcareNumber: '433456789',
    gender: GENDER.MALE,
    country: 'Portugal',
    contacts: [
      919603742
    ],
    vat: '269557610',
    email: 'valyo.mir@gmail.com'
  },
  {
    id: v4(),
    name: 'Lara Costa Castro',
    birth: '1986-02-10',
    healthcareNumber: '533456789',
    gender: GENDER.FEMALE,
    country: 'Portugal',
    contacts: [
      963483059
    ],
    email: 'paulina.jorge@gmail.com'
  }
]
//
// const externalPatients = [
//   {
//     id: v4(),
//     name: 'Alberto Caeiro',
//     birth: '1985-10-23',
//     healthcareNumber: '987654321',
//     gender: GENDER.MALE,
//     country: 'Portugal',
//     contacts: [
//       939495949
//     ],
//     email: 'a.c@gmail.com'
//   },
//   {
//     id: v4(),
//     name: 'Bruno Jorge',
//     birth: '1950-12-05',
//     healthcareNumber: '887654321',
//     gender: GENDER.MALE,
//     country: 'Portugal',
//     contacts: [
//       939495949
//     ],
//     email: 'a.c@gmail.com'
//   },
//   {
//     id: v4(),
//     name: 'Albertina Carvalho',
//     birth: '1943-08-02',
//     healthcareNumber: '787654321',
//     gender: GENDER.FEMALE,
//     country: 'Portugal',
//     contacts: [
//       939495949
//     ],
//     email: 'a.c@gmail.com'
//   },
//   {
//     id: v4(),
//     name: 'Beatriz Costa',
//     birth: '1970-05-21',
//     healthcareNumber: '687654321',
//     gender: GENDER.FEMALE,
//     country: 'Portugal',
//     contacts: [
//       939495949
//     ],
//     email: 'a.c@gmail.com'
//   }
// ]

export { manyPatients }
