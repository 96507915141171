export default class Guide {
  constructor (id, prescriber, date, receiptNumber, entries) {
    this._id = id
    this._prescriber = prescriber
    this._date = date
    this._receiptNumber = receiptNumber
    this._entries = entries
  }
  get id () {
    return this._id
  }

  get prescriber () {
    return this._prescriber
  }

  get date () {
    return this._date
  }

  get receiptNumber () {
    return this._receiptNumber
  }

  get entries () {
    return this._entries
  }

  set date (value) {
    this._date = value
  }

  set receiptNumber (value) {
    this._receiptNumber = value
  }

  set entries (value) {
    this._entries = value
  }

  static map (item, entries) {
    const { id, prescriber, date, receiptNumber } = item
    return new Guide(id, prescriber, date, receiptNumber, entries)
  }
}
