import React, { Fragment } from 'react'
import GuideEntry from '../../../../../../../../models/Guides/GuideEntry'
import { Button } from '../../../../../../../common/Button/Button'
import { Panel } from '../../../../../../../common/Panel/Panel'
import { UnitsInsertion } from './GuideEntryComponent'

const SOSViewer = ({units, unitsPosition = 'right', removeItem, entries}) => {
  const { condition, until } = entries[0]
  return <div className='col-xs-12 no-side-padding guide-entry-line'>
    <div className='col-sm-11 no-side-padding'>
      {unitsPosition === 'right'
        ? <div className='periods'>
          <div className='period-item'>{`Em SOS`}</div>
          <div className='period-item'>{`Se ${condition} | Até ${until}`}</div>
          <div className='line'><i className='fas fa-long-arrow-alt-right' /></div>
          <div className='units'>{`${GuideEntry.getUnit(units).displayValue} un.`}</div>
        </div>
        : <div className='periods'>
          <div className='units'>{`${GuideEntry.getUnit(units).displayValue} un.`}</div>
          <div className='line'><i className='fas fa-long-arrow-alt-right' /></div>
          <div className='period-item' style={{backgroundColor: 'var(--attention-color)'}}>{`Em SOS`}</div>
          <div className='period-item'>{`Se ${condition} | Até ${until}`}</div>
        </div>
      }
    </div>
    {removeItem
      ? <div className='col-sm-1 no-side-padding' style={{marginTop: '4px'}}>
        <Button type={'button'} text={<i className='fas fa-trash-alt' />} buttonType={'btn-danger btn-sm pull-right'} onClick={removeItem} />
      </div>
      : null
    }
  </div>
}

class SOSComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      units: '1'
    }
  }

  handleChange (name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    const { condition, until, units } = this.state
    const data = {
      type: 'sos',
      entries: [
        {
          condition,
          until
        }
      ],
      units
    }
    console.log(data)
    this.props.handleSubmit(data)
  }
  render () {
    const { condition, until } = this.state
    const { unitType } = this.props
    return <Fragment>
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Panel
          heading={'Medicação em SOS'}
          footer={<Fragment>
            <Button type={'button'} text={<span className='fas fa-trash-alt' />} buttonType={'btn-danger'} onClick={this.props.remove} />
            <Button type={'submit'} text={<span className='fas fa-plus' />} buttonType={'btn-success pull-right'} />
          </Fragment>}>
          <Fragment>
            <UnitsInsertion defaultValue={'1'} handleChange={units => this.setState({ units })} unitType={unitType} />
            <div className='form-group col-sm-4'>
              <label
                className='control-label'
                style={{ textAlign: 'right' }}>Se</label>
              <div className='no-side-padding'>
                <input
                  value={condition || ''}
                  type='text'
                  className='form-control'
                  name='condition'
                  onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
              </div>
            </div>
            <div className='form-group col-sm-4'>
              <label
                className='control-label'
                style={{ textAlign: 'right' }}>Até</label>
              <div className='no-side-padding'>
                <input
                  value={until || ''}
                  type='text'
                  className='form-control'
                  name='until'
                  onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
              </div>
            </div>
          </Fragment>
        </Panel>
      </form>
    </Fragment>
  }
}

export { SOSViewer, SOSComponent}
