import React from 'react'
import PropTypes from 'prop-types'
import {
  PILL_ICONS,
  CAPSULE_ICONS,
  DROP_ICONS,
  SUPPOSITORY_ICONS,
  BOTTLE_ICONS,
  GRANULATE_ICONS,
  INJECTION_ICONS,
  CREAM_ICONS,
  BANDAGE_ICONS,
  POWDER_ICONS,
  EFFERVESCENT_ICONS,
  EYE_DROP_ICONS, MOUTH_ICONS, INHALATOR_ICONS, PEN_ICONS, TRANSDERMIC_ICONS, NASAL_INHALATOR_ICONS
} from '../../../../../../../resources/utilities'

import './style.css'

import iconBandAid from '../../../../../../../resources/images/medicine-icons/icon_bandAid.svg'
import iconCapsule from '../../../../../../../resources/images/medicine-icons/icon_capsule.svg'
import iconCream from '../../../../../../../resources/images/medicine-icons/icon_cream.svg'
import iconDrops from '../../../../../../../resources/images/medicine-icons/icon_drops.svg'
import iconEffervescent from '../../../../../../../resources/images/medicine-icons/icon_effervescent.svg'
import iconPen from '../../../../../../../resources/images/medicine-icons/icon_pen.svg'
import iconPill from '../../../../../../../resources/images/medicine-icons/icon_pill.svg'
import iconPowder from '../../../../../../../resources/images/medicine-icons/icon_powder.svg'
import iconSachet from '../../../../../../../resources/images/medicine-icons/icon_sachet.svg'
import iconSuppository from '../../../../../../../resources/images/medicine-icons/icon_suppository.svg'
import iconSyringe from '../../../../../../../resources/images/medicine-icons/icon_syringe.svg'
import iconSyrup from '../../../../../../../resources/images/medicine-icons/icon_syrup.svg'
import iconEyeDrop from '../../../../../../../resources/images/medicine-icons/icon_eyeDrop.svg'
import iconTransdermic from '../../../../../../../resources/images/medicine-icons/icon_transdermic.svg'
import iconMouth from '../../../../../../../resources/images/medicine-icons/icon_mouth.svg'
import iconInhaler from '../../../../../../../resources/images/medicine-icons/icon_inhaler.svg'
import iconNasalInhalator from '../../../../../../../resources/images/medicine-icons/icon_nasalInhalator.svg'

const getDescriptionForType = (type) => {
  if (PILL_ICONS.includes(type) > 0) {
    return 'pill'
  } else if (CAPSULE_ICONS.includes(type) > 0) {
    return 'capsule'
  } else if (DROP_ICONS.includes(type) > 0) {
    return 'drops'
  } else if (SUPPOSITORY_ICONS.includes(type) > 0) {
    return 'suppository'
  } else if (BOTTLE_ICONS.includes(type) > 0) {
    return 'bottle'
  } else if (GRANULATE_ICONS.includes(type) > 0) {
    return 'granulate'
  } else if (INJECTION_ICONS.includes(type) > 0) {
    return 'injection'
  } else if (CREAM_ICONS.includes(type) > 0) {
    return 'cream'
  } else if (BANDAGE_ICONS.includes(type) > 0) {
    return 'bandage'
  } else if (POWDER_ICONS.includes(type) > 0) {
    return 'powder'
  } else if (EFFERVESCENT_ICONS.includes(type) > 0) {
    return 'effervescent'
  } else if (PEN_ICONS.includes(type) > 0) {
    return 'pen'
  } else if (EYE_DROP_ICONS.includes(type)) {
    return 'eyeDrop'
  } else if (INHALATOR_ICONS.includes(type)) {
    return 'inhalator'
  } else if (TRANSDERMIC_ICONS.includes(type)) {
    return 'transdermic'
  } else if (MOUTH_ICONS.includes(type)) {
    return 'mouth'
  } else if (NASAL_INHALATOR_ICONS.includes(type)) {
    return 'nasalInhalator'
  } else {
    return null
  }
}

const getIconForType = (type) => {
  switch (type) {
    case 'pill':
      return iconPill
    case 'capsule':
      return iconCapsule
    case 'drops':
      return iconDrops
    case 'suppository':
      return iconSuppository
    case 'bottle':
      return iconSyrup
    case 'granulate':
      return iconSachet
    case 'injection':
      return iconSyringe
    case 'cream':
      return iconCream
    case 'bandage':
      return iconBandAid
    case 'powder':
      return iconPowder
    case 'effervescent':
      return iconEffervescent
    case 'pen':
      return iconPen
    case 'eyeDrop':
      return iconEyeDrop
    case 'inhalator':
      return iconInhaler
    case 'mouth':
      return iconMouth
    case 'transdermic':
      return iconTransdermic
    case 'nasalInhalator':
      return iconNasalInhalator
    default:
      return ''
  }
}
const MedicineTypeIcon = ({type, color = 'white', small}) => {
  const icon = getIconForType(getDescriptionForType(type))
  return icon ? <img className={`custom-icon ${color} ${small ? 'small' : ''}`} src={icon} alt='Pill Medicine' /> : null
  // return <div>
  //   {/* <span>{getDescriptionForType(type)}</span> */}
  //   <div className='my-icon'>
  //     <img src={iconPill} alt='Pill Medicine' />
  //   </div>
  //   {/* <span className='fa fa-pills' /> */}
  // </div>
}

MedicineTypeIcon.propTypes = {
  type: PropTypes.number.isRequired,
  color: PropTypes.string
}

export default MedicineTypeIcon
