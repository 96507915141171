import React from 'react'
import defaultDoctor from '../../../../../resources/images/user-md-icon-green.svg'

const ProfileSectionsHeader = ({person}) => {
  return <div className='id-container'>
    <div className='photo'>
      {/* <div className='photo-edit-container selectable'> */}
      {/*  <span className='fas fa-camera' /> */}
      {/* </div> */}
      <img className='profile-photo' src={person.photo || defaultDoctor} alt='User Profile' />
    </div>
    <div className='information'>
      <div className='name'>{person.name}</div>
      <div data-tip={'Cédula Profissional'} className='number'>{person.professionalNumber}</div>
      {/* <div className='secondary-info'> */}
      {/*  <div><span className='text-muted'>Profissão: </span><span */}
      {/*    style={{ fontWeight: '900' }}>{getProfession(gender, profession)}</span></div> */}
      {/*  <div><span className='text-muted'>Especialidade: </span><span */}
      {/*    style={{ fontWeight: '900' }}>{specialty}</span></div> */}
      {/* </div> */}
    </div>
  </div>
}

export default ProfileSectionsHeader
