import React from 'react'
import Form from '../../../../../common/formComponents/Form'
import FormElement from '../../../../../common/formComponents/FormElement'

const DocumentAdditionForm = () => {
  return <div>
    <Form handleSubmit={() => {}}>
      <FormElement
        inputSize={6}
        type={'select'}
        label={'Tipo de Documento'}
        options={[
          { value: 'identityCard', displayValue: 'CC' },
          { value: 'passport', displayValue: 'Passaporte' },
          { value: 'driverLicense', displayValue: 'Carta de Condução' },
          { value: 'socSecNumber', displayValue: 'Segurança Social' },
          { value: 'fiscalNumber', displayValue: 'NIF' }]}
        name={'documentType'} />
      <FormElement inputSize={6} type={'text'} label={'Número do Documento'} name={'documentNumber'} />
    </Form>
  </div>
}

export default DocumentAdditionForm
