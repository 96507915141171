import React, { Fragment } from 'react'

import './style.css'
import { Button } from '../../../../common/Button/Button'
import Selector from '../../../../common/formComponents/Selector'

class EnhancedSearch extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      params: {
        value: '',
        name: '',
        birth: '',
        gender: ''
      },
      enhanced: false
    }
  }

  handleChange (e) {
    const {value} = e.target
    this.setState({ params: { value } })
    const parsedValue = parseInt(value)
    if (value !== '') {
      if (parsedValue.toString() === 'NaN') {
        this.setState({enhanced: true, params: { name: value }})
      } else {
        this.setState({enhanced: false})
      }
    }
  }

  checkVisibleInput () {
    const {name, birth, gender} = this.state.params
    if (!name && !birth && !gender) {
      this.setState({enhanced: false})
    }
  }

  handleEnhancedChange (name, value) {
    const params = {...this.state.params}
    params[name] = value
    const currentSearchParamsLength = Object.values(params).filter(p => p !== '').length
    this.setState({params}, () => {
      this.checkVisibleInput()
      if (value.length >= this.props.minSearchLength) {
        this.props.search(this.state.params)
      } else if (currentSearchParamsLength < 1) {
        this.props.search({})
      }
    })
  }

  enhancedDataSubmit (e) {
    e.preventDefault()
    window.alert('Not yet')
  }

  renderCompleteSearch () {
    return <Fragment>
      <div className='grouped-input input-group'>
        <span className='input-group-addon'><span>Nasc</span></span>
        <input
          required
          type='date'
          value={this.state.params['birth'] || ''}
          name='birth'
          placeholder='Nasc'
          className='form-control input-sm'
          onChange={(e) => this.handleEnhancedChange(e.target.name, e.target.value)} />
      </div>
      <div className='grouped-input input-group'>
        <span className='input-group-addon'><span>Sexo</span></span>
        <Selector
          required
          handleChange={(name, value) => this.handleEnhancedChange(name, value)}
          type={'select'}
          name={'gender'}
          options={[{value: 'male', displayValue: 'Masculino'}, {value: 'female', displayValue: 'Feminino'}]} />
      </div>
    </Fragment>
  }

  renderEnhanced () {
    return <form autoComplete='off' className='grouped-inputs-container' onSubmit={this.enhancedDataSubmit.bind(this)}>
      <div className='form-group form-inline grouped-inputs'>
        <div className='grouped-input input-group'>
          <span className='input-group-addon'><span>Nome</span></span>
          <input
            required
            autoFocus
            value={this.state.params['name'] || ''}
            type='text'
            name='name'
            placeholder='Nome'
            className='form-control input-sm'
            onChange={(e) => this.handleEnhancedChange(e.target.name, e.target.value)} />
        </div>
        {this.renderCompleteSearch()}
        <div className='pull-right'>
          <Button
            text={<span className='glyphicon glyphicon-search' />}
            buttonType={'btn btn-info'}
            type={'submit'} />
        </div>
      </div>
    </form>
  }

  handleKeyPress (e) {
    if (e.key === 'Enter') {
      console.log(this.state.params.value)
      this.props.handleSubmit(this.state.params.value)
    }
  }

  renderSimple () {
    return <div className='input-group'>
      <input
        onKeyPress={this.handleKeyPress.bind(this)}
        autoFocus={this.props.focus}
        type='text'
        value={this.state.params.value || ''}
        placeholder='Nº Utente'
        className='form-control'
        onChange={this.handleChange.bind(this)} />
      <div className='input-group-btn'>
        <Button
          onClick={() => this.props.handleSubmit(this.state.params.value)}
          text={<span className='glyphicon glyphicon-search' />}
          buttonType={'btn btn-info'}
          type={'button'} />
      </div>
    </div>
  }

  render () {
    return <div>
      {this.state.enhanced
        ? this.renderEnhanced()
        : this.renderSimple()
      }
    </div>
  }
}

EnhancedSearch.defaultProps = {
  minSearchLength: 3
}

export default EnhancedSearch
