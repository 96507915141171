import React from 'react'
import PropTypes from 'prop-types'

const Button = (props) => {
  return <button disabled={props.disabled} type={props.type} className={`btn ${props.buttonType}`} onClick={props.onClick}>{props.text}</button>
}

Button.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  buttonType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func
}

Button.defaultProps = {
  disabled: false,
  buttonType: 'btn-default'
}

export { Button }
