const ADD_ITEM = 'basket//ADD_ITEM'
const CHANGE_ITEM = 'basket//CHANGE_ITEM'
const REMOVE_ITEM = 'basket//REMOVE_ITEM'
const REMOVE_ALL = 'basket//REMOVE_ALL'

export {
  ADD_ITEM,
  CHANGE_ITEM,
  REMOVE_ITEM,
  REMOVE_ALL
}
