const FETCHING_DCIS = 'medicineType//FETCHING_DCIS'
const FETCH_DCIS_SUCCESS = 'medicineType//FETCH_DCIS_SUCCESS'
const FETCH_DCIS_FAILURE = 'medicineType//FETCH_DCIS_FAILURE'
const FETCHING_PHARMACEUTICAL_FORMS = 'medicineType//FETCHING_PHARMACEUTICAL_FORMS'
const FETCH_PHARMACEUTICAL_FORMS_SUCCESS = 'medicineType//FETCH_PHARMACEUTICAL_FORMS_SUCCESS'
const FETCH_PHARMACEUTICAL_FORMS_FAILURE = 'medicineType//FETCH_PHARMACEUTICAL_FORMS_FAILURE'
const FETCHING_ADMINISTRATION_WAYS = 'medicineType//FETCHING_ADMINISTRATION_WAYS'
const FETCH_ADMINISTRATION_WAYS_SUCCESS = 'medicineType//FETCH_ADMINISTRATION_WAYS_SUCCESS'
const FETCH_ADMINISTRATION_WAYS_FAILURE = 'medicineType//FETCH_ADMINISTRATION_WAYS_FAILURE'
const FETCHING_PRODUCT_TYPES = 'medicineType//FETCHING_PRODUCT_TYPES'
const FETCH_PRODUCT_TYPES_SUCCESS = 'medicineType//FETCH_PRODUCT_TYPES_SUCCESS'
const FETCH_PRODUCT_TYPES_FAILURE = 'medicineType//FETCH_PRODUCT_TYPES_FAILURE'
const FETCHING_PACKAGING_TYPES = 'medicineType//FETCHING_PACKAGING_TYPES'
const FETCH_PACKAGING_TYPES_SUCCESS = 'medicineType//FETCH_PACKAGING_TYPES_SUCCESS'
const FETCH_PACKAGING_TYPES_FAILURE = 'medicineType//FETCH_PACKAGING_TYPES_FAILURE'
const FETCHING_PATHOLOGY_DIPLOMAS = 'medicineType//FETCHING_PATHOLOGY_DIPLOMAS'
const FETCH_PATHOLOGY_DIPLOMAS_SUCCESS = 'medicineType//FETCH_PATHOLOGY_DIPLOMAS_SUCCESS'
const FETCH_PATHOLOGY_DIPLOMAS_FAILURE = 'medicineType//FETCH_PATHOLOGY_DIPLOMAS_FAILURE'

export {
  FETCHING_DCIS,
  FETCH_DCIS_SUCCESS,
  FETCH_DCIS_FAILURE,
  FETCHING_PHARMACEUTICAL_FORMS,
  FETCH_PHARMACEUTICAL_FORMS_SUCCESS,
  FETCH_PHARMACEUTICAL_FORMS_FAILURE,
  FETCHING_ADMINISTRATION_WAYS,
  FETCH_ADMINISTRATION_WAYS_SUCCESS,
  FETCH_ADMINISTRATION_WAYS_FAILURE,
  FETCHING_PRODUCT_TYPES,
  FETCH_PRODUCT_TYPES_SUCCESS,
  FETCH_PRODUCT_TYPES_FAILURE,
  FETCHING_PACKAGING_TYPES,
  FETCH_PACKAGING_TYPES_SUCCESS,
  FETCH_PACKAGING_TYPES_FAILURE,
  FETCHING_PATHOLOGY_DIPLOMAS,
  FETCH_PATHOLOGY_DIPLOMAS_SUCCESS,
  FETCH_PATHOLOGY_DIPLOMAS_FAILURE
}
