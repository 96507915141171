import v4 from 'uuid/v4'
import { RELATIVES } from '../models/FamilyBackground'
import { LATERALITY, STATUS } from '../models/Diagnosis'

const testPersonalBackgrounds = [
  {
    id: v4(),
    date: '2019-01-01',
    laterality: LATERALITY.LEFT,
    status: STATUS.RESOLVED,
    endDate: '2019-03-01',
    diagnosis: 'Fratura do Úmero'
  }
]

const testFamilyBackgrounds = [
  {
    id: v4(),
    diagnosis: 'Colesterol Alto',
    relative: RELATIVES.FATHER,
    observations: 'Observação'
  },
  {
    id: v4(),
    diagnosis: 'Cancro da Tirôide',
    relative: RELATIVES.MOTHER
  }
]

export { testFamilyBackgrounds, testPersonalBackgrounds }
