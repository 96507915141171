import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { modalsActions } from '../../../redux/modals/index'
import { ReactModal } from '../ReactModal/ReactModal'

const ModalsPortal = ({modals, close}) => {
  return modals.map((item, i) => {
    return <ReactModal
      key={i}
      name={item.title}
      toggleModal={() => close(item.id)}
      footer={item.footer}
      fullWidth={item.fullWidth}
      modalIsOpen>
      <Fragment>{item.content}</Fragment>
    </ReactModal>
  })
}

function mapStateToProps ({ modals }) {
  return { modals }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({close: modalsActions.close}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalsPortal)
