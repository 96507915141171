import React from 'react'
import PropTypes from 'prop-types'

import Autosuggest from 'react-autosuggest'

import './style.css'

export default class AutoCompleter extends React.Component {
  constructor (props) {
    super(props)
    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: props.defaultValue || '',
      suggestions: []
    }
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this)
    this.getSuggestions = this.getSuggestions.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.resetField && prevProps.resetField !== this.props.resetField) {
      this.clearField()
    }
  }

  // Use your imagination to render suggestions.
  renderSuggestion (suggestion) {
    return <div>
      {suggestion.displayValue}
    </div>
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue (suggestion) {
    return suggestion.displayValue
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions (value) {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const values = inputLength === 0 ? [] : this.props.options.filter(lang =>
      lang.displayValue.toLowerCase().slice(0, inputLength) === inputValue)
    // if (values.length === 0) {
    //   this.props.searchBeyondResults(inputValue)
    // }
    return values
  }

  onChange (event, { newValue }) {
    this.props.handleChange(newValue)
    this.setState({
      value: newValue
    })
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested ({ value }) {
    this.props.handleChange(value)
    this.setState({
      suggestions: this.getSuggestions(value)
    })
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested () {
    this.setState({
      suggestions: []
    })
  };

  onSuggestionSelected (e, suggestion) {
    if (this.props.onSuggestionSelected) {
      this.props.onSuggestionSelected(suggestion.suggestion)
    }
    this.setState({
      value: suggestion.suggestion.displayValue
    })
  }

  shouldRenderSuggestions (value) {
    return value.trim().length >= this.props.minChar
  }

  onKeyPress (e) {
    if (e.key === 'Enter') {
      this.props.searchBeyondResults(this.state.value)
      // this.props.handleSubmit(this.state.params.value)
    }
  }

  clearField () {
    this.setState({
      suggestions: [],
      value: this.props.defaultValue || ''
    })
  }

  render () {
    const { value, suggestions } = this.state

    // Autosuggest will pass through all these props to the input.
    const { autoFocus, placeholder, name, disabled, required, className = 'form-control' } = this.props
    const inputProps = {
      disabled,
      required,
      name,
      placeholder,
      onKeyPress: this.onKeyPress.bind(this),
      value,
      autoFocus,
      onFocus: this.onFocus,
      onChange: this.onChange,
      className
    }
    const theme = {
      suggestionsContainerOpen: 'autocomplete-dropdown-container',
      suggestionsList: 'list-group autocomplete-list popped-list',
      suggestion: 'autocomplete-list-suggestion list-group-item'
    }
    return <Autosuggest
      theme={theme}
      onSuggestionSelected={this.onSuggestionSelected}
      suggestions={suggestions}
      shouldRenderSuggestions={this.shouldRenderSuggestions}
      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      getSuggestionValue={this.getSuggestionValue}
      renderSuggestion={this.renderSuggestion}
      inputProps={inputProps}
    />
  }
}

AutoCompleter.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  minChar: PropTypes.number.isRequired,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  onSuggestionSelected: PropTypes.func,
  handleChange: PropTypes.func,
  resetField: PropTypes.bool,
  searchBeyondResults: PropTypes.func
}

AutoCompleter.defaultProps = {
  handleChange: () => {},
  onSearchChange: () => {},
  searchBeyondResults: () => {},
  minChar: 3,
  resetField: false
}
