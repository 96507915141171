import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { getChecked } from './FormElement'
import { DisabledInput } from './Input'

class BoxSelect extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      [props.groupName]: []
    }
  }

  componentDidMount () {
    const {defaultValue, type, groupName} = this.props
    if (type === 'checkbox') {
      this.setState({
        [groupName]: defaultValue
      })
    } else {
      if (defaultValue) {
        this.setState({
          [groupName]: [defaultValue]
        })
      }
    }
  }

  handleChange ({name, value, type, checked: checkStatus}) {
    if (type === 'checkbox') {
      const checked = getChecked(this.state[name], name, value, checkStatus)
      this.setState({
        [name]: checked
      })
      this.props.onChange && this.props.onChange(name, checked)
      this.props.handleChange(name, checked)
    } else {
      this.setState({
        [name]: [value]
      })
      if (this.props.onChange) {
        this.props.onChange(name, value)
      }
      this.props.handleChange(name, value)
    }
  }

  render () {
    const {groupName, disabled, options, type, required} = this.props
    return options.map((option, index) => {
      return <Fragment key={index}>
        <label className='radio-inline'>
          {disabled
            ? this.state[groupName].includes(option.value) ? <DisabledInput content={option.displayValue} /> : null
            : type === 'radio'
              ? <Radio
                required={required}
                checked={this.state[groupName].includes(option.value)}
                value={option.value}
                handleChange={this.handleChange.bind(this)}
                disabled={disabled}
                name={groupName} />
              : <Checkbox
                required={required}
                checked={this.state[groupName].includes(option.value)}
                value={option.value}
                handleChange={this.handleChange.bind(this)}
                disabled={disabled}
                name={groupName} />
          }
          {disabled ? null : `${option.displayValue}`}
        </label>
      </Fragment>
    })
  }
}

const Checkbox = ({disabled, handleChange, name, value, checked}) => {
  return <input
    disabled={disabled}
    onChange={(e) => handleChange(e.target)}
    checked={checked}
    type={'checkbox'}
    name={name}
    value={value} />
}

const Radio = ({disabled, handleChange, name, value, checked, required}) => {
  return <input
    required={required}
    disabled={disabled}
    onChange={(e) => handleChange(e.target)}
    checked={checked}
    type={'radio'}
    name={name}
    value={value} />
}

BoxSelect.propTypes = {
  groupName: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({value: PropTypes.string, displayValue: PropTypes.string})).isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  handleChange: PropTypes.func
}

BoxSelect.defaultProps = {
  handleChange: () => {},
  options: []
}

export default BoxSelect
