import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import './style.css'

const CustomLink = (props) => {
  return <li>
    <NavLink
      activeClassName='active-nav'
      to={props.to} exact={props.exact}>{props.label}</NavLink>
  </li>
}

CustomLink.propTypes = {
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
}

export { CustomLink }
