import React from 'react'
import PropTypes from 'prop-types'

import './style.css'
import { convertReceiptToPrescribedMedicine } from '../../../../../../../resources/utilities'
import ConfirmationMedicineView from '../../MedicineView/ConfirmationMedicineView/ConfirmationMedicineView'

const ReceiptVisualizer = ({receipt}) => {
  if (receipt !== undefined) {
    return <div>
      <ul className='list-group'>
        {
          convertReceiptToPrescribedMedicine(receipt.medicines).map(({renewable, dosage, medicine, quantity}, i) => {
            return <li key={i} className='list-group-item medicine-list-line'>
              <div className='clearfix'>
                <ConfirmationMedicineView
                  editable={false}
                  administrationType={medicine.administrationType}
                  medicineType={medicine.medicineType}
                  commercialName={medicine.commercialName}
                  dose={medicine.dose}
                  renewable={renewable}
                  quantity={quantity}
                  dosage={dosage}
                  units={medicine.units}
                  activeComponent={medicine.activeComponent}
                  generic={medicine.isGeneric}
                  packageType={medicine.packageType} />
              </div>
            </li>
          })
        }
      </ul>
    </div>
  } else {
    return null
  }
}

ReceiptVisualizer.propTypes = {
  receipt: PropTypes.object.isRequired
}

export default ReceiptVisualizer
