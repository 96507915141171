import React from 'react'
import PropTypes from 'prop-types'

const Range = ({label, name, disabled, min, max, step, defaultValue = (max / 2), handleChange}) => {
  if (defaultValue) {
    handleChange(name, defaultValue)
  }
  return <input
    onChange={(e) => handleChange(e.target.name, e.target.value)}
    name={name}
    disabled={disabled}
    className='form-control'
    type='range'
    defaultValue={parseInt(defaultValue, 10)}
    min={min}
    max={max}
    step={step} />
}

Range.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func
}

Range.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  handleChange: () => {}
}

export default Range
