import React from 'react'
import PropTypes from 'prop-types'

const LabelWrapper = ({label, name, input, inputSize, formType}) => {
  const labelSizeValue = formType === 'inline' ? '' : `col-sm-${12 - inputSize}`
  const inputSizeValue = formType === 'inline' ? '' : `col-sm-${inputSize}`
  return <div className='form-group'>
    <label htmlFor={`input-${name}`} className={`${labelSizeValue} control-label`}>{label}</label>
    <div className={`${inputSizeValue}`}>
      {input}
    </div>
  </div>
  // return <div className='uk-margin'>
  //   <label className='uk-form-label label-align-right' htmlFor={name}>{label}</label>
  //   <div className='uk-form-controls'>
  //     {input}
  //   </div>
  // </div>
}

LabelWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inputSize: PropTypes.number.isRequired,
  input: PropTypes.node.isRequired
}

LabelWrapper.defaultProps = {
  inputSize: 10
}

export default LabelWrapper
