import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import userIcon from '../../../resources/images/user-icon-2.svg'

import './style.css'
import { Link, NavLink } from 'react-router-dom'
import Patient from '../../../models/Patient'
import { getAgeString } from '../../../resources/utilities'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'

class SideButton extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: props.defaultExpanded || false
    }
    this.handleItemClick = this.handleItemClick.bind(this)
  }

  componentDidMount () {
    const {location, subNavItems = []} = this.props
    const currentLocation = location.pathname.split('/')
    const lastRoute = currentLocation[currentLocation.length - 1]
    subNavItems.forEach(item => {
      const routes = item.to.split('/')
      const last = routes[routes.length - 1]
      if (last === lastRoute) {
        this.setState({
          expanded: true
        })
      }
    })
  }

  renderSubnavItems (items, selectInner) {
    return items.map((item, index) => {
      return <Fragment key={index}>
        <li data-tip={item.title} className={`nav-item inner-item ${item.inactive ? 'disabled' : ''}`} tabIndex={index}>
          <NavLink activeClassName='active' to={item.to} disabled={item.inactive} onClick={selectInner}>
            <span className={`fas ${item.icon}`} />
            <div className='text'>
              {item.title}
              {item.notification
                ? <span className='badge'>{item.notification}</span>
                : null
              }
            </div>
          </NavLink>
        </li>
        <ReactTooltip className='custom-tooltip' delayShow={300} position='right' effect='solid' type='light' />
      </Fragment>
    })
  }

  selectInner () {
    this.props.closeMenu()
  }

  handleItemClick () {
    const { subNavItems = [], history } = this.props
    if (subNavItems.length === 1) {
      history.replace(subNavItems[0].to)
      this.setState({
        expanded: true
      })
    }
    if (subNavItems.length > 1) {
      this.setState({
        expanded: !this.state.expanded
      })
    } else {
      this.props.closeMenu()
    }
  }

  render () {
    const { text, icon, index, link = '#', inactive = false, subNavItems = [], notification, exact } = this.props
    return <Fragment>
      <li data-tip={text} className={`nav-item ${inactive ? 'disabled' : ''}`} tabIndex={index}>
        <NavLink exact={exact} activeClassName='active' to={subNavItems.length < 1 ? link : '#'} disabled={inactive} onClick={() => { this.handleItemClick() }}>
          <span className={`fas ${icon}`} />
          <div className='text'>
            {text}
            {notification
              ? <span className='badge'>{notification}</span>
              : null
            }
            <div className='caret-container'>
              {subNavItems.length
                ? <span className='caret' />
                : null
              }
            </div>
          </div>
        </NavLink>
      </li>
      {this.state.expanded
        ? this.renderSubnavItems(subNavItems, this.selectInner.bind(this))
        : null
      }
      <ReactTooltip className='custom-tooltip' delayShow={300} position='right' effect='solid' type={'light'} />
    </Fragment>
  }
}

const PatientId = ({patient}) => {
  const { photo, birth, healthcareNumber, gender } = patient
  const birthDate = moment(new Date(birth))
  return <div className='patient-identification-box'>
    <Link to={`/contacts/${patient.healthcareNumber}`}><img className='photo' src={photo || userIcon} alt='User Profile' /></Link>
    <div className='information'>
      <Link to={`/contacts/${patient.healthcareNumber}`}><div className='name'>{patient.safeLengthName(40)}</div></Link>
      <hr />
      <div className='birthDate'>
        <span className={`gender fas fa-${gender === 'male' ? 'mars' : 'venus'}`} />
        {`${birthDate.format('L')} (${getAgeString(birth)})`}
      </div>
      <div className='healthcareNumber'><span className='text-muted'>SNS - </span> {healthcareNumber}</div>
    </div>
  </div>
}

export default class SideNav extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false
    }
  }

  closeMenu () {
    this.setState({
      visible: false
    })
  }

  render () {
    const { visible } = this.state
    const { items, children, patient, location, history } = this.props
    return <div className={'sidenav-container'}>
      <div className='smartphone-menu-trigger' onClick={() => this.setState({visible: !visible})}>
        <span className='fas fa-bars' />
      </div>
      <nav className={`menu ${visible ? 'open' : ''}`} tabIndex='0'>
        <div className={`expand-button`}>
          <span onClick={() => this.setState({visible: !visible})} className={`fas fa-angle-double-right ${visible ? 'fa-flip-horizontal' : ''}`} />
        </div>
        <div onClick={this.closeMenu.bind(this)} ><span className='fas fa-times close-button' /></div>
        {patient
          ? <PatientId patient={patient} />
          : null
        }
        <ul className={'items'}>
          {items.map((item, i) => {
            if (item.separator) {
              return <hr key={i} className='no-margin-hr' style={{borderTopWidth: '2px'}} />
            } else {
              return <SideButton exact={item.exact} closeMenu={this.closeMenu.bind(this)} defaultExpanded={item.defaultExpanded} history={history} location={location} notification={item.notification} subNavItems={item.subNavItems} key={i} text={item.title} icon={item.icon} link={item.to} index={i} inactive={item.inactive} />
            }
          })}
        </ul>
      </nav>
      <div className='content'>
        {children}
      </div>
    </div>
  }
}

SideNav.defaultProps = {
  items: []
}

SideNav.propTypes = {
  patient: PropTypes.instanceOf(Patient),
  location: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({title: PropTypes.string, icon: PropTypes.string, to: PropTypes.string, inactive: PropTypes.bool}))
}
