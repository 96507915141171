import React, { Component, Fragment } from 'react'

import './style.css'
import { Panel } from '../../../../common/Panel/Panel'
import { Button } from '../../../../common/Button/Button'
import { ReactModal } from '../../../../common/ReactModal/ReactModal'
import { DisabledInput } from '../../../../common/formComponents/Input'

const CreditCard = ({lastDigits, cardType}) => {
  let type = 'fas fa-credit-card'
  if (cardType === 'visa') {
    type = 'fab fa-cc-visa'
  } else if (cardType === 'master') {
    type = 'fab fa-cc-mastercard'
  } else if (cardType === 'amex') {
    type = 'fab fa-cc-amex'
  }
  return <div className='list-group-item card-line'>
    <div className='card-type'>
      {<i className={`${type} fa-2x`} />}
    </div>
    <div className='card-number'>{`Cartão que termina em `}<strong>{lastDigits}</strong></div>
    <div className='action-buttons'>
      <Button type={'button'} buttonType={'btn-danger btn-xs'} text={<span className='fas fa-trash-alt' />} />
    </div>
  </div>
}

class CreditCardForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      cardNumber: ''
    }
  }

  render () {
    return <div className='col-xs-12 col-sm-8 col-sm-offset-2 billing-form'>
      <div className='col-xs-12 margin-bottom-form-input'>
        <div className='form-group'>
          <div className='col-sm-12'>
            <div className='input-group'>
              <div className='input-group-addon btn-left'><i className='fas fa-user' /></div>
              <input
                type='text'
                className='form-control'
                name='cardName'
                placeholder='Nome' />
            </div>
          </div>
        </div>
      </div>
      <div className='col-xs-12 margin-bottom-form-input'>
        <div className='form-group'>
          <div className='col-sm-12'>
            <div className='input-group'>
              <div className='input-group-addon btn-left'><i className='fas fa-credit-card' /></div>
              <input
                type='text'
                className='form-control'
                name='cardNumber'
                placeholder='Número' />
            </div>
          </div>
        </div>
      </div>
      <div className='col-xs-12'>
        <div className='form-group'>
          <div className='col-sm-6'>
            <div className='input-group'>
              <div className='input-group-addon btn-left'><i className='fas fa-calendar' /></div>
              <input
                type='text'
                className='form-control'
                name='expiryMonthYear'
                placeholder='MM/YY' />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='input-group'>
              <div className='input-group-addon btn-left'><i className='fas fa-lock' /></div>
              <input
                type='number'
                maxLength={3}
                className='form-control last-right-child'
                name='cvc2'
                id='CVV'
                placeholder='CVV' />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

class CreditCardsPanel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }
  render () {
    return <Panel
      rightHeader={
        <Button
          onClick={this.toggleModal.bind(this)}
          type={'button'}
          buttonType={'btn-success btn-xs'}
          text={<span className='fas fa-plus' />} />}
      heading='Cartões'>
      <Fragment>
        <div className='cards-list list-group'>
          <CreditCard cardType={'visa'} lastDigits={'1234'} />
        </div>
        <ReactModal
          footer={<Button type={'button'} text={'Guardar'} buttonType={'btn-success pull-right'} />}
          toggleModal={this.toggleModal.bind(this)}
          name={'Adicionar Cartão'}
          modalIsOpen={this.state.modalIsOpen}>
          <CreditCardForm />
        </ReactModal>
      </Fragment>
    </Panel>
  }
}

const FiscalDataForm = () => {
  return <form className='fiscal-form'>
    <div className='form-group'>
      <label htmlFor='name'>Nome</label>
      <input type='text' className='form-control' id='name' placeholder='Nome' />
    </div>
    <div className='form-group'>
      <label htmlFor='address'>Morada</label>
      <input type='text' className='form-control' id='address' placeholder='Morada' />
    </div>
    <div className='form-group clearfix no-side-padding'>
      <div className='col-sm-6 inner-left-column'>
        <label htmlFor='address'>Código Postal</label>
        <input
          type='text'
          className='form-control'
          name='postalCode'
          id='postalCode'
          placeholder='' />
      </div>
      <div className='col-sm-6 inner-right-column'>
        <label htmlFor='address'>Localidade</label>
        <input
          type='text'
          className='form-control'
          name='location'
          id='location'
          placeholder='' />
      </div>
    </div>
    <div className='form-group'>
      <label htmlFor='vat'>NIF/NIPC</label>
      <input type='text' className='form-control' id='vat' placeholder='NIF' />
    </div>
  </form>
}

class FiscalDataPanel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }
  render () {
    return <Fragment>
      <Panel
        heading={'Informação Fiscal'} rightHeader={
          <Button
            onClick={this.toggleModal.bind(this)}
            type={'button'}
            buttonType={'btn-info btn-xs'}
            text={<span className='fas fa-edit' />} />}>
        <form className='fiscal-form'>
          <div className='form-group'>
            <label htmlFor='name'>Nome</label>
            <DisabledInput content={''} />
          </div>
          <div className='form-group'>
            <label htmlFor='address'>Morada</label>
            <DisabledInput content={''} />
          </div>
          <div className='form-group clearfix'>
            <div className='col-sm-6 inner-left-column'>
              <label htmlFor='address'>Código Postal</label>
              <DisabledInput placeholder={''} content={''} />
            </div>
            <div className='col-sm-6 inner-right-column'>
              <label htmlFor='address'>Localidade</label>
              <DisabledInput placeholder={''} content={''} />
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='vat'>NIF/NIPC</label>
            <DisabledInput content={''} />
          </div>
        </form>
      </Panel>
      <ReactModal
        footer={<Button type={'button'} text={'Guardar'} buttonType={'btn-success pull-right'} />}
        toggleModal={this.toggleModal.bind(this)}
        name={'Alterar Dados Fiscais'}
        modalIsOpen={this.state.modalIsOpen}>
        <FiscalDataForm />
      </ReactModal>
    </Fragment>
  }
}

const Billing = () => {
  return <div className='container'>
    <div className='col-xs-12'>
      <CreditCardsPanel />
    </div>
    <div className='col-xs-12'>
      <FiscalDataPanel />
    </div>
  </div>
}

export default Billing
