import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import './style.css'
import moment from 'moment'

class TextInsertion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      content: ''
    }
  }

  handleSend () {
    const { sendMessage } = this.props
    const { content } = this.state
    if (content !== '' && content !== ' ') {
      sendMessage(content)
      this.setState({
        content: ''
      })
    }
  }

  render () {
    const {content} = this.state
    return <div className='form-inline send-message-box'>
      <textarea
        value={content}
        onChange={(e) => this.setState({content: e.target.value})}
        className='form-control textarea' />
      <button disabled={this.state.content === ''} className='btn btn-default' onClick={this.handleSend.bind(this)}>Send</button>
    </div>
  }
}

const LeftSideBubble = ({content, dateTime}) => {
  return <div className='message'>
    <div className='clearfix'>
      <div className='left-side pull-left'>{content}</div>
    </div>
    <div className='small-text small-text-left text-muted'>{moment(new Date(dateTime)).format('LLL')}</div>
  </div>
}

const RightSideBubble = ({content, dateTime}) => {
  return <div className='message clearfix'>
    <div className='clearfix'>
      <div className='right-side pull-right'>{content}</div>
    </div>
    <div className='small-text pull-right small-text-right text-muted'>{moment(new Date(dateTime)).format('LLL')}</div>
  </div>
}

const MessageBubble = ({message, currentUserId}) => {
  if (message.senderId === currentUserId) {
    return <RightSideBubble {...message} />
  } else {
    return <LeftSideBubble {...message} />
  }
}

const renderMessages = (messages, currentUserId) => {
  return messages.map((message, i) => <MessageBubble key={i} message={message} currentUserId={currentUserId} />)
}

class Conversation extends React.Component {
  componentDidMount () {
    // this.scrollToBottom()
  }

  componentDidUpdate () {
    // this.scrollToBottom()
  }

  scrollToBottom () {
    this.messagesEnd.scrollIntoView({behavior: 'smooth'})
  }

  render () {
    const { messages, sendMessage } = this.props
    return <Fragment>
      <div className='conversation-container'>
        <hr className='no-margin-hr' />
        <h4 className='text-muted text-center'><p>Início da Conversa</p><p>|</p></h4>
        {
          renderMessages(messages, 1)
        }
        {/* Dummy div for scrolling purposes */}
        <div style={{float: 'left', clear: 'both'}} ref={(el) => { this.messagesEnd = el }} />
      </div>
      <div className='send-container'>
        <TextInsertion sendMessage={sendMessage} />
      </div>
    </Fragment>
  }
}

Conversation.propTypes = {
  messages: PropTypes.array,
  sendMessage: PropTypes.func
}

export default Conversation
