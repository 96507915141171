import {
  ADD_ITEM,
  CHANGE_ITEM,
  REMOVE_ITEM,
  REMOVE_ALL
} from './types'

// TODO There is a behaviour that could not be what is pretended when adding multiple times the favorite to the receipt.
//  For now it will stay like this, but will be changed in the future

const basket = (state = [], action) => {
  switch (action.type) {
    case ADD_ITEM:
      let newArr = state.slice()
      const itemIndex = newArr.findIndex(item => item.medicineId === action.payload.item.medicine.id)
      if (itemIndex !== -1) {
        return state.map((item, index) => {
          if (index !== itemIndex) {
            return item
          }
          return {
            ...item,
            ...action.payload.item
          }
        })
      } else {
        const itemIndex = action.payload.index || state.length
        newArr.splice(itemIndex, 0, action.payload.item)
        return newArr
      }
    case CHANGE_ITEM:
      return state.map((item, index) => {
        if (index !== action.payload.index) {
          return item
        } else {
          return {
            ...item,
            ...action.payload.data
          }
        }
      })
    case REMOVE_ITEM:
      // return array.filter((item, index) => index !== action.index)
      // return state.slice().splice(action.payload.index, 1)
      return state.filter((item, index) => index !== action.payload.index)
    case REMOVE_ALL:
      return []
    default:
      return state
  }
}
export default basket
