import React, { Fragment } from 'react'

import './style.css'
import { testDoctorReceipts } from '../../../../../test/testReceipt'
import { Button } from '../../../../common/Button/Button'
import { ReactModal } from '../../../../common/ReactModal/ReactModal'
import ChoosePatient from './ChoosePatient'
import { connect } from 'react-redux'
import { basketActions } from '../../../../../redux/basket'
import { notify } from 'reapop'
import { bindActionCreators } from 'redux'
import { convertItemsForBasket } from '../../../../../resources/utilities'
import ReceiptVisualizer from '../../Patient/Prescription/Receipts/ReceiptVisualizer/ReceiptVisualizer'
import ReceiptList from './ReceiptList/ReceiptList'
import moment from 'moment'

const DefaultFooter = ({sendAvailable, rightButton}) => {
  return <Fragment>
    {sendAvailable
      ? <div className='pull-left margin-bottom'>
        <Button type={'button'} buttonType={'btn-default'} text={'Imprimir'} />
        <Button type={'button'} buttonType={'btn-default'} text={'Enviar por Email'} />
      </div>
      : null
    }
    {rightButton
      ? <div className='footer pull-right'>
        {rightButton}
      </div>
      : null
    }
  </Fragment>
}

const RepeatReceiptButton = ({error, patientNumber = '123', handleChange, handleSubmit}) => {
  return <div className='patient-select form-inline'>
    <div className={`input-group ${error ? 'has-error' : ''}`}>
      <input
        type='text'
        className={`form-control ${error ? 'has-error' : ''}`}
        onChange={handleChange}
        placeholder='Nº SNS' />
      <div className='input-group-btn'>
        <Button
          onClick={() => handleSubmit(patientNumber)}
          text={<Fragment>
            <span className='hidden-sm'>Prescrever</span>
            <span className='visible-sm fa fa-prescription button-fa' />
          </Fragment>}
          buttonType={`btn btn-${error ? 'warning' : 'success'}`}
          type={'button'} />
      </div>
    </div>
  </div>
}

class DoctorHistory extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      openedReceiptNumber: null
    }
  }

  openReceipt (number) {
    this.setState({
      openedReceiptNumber: number
    })
    this.toggleModal()
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  addMedicinesToReceipt (medicines) {
    const { addItems, notify, history, patientHealthcareNumber } = this.props
    addItems(convertItemsForBasket(medicines))
    notify({
      message: `Medicamentos adicionados à Receita.`,
      status: 'info',
      dismissible: true,
      dismissAfter: 5000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
    history.push(`/${patientHealthcareNumber}/prescription/prescribe`)
  }
  addToReceipt () {
    const { openedReceiptNumber } = this.state
    if (openedReceiptNumber) {
      const receipt = testDoctorReceipts.find(r => r.number === openedReceiptNumber)
      this.addMedicinesToReceipt(receipt.medicines)
    }
  }

  render () {
    const { openedReceiptNumber, modalIsOpen } = this.state
    const { props } = this
    return <div className='full-height patient-history-container'>
      <div className='col-md-12 full-height no-side-padding-for-container history-panel'>
        <ReceiptList
          itemClickHandle={this.openReceipt.bind(this)}
          {...props}
          electedReceipt={props.match}
          receipts={testDoctorReceipts}
          repeatButton={<RepeatReceiptButton />} />
      </div>
      {openedReceiptNumber
        ? <ReactModal
          customClass={'modal-lg'}
          toggleModal={this.toggleModal.bind(this)}
          name={`*${testDoctorReceipts.find(r => r.number === openedReceiptNumber).number}* | ${moment(new Date(testDoctorReceipts.find(r => r.number === openedReceiptNumber).date)).format('L')}`}
          modalIsOpen={modalIsOpen}
          footer={<DefaultFooter sendAvailable rightButton={<ChoosePatient patientChoiceSuccess={() => this.addToReceipt()} />} />}>
          <ReceiptVisualizer receipt={testDoctorReceipts.find(r => r.number === openedReceiptNumber)} />
        </ReactModal>
        : null
      }
    </div>
  }
}

const mapStateToProps = ({patient}) => {
  return {
    patientExists: !!patient.data,
    patientHealthcareNumber: patient.patientHealthcareNumber
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({...basketActions, notify}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorHistory)
