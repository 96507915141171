import React from 'react'
import PatientInsertion from './PatientInsertion'

export default class PatientInsertionContainer extends React.Component {
  submitData (data) {
    console.log(data)
  }

  render () {
    return <PatientInsertion handleSubmit={this.submitData} />
  }
}
