import React, { Fragment } from 'react'
import testReceipts  from '../../../../../../../test/testReceipt'
import ReceiptVisualizer from './ReceiptVisualizer'
import { Button } from '../../../../../../common/Button/Button'
import { ReactModal } from '../../../../../../common/ReactModal/ReactModal'
import { basketActions } from '../../../../../../../redux/basket'
import { notify } from 'reapop'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { convertItemsForBasket } from '../../../../../../../resources/utilities'
import moment from 'moment'

const DefaultFooter = ({sendAvailable, rightButton}) => {
  return <Fragment>
    {sendAvailable
      ? <div className='pull-left'>
        <Button type={'button'} text={'Imprimir'} />
        <Button type={'button'} text={'Enviar por Email'} />
      </div>
      : null
    }
    {rightButton
      ? <div className='footer pull-right'>
        {rightButton}
      </div>
      : null
    }
  </Fragment>
}

const ReceiptVisualizerContainer = ({receiptNumber, toggleModal, modalIsOpen, history, addItems, notify, patientHealthcareNumber}) => {
  const receipt = testReceipts.find(r => r.number === receiptNumber)
  const addMedicinesToReceipt = () => {
    addItems(convertItemsForBasket(receipt.medicines))
    notify({
      message: `Medicamentos adicionados à Receita.`,
      status: 'info',
      dismissible: true,
      dismissAfter: 5000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
    history.push(`/${patientHealthcareNumber}/prescription/prescribe`)
  }
  console.log(receipt)
  return <ReactModal
    customClass={'modal-lg'}
    toggleModal={toggleModal}
    name={`Receita nº *${receipt.number}* | ${moment(new Date(receipt.date)).format('L')} | ${receipt.prescriber}`}
    modalIsOpen={modalIsOpen}
    footer={<DefaultFooter sendAvailable rightButton={<Button onClick={addMedicinesToReceipt} type={'button'} buttonType={'btn-success'} text={'Repetir Prescrição'} />} />}>
    <ReceiptVisualizer receipt={receipt} />
  </ReactModal>
}

const mapStateToProps = ({patient}) => {
  return {
    patientHealthcareNumber: patient.patientHealthcareNumber
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({...basketActions, notify}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptVisualizerContainer)
