import React, { Fragment } from 'react'

import './style.css'
import { TabsContainer } from '../../../common/TabsContainer/TabsContainer'
import moment from 'moment'
import { NavLink, Route, Switch } from 'react-router-dom'
import defaultUser from '../../../../resources/images/user-icon-green-2.svg'
import defaultDoctor from '../../../../resources/images/user-md-icon-green.svg'
import { getAgeString, sortByName } from '../../../../resources/utilities'
import { manyPatients } from '../../../../test/testPatient'
import Patient from '../../../../models/Patient'
import ContactView from './ContactView/ContactView'
import ProfessionalStaffView from './ProfessionalStaffView/ProfessionalStaffView'
import { Button } from '../../../common/Button/Button'
import FormElement from '../../../common/formComponents/FormElement'
import Form from '../../../common/formComponents/Form'
import testProfessionals from '../../../../test/testProfessionals'
import MedicalStaff from '../../../../models/MedicalStaff'
import { ReactModal } from '../../../common/ReactModal/ReactModal'
import PatientInsertionContainer from './PatientInsertion/PatientInsertionContainer'
import { userAPI } from '../../../../redux/infarmed'

const all = manyPatients

const recent = [
  ...manyPatients.slice(0, 5)
]

const Search = () => {
  return <div className='margin-bottom'>
    <Form type={'horizontal'} handleSubmit={() => {}}>
      <FormElement type={'text'} label={''} placeholder={'Pesquisar'} name={'search'} />
    </Form>
  </div>
}

const Recent = ({patients, currentUrl}) => {
  return <div>
    {patients.map((item, i) => {
      const patient = new Patient(item.id, item.healthcareNumber, item.name, item.birth, item.gender, item.country, item.contacts, item.email, item.photo)
      return <ContactCard key={i} currentUrl={currentUrl} patient={patient} />
    })}
  </div>
}

const All = ({currentUrl}) => {
  return <Fragment>
    {all.sort(sortByName).map((item, i) => {
      const patient = new Patient(item.id, item.healthcareNumber, item.name, item.birth, item.gender, item.country, item.contacts, item.email, item.photo)
      return <ContactCard key={i} currentUrl={currentUrl} patient={patient} />
    })}
  </Fragment>
}

const Professionals = ({currentUrl}) => {
  return <Fragment>
    {testProfessionals.sort(sortByName).map((item, i) => {
      const person = new MedicalStaff(item.id, item.name, item.birth, item.photo, item.professionalNumber, item.specialty, item.gender, item.profession)
      return <ProfessionalCard key={i} currentUrl={currentUrl} person={person} />
    })}
  </Fragment>
}

const ProfessionalCard = ({currentUrl, person, isSelected}) => {
  const { name, photo, id: userId, specialty } = person
  return <NavLink activeClassName={'active'} to={`${currentUrl}/professional/${userId}`} replace>
    <div className={`contact-card ${isSelected ? 'active' : ''}`}>
      <div className='content'>
        <div className='photo-container'>
          <img className='photo professional' src={photo || defaultDoctor} alt='User Profile' />
        </div>
        <div className='information'>
          <div className='name'>{`${person.getAbreviatedTitle()} ${name}`}</div>
          <div>{person.getProfessionString()}</div>
          <span className='text-muted'>Especialidade:</span> {specialty}
        </div>
      </div>
    </div>
  </NavLink>
}

const ContactCard = ({currentUrl, patient}) => {
  const { name, birth, photo, gender, healthcareNumber } = patient
  const birthDate = moment(new Date(birth))
  return <NavLink activeClassName={'active'} to={`${currentUrl}/${healthcareNumber}`} replace>
    <div className={`contact-card`}>
      <div className='content'>
        <div className='photo-container'>
          <img className='photo' src={photo || defaultUser} alt='User Profile' />
        </div>
        <div className='information'>
          <div className='name'>{name}</div>
          <div className='birthDate'>
            <span className={`gender fas fa-${gender === 'male' ? 'mars' : 'venus'}`} />
            <span className='text-muted'>Idade:</span> {getAgeString(birth)} ({birthDate.format('L')})
          </div>
        </div>
      </div>
    </div>
  </NavLink>
}

const PatientSelector = ({match, createPatient, patients}) => {
  return <div className='selector-content'>
    <div className='col-xs-12 search-container'>
      <Search />
    </div>
    <TabsContainer
      marginBody={'small'}
      content={[
        {
          hint: 'Recentes',
          label: <span className='fas fa-clock' />,
          content: <Recent patients={patients} currentUrl={match.url} />
        },
        {
          hint: 'Todos',
          label: <span style={{fontWeight: 900}}>Aa</span>,
          content: <All currentUrl={match.url} />
        },
        {
          hint: 'Profissionais',
          label: <span className='fas fa-user-md' />,
          content: <Professionals currentUrl={match.url} />
        }
      ]}
      rightButton={<Button onClick={createPatient} text={<span className='fas fa-plus' />} buttonType={'btn-success btn-xs btn-nav-plus'} type={'button'} />} />
  </div>
}

const SelectPatient = () => {
  return <div className='select-patient'>
    <span className='fas fa-list fa-3x' />
    <h3>Por Favor, Selecione um Contacto</h3>
  </div>
}

class Contacts extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      testUsers: recent
    }
  }

  componentDidMount () {
    console.log(userAPI.rnu('198760324'))
    // userAPI.rnu('198760324').then(rnuRes => {
    //   console.log(rnuRes)
    // })
    userAPI.user(356047551).then(result => {
      // birth:
      // "1994-11-11"
        // contacts:
      // Array[1]
      // country:
      // "Portugal"
      // email:
      // "valyo.mir@gmail.com"
      // gender:
      // "female"
      // healthcareNumber:
      // "523456789"
      // id:
      // "101975fa-eea2-4096-8e12-f472bd90d0d5"
      // name:
      // "Vitória Martins Melo"
      // vat:
      // "269557610"
      // 5:
      console.log(result)
      userAPI.contacts(result).then(contacts => {
        const obj = {
          birth: moment(result.birthDate).format('YYYY/MM/DD'),
          contacts: [],
          country: result.nationality.name,
          email: '',
          gender: result.gender.value,
          name: `${result.givenName} ${result.familyName}`,
          vat: '',
          healthcareNumber: result.id,
          id: result.id
        }

        // result.id, result.id, `${result.givenName} ${result.familyName}`, result.birthDate, result.gender.value, result.nationality.name
        const curr = this.state.testUsers
        manyPatients.push(obj)
        curr.push(obj)
      })
    })

  }

  // componentDidMount () {
  //   const users = []
  //   api.User(999999999).then((res) => {
  //     // {
  //     //     id: v4(),
  //     //     name: 'Felipe Gonçalves Santos',
  //     //     birth: '1986-02-10',
  //     //     photo: testUser,
  //     //     healthcareNumber: '223456789',
  //     //     gender: GENDER.MALE,
  //     //     country: 'Portugal',
  //     //     contacts: [
  //     //       963483059
  //     //     ],
  //     //     email: 'paulina.jorge@gmail.com'
  //     //   },
  //     console.log(res)
  //     users.push({
  //       id: res.id,
  //       name: `${res.givenName} ${res.familyName}`,
  //       birth: res.birthDate,
  //       healthcareNumber: 123456789,
  //       gender: res.gender.value,
  //       country: res.nationality.name
  //     })
  //     users.push(manyPatients[0])
  //     this.setState({
  //       testUsers: users
  //     })
  //   })
  // }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }
  render () {
    const { props } = this
    return <div className='container-fluid contacts-container'>
      <div className='col-sm-6 col-md-5 side-container'>
        <Route
          path={`${props.match.url}/:healthcareNumber`}
          children={({match}) => <PatientSelector patients={this.state.testUsers} createPatient={this.toggleModal.bind(this)} selectedPatientNumber={match && match.params.healthcareNumber} {...props} />} />
      </div>
      <div className='col-xs-12 col-sm-6 col-md-7 no-side-padding-for-container'>
        <Switch>
          <Route exact path={`${props.match.url}/`} render={() => <SelectPatient />} />
          <Route path={`${props.match.url}/professional/:id`} render={({match}) => <ProfessionalStaffView match={match} selectedNumber={match.params.id} />} />
          <Route path={`${props.match.url}/:healthcareNumber`} render={({match}) => <ContactView match={match} selectedPatientHealthcareNumber={match.params.healthcareNumber} />} />
        </Switch>
      </div>
      <ReactModal
        name={'Ficha de Inscrição'}
        toggleModal={this.toggleModal.bind(this)}
        modalIsOpen={this.state.modalIsOpen}
        footer={<Button type={'button'} text={'Guardar'} buttonType={'btn-success'} onClick={this.toggleModal.bind(this)} />}
        fullWidth>
        <PatientInsertionContainer />
      </ReactModal>
    </div>
  }
}

export default Contacts
