import React from 'react'
import PropTypes from 'prop-types'
const FieldSet = ({children}) => {
  return <fieldset>
    {children}
  </fieldset>
}

FieldSet.propTypes = {
  children: PropTypes.node.isRequired
}

const Legend = ({text}) => {
  return <legend>{text}</legend>
}

Legend.propTypes = {
  text: PropTypes.string.isRequired
}

export { FieldSet, Legend }
