import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import './style.css'
import { sortByDateDesc } from '../../../resources/utilities'
import ReactTooltip from 'react-tooltip'

export class Timeline extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      elements: null
    }
  }

  componentDidMount () {
    this.updateComponentData(this.props.startDate, this.props.data)
  }

  updateComponentData (startDate, data) {
    if (startDate && data) {
      let elements = {}
      data.sort(sortByDateDesc).forEach(currElement => {
        const date = moment(currElement.date)
        let description = date.format('YYYY')
        if (elements[description]) {
          const element = {
            date: date,
            text: currElement.text,
            state: currElement.state
          }
          elements[description].push(element)
        } else {
          const element = {
            date: date,
            text: currElement.text,
            state: currElement.state
          }
          elements[description] = []
          elements[description].push(element)
        }
      })
      this.setState({
        elements
      })
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.updateComponentData(this.props.startDate, this.props.data)
    }
  }

  renderTooltip () {
    return <div>Ola</div>
  }

  render () {
    return <div>
      {this.state.elements
        ? <div className='timeline'>
          {
            Object.keys(this.state.elements).reverse().map(function (key) {
              return <div key={key}>
                <h3>{key}</h3>
                <ul>
                  {this.state.elements[key].map((value, index) => {
                    return <li key={index} className={value.state}>
                      <div data-tip='' data-for={'global'}>
                        <h4><span>{`${value.text}`}</span></h4>
                        <time>{moment(value.date).format('L')}</time>
                      </div>
                      <ReactTooltip id='global' delayShow={300} effect='solid' type='light'>
                        Conteúdo extra a definir.
                      </ReactTooltip>
                    </li>
                  })}
                </ul>
              </div>
            }.bind(this))
          }
        </div>
        : <h3>Sem Elementos</h3>
      }
    </div>
  }
}

Timeline.propTypes = {
  startDate: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    state: PropTypes.oneOf(['green', 'red', 'yellow', 'purple', 'orange']).isRequired
  }))
}
