import React, { Fragment } from 'react'
import Prescribe from './Prescribe'
import { connect } from 'react-redux'
import { searchActions } from '../../../../../../redux/search'
import { medicineDataActions } from '../../../../../../redux/medicineData'
import { bindActionCreators } from 'redux'
import { convertSearchResultsToMedicinesList } from '../../../../../../resources/utilities'
import { SEARCH } from './Search/SearchForm/SearchForm'
import { notify } from 'reapop'
import ReceiptConfirmationContainer from './ReceiptConfirmation/ReceiptConfirmationContainer'

class PrescribeContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  componentWillUnmount () {
    this.props.resetSearch()
  }

  handleBasketSubmit () {
    const { basketItems, notify } = this.props
    const basketIsMissingDosages = basketItems.filter(item => !item.dosage).length !== 0
    const basketWrongJustificationDays = basketItems.filter(item => item.justificationAdditionalData < 29)
    if (basketWrongJustificationDays.length) {
      notify({
        id: 'wrong-day-number',
        message: `O número de dias mínimo é 29.`,
        status: 'error',
        dismissible: true,
        dismissAfter: 5000,
        position: 'br',
        closeButton: true,
        allowHTML: false
      })
    } else {
      if (basketIsMissingDosages) {
        notify({
          id: 'no-dosages',
          message: `Por Favor, Preencha a Posologia.`,
          status: 'error',
          dismissible: true,
          dismissAfter: 5000,
          position: 'br',
          closeButton: true,
          allowHTML: false
        })
      } else {
        if (this.props.basketHasItems) {
          this.toggleModal()
        } else {
          notify({
            id: 'no-medicines',
            message: `Sem medicamentos.`,
            status: 'info',
            dismissible: true,
            dismissAfter: 5000,
            position: 'br',
            closeButton: true,
            allowHTML: false
          })
        }
      }
    }
  }
  render () {
    const { freeFormSearch, loading, searchHasValue, searchResults, basketHasItems } = this.props
    return <Fragment>
      <ReceiptConfirmationContainer modalIsOpen={this.state.modalIsOpen} toggleModal={this.toggleModal.bind(this)} />
      <Prescribe
        notify={notify}
        emptyBasket={!basketHasItems}
        freeFormPrescription={freeFormSearch}
        searching={loading}
        searchHasValue={searchHasValue}
        handleSubmitClick={this.handleBasketSubmit.bind(this)}
        searchResults={searchResults} />
    </Fragment>
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({resetSearch: searchActions.reset, notify, ...medicineDataActions}, dispatch)
}

function mapStateToProps ({basket, search, medicineData}) {
  return {
    freeFormSearch: search.searchType === SEARCH.FREE_FORM,
    searchResults: convertSearchResultsToMedicinesList(search.results, medicineData.dcis),
    searchHasValue: false,
    loading: search.loading,
    basketItems: basket,
    basketHasItems: !!basket.length
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrescribeContainer)
