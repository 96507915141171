import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { patientActions } from '../../../../redux/patient'
import { connect } from 'react-redux'
import SideNav from '../../../common/SideNav/SideNav'
import Patient from '../../../../models/Patient'
import PrescriptionRouting from './Prescription/PrescriptionRouting'
import Allergies from './Allergies/Allergies'
import Diagnostics from './Diagnostics/Diagnostics'
import { medicineDataActions } from '../../../../redux/medicineData'
import MedicationList from './MedicationList/MedicationList'
import testReceipts from '../../../../test/testReceipt'

const navItems = (currentUrl, guidesBadgeCount) => [
  {
    title: 'Agenda',
    to: `${currentUrl}/schedule`,
    icon: 'fa-calendar-alt',
    inactive: true
  },
  {
    title: 'Mensagens',
    to: `${currentUrl}/messages`,
    icon: 'fa-comments',
    notification: '1',
    inactive: true
  },
  {
    title: 'Prescrição',
    to: `${currentUrl}/prescription`,
    icon: 'fa-prescription',
    defaultExpanded: true,
    subNavItems: [
      {
        title: 'Prescrever',
        to: `${currentUrl}/prescription/prescribe`,
        icon: 'fa-pen'
      },
      {
        title: 'Histórico Receitas',
        to: `${currentUrl}/prescription/history`,
        icon: 'fa-history'
      },
      {
        title: 'Guias Tratamento',
        notification: guidesBadgeCount > 0 ? guidesBadgeCount : null,
        to: `${currentUrl}/prescription/guides`,
        icon: 'fa-tasks'
      }
    ]
  },
  {
    separator: true
  },
  {
    title: 'MCDTs',
    to: `${currentUrl}/mcdts`,
    icon: 'fa-file-medical-alt',
    inactive: true
  },
  {
    title: 'Diagnósticos',
    to: `${currentUrl}/diagnostics`,
    icon: 'fa-diagnoses'
  },
  {
    title: 'Alergias',
    to: `${currentUrl}/allergies`,
    icon: 'fa-allergies'
  },
  {
    title: 'Medicação',
    to: `${currentUrl}/medication`,
    icon: 'fa-prescription-bottle-alt'
  },
  {
    title: 'Ficha Clínica',
    to: `${currentUrl}/clinicalFile`,
    icon: 'fa-file-medical',
    inactive: true
  },
  {
    title: 'Observações',
    to: `${currentUrl}/observations`,
    icon: 'fa-eye',
    inactive: true
  }
]

class PatientRouting extends React.Component {
  componentDidMount () {
    this.props.getDCIS()
    this.props.getPharmaceuticalForms()
    this.props.getAdministrationWays()
    this.props.getProductTypes()
    this.props.getPackagingTypes()
    this.props.getPathologyDiplomas()
    const { patient, match, setPatient } = this.props
    if (!patient || patient.id !== match.params.id) {
      setPatient(match.params.id)
        .then(() => {})
        .catch(() => {})
    }
  }

  componentWillUnmount () {
    this.props.removePatient()
  }

  render () {
    const { match, patient, location, loadingMedicineData, history } = this.props
    const guidesCounter = testReceipts.length
    if (patient && !loadingMedicineData) {
      return <SideNav history={history} location={location} items={navItems(match.url, guidesCounter)} patient={patient}>
        <Switch>
          <Route
            exact
            path={`${match.url}`}
            render={() => <Redirect to={`${match.url}/prescription`} replace />} />
          <Route
            path={`${match.url}/prescription`}
            render={props => <PrescriptionRouting {...props} />} />
          <Route
            path={`${match.url}/allergies`}
            render={() => <Allergies />} />
          <Route
            path={`${match.url}/diagnostics`}
            render={() => <Diagnostics />} />
          <Route
            path={`${match.url}/medication`}
            render={() => <MedicationList />} />
        </Switch>
      </SideNav>
    } else {
      return <div className='loading-window'>
        <span className='fas fa-spinner fa-4x fa-pulse' />
      </div>
    }
  }
}

// const PatientPrescriptionRouting2 = (props) => {
//   return <SideNav items={navItems(props.match.url)} patient={props.patient}>
//     <Content {...props} />
//   </SideNav>
// }

const mapStateToProps = ({patient, medicineData}) => {
  let patientObject
  if (patient.data) {
    const { id, healthcareNumber, name, birth, gender, country, contacts, email, photo } = patient.data
    patientObject = new Patient(id, healthcareNumber, name, birth, gender, country, contacts, email, photo)
  }
  const { administrationWays, productTypes, dcis, pharmaceuticalForms, pathologyDiplomas } = medicineData
  const loadingMedicineData = !!dcis.length &&
    !!productTypes.length &&
    !!pharmaceuticalForms.length &&
    !!administrationWays.length &&
    !!pathologyDiplomas.length
  return {
    loadingMedicineData: !loadingMedicineData,
    patient: patientObject,
    fetching: patient.fetching,
    error: patient.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...patientActions, ...medicineDataActions}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientRouting)
