const testProfessionals = [
  {
    id: '123',
    name: 'Jorge Pires Costa e Silva',
    birth: '',
    professionalNumber: 'OM:34343',
    profession: 'doctor',
    specialty: 'Medicina Geral e Familiar',
    gender: 'male'
  },
  {
    id: '1234',
    name: 'Ana Gomes da Costa',
    birth: '',
    professionalNumber: 'OM:34343',
    specialty: 'Saúde Materna',
    gender: 'female',
    profession: 'nurse'
  },
  {
    id: '12345',
    name: 'Joana Gonçalves Henriques de Martins Lopes',
    birth: '',
    professionalNumber: 'OM:34343',
    specialty: 'Medicina Geral e Familiar',
    gender: 'female',
    profession: 'doctor'
  }
]

export default testProfessionals
