import React, { Component } from 'react'
import { Button } from '../../../../common/Button/Button'
import { bindActionCreators } from 'redux'
import { patientActions } from '../../../../../redux/patient'
import { modalsActions } from '../../../../../redux/modals'
import { connect } from 'react-redux'
import { notify } from 'reapop'

const renderErrorMessage = (error) => {
  return <div className='col-xs-12 margin-top'>
    <div className='alert alert-danger' role='alert'>
      {error.type === 'NOT_FOUND' ? 'Utente Não Encontrado' : 'Ocorreu um erro, por favor tente de novo.' }
    </div>
  </div>
}

class ChoosePatient extends Component {
  constructor (props) {
    super(props)
    this.state = {
      patientNumber: '',
      redirectToPrescription: false
    }
    this.renderContent = this.renderContent.bind(this)
  }

  handleChange ({target}) {
    this.setState({
      patientNumber: target.value
    })
  }

  handleKeyPress (e) {
    if (e.key === 'Enter') {
      this.handleSubmit(this.state.patientNumber)
    }
  }

  handleSubmit (patientNumber) {
    const { setPatient, notify, close, patientChoiceSuccess } = this.props
    setPatient(patientNumber)
      .then(() => {
        notify({
          message: `Utente Escolhido`,
          status: 'info',
          dismissible: true,
          dismissAfter: 3000,
          position: 'br',
          closeButton: true,
          allowHTML: false
        })
        close('choose-patient')
        patientChoiceSuccess()
      })
      .catch(() => {})
  }

  renderContent (loading) {
    return <div className='col-xs-12 input-group patient-number-input'>
      <input
        onKeyPress={this.state.patientNumber ? this.handleKeyPress.bind(this) : null}
        type='text'
        className='form-control input-lg'
        value={this.state.patientNumber}
        onChange={this.handleChange.bind(this)}
        placeholder='Nº Utente' />
      <div className='input-group-btn'>
        <Button
          disabled={this.state.patientNumber === ''}
          onClick={() => this.handleSubmit(this.state.patientNumber)}
          text={loading ? 'A Carregar...' : 'Procurar'}
          buttonType={'btn btn-success btn-lg'}
          type={'button'} />
      </div>
    </div>
  }

  render () {
    const { patientError, fetchingPatient } = this.props
    return <div className='row clearfix margin-bottom small-margin'>
      {patientError
        ? renderErrorMessage(patientError)
        : null
      }
      {this.renderContent(fetchingPatient)}
    </div>
  }
}

const mapStateToProps = ({patient}) => {
  return {
    fetchingPatient: patient.fetching,
    patientError: patient.error,
    patient: patient.data
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...patientActions, ...modalsActions, notify }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePatient)
