import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const splitIntTwo = (arr) => {
  const middle = Math.ceil(arr.length / 2)
  return [
    arr.slice(0, middle),
    arr.slice(middle)
  ]
}

const Pathologies = ({ pathologyDiplomas }) => {
  const splittedArr = splitIntTwo(pathologyDiplomas)
  return <div className='container-fluid'>
    {splittedArr.map((part, i) => {
      return <div className='col-md-6' key={i}>
        <div className='list-group'>
          {part.map((object, i) => {
            return <li key={i} className='list-group-item'>
              <div className='input-group two-line-group'>
                <div className='form-control'>
                  <div className='title'>{object.pathologyName}</div>
                  <p className='subtext'>{object.diplomaName}</p>
                </div>
                <span className='input-group-addon btn-right pathology-addon'>
                  <input type='checkbox' aria-label='...' />
                </span>
              </div>
            </li>
          })}
        </div>

      </div>
    })}
    {/*<div className='col-xs-12 no-side-padding'>*/}
    {/*  <form>*/}
    {/*    <div className='col-xs-12 no-side-padding'>*/}
    {/*      <div className='form-group'>*/}
    {/*        <label>Regime Especial de Comparticipação de Medicamentos (R)</label>*/}
    {/*        <input disabled type='text' className='form-control' />*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*    <div className='col-xs-12 no-side-padding'>*/}
    {/*      <SectionTitle title={'Outras Comparticipações (O)'} size={'h5'} smallMargins />*/}
    {/*      <EnhancedCheckBox handleCheck={this.handleItemCheck.bind(this)} title={'Doentes Crónicos com Guia'} />*/}
    {/*      <EnhancedCheckBox*/}
    {/*        handleCheck={this.handleItemCheck.bind(this)}*/}
    {/*        title={'Doentes Crónicos com Medicação Especial'} />*/}
    {/*      <EnhancedCheckBox*/}
    {/*        handleCheck={this.handleItemCheck.bind(this)}*/}
    {/*        title={'Doentes Profissionais'} />*/}
    {/*      <EnhancedCheckBox*/}
    {/*        handleCheck={this.handleItemCheck.bind(this)}*/}
    {/*        title={'Pensionistas Ind. Lanificios F.E. Seg. Social'} />*/}
    {/*    </div>*/}
    {/*  </form>*/}
    {/*</div>*/}
    {/*{this.state.hasCheckboxActive*/}
    {/*  ? <div className='col-xs-12 no-side-padding'>*/}
    {/*    <Panel heading={'Patologias'}>*/}
    {/*      <form className='form-horizontal'>*/}
    {/*        {PATHOLOGIES.map(pathology => {*/}
    {/*          return <div key={pathology.id} className='checkbox'>*/}
    {/*            <label>*/}
    {/*              <input type='checkbox' /> {pathology.description}*/}
    {/*            </label>*/}
    {/*          </div>*/}
    {/*        })}*/}
    {/*      </form>*/}
    {/*    </Panel>*/}
    {/*  </div>*/}
    {/*  : null*/}
    {/*}*/}
  </div>
}

Pathologies.propTypes = {
  pathologyDiplomas: PropTypes.array.isRequired
}

export default Pathologies
