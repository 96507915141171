class PrescribedMedicine {
  constructor (medicine, quantity, dosage, renewable, justification) {
    this._medicine = medicine
    this._quantity = quantity
    this._dosage = dosage
    this._renewable = renewable
    this._justification = justification
  }

  get medicine () {
    return this._medicine
  }

  get quantity () {
    return this._quantity
  }

  get dosage () {
    return this._dosage
  }

  get renewable () {
    return this._renewable
  }

  get justification () {
    return this._justification
  }

  set quantity (value) {
    this._quantity = value
  }

  set dosage (value) {
    this._dosage = value
  }

  set renewable (value) {
    this._renewable = value
  }

  set justification (value) {
    this._justification = value
  }
}

export default PrescribedMedicine
