import React, { Fragment } from 'react'
import { Panel } from '../../../../../../../common/Panel/Panel'
import { Button } from '../../../../../../../common/Button/Button'
import { Weaning } from '../../../../../../../../models/Guides/Periods'
import moment from 'moment'

const WeaningViewer = ({unitsPosition = 'right', removeItem, entries}) => {
  const { reductionPercentage, interval, periodType, startDate, minimumUnits } = entries[0]
  const description = Weaning.getTypes().filter(item => item.value === periodType)[0]
  return <div className='col-xs-12 no-side-padding guide-entry-line'>
    <div className='col-sm-11 no-side-padding'>
      {unitsPosition === 'right'
        ? <div className='periods'>
          <div className='period-item'>{`Reduzir ${reductionPercentage}% | A cada ${interval} ${description.displayValue} | Até ${minimumUnits} mg.`}</div>
          <div className='period-item'>{`A começar a ${moment(new Date(startDate)).format('L')}`}</div>
          <div className='line'><i className='fas fa-long-arrow-alt-right' /></div>
          <div className='units'>Desmame</div>
        </div>
        : <div className='periods'>
          <div className='units'>Desmame</div>
          <div className='line'><i className='fas fa-long-arrow-alt-right' /></div>
          <div className='period-item'>{`Reduzir ${reductionPercentage}% | A cada ${interval} ${description.displayValue} | Até ${minimumUnits} mg.`}</div>
          <div className='period-item'>{`A começar a ${moment(new Date(startDate)).format('L')}`}</div>
        </div>
      }
    </div>
    {removeItem
      ? <div className='col-sm-1 no-side-padding' style={{marginTop: '4px'}}>
        <Button type={'button'} text={<i className='fas fa-trash-alt' />} buttonType={'btn-danger btn-sm pull-right'} onClick={removeItem} />
      </div>
      : null
    }
  </div>
}

class WeaningComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      startDate: moment(new Date()).format('YYYY/MM/DD')
    }
  }

  handleChange (name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    const { type, reductionPercentage, interval, startDate, minimumUnits } = this.state
    this.props.handleSubmit({
      type: 'weaning',
      entries: [
        {
          reductionPercentage,
          startDate,
          minimumUnits,
          interval,
          periodType: type
        }
      ]
    })
  }
  render () {
    const { type, reductionPercentage, interval, minimumUnits, startDate } = this.state
    return <Fragment>
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Panel
          heading={'Desmame da Medicação'}
          footer={<Fragment>
            <Button type={'button'} text={<span className='fas fa-trash-alt' />} buttonType={'btn-danger'} onClick={this.props.remove} />
            <Button type={'submit'} text={<span className='fas fa-plus' />} buttonType={'btn-success pull-right'} />
          </Fragment>}>
          <Fragment>
            <div className='form-group col-sm-3 no-side-padding'>
              <label className='control-label' style={{ textAlign: 'right' }}>Data Início</label>
              <div className=''>
                <input
                  required
                  onChange={e => this.handleChange(e.target.name, e.target.value)}
                  value={startDate || moment(new Date()).format('YYYY/MM/DD')}
                  type='date'
                  min={moment(new Date()).format('YYYY/MM/DD')}
                  className='form-control'
                  name='startDate' />
              </div>
            </div>
            <div className='form-group col-sm-2'>
              <label className='control-label' style={{ textAlign: 'right' }}>Redução</label>
              <div className=''>
                <input
                  required
                  onChange={e => this.handleChange(e.target.name, e.target.value)}
                  value={reductionPercentage || ''}
                  type='number'
                  min={0}
                  placeholder='%'
                  className='form-control'
                  name='reductionPercentage' />
              </div>
            </div>
            <div className='form-group col-sm-4'>
              <label className='control-label' style={{ textAlign: 'right' }}>A cada</label>
              <div className=''>
                <div className='input-group input-select-group'>
                  <input
                    required
                    onChange={e => this.handleChange(e.target.name, e.target.value)}
                    value={interval || ''}
                    type='number'
                    min={1}
                    className='form-control'
                    name='interval' />
                  <select
                    required
                    onChange={e => this.handleChange(e.target.name, e.target.value)}
                    value={type || ''}
                    name='type'
                    className='form-control btn-right'>
                    <option value='' hidden disabled />
                    {Weaning.getTypes().map(({ value, displayValue }, i) => {
                      return <option key={i} value={value}>{displayValue}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className='form-group col-sm-2 no-side-padding'>
              <label className='control-label' style={{ textAlign: 'right' }}>Até</label>
              <div className=''>
                <div className='input-group'>
                  <input
                    required
                    onChange={e => this.handleChange(e.target.name, e.target.value)}
                    value={minimumUnits || ''}
                    type='number'
                    min={0}
                    className='form-control'
                    name='minimumUnits' />
                  <span className='input-group-addon btn-right'>mg</span>
                </div>
              </div>
            </div>
          </Fragment>
        </Panel>
      </form>
    </Fragment>
  }
}

export { WeaningComponent, WeaningViewer }
