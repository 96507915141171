import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Notifications from './Notifications'

import './style.css'
import Favorites from './Favorites/Favorites'
import SideNav from '../../../common/SideNav/SideNav'
import DoctorHistory from './DoctorHistory/DoctorHistory'
import testUser from '../../../../test/testUser'
import PersonalView from './Data/Personal/View'
import ProfessionalView from './Data/Professional/View'
import DocumentsView from './Data/Documents/View'
import Billing from './Billing/Billing'

const navItems = (currentUrl) => [
  {
    title: 'Dados do Utilizador',
    to: `${currentUrl}/data`,
    exact: true,
    icon: 'fa-user-circle',
    defaultExpanded: true,
    subNavItems: [
      {
        title: 'Pessoais',
        to: `${currentUrl}/data/personal`,
        icon: 'fa-user'
      },
      {
        title: 'Profissionais',
        to: `${currentUrl}/data/professional`,
        icon: 'fa-user-md'
      },
      {
        title: 'Documentos',
        to: `${currentUrl}/data/documents`,
        icon: 'fa-id-card'
      }
    ]
  },
  {
    title: 'Pagamentos',
    to: `${currentUrl}/billing`,
    icon: 'fa-credit-card',
    defaultExpanded: true,
    exact: true,
    subNavItems: [
      {
        title: 'Cartões',
        to: `${currentUrl}/billing/payments`,
        icon: 'fa-credit-card'
      }
    ]
  },
  {
    title: 'Notificações',
    to: `${currentUrl}/notifications`,
    icon: 'fa-bell',
    exact: true,
    inactive: true
  },
  {
    title: 'Prescrição',
    to: `${currentUrl}/prescription`,
    icon: 'fa-prescription',
    defaultExpanded: true,
    exact: true,
    subNavItems: [
      {
        title: 'Favoritos',
        to: `${currentUrl}/prescription/favorites`,
        icon: 'fa-star'
      },
      {
        title: 'Histórico Receitas',
        to: `${currentUrl}/prescription/history`,
        icon: 'fa-history'
      }
    ]
  }
]

const ProfileRouting = ({ match, location, history }) => {
  return <div className='container-fluid profile-container'>
    <SideNav items={navItems(match.url)} location={location} history={history}>
      <Switch>
        <Route exact path={`${match.url}/`} render={() => <Redirect to={`${match.url}/data/personal`} />} />
        <Route path={`${match.url}/data/personal`} render={() => <PersonalView person={testUser} />} />
        <Route path={`${match.url}/data/professional`} render={() => <ProfessionalView person={testUser} />} />
        <Route path={`${match.url}/data/documents`} render={() => <DocumentsView person={testUser} />} />
        <Route path={`${match.url}/billing`} render={() => <Billing />} />
        <Route path={`${match.url}/notifications`} render={() => <Notifications />} />
        <Route path={`${match.url}/prescription/favorites`} render={props => <Favorites {...props} />} />
        <Route path={`${match.url}/prescription/history`} render={props => <DoctorHistory {...props} />} />
      </Switch>
    </SideNav>
  </div>
}
export default ProfileRouting
