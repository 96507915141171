import React, { Component } from 'react'
import Pathologies from './Pathologies'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { medicineDataActions } from '../../../../../../redux/medicineData'

class PathologiesContainer extends Component {
  componentDidMount () {
    if (!this.props.pathologyDiplomas.length) {
      this.props.getPathologyDiplomas()
    }
  }

  render () {
    let { pathologyDiplomas } = this.props
    if (pathologyDiplomas.length) {
      return <Pathologies pathologyDiplomas={pathologyDiplomas} />
    } else {
      return <div>Loading</div>
    }
  }
}

PathologiesContainer.propTypes = { patientHealthcareNumber: PropTypes.string }

const mapStateToProps = ({medicineData}) => {
  return {
    pathologyDiplomas: medicineData.pathologyDiplomas
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(medicineDataActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PathologiesContainer)
