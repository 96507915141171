const UNITS_OPTIONS = [
  { value: '1', displayValue: '1', primary: true },
  { value: '0.5', displayValue: '1/2', primary: true },
  { value: '1.5', displayValue: '1,5', primary: true },
  { value: '2', displayValue: '2', primary: true },
  { value: '2.5', displayValue: '2,5' },
  { value: '0.25', displayValue: '1/4' },
  { value: '0.33', displayValue: '1/3' },
  { value: '0.75', displayValue: '3/4' },
  { value: '3', displayValue: '3' },
  { value: '3.5', displayValue: '3,5' },
  { value: '4', displayValue: '4' },
  { value: '5', displayValue: '5' },
  { value: '6', displayValue: '6' },
  { value: '7', displayValue: '7' },
  { value: '8', displayValue: '8' },
  { value: '9', displayValue: '9' },
  { value: '10', displayValue: '10' }
]

export default class GuideEntry {
  constructor (id, designation, dosage, start, unitType, generic, duration, observations, sos, periods) {
    this._id = id
    this._designation = designation
    this._dosage = dosage
    this._start = start
    this._unitType = unitType
    this._generic = generic
    this._duration = duration
    this._observations = observations
    this._sos = sos
    this._periods = periods || []
  }

  get id () {
    return this._id
  }

  get designation () {
    return this._designation
  }

  get dosage () {
    return this._dosage
  }

  get start () {
    return this._start
  }

  get unitType () {
    return this._unitType
  }

  get generic () {
    return this._generic
  }

  get duration () {
    return this._duration
  }

  get observations () {
    return this._observations
  }

  get sos () {
    return this._sos
  }

  get periods () {
    return this._periods
  }

  static map (entry) {
    const { id, designation, dosage, start, unitType, duration, observations, sos, periods, isGeneric } = entry
    return new GuideEntry(id, designation, dosage, start, unitType, isGeneric, duration, observations, sos, periods)
  }

  static getUnit (value) {
    return UNITS_OPTIONS.filter(item => item.value === value)[0]
  }
  static getUnits () {
    return UNITS_OPTIONS
  }

  set duration (value) {
    this._duration = value
  }

  set sos (value) {
    this._sos = value
  }

  set start (value) {
    this._start = value
  }

  set observations (value) {
    this._observations = value
  }

  set periods (value) {
    this._periods = value
  }
}

export { UNITS_OPTIONS }
