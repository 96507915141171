import React from 'react'
import { Panel } from '../../../../common/Panel/Panel'
import Diagnosis, { LATERALITY, SITUATION, STATUS, TYPE } from '../../../../../models/Diagnosis'
import testDiagnostics from '../../../../../test/testDiagnostics'
import moment from 'moment'
import PersonalBackground from '../../../../../models/PersonalBackground'
import FamilyBackground, { RELATIVES } from '../../../../../models/FamilyBackground'
import { testFamilyBackgrounds, testPersonalBackgrounds } from '../../../../../test/testBackground'
import { ReactModal } from '../../../../common/ReactModal/ReactModal'
import PersonalBackgroundsForm from './PersonalBackgroundsForm'
import FamilyBackgroundsForm from './FamilyBackgroundsForm'
import DiagnosisInsertionForm from './DiagnosisInsertionForm'

import './style.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { notify } from 'reapop'

function getPrimaryDiagnostics (diagnostics) {
  return diagnostics.filter(diagnostic => diagnostic.type === TYPE.PRIMARY)
    .map(({ id, type, disease, startDate, laterality, situation, status, endDate }) => (
      new Diagnosis(id, type, disease, startDate, laterality, situation, status, endDate)
    ))
}

function getSecondaryDiagnostics (diagnostics) {
  return diagnostics.filter(diagnostic => diagnostic.type === TYPE.SECONDARY)
    .map(({ id, type, disease, startDate, laterality, situation, status, endDate }) => (
      new Diagnosis(id, type, disease, startDate, laterality, situation, status, endDate)
    ))
}

function getPersonalBackground (elements) {
  return elements.map(({ id, date, laterality, status, diagnosis, endDate }) => (
    new PersonalBackground(id, date, laterality, status, diagnosis, endDate)
  ))
}

function getFamilyBackground (elements) {
  return elements.map(({ id, relative, diagnosis, observations }) => new FamilyBackground(id, relative, diagnosis, observations))
}

const OptionsDropdown = ({ alignSide = 'left', toggleModal }) => {
  return <div className={`dropdown align-${alignSide}`}>
    <button
      className='btn btn-success dropdown-toggle'
      type='button'
      id='dropdownMenu1'
      data-toggle='dropdown'
      aria-haspopup='true'
      aria-expanded='true'>
      <span className='fas fa-plus' />
      <span className='caret' />
    </button>
    <ul className='dropdown-menu' aria-labelledby='dropdownMenu1'>
      <li className='dropdrown-group-title'>Diagnóstico</li>
      <li onClick={() => toggleModal('primary')}>
        <button className='bordered'>Principal</button>
      </li>
      <li onClick={() => toggleModal('secondary')}>
        <button className='bordered'>Secundário</button>
      </li>
      <li role='separator' className='divider' />
      <li className='dropdrown-group-title'>Antecedente</li>
      <li onClick={() => toggleModal('personal')}>
        <button>Pessoal</button>
      </li>
      <li onClick={() => toggleModal('family')}>
        <button>Familiar</button>
      </li>
    </ul>
  </div>
}

const DiagnosisTable = ({ diagnostics, handleItemClick }) => {
  return <table className='table table-hover table-striped'>
    <thead>
      <tr>
        <th>Descrição</th>
        <th>Data Início</th>
        <th>Lateralidade</th>
        <th>Situação</th>
        <th>Estado</th>
        <th>Data Resolução</th>
      </tr>
    </thead>
    <tbody>
      {diagnostics.map((diagnostic) => {
        return <tr key={diagnostic.id} className={`clickable`} onClick={() => { handleItemClick(diagnostic) }}>
          <td>{diagnostic.disease}</td>
          <td>{moment(new Date(diagnostic.startDate)).format('MM/YYYY')}</td>
          <td>{LATERALITY.getDescription(diagnostic.laterality)}</td>
          <td>{SITUATION.getDescription(diagnostic.situation)}</td>
          <td>{STATUS.getDescription(diagnostic.status)}</td>
          <td>{diagnostic.endDate ? moment(new Date(diagnostic.endDate)).format('L') : '-----'}</td>
        </tr>
      })}
    </tbody>
  </table>
}

const PersonalBackgroundTable = ({ items, handleItemClick }) => {
  return <table className='table table-hover table-striped'>
    <thead>
      <tr>
        <th>Diagnóstico</th>
        <th>Data</th>
        <th>Lateralidade</th>
        <th>Estado</th>
        <th>Data Fim</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, i) => {
        return <tr key={i} className={`clickable`} onClick={() => { handleItemClick(item, i) }}>
          <td>{item.diagnosis}</td>
          <td>{moment(new Date(item.date)).format('L')}</td>
          <td>{LATERALITY.getDescription(item.laterality)}</td>
          <td>{STATUS.getDescription(item.status)}</td>
          <td>{item.endDate !== '' ? moment(new Date(item.endDate)).format('L') : '----'}</td>
        </tr>
      })}
    </tbody>
  </table>
}

const FamilyBackgroundTable = ({ items, handleItemClick }) => {
  return <table className='table table-hover table-striped'>
    <thead>
      <tr>
        <th>Diagnóstico</th>
        <th>Grau de Parentesco</th>
        <th>Observações</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, i) => {
        return <tr key={i} className={`clickable`} onClick={() => { handleItemClick(item, i) }}>
          <td>{item.diagnosis}</td>
          <td>{RELATIVES.getDescription(item.relative)}</td>
          <td>{item.observations}</td>
        </tr>
      })}
    </tbody>
  </table>
}

class Diagnostics extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      diagnostics: testDiagnostics,
      personalBackgrounds: testPersonalBackgrounds,
      familyBackgrounds: testFamilyBackgrounds,
      openedModal: '',
      editing: false,
      modalIsOpen: false,
      clickedItem: null
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.getTitleForModal = this.getTitleForModal.bind(this)
  }

  toggleModal (name = null, editing = true) {
    const selectedFields = name ? {} : { clickedItem: null }
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
      openedModal: name,
      editing,
      ...selectedFields
    })
  }

  renderModalContent (name) {
    switch (name) {
      case 'primary':
        return <DiagnosisInsertionForm
          enableDelete={this.state.clickedItem !== null}
          data={this.state.clickedItem || { startDate: moment(new Date()).format('YYYY-MM-DD'), type: 'primary' }}
          handleSubmit={this.handleDiagnosisSubmit.bind(this)} />
      case 'secondary':
        return <DiagnosisInsertionForm
          enableDelete={this.state.clickedItem !== null}
          data={this.state.clickedItem || { startDate: moment(new Date()).format('YYYY-MM-DD'), type: 'secondary' }}
          handleSubmit={this.handleDiagnosisSubmit.bind(this)} />
      case 'personal':
        return <PersonalBackgroundsForm
          enableDelete={this.state.clickedItem !== null}
          data={this.state.clickedItem || {}}
          handleSubmit={this.handlePersonalBackgroundSubmit.bind(this)} />
      case 'family':
        return <FamilyBackgroundsForm
          enableDelete={this.state.clickedItem !== null}
          data={this.state.clickedItem || {}}
          handleSubmit={this.handleFamilyBackgroundSubmit.bind(this)} />
      default:
        return null
    }
  }

  getTitleForModal (name) {
    const { editing } = this.state
    switch (name) {
      case 'primary':
      case 'secondary':
        return editing ? 'Alterar Diagnóstico' : 'Adicionar Diagnóstico'
      case 'personal':
        return editing ? 'Alterar Antecedente Pessoal' : 'Adicionar Antecedente Pessoal'
      case 'family':
        return editing ? 'Alterar Antecedente Familiar' : 'Adicionar Antecedente Familiar'
      default:
        return ''
    }
  }

  handleDiagnosisSubmit (values, removeItem) {
    console.log(removeItem)
    const current = [...this.state.diagnostics]
    const itemIndex = current.findIndex(item => item.id === values.id)
    if (itemIndex > -1) {
      if (!removeItem) {
        current[itemIndex] = values
        testDiagnostics[itemIndex] = values
      } else {
        current.splice(itemIndex, 1)
        testDiagnostics.splice(itemIndex, 1)
      }
    } else {
      current.push(values)
      testDiagnostics.push(values)
    }
    this.setState({
      diagnostics: current,
      clickedItem: null,
      modalIsOpen: false
    })
    this.props.notify({
      message: removeItem ? 'Diagnóstico Removido.' : this.state.editing ? 'Alterações Guardadas.' : 'Diagnóstico Adicionado.',
      status: removeItem ? 'error' : 'info',
      dismissible: true,
      dismissAfter: 5000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
  }

  handlePersonalBackgroundSubmit (values, removeItem) {
    const current = [...this.state.personalBackgrounds]
    const itemIndex = current.findIndex(item => item.id === values.id)
    if (itemIndex > -1) {
      if (!removeItem) {
        current[itemIndex] = values
        testPersonalBackgrounds[itemIndex] = values
      } else {
        current.splice(itemIndex, 1)
        testPersonalBackgrounds.splice(itemIndex, 1)
      }
    } else {
      current.push(values)
      testPersonalBackgrounds.push(values)
    }
    this.setState({
      personalBackgrounds: current,
      clickedItem: null,
      modalIsOpen: false,
      editing: false
    })
    this.props.notify({
      message: removeItem ? 'Antecedente Pessoal Removido.' : this.state.editing ? 'Alterações Guardadas.' : 'Antecedente Pessoal Adicionado.',
      status: removeItem ? 'error' : 'info',
      dismissible: true,
      dismissAfter: 5000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
  }

  handleFamilyBackgroundSubmit (values, removeItem) {
    const current = [...this.state.familyBackgrounds]
    const itemIndex = current.findIndex(item => item.id === values.id)
    if (itemIndex > -1) {
      if (!removeItem) {
        current[itemIndex] = values
        testFamilyBackgrounds[itemIndex] = values
      } else {
        current.splice(itemIndex, 1)
        testFamilyBackgrounds.splice(itemIndex, 1)
      }
    } else {
      current.push(values)
      testFamilyBackgrounds.push(values)
    }
    this.setState({
      familyBackgrounds: current,
      modalIsOpen: false,
      editing: false,
      clickedItem: null
    })
    this.props.notify({
      message: removeItem ? 'Antecedente Familiar Removido.' : this.state.editing ? 'Alterações Guardadas.' : 'Antecedente Familiar Adicionado.',
      status: removeItem ? 'error' : 'info',
      dismissible: true,
      dismissAfter: 5000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
  }

  handleItemClick (windowName, item) {
    this.setState({
      editing: true,
      clickedItem: item
    }, () => {
      this.toggleModal(windowName, true)
    })
  }

  render () {
    const { modalIsOpen, familyBackgrounds, diagnostics, personalBackgrounds, openedModal, editing } = this.state
    return <div className='container-fluid'>
      <div className='col-xs-12 no-side-padding buttons-container'>
        <OptionsDropdown alignSide={'right'} toggleModal={(name) => this.toggleModal(name, false)} />
      </div>
      <div className='col-xs-12 no-side-padding'>
        <Panel heading={'Diagnósticos Principais'} panelType={'panel-info'}>
          <DiagnosisTable
            handleItemClick={(item) => this.handleItemClick('primary', item)}
            diagnostics={getPrimaryDiagnostics(diagnostics)} />
        </Panel>
        <Panel heading={'Diagnósticos Secundários'} panelType={'panel-info'}>
          <DiagnosisTable
            handleItemClick={(item) => this.handleItemClick('secondary', item)}
            diagnostics={getSecondaryDiagnostics(diagnostics)} />
        </Panel>
        <Panel heading={'Antecedentes Pessoais'} panelType={'panel-info'}>
          <PersonalBackgroundTable
            handleItemClick={(item, index) => this.handleItemClick('personal', item, index)}
            items={getPersonalBackground(personalBackgrounds)} />
        </Panel>
        <Panel heading={'Antecedentes Familiares'} panelType={'panel-info'}>
          <FamilyBackgroundTable
            handleItemClick={(item, index) => this.handleItemClick('family', item, index)}
            items={getFamilyBackground(familyBackgrounds)} />
        </Panel>
      </div>
      <ReactModal
        innerFooter
        toggleModal={() => this.toggleModal()}
        name={this.getTitleForModal(openedModal, editing)}
        modalIsOpen={modalIsOpen}>
        <div>
          {this.renderModalContent(openedModal)}
        </div>
      </ReactModal>
    </div>
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ notify }, dispatch)
}

export default connect(null, mapDispatchToProps)(Diagnostics)
