const extractor = (name) => process.env[`REACT_APP_${name}`]

const configurationAuth0 = {
  domain: extractor('WEB_AUTH_DOMAIN'),
  clientID: extractor('WEB_AUTH_CLIENT_ID'),
  responseType: extractor('WEB_AUTH_RESPONSE_TYPE'),
  audience: extractor('WEB_AUTH_AUDIENCE'),
  scope: extractor('WEB_AUTH_SCOPE'),
  redirectUri: extractor('WEB_AUTH_REDIRECT_URI')
}
const basePathConfiguration = extractor('REACT_APP_BASEPATH')

const demoApp = extractor('DEMO_VERSION')

export { configurationAuth0, basePathConfiguration, demoApp }
