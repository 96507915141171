import React from 'react'
import v4 from 'uuid/v4'
import { LATERALITY, SITUATION, STATUS, TYPE } from '../../../../../models/Diagnosis'
import { Button } from '../../../../common/Button/Button'

export default class DiagnosisInsertionForm extends React.Component {
  constructor (props) {
    super(props)
    const defaultData = {
      id: props.data.id || v4(),
      type: props.data.type || '',
      laterality: props.data.laterality || '',
      endDate: props.data.endDate || '',
      disease: props.data.disease || '',
      status: props.data.status || '',
      situation: props.data.situation || '',
      startDate: props.data.startDate || ''
    }
    this.state = {
      ...defaultData
    }
  }

  handleStatusChange (value) {
    this.setState({
      status: value
    })
  }

  handleChange (name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    this.props.handleSubmit(this.state)
  }

  render () {
    const { handleSubmit, enableDelete = false } = this.props
    const { type, laterality, endDate, disease, id, status, situation, startDate } = this.state
    return <form onSubmit={(e) => this.handleSubmit(e)}>
      <div className='modal-body'>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Relevância</label>
            <div className=''>
              <select required className='form-control' name={'type'} value={type || ''} onChange={({target}) => this.handleChange(target.name, target.value)}>
                <option value={''} hidden disabled />
                <option value={TYPE.PRIMARY}>{TYPE.getDescription(TYPE.PRIMARY)}</option>
                <option value={TYPE.SECONDARY}>{TYPE.getDescription(TYPE.SECONDARY)}</option>
              </select>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Descrição</label>
            <div className=''>
              <input
                required
                type='text'
                className='form-control'
                name={'disease'}
                value={disease}
                onChange={({target}) => this.handleChange(target.name, target.value)} />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Lateralidade</label>
            <div className=''>
              <select required className='form-control' name={'laterality'} value={laterality} onChange={({target}) => this.handleChange(target.name, target.value)}>
                <option value={''} hidden disabled />
                <option value={LATERALITY.LEFT}>{LATERALITY.getDescription(LATERALITY.LEFT)}</option>
                <option value={LATERALITY.RIGHT}>{LATERALITY.getDescription(LATERALITY.RIGHT)}</option>
                <option value={LATERALITY.NOT_APPLICABLE}>{LATERALITY.getDescription(LATERALITY.NOT_APPLICABLE)}</option>
              </select>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Dia Início</label>
            <div className=''>
              <input
                required
                type='text'
                className='form-control'
                name={'startDate'}
                value={startDate}
                onChange={({target}) => this.handleChange(target.name, target.value)} />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Situação</label>
            <div className=''>
              <select required className='form-control' name={'situation'} value={situation} onChange={({target}) => this.handleChange(target.name, target.value)}>
                <option value={''} hidden disabled />
                <option value={SITUATION.CONFIRMED}>{SITUATION.getDescription(SITUATION.CONFIRMED)}</option>
                <option value={SITUATION.UNCONFIRMED}>{SITUATION.getDescription(SITUATION.UNCONFIRMED)}</option>
                <option value={SITUATION.SUSPECT}>{SITUATION.getDescription(SITUATION.SUSPECT)}</option>
                <option value={SITUATION.PROBABLE}>{SITUATION.getDescription(SITUATION.PROBABLE)}</option>
              </select>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-sm-12 margin-bottom-form-input'>
            <label className='control-label' style={{ textAlign: 'right' }}>Estado</label>
            <div className=''>
              <select required className='form-control' name={'status'} value={status} onChange={({target}) => this.handleChange(target.name, target.value)}>
                <option value={''} hidden disabled />
                <option value={STATUS.RESOLVED}>{STATUS.getDescription(STATUS.RESOLVED)}</option>
                <option value={STATUS.UNRESOLVED}>{STATUS.getDescription(STATUS.UNRESOLVED)}</option>
                <option value={STATUS.STABLE_DISEASE}>{STATUS.getDescription(STATUS.STABLE_DISEASE)}</option>
                <option value={STATUS.PARTIAL_REMISSION}>{STATUS.getDescription(STATUS.PARTIAL_REMISSION)}</option>
                <option value={STATUS.DISEASE_PROGRESSION}>{STATUS.getDescription(STATUS.DISEASE_PROGRESSION)}</option>
              </select>
            </div>
          </div>
        </div>
        {this.state.status === STATUS.RESOLVED
          ? <div className='form-group'>
            <div className='col-sm-12 margin-bottom-form-input'>
              <label className='control-label' style={{ textAlign: 'right' }}>Data Resolução</label>
              <div className=''>
                <input
                  required
                  type='date'
                  className='form-control'
                  name={'endDate'}
                  value={endDate}
                  onChange={({target}) => this.handleChange(target.name, target.value)} />
              </div>
            </div>
          </div>
          : null
        }
      </div>
      <div className='modal-footer'>
        {enableDelete ? <Button type={'button'} onClick={() => handleSubmit({id: id}, true)} text={<span className='fas fa-trash-alt' />} buttonType={'btn-danger pull-left'} /> : <div /> }
        <Button type={'submit'} text={'Guardar'} buttonType={'btn-success pull-right'} />
      </div>
    </form>
  }
}
