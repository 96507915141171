import React, { Component } from 'react'
import { GENDER } from '../../../../../../resources/utilities'
import { Panel } from '../../../../../common/Panel/Panel'
import { Button } from '../../../../../common/Button/Button'
import PropTypes from 'prop-types'
import { ReactModal } from '../../../../../common/ReactModal/ReactModal'
import ProfileForm from './Form'

import './style.css'
import { DisabledInput } from '../../../../../common/formComponents/Input'
import ProfileSectionsHeader from '../ProfileSectionsHeader'

class View extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }
  }

  toggleModal () {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  render () {
    let { person } = this.props
    return <div className='container-fluid doctor-profile'>
      <ProfileSectionsHeader person={person} />
      <Panel
        heading={'Dados Pessoais'}
        panelType={'panel-default'}
        rightHeader={<Button onClick={this.toggleModal.bind(this)} type={'submit'}
          text={<i className='fas fa-edit' />} buttonType={'btn-info btn-xs'} />}>
        {/* <div className='margin-bottom'> */}
        {/*  <Form handleSubmit={() => {}} type={'horizontal'}> */}
        {/*    <FormElement disabled={!editable} inputSize={9} type={'text'} label={'Nome Completo'} */}
        {/*      defaultValue={person.name} name={'fullName'} /> */}
        {/*    <FormElement disabled={!editable} inputSize={9} type={'radio'} label={'Sexo'} name={'gender'} */}
        {/*      defaultValue={person.gender} options={[ */}
        {/*      { value: GENDER.MALE, displayValue: 'Masculino' }, */}
        {/*      { value: GENDER.FEMALE, displayValue: 'Feminino' }]} /> */}
        {/*    <div className='form-group'> */}
        {/*      <label className='control-label col-sm-3' style={{ textAlign: 'right' }}>Data Nascimento</label> */}
        {/*      <div className='col-sm-4'> */}
        {/*        <DisabledInput content={person.birth} /> */}
        {/*      </div> */}
        {/*      <label className='control-label col-sm-2' style={{ textAlign: 'right' }}>Estado Civil</label> */}
        {/*      <div className='col-sm-3'> */}
        {/*        <DisabledInput content={''} /> */}
        {/*      </div> */}
        {/*    </div> */}
        {/*    <div className='form-group'> */}
        {/*      <label className='control-label col-sm-3' style={{ textAlign: 'right' }}>Naturalidade</label> */}
        {/*      <div className='col-sm-4'> */}
        {/*        <DisabledInput content={''} /> */}
        {/*      </div> */}
        {/*      <label className='control-label col-sm-2' style={{ textAlign: 'right' }}>Nacionalidade</label> */}
        {/*      <div className='col-sm-3'> */}
        {/*        <DisabledInput content={''} /> */}
        {/*      </div> */}
        {/*    </div> */}
        {/*    <FormElement disabled={!editable} inputSize={9} type={'text'} label={'Morada Pessoal'} */}
        {/*      name={'personalAddress'} /> */}
        {/*    <div className='form-group'> */}
        {/*      <label className='control-label col-sm-3' style={{ textAlign: 'right' }}>Código Postal</label> */}
        {/*      <div className='col-sm-3'> */}
        {/*        <DisabledInput content={''} /> */}
        {/*      </div> */}
        {/*      <label className='control-label col-sm-2' style={{ textAlign: 'right' }}>Localidade</label> */}
        {/*      <div className='col-sm-4'> */}
        {/*        <DisabledInput content={''} /> */}
        {/*      </div> */}
        {/*    </div> */}
        {/*    <div className='form-group'> */}
        {/*      <label className='control-label col-sm-3' style={{ textAlign: 'right' }}>Telefone</label> */}
        {/*      <div className='col-sm-3'> */}
        {/*        <DisabledInput content={''} /> */}
        {/*      </div> */}
        {/*      <label className='control-label col-sm-2' style={{ textAlign: 'right' }}>Email</label> */}
        {/*      <div className='col-sm-4'> */}
        {/*        <DisabledInput content={''} /> */}
        {/*      </div> */}
        {/*    </div> */}
        {/*  </Form> */}
        {/* </div> */}
        <form>
          <div className='form-group col-sm-12'>
            <label className='control-label' style={{ textAlign: 'right' }}>Nome Completo</label>
            <div className=''>
              <DisabledInput content={person.name} />
            </div>
          </div>
          <div className='form-group col-sm-12'>
            <label className='control-label' style={{ textAlign: 'right' }}>Sexo</label>
            <div className=''>
              <DisabledInput content={GENDER.getDescription(person.gender)} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Data Nascimento</label>
            <div className='margin-bottom-form-input'>
              <DisabledInput content={person.birth} />
            </div>
            <label className='control-label' style={{ textAlign: 'right' }}>Estado Civil</label>
            <div className=''>
              <DisabledInput content={''} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Naturalidade</label>
            <div className='margin-bottom-form-input'>
              <DisabledInput content={''} />
            </div>
            <label className='control-label' style={{ textAlign: 'right' }}>Nacionalidade</label>
            <div className=''>
              <DisabledInput content={''} />
            </div>
          </div>
          <div className='form-group col-sm-12'>
            <label className='control-label' style={{ textAlign: 'right' }}>Morada Pessoal</label>
            <div className=''>
              <DisabledInput content={''} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Código Postal</label>
            <div className=''>
              <DisabledInput content={''} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Localidade</label>
            <div className=''>
              <DisabledInput content={''} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Telefone</label>
            <div className=''>
              <DisabledInput content={''} />
            </div>
          </div>
          <div className='form-group col-sm-6'>
            <label className='control-label' style={{ textAlign: 'right' }}>Email</label>
            <div className=''>
              <DisabledInput content={''} />
            </div>
          </div>
        </form>
      </Panel>
      <ReactModal
        footer={<Button onClick={this.toggleModal.bind(this)} type={'submit'} text={'Guardar'} buttonType={'btn-success pull-right'} />}
        name={'Editar Dados Pessoais'}
        toggleModal={this.toggleModal.bind(this)}
        modalIsOpen={this.state.modalIsOpen}>
        <ProfileForm person={person} editable />
      </ReactModal>
    </div>
  }
}

View.propTypes = {
  editable: PropTypes.any,
  person: PropTypes.shape({})
}

View.defaultProps = { person: {} }

export default View
