import React from 'react'
import FormElement from '../../../../../common/formComponents/FormElement'
import { Section } from './Section'
import { GENDER } from '../../../../../../resources/utilities'

const ADDITIONAL_DOCUMENT_FIELDS = [
  {
    type: 'passport',
    label: 'Passaporte',
    inputs: [
      {
        name: 'value',
        type: 'text',
        placeholder: '12345678'
      },
      {
        name: 'expiryDate',
        type: 'date',
        placeholder: '11/11/1994'
      },
      {
        name: 'emissor',
        type: 'text',
        placeholder: 'Emissor'
      }
    ]
  },
  {
    type: 'driverLicense',
    label: 'Carta de Condução',
    inputs: [
      {
        name: 'value',
        type: 'text',
        placeholder: '12345678'
      },
      {
        name: 'expiryDate',
        type: 'date',
        placeholder: '11/11/1994'
      }
    ]
  },
  {
    type: 'socialSecNumber',
    label: 'Nº Segurança Social',
    inputs: [
      {
        name: 'value',
        type: 'text',
        placeholder: '12345678'
      }
    ]
  }
]

const PatientData = ({person}) => {
  return <div className='clearfix'>
    <div className='margin-bottom'>
      <form>
        <div className='form-group col-sm-12'>
          <label className='control-label' style={{ textAlign: 'right' }}>Nome Completo</label>
          <div className=''>
            <input type='text' className='form-control' name={'fullName'} defaultValue={person.name} />
          </div>
        </div>
        <div className='form-group col-sm-12'>
          <label className='control-label' style={{ textAlign: 'right' }}>Nome do Pai</label>
          <div className=''>
            <input type='text' className='form-control' name={'fatherName'} />
          </div>
        </div>
        <div className='form-group col-sm-12'>
          <label className='control-label' style={{ textAlign: 'right' }}>Nome da Mãe</label>
          <div className=''>
            <input type='text' className='form-control' name={'motherName'} />
          </div>
        </div>
        <div className='form-group col-sm-4'>
          <label className='control-label' style={{ textAlign: 'right' }}>Sexo</label>
          <div className='form-control' style={{border: 'none', boxShadow: 'none'}}>
            <div className='radio-inline'>
              <label className=''>
                <input type='radio' name='genderSelect' value='male' checked={person.gender === GENDER.MALE} onChange={() => {}} />
                Masculino
              </label>
            </div>
            <div className='radio-inline'>
              <label className=''>
                <input type='radio' name='genderSelect' value='female' checked={person.gender === GENDER.FEMALE} onChange={() => {}} />
                Feminino
              </label>
            </div>
          </div>
        </div>
        <div className='form-group col-sm-4'>
          <label className='control-label' style={{ textAlign: 'right' }}>Data Nascimento</label>
          <div className=''>
            <input type='date' className='form-control' defaultValue={person.birth} />
          </div>
        </div>
        <div className='form-group col-sm-4'>
          <label className='control-label' style={{ textAlign: 'right' }}>Estado Civil</label>
          <div className=''>
            <select name='maritalState' className='form-control' defaultValue={''}>
              <option value='' hidden disabled />
              <option value='single'>Solteiro/a</option>
              <option value='maried'>Casado/a</option>
              <option value='divorced'>Divorciado/a</option>
              <option value='widow'>Viúvo/a</option>
            </select>
          </div>
        </div>
        <div className='form-group col-sm-6'>
          <label className='control-label' style={{ textAlign: 'right' }}>Naturalidade</label>
          <div className=''>
            <input type='text' className='form-control' name={'nationality'} defaultValue={person.country} />
          </div>
        </div>
        <div className='form-group col-sm-6'>
          <label className='control-label' style={{ textAlign: 'right' }}>Nacionalidade</label>
          <div className=''>
            <input type='text' className='form-control' name={'naturality'} />
          </div>
        </div>
        <div className='form-group col-sm-12'>
          <label className='control-label' style={{ textAlign: 'right' }}>Profissão</label>
          <div className=''>
            <input type='text' className='form-control' name={'profession'} />
          </div>
        </div>
        <div className='form-group col-sm-12'>
          <label className='control-label' style={{ textAlign: 'right' }}>Morada Pessoal</label>
          <div className=''>
            <input type='text' className='form-control' name={'personalAddress'} />
          </div>
        </div>
        <div className='form-group col-sm-6'>
          <label className='control-label' style={{ textAlign: 'right' }}>Código Postal</label>
          <div className=''>
            <input type='text' className='form-control' name={'postalCode'} />
          </div>
        </div>
        <div className='form-group col-sm-6'>
          <label className='control-label' style={{ textAlign: 'right' }}>Localidade</label>
          <div className=''>
            <input type='text' className='form-control' name={'location'} />
          </div>
        </div>
        <div className='form-group col-sm-6'>
          <label className='control-label' style={{ textAlign: 'right' }}>Telefone</label>
          <div className=''>
            <input type='tel' className='form-control' name={'phoneNumber'} defaultValue={person.contacts.length && person.contacts[0]} />
          </div>
        </div>
        <div className='form-group col-sm-6'>
          <label className='control-label' style={{ textAlign: 'right' }}>Email</label>
          <div className=''>
            <input type='email' className='form-control' name={'email'} defaultValue={person.email} />
          </div>
        </div>
        <div className='col-sm-12'>
          <Section titleSize={'h4'} title={'Documentos'} smallMargins additionalFieldSelectorOptions={ADDITIONAL_DOCUMENT_FIELDS}>
            <div className='col-sm-4'>
              <FormElement formType={'inline'} type={'text'} label={'Nº CC'} name={'identityCardNumber'} />
            </div>
            <div className='col-sm-4'>
              <FormElement formType={'inline'} type={'text'} label={'NIF'} name={'fiscalNumber'} />
            </div>
            <div className='col-sm-4'>
              <FormElement formType={'inline'} type={'text'} label={'Nº SNS'} name={'sns'} defaultValue={person.healthcareNumber} />
            </div>
          </Section>
        </div>
      </form>
    </div>
  </div>
}
// const PatientData = ({data}) => {
//   const { healthcareNumber, contacts, country, gender, birth, name, email, vat } = data
//   return <div className='container-fluid no-side-padding'>
//     <form className='col-xs-12 form-horizontal patient-insertion'>
//       <div className='col-xs-12 no-side-padding'>
//         <FormElement type={'text'} inputSize={10} label={'Nome'} name={'name'} defaultValue={name} />
//       </div>
//       <div className='col-xs-12 no-side-padding'>
//         <FormElement type={'text'} inputSize={10} label={'Nome do Pai'} name={'fatherName'} />
//       </div>
//       <div className='col-xs-12 no-side-padding'>
//         <FormElement type={'text'} inputSize={10} label={'Nome da Mãe'} name={'motherName'} />
//       </div>
//       <div className='col-xs-12 no-side-padding'>
//         <div className='col-xs-12 col-sm-6 col-md-3 no-side-padding'>
//           <FormElement
//             type={'radio'}
//             inputSize={10}
//             label={'Sexo'}
//             name={'gender'}
//             defaultValue={gender}
//             options={[{ value: GENDER.MALE, displayValue: 'Masculino' }, {
//               value: GENDER.FEMALE,
//               displayValue: 'Feminino'
//             }]} />
//         </div>
//         <div className='col-xs-12 col-sm-6 col-md-5 no-side-padding'>
//           <FormElement type={'date'} defaultValue={birth} inputSize={6} label={'Data Nascimento'} name={'birthDate'} />
//         </div>
//         <div className='col-xs-12 col-sm-6 col-md-4 no-side-padding'>
//           <FormElement
//             type={'select'}
//             inputSize={7}
//             label={'Estado Civil'}
//             name={'maritalState'}
//             options={[
//               { value: 'single', displayValue: 'Solteiro/a' },
//               { value: 'maried', displayValue: 'Casado/a' },
//               { value: 'divorced', displayValue: 'Divorciado/a' },
//               { value: 'widow', displayValue: 'Viúvo/a' }]} />
//         </div>
//       </div>
//       <div className='col-xs-12 no-side-padding'>
//         <div className='col-xs-12 col-sm-6 no-side-padding'>
//           <FormElement type={'text'} inputSize={8} defaultValue={country}label={'Nacionalidade'} name={'nationality'} />
//         </div>
//         <div className='col-xs-12 col-sm-6 no-side-padding'>
//           <FormElement type={'text'} inputSize={7} label={'Profissão'} name={'profession'} />
//         </div>
//       </div>
//       <div className='col-xs-12 no-side-padding'>
//         <div className='col-xs-12 no-side-padding'>
//           <FormElement type={'text'} inputSize={10} label={'Naturalidade'} name={'naturalFrom'} />
//         </div>
//       </div>
//       <div className='col-xs-12 no-side-padding'>
//         <div className='col-xs-12 no-side-padding'>
//           <FormElement type={'text'} inputSize={10} label={'Morada'} name={'adressStreet'} />
//         </div>
//         <div className='col-xs-10 col-sm-6 col-md-4 unaligned-fields'>
//           <FormElement
//             type={'text'}
//             inputSize={6}
//             label={'Código Postal'}
//             name={'postalCode'}
//             placeholder={'0000-000'} />
//         </div>
//         <div className='col-xs-10 col-sm-6 col-md-5'>
//           <FormElement type={'text'} inputSize={7} label={'Localidade'} name={'location'} />
//         </div>
//       </div>
//       <div className='col-xs-12 col-md-6'>
//         <Section
//           title={'Documentos'}
//           titleSize={'h4'}
//           additionalFieldSelectorOptions={[
//             { value: 'citizenCard', displayValue: 'CC' },
//             { value: 'idCard', displayValue: 'BI' },
//             { value: 'passport', displayValue: 'Passaporte' },
//             { value: 'other', displayValue: 'Outro' }]}>
//           <FormElement
//             type={'text'}
//             label={'CC'}
//             name={'identityCardNumber'}
//             placeholder={'Número de Identifição Civil'} />
//           <FormElement
//             disabled
//             type={'text'}
//             label={'Nº SNS'}
//             defaultValue={healthcareNumber}
//             name={'healthNumber'}
//             placeholder={'Nº Utente do SNS'} />
//           <FormElement
//             type={'number'}
//             defaultValue={vat}
//             label={'NIF'}
//             name={'vatNumber'}
//             placeholder={'Número de Identifição Fiscal'} />
//         </Section>
//       </div>
//       <div className='col-xs-12 col-md-6'>
//         <Section
//           title={'Contactos'}
//           titleSize={'h4'}
//           additionalFieldSelectorOptions={[
//             { value: 'email', displayValue: 'Email' },
//             { value: 'phone', displayValue: 'Telefone' },
//             { value: 'other', displayValue: 'Outro' }]}>
//           <FormElement type={'phone'} label={'Telefone'} defaultValue={contacts[0]} name={'phone'} />
//           <FormElement type={'email'} label={'Email'} name={'email'} defaultValue={email} />
//         </Section>
//       </div>
//     </form>
//   </div>
// }

export default PatientData
