import React from 'react'
import PropTypes from 'prop-types'

class MedicineChangeView extends React.Component {
  render () {
    return <table className='table table-striped table-hover'>
      <thead>
        <tr>
          <th>Medicamento</th>
          <th>PVP</th>
          <th>% Comp.</th>
          <th>Valor Comp.</th>
          <th>Valor Final</th>
        </tr>
      </thead>
      <tbody>
        {this.props.medicines.map((item, index) => {
          const copaymentValue = parseFloat(item.price - (item.copayment * item.price))
          const finalValue = item.price - copaymentValue
          return <tr key={index} onClick={() => this.props.handleItemClick(item)}>
            <td>{`${item.commercialName}, ${item.medicineType.name}, ${item.dose}, ${item.packageType.name} - ${item.units} un. - ${item.administrationType.name}`}</td>
            <td>{item.price}€</td>
            <td>{`${item.copayment * 100}%`}</td>
            <td>{copaymentValue.toFixed(2)}</td>
            <td>{finalValue.toFixed(2)}</td>
          </tr>
        })}
      </tbody>
    </table>
  }
}

MedicineChangeView.propTypes = {
  medicines: PropTypes.array.isRequired,
  handleItemClick: PropTypes.func.isRequired
}

export default MedicineChangeView
