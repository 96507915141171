import React from 'react'
import FavoritesView from './FavoritesView'
import { connect } from 'react-redux'
import { doctorActions } from '../../../../../redux/doctor'
import { basketActions } from '../../../../../redux/basket'
import { notify } from 'reapop'

import Favorite from '../../../../../models/Favorite'
import { bindActionCreators } from 'redux'

class FavoritesViewContainer extends React.Component {
  componentDidMount () {
    // this.props.removeAll()
  }
  favoriteAddHandler (favorite) {
    favorite.medicines.forEach(medicine => {
      console.log(medicine)
      this.props.addItem({
        medicineId: medicine.id,
        dosage: medicine.dosage,
        quantity: medicine.quantity,
        renewable: medicine.renewable
      })
      // this.props.addItem(medicine.id)
      // this.props.changeDosage(medicine.id, medicine.dosage)
      // this.props.changeRenewable(medicine.id, medicine.renewable)
    })
    this.props.notify({
      message: `Favoritos adicionados à Receita.`,
      status: 'info',
      dismissible: true,
      dismissAfter: 5000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
  }

  render () {
    const { editable, match, favorites } = this.props
    return <FavoritesView editable={editable} match={match} addFavoriteToReceipt={this.favoriteAddHandler.bind(this)} favorites={favorites} />
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({...doctorActions, ...basketActions, notify}, dispatch)
}

const mapStateToProps = ({doctor}) => {
  const favorites = doctor.favorites.map(fav => new Favorite(fav.id, fav.name, fav.items))
  return {
    favorites
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesViewContainer)
