export default class PersonalBackground {
  constructor (id, date, laterality, status, diagnosis, endDate) {
    this._id = id
    this._date = date
    this._laterality = laterality
    this._status = status
    this._diagnosis = diagnosis
    this._endDate = endDate
  }

  get id () {
    return this._id
  }

  get date () {
    return this._date
  }

  get laterality () {
    return this._laterality
  }

  get status () {
    return this._status
  }

  get diagnosis () {
    return this._diagnosis
  }

  get endDate () {
    return this._endDate
  }
}
