import {
  FETCHING_DCIS,
  FETCH_DCIS_SUCCESS,
  FETCH_DCIS_FAILURE,
  FETCHING_PHARMACEUTICAL_FORMS,
  FETCH_PHARMACEUTICAL_FORMS_SUCCESS,
  FETCH_PHARMACEUTICAL_FORMS_FAILURE,
  FETCHING_ADMINISTRATION_WAYS,
  FETCH_ADMINISTRATION_WAYS_SUCCESS,
  FETCH_ADMINISTRATION_WAYS_FAILURE,
  FETCHING_PRODUCT_TYPES,
  FETCH_PRODUCT_TYPES_SUCCESS,
  FETCH_PRODUCT_TYPES_FAILURE,
  FETCH_PACKAGING_TYPES_FAILURE,
  FETCH_PACKAGING_TYPES_SUCCESS,
  FETCHING_PACKAGING_TYPES,
  FETCHING_PATHOLOGY_DIPLOMAS,
  FETCH_PATHOLOGY_DIPLOMAS_SUCCESS,
  FETCH_PATHOLOGY_DIPLOMAS_FAILURE
} from './types'
import getInfarmed from '../infarmed'

export function getDCIS () {
  const infarmed = getInfarmed()
  return dispatch => {
    dispatch({
      type: FETCHING_DCIS
    })
    infarmed.DCI().then(results => {
      dispatch({
        type: FETCH_DCIS_SUCCESS,
        payload: results
      })
    }).catch(error => {
      console.log(error)
      dispatch({
        type: FETCH_DCIS_FAILURE
      })
    })
  }
}

export function getPathologyDiplomas () {
  const infarmed = getInfarmed()
  return dispatch => {
    dispatch({
      type: FETCHING_PATHOLOGY_DIPLOMAS
    })
    infarmed.PathologyDiploma()
      .then(results => {
        dispatch({
          type: FETCH_PATHOLOGY_DIPLOMAS_SUCCESS,
          payload: results
        })
      })
      .catch(error => {
        console.log(error)
        dispatch({
          type: FETCH_PATHOLOGY_DIPLOMAS_FAILURE
        })
      })
  }
}

export function getPharmaceuticalForms () {
  const infarmed = getInfarmed()
  return dispatch => {
    dispatch({
      type: FETCHING_PHARMACEUTICAL_FORMS
    })
    infarmed.PharmForm().then(results => {
      dispatch({
        type: FETCH_PHARMACEUTICAL_FORMS_SUCCESS,
        payload: results
      })
    }).catch(error => {
      console.log(error)
      dispatch({
        type: FETCH_PHARMACEUTICAL_FORMS_FAILURE
      })
    })
  }
}

export function getAdministrationWays () {
  const infarmed = getInfarmed()
  return dispatch => {
    dispatch({
      type: FETCHING_ADMINISTRATION_WAYS
    })
    infarmed.AdministrationWay().then(results => {
      dispatch({
        type: FETCH_ADMINISTRATION_WAYS_SUCCESS,
        payload: results
      })
    }).catch(error => {
      console.log(error)
      dispatch({
        type: FETCH_ADMINISTRATION_WAYS_FAILURE
      })
    })
  }
}

export function getPackagingTypes () {
  const infarmed = getInfarmed()
  return dispatch => {
    dispatch({
      type: FETCHING_PACKAGING_TYPES
    })
    infarmed.PackagingType().then(results => {
      dispatch({
        type: FETCH_PACKAGING_TYPES_SUCCESS,
        payload: results
      })
    }).catch(error => {
      console.log(error)
      dispatch({
        type: FETCH_PACKAGING_TYPES_FAILURE
      })
    })
  }
}

export function getProductTypes () {
  const infarmed = getInfarmed()
  return dispatch => {
    dispatch({
      type: FETCHING_PRODUCT_TYPES
    })
    const product = infarmed.ProductType()
    product.then(results => {
      dispatch({
        type: FETCH_PRODUCT_TYPES_SUCCESS,
        payload: results
      })
    }).catch(error => {
      console.log(error)
      dispatch({
        type: FETCH_PRODUCT_TYPES_FAILURE
      })
    })
  }
}
