import React from 'react'
import PropTypes from 'prop-types'
import BoxSelect from './BoxSelect'
import { DisabledInput } from './Input'

export default class Selector extends React.Component {
  constructor (props) {
    super(props)
    this.state = {value: ''}
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    const {name, defaultValue} = this.props
    if (defaultValue) {
      this.handleChange(name, defaultValue)
    }
  }

  handleChange (name, value) {
    this.setState({
      value
    })
    if (this.props.onChange) {
      this.props.onChange(name, value)
    }
    this.props.handleChange(name, value)
  }
  render () {
    const { type, name, disabled, options, defaultValue = '', handleChange, onChange, required } = this.props
    if (type === 'select') {
      if (disabled) {
        return <DisabledInput content={this.state.value} />
      } else {
        return <select
          required={required}
          disabled={disabled}
          value={this.state.value}
          onChange={(e) => this.handleChange(e.target.name, e.target.value)}
          className='form-control'
          name={name}>
          <option hidden value={''} />
          {options.map((option, index) => <option key={index} value={option.value}>{option.displayValue}</option>)}
        </select>
      }
    } else {
      return <BoxSelect
        required={required}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
        handleChange={handleChange}
        type={type}
        groupName={name}
        options={options} />
    }
  }
}

Selector.propTypes = {
  type: PropTypes.oneOf(['select', 'radio', 'checkbox']).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    displayValue: PropTypes.string.isRequired
  })).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  handleChange: PropTypes.func
}

Selector.defaultProps = {
  handleChange: () => {},
  options: []
}
