import { TYPE_CHANGE, FETCH_SUCCESS, ERROR_FETCHING, RESET, FETCHING_RESULTS } from './types'
import { DEFAULT_ADMIN_WAY } from '../infarmed'

const search = (state = {
  current: '',
  searchType: 'innAndCommercial',
  adminWay: DEFAULT_ADMIN_WAY,
  pharmForm: '',
  packageType: '',
  dosage: '',
  submitted: false,
  focus: false,
  loading: false,
  results: []
}, action) => {
  switch (action.type) {
    case ERROR_FETCHING:
      return Object.assign({}, state, {
        error: action.payload.error,
        loading: false
      })
    case FETCHING_RESULTS:
      return Object.assign({}, state, {
        loading: true
      })
    case FETCH_SUCCESS:
      return Object.assign({}, state, {
        results: action.payload.results,
        loading: false
      })
    case TYPE_CHANGE:
      return Object.assign({}, state, {
        submitted: false,
        [action.payload.name]: action.payload.value
      })
    case RESET:
      return Object.assign({}, state, {
        results: [],
        submitted: true,
        loading: false,
        dosage: '',
        searchType: 'innAndCommercial',
        adminWay: DEFAULT_ADMIN_WAY,
        pharmForm: '',
        packageType: '',
        focus: action.payload.focus || false,
        current: null
      })
    default:
      return state
  }
}

export default search
