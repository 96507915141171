const SET_PATIENT = 'patient//SET_PATIENT'
const REMOVE_PATIENT = 'patient//REMOVE_PATIENT'
const FETCH_PATIENT = 'patient//FETCH_PATIENT'
const ERROR_FETCHING_PATIENT = 'patient//ERROR_FETCHING_PATIENT'

export {
  SET_PATIENT,
  REMOVE_PATIENT,
  FETCH_PATIENT,
  ERROR_FETCHING_PATIENT
}
