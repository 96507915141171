import React from 'react'
import { Button } from '../../../../../../common/Button/Button'
import PropTypes from 'prop-types'

import './style.css'

import MedicineTypeIcon from '../MedicineTypeIcon/MedicineTypeIcon'
import commercialIcon from '../../../../../../../resources/images/medicine-icons/icon_commercial.svg'
import genericIcon from '../../../../../../../resources/images/medicine-icons/icon_generic.svg'

// const withHighlight = (content, byChar) => {
//   return <HighlightedTextContainer content={content} byChar={byChar} />
// }

const PrescriptionMedicineView = ({ medicine, quantity = 0, addItem, hasAdminWay, hasPharmaceuticalForm, hasPresentationForm }) => {
  return <div onClick={() => addItem(medicine.id)} className={`col-xs-12 no-side-padding medication clickable ${quantity ? 'selected' : ''}`}>
    <div className='first-line'>
      <div className='name'>
        {medicine.activeComponent} | {medicine.medicineType && hasPharmaceuticalForm ? <strong>{medicine.medicineType.name}</strong> : medicine.medicineType.name} <MedicineTypeIcon color='black' type={medicine.medicineType && medicine.medicineType.id} />
      </div>
      <div className='buttons'>
        <Button
          text={'Adicionar'}
          buttonType={'btn plus-button btn-sm'}
          type={'button'}
          onClick={() => addItem(medicine.id)} />
      </div>
    </div>
    <div className='additional-content'>
      <div className='information'>
        <span className='secondary bold'>{medicine.isGeneric
          ? <img className={`custom-icon center-with-text commercial-icon`} src={genericIcon} alt='Commercial Medicine' />
          : <img className={`custom-icon center-with-text commercial-icon`} src={commercialIcon} alt='Commercial Medicine' />
        }{medicine.commercialName}</span>
        <span className='secondary bold'>{medicine.dose}</span>
        <span className='secondary'>{medicine.packageType && hasPresentationForm ? <strong>{medicine.packageType.name}</strong> : medicine.packageType.name}</span>
        <span className='secondary'>{medicine.units} unidade(s)</span>
        <span className='secondary'>{hasAdminWay ? <strong>{medicine.administrationType.name}</strong> : medicine.administrationType.name}</span>
      </div>
    </div>
  </div>
}

PrescriptionMedicineView.propTypes = {
  medicine: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  dosage: PropTypes.string,
  renewable: PropTypes.number,
  quantity: PropTypes.number,
  remove: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  handleDosageChange: PropTypes.func.isRequired,
  handleRenewChange: PropTypes.func.isRequired
}

PrescriptionMedicineView.defaultProps = {
  quantity: 0
}

export default PrescriptionMedicineView
