import React from 'react'

import './style.css'
import moment from 'moment'
import Conversation from './Conversation/Conversation'
import QRCodeContainer from './QRCode/QRCodeContainer'

const testMessages = [
  {
    senderId: 0,
    dateTime: '2019-02-06T13:00:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet consequat turpis. Praesent tempor commodo ante, sed pellentesque dolor vulputate a. Etiam faucibus arcu ut scelerisque ultrices. Duis et venenatis turpis, id sodales nibh. Maecenas consectetur arcu ac felis aliquam, lobortis viverra purus accumsan. Vestibulum vel tristique felis, sit amet ornare diam. Aenean fringilla ac dolor at lobortis. Aenean rutrum ut nulla at auctor. Etiam mollis dignissim nisl, at molestie justo dictum non. Nulla eleifend eleifend mauris, eu hendrerit nibh. Donec pulvinar et ligula sit amet molestie.'
  },
  {
    senderId: 0,
    dateTime: '2019-02-06T13:10:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet consequat turpis.'
  },
  {
    senderId: 0,
    dateTime: '2019-02-06T13:18:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  {
    senderId: 1,
    dateTime: '2019-02-06T13:19:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet consequat turpis.'
  },
  {
    senderId: 1,
    dateTime: '2019-02-06T13:20:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet consequat turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet consequat turpis.'
  },
  {
    senderId: 0,
    dateTime: '2019-02-06T13:28:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  {
    senderId: 1,
    dateTime: '2019-02-06T13:30:00Z',
    content: 'Lorem ipsum dolor sit amet'
  }
]

const noConnection = true

const ConversationLine = ({date, lastThreeMessages, sender}) => {
  return <div className='conversation-line'>
    <div className='clearfix'>
      <div className='pull-left'>{sender}</div>
      <div className='pull-right text-muted'>{moment(new Date(date)).format('L')}</div>
    </div>
    <ul className='messages text-muted'>
      {lastThreeMessages.map((message, i) => {
        return <li key={i}>{message.content}</li>
      })
      }
    </ul>
  </div>
}

class Messages extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      messages: testMessages
    }
    this.sendMessageHandler = this.sendMessageHandler.bind(this)
  }

  sendMessageHandler (message) {
    const current = this.state.messages
    current.push({
      senderId: 1,
      dateTime: new Date().toString(),
      content: message
    })
    this.setState({
      messages: current
    })
  }

  render () {
    if (noConnection) {
      return <QRCodeContainer />
    } else {
      return <div className='messages-window-container'>
        <div className='col-xs-12 col-sm-4 no-side-padding-for-container' style={{height: '100%'}}>
          <div className='panel panel-default messages'>
            <div className='panel-title text-center'>
              <h4>Conversas</h4>
            </div>
            <div className='panel-body'>
              <div className='margin-bottom'>
                <div className='input-group'>
                  <input type='text' className='form-control' placeholder='Pesquisa' />
                  <div className='input-group-btn'>
                    <button className='btn btn-info' type='submit'>
                      <span className='glyphicon glyphicon-search' />
                    </button>
                  </div>
                </div>
              </div>
              <div className='list-group'>
                <div className='list-group-item message-item'>
                  <ConversationLine date={'1999-1-1'} lastThreeMessages={[{content: 'Hello '}]} sender={'Paulo'} />
                </div>
                <div className='list-group-item message-item'>
                  <ConversationLine date={'1999-1-1'} lastThreeMessages={[{content: 'Hello2'}]} sender={'Paulo'} />
                </div>
                <div className='list-group-item message-item'>
                  <ConversationLine date={'1999-1-1'} lastThreeMessages={[{content: 'Hello3'}]} sender={'Paulo'} />
                </div>
                <div className='list-group-item message-item'>
                  <ConversationLine date={'1999-1-1'} lastThreeMessages={[{content: 'Hello4'}]} sender={'Paulo'} />
                </div>
                <div className='list-group-item message-item'>
                  <ConversationLine date={'1999-1-1'} lastThreeMessages={[{content: 'Hello5'}]} sender={'Paulo'} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xs-12 col-sm-8' style={{height: '100%'}}>
          <Conversation sendMessage={this.sendMessageHandler} messages={this.state.messages} />
        </div>
      </div>
    }
  }
}

export default Messages
