import React from 'react'

const Form = ({editable, person = {}}) => {
  return <div className='margin-bottom'>
    <form>
      <div className='form-group col-sm-12'>
        <label className='control-label' style={{ textAlign: 'right' }}>Nome Completo</label>
        <div className=''>
          <input disabled={!editable} type='text' className='form-control' name={'fullName'} defaultValue={person.name} />
        </div>
      </div>
      <div className='form-group col-sm-12'>
        <label className='control-label' style={{ textAlign: 'right' }}>Sexo</label>
        <div className=''>
          <div className='radio-inline'>
            <label className=''>
              <input type='radio' name='genderSelect' value='male' checked={person.gender === 'male'} onChange={() => {}} />
              Masculino
            </label>
          </div>
          <div className='radio-inline'>
            <label className=''>
              <input type='radio' name='genderSelect' value='female' checked={person.gender === 'female'} onChange={() => {}} />
              Feminino
            </label>
          </div>
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Data Nascimento</label>
        <div className='margin-bottom-form-input'>
          <input disabled={!editable} type='date' className='form-control' defaultValue={person.birth} />
        </div>
        <label className='control-label' style={{ textAlign: 'right' }}>Estado Civil</label>
        <div className=''>
          <select disabled={!editable} name='maritalState' className='form-control' defaultValue={''}>
            <option value='' hidden disabled />
            <option value='single'>Solteiro/a</option>
            <option value='maried'>Casado/a</option>
            <option value='divorced'>Divorciado/a</option>
            <option value='widow'>Viúvo/a</option>
          </select>
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Naturalidade</label>
        <div className='margin-bottom-form-input'>
          <input disabled={!editable} type='text' className='form-control' name={'nationality'} />
        </div>
        <label className='control-label' style={{ textAlign: 'right' }}>Nacionalidade</label>
        <div className=''>
          <input disabled={!editable} type='text' className='form-control' name={'naturality'} />
        </div>
      </div>
      <div className='form-group col-sm-12'>
        <label className='control-label' style={{ textAlign: 'right' }}>Morada Pessoal</label>
        <div className=''>
          <input disabled={!editable} type='text' className='form-control' name={'personalAddress'} />
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Código Postal</label>
        <div className=''>
          <input disabled={!editable} type='text' className='form-control' name={'postalCode'} />
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Localidade</label>
        <div className=''>
          <input disabled={!editable} type='text' className='form-control' name={'location'} />
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Telefone</label>
        <div className=''>
          <input disabled={!editable} type='tel' className='form-control' name={'phoneNumber'} />
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Email</label>
        <div className=''>
          <input disabled={!editable} type='email' className='form-control' name={'email'} />
        </div>
      </div>
    </form>
  </div>
}

export default Form
