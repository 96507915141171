import React, { Component } from 'react'
import FavoritesViewContainer from './FavoritesViewContainer'
import { Route, Switch } from 'react-router-dom'
import FavoritesAdditionContainer from './FavoriteAddition/FavoritesAdditionContainer'
import FavoriteEditContainer from './FavoriteEdit/FavoriteEditContainer'
import { medicineDataActions } from '../../../../../redux/medicineData'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'

class Favorites extends Component {
  componentDidMount () {
    this.props.getDCIS()
    this.props.getPharmaceuticalForms()
    this.props.getAdministrationWays()
    this.props.getProductTypes()
    this.props.getPackagingTypes()
    this.props.getPathologyDiplomas()
  }

  render () {
    let { match, loadingMedicineData } = this.props
    if (loadingMedicineData) {
      return <div className='loading-window'>
        <span className='fas fa-spinner fa-4x fa-pulse' />
      </div>
    } else {
      return <div className='full-height container-fluid'>
        <Switch>
          <Route exact path={match.url} render={(props) => <Content {...props} />} />
          <Route path={`${match.url}/add`} render={(props) => <FavoritesAdditionContainer {...props} />} />
          <Route path={`${match.url}/edit/:favoriteId`} render={(props) => <FavoriteEditContainer {...props} />} />
        </Switch>
      </div>
    }
  }
}

Favorites.propTypes = {
  match: PropTypes.object
}

const Content = ({match}) => {
  return <div className={'margin-bottom'}>
    <FavoritesViewContainer editable match={match} />
  </div>
}

const mapStateToProps = ({medicineData}) => {
  const { administrationWays, productTypes, dcis, pharmaceuticalForms, pathologyDiplomas } = medicineData
  const loadingMedicineData = !!dcis.length &&
    !!productTypes.length &&
    !!pharmaceuticalForms.length &&
    !!administrationWays.length &&
    !!pathologyDiplomas.length
  return {
    loadingMedicineData: !loadingMedicineData
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...medicineDataActions}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites)
