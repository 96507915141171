import { SET_PATIENT, REMOVE_PATIENT, FETCH_PATIENT, ERROR_FETCHING_PATIENT } from './types'

import { manyPatients } from '../../test/testPatient'

export function setPatient (patientHealthNumber) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_PATIENT,
        payload: patientHealthNumber
      })
      setTimeout(() => {
        const patient = manyPatients.filter(patient => patient.healthcareNumber === patientHealthNumber)[0]
        if (patient) {
          dispatch({
            type: SET_PATIENT,
            payload: patient
          })
          resolve(patient)
        } else {
          reject(new Error('NOT_FOUND'))
          dispatch({
            type: ERROR_FETCHING_PATIENT,
            payload: {
              type: 'NOT_FOUND'
            }
          })
        }
      }, 300)
    })
  }
}
export function removePatient () {
  return {
    type: REMOVE_PATIENT
  }
}
