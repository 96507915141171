import React, { Component, Fragment } from 'react'
import { Panel } from '../../../../common/Panel/Panel'

import './style.css'
import testReceipts from '../../../../../test/testReceipt'
import moment from 'moment'
import { ReactModal } from '../../../../common/ReactModal/ReactModal'
import MedicationItem from './MedicationItem'
import MedicationListItem from './MedicationListItem'
import { Button } from '../../../../common/Button/Button'
import MedicationInsertionForm from './MedicationInsertionForm'
import { connect } from 'react-redux'

const MED_TYPES = {
  ANTI_INFECTIOUS: 'antiInfectious',
  CENTRAL_NERVOUS_SYSTEM: 'centralNervousSystem',
  CARDIOVASCULAR_APPARATUS: 'cardiovascularApparatus',
  BLOOD: 'blood',
  RESPIRATORY_SYSTEM: 'respiratorySystem',
  DIGESTIVE_SYSTEM: 'digestiveSystem',
  GENITOURINARY_SYSTEM: 'genitourinarySystem',
  HORMONES_ENDOCRINE: 'hormonesEndocrine',
  LOCOMOTIVE_APPARATUS: 'locomotiveApparatus',
  ANTI_ALLERGIC: 'antiAllergic',
  NUTRITION_METABOLISM: 'nutritionMetabolism',
  VOLUMETRIC_ELECTROLYTIC: 'volumetricElectrolytic',
  SKIN: 'skin',
  OTORHINOLARYNGOLOGICAL: 'otorhinolaryngological',
  OCULAR: 'ocular',
  ANTINEOPLASTIC_IMMUNOMODULATORY: 'antineoplasticImmunomodulatory',
  INTOXICATION: 'intoxication',
  VACCINES_IMMUNOGLOBULINS: 'vaccinesImmunoglobulins',
  getDescription: (type) => {
    switch (type) {
      case 'antiInfectious':
        return 'Anti-infecciosos'
      case 'centralNervousSystem':
        return 'Sistema Nervoso Central'
      case 'cardiovascularApparatus':
        return 'Aparelho Cardiovascular'
      case 'blood':
        return 'Sangue'
      case 'respiratorySystem':
        return 'Aparelho Respiratório'
      case 'digestiveSystem':
        return 'Aparelho Digestivo'
      case 'genitourinarySystem':
        return 'Aparelho Geniturinário'
      case 'hormonesEndocrine':
        return 'Hormonas e Medicamentos Usados no Tratamento das Doenças Endócrinas'
      case 'locomotiveApparatus':
        return 'Aparelho Locomotor'
      case 'antiAllergic':
        return 'Medicação Antialérgica'
      case 'nutritionMetabolism':
        return 'Nutrição e Metabolismo'
      case 'volumetricElectrolytic':
        return 'Corretivos da Volémia e das Alterações Electrolíticas'
      case 'skin':
        return 'Medicamentos Usados em Afecções Cutâneas'
      case 'otorhinolaryngological':
        return 'Medicamentos Usados em Afecções Otorrinolaringológicas'
      case 'ocular':
        return 'Medicamentos Usados no Tratamento das Afecções Oculares'
      case 'antineoplasticImmunomodulatory':
        return 'Medicamentos Antineoplásicos e Imunomoduladores'
      case 'intoxication':
        return 'Medicamentos Usados no Tratamento de Intoxicações'
      case 'vaccinesImmunoglobulins':
        return 'Vacinas e Imunoglobulinas'
      default:
        return type
    }
  },
  getColor: (type) => {
    switch (type) {
      case 'antiInfectious':
        return {
          background: 'rgba(155,78,155,0.94)',
          textColor: 'white'
        }
      case 'centralNervousSystem':
        return {
          background: '#03b27c',
          textColor: 'white'
        }
      case 'cardiovascularApparatus':
        return {
          background: '#a82d2d',
          textColor: 'white'
        }
      case 'blood':
        return {
          background: 'rgba(228,56,56,0.97)',
          textColor: 'white'
        }
      case 'respiratorySystem':
        return {
          background: '#5aacff',
          textColor: 'white'
        }
      case 'digestiveSystem':
        return {
          background: '#ce9d3c',
          textColor: 'white'
        }
      case 'genitourinarySystem':
        return {
          background: 'rgba(210,185,17,0.93)',
          textColor: 'white'
        }
      case 'hormonesEndocrine':
        return {
          background: 'rgba(90,206,54,0.71)',
          textColor: 'white'
        }
      case 'locomotiveApparatus':
        return {
          background: '#db6435',
          textColor: 'white'
        }
      case 'antiAllergic':
        return {
          background: '#7373ff',
          textColor: 'white'
        }
      case 'nutritionMetabolism':
        return {
          background: '#ff9d15',
          textColor: 'white'
        }
      case 'volumetricElectrolytic':
        return {
          background: 'white',
          borderColor: 'black',
          textColor: 'black'
        }
      case 'skin':
        return {
          background: 'rgba(251,114,61,0.46)',
          textColor: 'white'
        }
      case 'otorhinolaryngological':
        return {
          background: '#c5c5c5',
          textColor: 'white'
        }
      case 'ocular':
        return {
          background: '#c0c0ff',
          textColor: 'white'
        }
      case 'antineoplasticImmunomodulatory':
        return {
          background: '#474747',
          textColor: '#ffffff'
        }
      case 'intoxication':
        return {
          background: 'white',
          borderColor: 'black',
          textColor: 'black'
        }
      case 'vaccinesImmunoglobulins':
        return {
          background: 'rgba(226,179,187,0.98)',
          textColor: 'white'
        }
      default:
        return type
    }
  },
  getAllTypes: [
    'antiInfectious',
    'centralNervousSystem',
    'cardiovascularApparatus',
    'blood',
    'respiratorySystem',
    'digestiveSystem',
    'genitourinarySystem',
    'hormonesEndocrine',
    'locomotiveApparatus',
    'antiAllergic',
    'nutritionMetabolism',
    'volumetricElectrolytic',
    'skin',
    'otorhinolaryngological',
    'ocular',
    'antineoplasticImmunomodulatory',
    'intoxication',
    'vaccinesImmunoglobulins'
  ]
}

const enhancedTestReceipts = []

testReceipts.forEach(item => {
  const med = item.medicines[0]
  const name = `${med.name}, ${med.dose}`
  MED_TYPES.getAllTypes.forEach(type => {
    enhancedTestReceipts.push(new MedicationListItem(name, med.dosage, item.date, item.prescriber, type))
  })
})

const byType = []
MED_TYPES.getAllTypes.forEach(type => {
  byType.push({
    type,
    items: enhancedTestReceipts.filter(item => item.medType === type)
  })
})
const MedicationList = ({onItemClick}) => {
  return <table className={`table table-striped medication-list ${onItemClick ? 'table-hover' : ''}`}>
    <thead>
      <tr>
        <th>DCI/NC</th>
        <th>Posologia</th>
        <th>Data Prescrição</th>
        <th className='text-align-right'>Prescritor</th>
      </tr>
    </thead>
    <tbody>
      {byType.map((type, i) => {
        const borderTD = MED_TYPES.getColor(type.type).borderColor ? {
          borderTop: `1px solid ${MED_TYPES.getColor(type.type).borderColor}`,
          borderBottom: `1px solid ${MED_TYPES.getColor(type.type).borderColor}`} : null
        if (type.items.length > 0) {
          return [
            <tr
              className='separator-line'
              key={`${type.type}_${i}`}
              style={{
                borderTop: `1px solid ${MED_TYPES.getColor(type.type).borderColor}`,
                borderBottom: `1px solid ${MED_TYPES.getColor(type.type).borderColor}`,
                backgroundColor: MED_TYPES.getColor(type.type).background,
                color: MED_TYPES.getColor(type.type).textColor
              }}>
              <td style={borderTD} colSpan='4'>{MED_TYPES.getDescription(type.type)}</td>
            </tr>,
            type.items.map((item, index) => {
              return <tr key={index} onClick={() => onItemClick(item)} className='clickable'>
                <td>{item.name}</td>
                <td>{item.dosage}</td>
                <td className='prescriber-cell'>{moment(new Date(item.date)).format('L')}</td>
                <td className='text-align-right prescriber-cell'>{item.prescriber}</td>
              </tr>
            })
          ]
        } else {
          return null
        }
      })}
    </tbody>
  </table>
}

export { MedicationList }

class MedicationListPanel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openedMedicine: null,
      insertionModalIsOpen: false
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.toggleInsertion = this.toggleInsertion.bind(this)
  }

  toggleModal (medicine = null) {
    console.log(medicine)
    this.setState({
      openedMedicine: medicine
    })
  }

  toggleInsertion () {
    this.setState({
      insertionModalIsOpen: !this.state.insertionModalIsOpen
    })
  }

  render () {
    const { openedMedicine, insertionModalIsOpen } = this.state
    return <Panel
      heading={'Medicação'}
      rightHeader={<Button type={'button'} onClick={this.toggleInsertion} buttonType={'btn-success btn-sm'} text={<i className='fa fa-plus' />} />}>
      <Fragment>
        <div className='col-xs-12'>
          <MedicationList onItemClick={this.toggleModal} />
        </div>
        {openedMedicine
          ? <ReactModal
            name={openedMedicine.name}
            toggleModal={() => this.toggleModal()}
            footer={<Button type={'button'} buttonType={'btn-danger pull-left'} text={<span className='fas fa-trash-alt' />} />}
            modalIsOpen={!!openedMedicine}>
            <MedicationItem medicine={openedMedicine} />
          </ReactModal>
          : null
        }
        <ReactModal
          innerFooter
          name={'Adicionar Medicação'}
          toggleModal={() => this.toggleInsertion()}
          modalIsOpen={insertionModalIsOpen}>
          <MedicationInsertionForm dcis={this.props.dcis} toggleModal={this.toggleInsertion} />
        </ReactModal>
      </Fragment>
    </Panel>
  }
}

const mapStateToProps = ({medicineData}) => {
  return {
    dcis: medicineData.dcis
  }
}

export default connect(mapStateToProps)(MedicationListPanel)
