import { Button } from '../../../../../../../common/Button/Button'
import React, { Fragment } from 'react'
import { Panel } from '../../../../../../../common/Panel/Panel'
import PropTypes from 'prop-types'
import { UnitsInsertion } from './GuideEntryComponent'
import { Meal } from '../../../../../../../../models/Guides/Periods'
import GuideEntry from '../../../../../../../../models/Guides/GuideEntry'

const MealsViewer = ({units, entries, time, unitsPosition = 'right', removeItem}) => {
  const values = Meal.getValues()
  const orderedEntries = entries.sort((a, b) => {
    const valueA = values.filter(item => item.value === a)[0]
    const valueB = values.filter(item => item.value === b)[0]
    const dayA = parseInt(valueA.sorter)
    const dayB = parseInt(valueB.sorter)
    if (dayA < dayB) {
      return -1
    } else if (dayA > dayB) {
      return 1
    } else {
      return 0
    }
  })
  let timeDescription = Meal.getTimes().filter(item => item.value === time)[0]
  if (timeDescription && timeDescription.value === 'during') {
    timeDescription.displayValue = 'Ao'
  }
  return <div className='col-xs-12 no-side-padding guide-entry-line'>
    <div className='col-sm-11 no-side-padding'>
      {unitsPosition === 'right'
        ? <div className='periods'>
          {timeDescription ? <div className='period-item' style={{backgroundColor: 'var(--attention-color)'}}>{timeDescription.displayValue}</div> : null }
          {orderedEntries.map((entry, i) => {
            const description = Meal.getValues().filter(item => item.value === entry)[0]
            return <div className='period-item' key={i}>{(description && description.displayValue) || entry}</div>
          })}
          <div className='line'><i className='fas fa-long-arrow-alt-right' /></div>
          <div className='units'>{`${GuideEntry.getUnit(units).displayValue} un.`}</div>
        </div>
        : <div className='periods'>
          <div className='units'>{`${GuideEntry.getUnit(units).displayValue} un.`}</div>
          <div className='line'><i className='fas fa-long-arrow-alt-right' /></div>

          {timeDescription ? <div className='period-item' style={{backgroundColor: 'var(--attention-color)'}}>{timeDescription.displayValue}</div> : null }
          {orderedEntries.map((entry, i) => {
            const description = Meal.getValues().filter(item => item.value === entry)[0]
            return <div className='period-item' key={i}>{(description && description.displayValue) || entry}</div>
          })}
        </div>
      }
    </div>
    {removeItem
      ? <div className='col-sm-1 no-side-padding' style={{marginTop: '4px'}}>
        <Button type={'button'} text={<i className='fas fa-trash-alt' />} buttonType={'btn-danger btn-sm pull-right'} onClick={removeItem} />
      </div>
      : null
    }
  </div>
}

class MealsInsertion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      units: '1',
      time: '',
      entries: []
    }
  }

  handleSubmit (e) {
    e.preventDefault()
    const { entries, units, time } = this.state
    const data = {
      type: 'meal',
      time: time === 'N_A' ? null : time,
      entries,
      units
    }
    console.log(data)
    this.props.handleSubmit(data)
  }

  handleChange (value, checked) {
    const entries = [...this.state.entries]
    if (checked) {
      entries.push(value)
      this.setState({
        entries
      })
    } else {
      const index = entries.findIndex(item => item === value)
      entries.splice(index, 1)
      this.setState({
        entries
      })
    }
  }

  render () {
    let { remove, unitType } = this.props
    let { time } = this.state
    return <Fragment>
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Panel heading={'Medicação às Refeições'} footer={<Fragment>
          <Button type={'button'} text={<span className='fas fa-trash-alt' />} buttonType={'btn-danger'} onClick={remove} />
          <Button type={'submit'} disabled={!this.state.entries.length} text={<span className='fas fa-plus' />} buttonType={'btn-success pull-right'} />
        </Fragment>}>
          <Fragment>
            <div className='form-group'>
              <label className='' style={{ textAlign: 'right' }}>Refeições</label>
            </div>
            <div className='form-group'>
              {Meal.getValues().map(({ value, displayValue }, i) => {
                return <div className='checkbox-inline' key={i}>
                  <label className='checkbox-inline'>
                    <input onChange={e => this.handleChange(e.target.value, e.target.checked)} type='checkbox' value={value} /> {displayValue}
                  </label>
                </div>
              })}
            </div>
            <div className='form-group col-sm-4'>
              <label
                className='control-label'
                style={{ textAlign: 'right' }}>Momento</label>
              <div className='no-side-padding'>
                <select value={time} className='form-control' name='time' onChange={(e) => this.setState({ [e.target.name]: e.target.value })}>
                  <option value='' hidden />
                  {Meal.getTimes().map((item, i) => (
                    <option key={i} value={item.value}>{item.displayValue}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className=''>
              <UnitsInsertion defaultValue={'1'} handleChange={units => this.setState({ units })} unitType={unitType} />
            </div>
          </Fragment>
        </Panel>
      </form>
    </Fragment>
  }
}

MealsInsertion.propTypes = {
  remove: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export { MealsViewer, MealsInsertion }
