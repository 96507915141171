import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const SectionTitle = (props) => {
  const { subText, smallMargins, rightField, icon, size, title, noHR } = props
  switch (size) {
    case 'h1':
      return <div className={`page-header ${smallMargins ? 'margin-small' : ''} ${noHR ? 'no-hr' : ''}`}>
        <h1><span>{icon}</span>{title} <small>{subText}</small><span className='pull-right'>{rightField}</span></h1>
      </div>
    case 'h2':
      return <div className={`page-header ${smallMargins ? 'margin-small' : ''} ${noHR ? 'no-hr' : ''}`}>
        <h2><span>{icon}</span>{title} <small>{subText}</small><span className='pull-right'>{rightField}</span></h2>
      </div>
    case 'h3':
      return <div className={`page-header ${smallMargins ? 'margin-small' : ''} ${noHR ? 'no-hr' : ''}`}>
        <h3><span>{icon}</span>{title} <small>{subText}</small><span className='pull-right'>{rightField}</span></h3>
      </div>
    case 'h4':
      return <div className={`page-header ${smallMargins ? 'margin-small' : ''} ${noHR ? 'no-hr' : ''}`}>
        <h4><span>{icon}</span>{title} <small>{subText}</small><span className='pull-right'>{rightField}</span></h4>
      </div>
    case 'h5':
      return <div className={`page-header ${smallMargins ? 'margin-small' : ''} ${noHR ? 'no-hr' : ''}`}>
        <h5><span>{icon}</span>{title} <small>{subText}</small><span className='pull-right'>{rightField}</span></h5>
      </div>
    default:
      return <div className={`page-header ${smallMargins ? 'margin-small' : ''} ${noHR ? 'no-hr' : ''}`}>
        <h1><span>{icon}</span>{title} <small>{subText}</small><span className='pull-right'>{rightField}</span></h1>
      </div>
  }
}

SectionTitle.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']).isRequired,
  subText: PropTypes.string,
  rightField: PropTypes.object,
  smallMargins: PropTypes.bool,
  noHR: PropTypes.bool
}

SectionTitle.defaultProps = {
  size: 'h1',
  smallMargin: false,
  noHR: false
}

export default SectionTitle
