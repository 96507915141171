import React from 'react'
import './style.css'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../common/Button/Button'
import { TabsContainer } from '../../../../../../common/TabsContainer/TabsContainer'

export function sortByDoctor (a, b) {
  const itemA = a.prescriber
  const itemB = b.prescriber
  return itemA < itemB ? -1 : itemA > itemB ? 1 : 0
}

class ReceiptItem extends React.Component {
  render () {
    const { date, leftText, medicines } = this.props
    return <div className='receipt-item clickable' onClick={this.props.handleClick}>
      <div className='clearfix' style={{ fontWeight: 700 }}>
        <div className='pull-left'>{moment(new Date(date)).format('L')}</div>
        <div className='pull-right text-muted'>{leftText}</div>
      </div>
      <ul className='drugs clearfix'>
        {medicines.map((medicine, i) => {
          return <li key={i}>{medicine.name}</li>
        })
        }
      </ul>
    </div>
  }
}

const ReceiptsByMonth = ({ receipts, itemClickHandle, defaultRepeat, repeatButton }) => {
  const sett = new Set(receipts.map(receipt => moment(new Date(receipt.date)).format('M/YYYY')))
  const arr = Array.from(sett.values())
  const currentYear = moment(new Date()).get('year').toString()
  const months = moment.months()
  return <div className='monthly-receipt-list'>
    {arr.map(item => {
      const itemMonth = item.split('/')[0]
      const itemYear = item.split('/')[1]
      const receiptsForMonth = receipts.filter(receipt => {
        const year = (moment(new Date(receipt.date)).get('year')).toString()
        const month = (moment(new Date(receipt.date)).get('months') + 1).toString()
        return year === itemYear && month === itemMonth
      })
      return <div key={item}>
        <div className='month'>{`${months[parseInt(itemMonth) - 1]} ${itemYear === currentYear ? '' : itemYear}`}</div>
        <div className='list-group receipt-list'>
          {receiptsForMonth.map((receipt, i) => {
            return <div key={i} className='list-group-item'>
              <ReceiptItem
                handleClick={() => itemClickHandle(receipt.number)}
                defaultRepeat={defaultRepeat}
                repeatButton={repeatButton}
                number={receipt.number}
                leftText={receipt.prescriber}
                medicines={receipt.medicines}
                date={receipt.date} />
            </div>
          })}
        </div>
      </div>
    })}
  </div>
}

const ReceiptsAlphabeticalOrder = ({receipts, itemClickHandle, defaultRepeat, repeatButton}) => {
  const receiptsByPatient = receipts.sort(sortByDoctor)
  const sett = new Set(receiptsByPatient.map(receipt => receipt.prescriber.split('')[0]))
  const arr = Array.from(sett.values())
  return <div className='monthly-receipt-list'>
    {arr.map(item => {
      const itemInitial = item
      const receiptsForInitial = receipts.filter(receipt => {
        const patientInitial = receipt.prescriber.split('')[0]
        return itemInitial === patientInitial
      })
      return <div key={item}>
        <div className='month'>{item}</div>
        <div className='list-group receipt-list'>
          {receiptsForInitial.map((receipt, i) => {
            return <div key={i} className='list-group-item'>
              <ReceiptItem
                handleClick={() => itemClickHandle(receipt.number)}
                defaultRepeat={defaultRepeat}
                repeatButton={repeatButton}
                number={receipt.number}
                leftText={receipt.prescriber}
                medicines={receipt.medicines}
                date={receipt.date} />
            </div>
          })}
        </div>
      </div>
    })}
  </div>
}

const TabsView = (props) => {
  return <div className='top-margin'>
    <TabsContainer marginBody={'small'} content={[
      {
        hint: 'Data',
        label: <span className='fas fa-calendar' />,
        content: <ReceiptsByMonth {...props} />
      },
      {
        hint: 'Ordem Alfabética',
        label: <span style={{fontWeight: 900}}>Aa</span>,
        content: <ReceiptsAlphabeticalOrder {...props} />
      }
    ]} />
  </div>
}

const filterReceiptsByDate = (date, arr) => {
  return arr.filter(receipt => {
    console.log(date)
    const receiptDate = moment(new Date(receipt.date)).format('L')
    const searchDate = moment(date, 'DD/MM/YYYY').format('L')
    console.log(receiptDate)
    console.log(searchDate)
    return receiptDate === searchDate
  })
}

const filterReceiptsByPrescriber = (doctor, arr) => {
  return arr.filter(receipt => {
    return receipt.prescriber.toLowerCase().includes(doctor.toLowerCase())
  })
}

const filterReceiptsByMedicine = (medicine, arr) => {
  const receipts = []
  arr.forEach(receipt => {
    const meds = receipt.medicines.filter(med => {
      return med.name.toLowerCase().includes(medicine.toLowerCase())
    })
    if (meds.length > 0) {
      receipts.push(receipt)
    }
  })
  return receipts
}

const filterReceipts = (search, arr) => {
  console.log()
  if (!search) {
    return arr
  } else {
    const receipts = []
    receipts.push(...filterReceiptsByDate(search, arr))
    receipts.push(...filterReceiptsByMedicine(search, arr))
    receipts.push(...filterReceiptsByPrescriber(search, arr))
    return receipts
  }
}

export default class ReceiptList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      search: ''
    }
  }

  render () {
    const { receipts, repeatButton, defaultRepeat, itemClickHandle, searchAvailable } = this.props
    const filteredReceipts = filterReceipts(this.state.search, receipts)
    return <div className='receipt-selector container-fluid'>
      {searchAvailable
        ? <div className='input-group patient-number-input'>
          <input
            value={this.state.search}
            type='text'
            onChange={e => this.setState({search: e.target.value})}
            className='form-control'
            placeholder='Data, Médico, Medicamento' />
          <div className='input-group-btn'>
            <Button
              text={<span className='glyphicon glyphicon-search' />}
              buttontype={'btn btn-info'}
              type={'button'} />
          </div>
        </div>
        : null
      }
      {filteredReceipts.length
        ? <TabsView
          itemClickHandle={itemClickHandle}
          receipts={filteredReceipts}
          defaultRepeat={defaultRepeat}
          repeatButton={repeatButton} />
        : <div className='jumbotron margin-top' style={{borderRadius: '20px'}}>
          <h3 className='text-center'>
            <p>Sem Receitas</p>
          </h3>
        </div>
      }
    </div>
  }
}

ReceiptList.propTypes = {
  itemClickHandle: PropTypes.func.isRequired,
  receipts: PropTypes.array.isRequired,
  repeatButton: PropTypes.object,
  defaultRepeat: PropTypes.bool,
  searchAvailable: PropTypes.bool
}

ReceiptList.defaultProps = {
  searchAvailable: true,
  defaultRepeat: false
}
