import patient from './patient'
import doctor from './doctor'
import basket from './basket'
import search from './search'
import modals from './modals'
import medicineData from './medicineData'
import {reducer as notificationsReducer} from 'reapop'

const reducers = {patient, doctor, basket, search, medicineData, modals, notifications: notificationsReducer()}

export default reducers
