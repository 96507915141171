import defaultDoctor from '../../../../../resources/images/user-md-icon-green.svg'
import { Link } from 'react-router-dom'
import { Button } from '../../../../common/Button/Button'
import React from 'react'
import ReactTooltip from 'react-tooltip'

import './style.css'
import testProfessionals from '../../../../../test/testProfessionals'
import MedicalStaff from '../../../../../models/MedicalStaff'

const color = 'white'

const ProfessionalStaffView = ({selectedNumber}) => {
  const obj = testProfessionals.find(p => p.id === selectedNumber)
  if (obj) {
    const person = new MedicalStaff(obj.id, obj.name, obj.birth, obj.photo, obj.professionalNumber, obj.specialty, obj.gender, obj.profession)
    const { name, photo, professionalNumber } = person
    return <div className='doctor-container'>
      <div className='id-container'>
        <div className='photo'>
          <img className='profile-photo' src={photo || defaultDoctor} alt='User Profile' />
        </div>
        <div className='information'>
          <div className='name'>{name}</div>
          <div data-tip={'Cédula Profissional'} className='number'>{professionalNumber}</div>
          {/* <div className='secondary-info'> */}
          {/*  <div><span className='text-muted'>Profissão: </span><span style={{fontWeight: '900'}}>{getProfession(gender, profession)}</span></div> */}
          {/*  <div><span className='text-muted'>Especialidade: </span><span style={{fontWeight: '900'}}>{specialty}</span></div> */}
          {/* </div> */}
        </div>
      </div>
      <div className='actions-container'>
        <div className='buttons'>
          <Link to={`/messages/${selectedNumber}`}>
            <div data-tip={'Mensagens'}>
              <Button type={'button'} buttonType={'btn-info'} text={<span style={{color}} className='fa fa-comments fa-2x' />} />
            </div>
          </Link>
        </div>
      </div>
      <ReactTooltip delayShow={300} effect='solid' type='light' />
    </div>
  }
  return null
}
export default ProfessionalStaffView
