class Meal {
  constructor (description, units) {
    this._description = description
    this._units = units
  }

  get description () {
    return this._description
  }

  get units () {
    return this._units
  }

  static getTimes () {
    return [
      { value: 'before', displayValue: 'Antes', sorter: 1 },
      { value: 'during', displayValue: 'Durante', sorter: 2 },
      { value: 'after', displayValue: 'Depois', sorter: 3 },
      { value: 'N_A', displayValue: 'N/A', sorter: 4 }
    ]
  }

  static getValues () {
    return [
      { value: 'fasting', displayValue: 'Em Jejum', sorter: 1 },
      { value: 'breakfast', displayValue: 'Pequeno-almoço', sorter: 2 },
      { value: 'lunch', displayValue: 'Almoço', sorter: 3 },
      { value: 'snack', displayValue: 'Lanche', sorter: 4 },
      { value: 'dinner', displayValue: 'Jantar', sorter: 5 },
      { value: 'supper', displayValue: 'Ceia', sorter: 6 },
      { value: 'beforeSleep', displayValue: 'Ao Deitar', sorter: 7 }
    ]
  }
}

class SpecificPeriod {
  constructor (type, interval, start, units) {
    this._type = type
    this._interval = interval
    this._start = start
    this._units = units
  }

  get type () {
    return this._type
  }

  get interval () {
    return this._interval
  }

  get start () {
    return this._start
  }

  get units () {
    return this._units
  }

  static getTypes () {
    return [
      { value: 'minutes', displayValue: 'Minutos' },
      { value: 'hours', displayValue: 'Horas' },
      { value: 'days', displayValue: 'Dias' },
      { value: 'weeks', displayValue: 'Semanas' },
      { value: 'months', displayValue: 'Meses' }
    ]
  }

  static getValues (type) {
    switch (type) {
      case 'minutes':
        return [
          { value: '0000', displayValue: '00:00' },
          { value: '0010', displayValue: '00:10' },
          { value: '0020', displayValue: '00:20' },
          { value: '0030', displayValue: '00:30' },
          { value: '0040', displayValue: '00:40' },
          { value: '0050', displayValue: '00:50' },
          { value: '0100', displayValue: '01:00' },
          { value: '0110', displayValue: '01:10' },
          { value: '0120', displayValue: '01:20' },
          { value: '0130', displayValue: '01:30' },
          { value: '0140', displayValue: '01:40' },
          { value: '0150', displayValue: '01:50' },
          { value: '0200', displayValue: '02:00' },
          { value: '0210', displayValue: '02:10' },
          { value: '0220', displayValue: '02:20' },
          { value: '0230', displayValue: '02:30' },
          { value: '0240', displayValue: '02:40' },
          { value: '0250', displayValue: '02:50' },
          { value: '0300', displayValue: '03:00' },
          { value: '0310', displayValue: '03:10' },
          { value: '0320', displayValue: '03:20' },
          { value: '0330', displayValue: '03:30' },
          { value: '0340', displayValue: '03:40' },
          { value: '0350', displayValue: '03:50' },
          { value: '0400', displayValue: '04:00' },
          { value: '0410', displayValue: '04:10' },
          { value: '0420', displayValue: '04:20' },
          { value: '0430', displayValue: '04:30' },
          { value: '0440', displayValue: '04:40' },
          { value: '0450', displayValue: '04:50' },
          { value: '0500', displayValue: '05:00' },
          { value: '0510', displayValue: '05:10' },
          { value: '0520', displayValue: '05:20' },
          { value: '0530', displayValue: '05:30' },
          { value: '0540', displayValue: '05:40' },
          { value: '0550', displayValue: '05:50' },
          { value: '0600', displayValue: '06:00' },
          { value: '0610', displayValue: '06:10' },
          { value: '0620', displayValue: '06:20' },
          { value: '0630', displayValue: '06:30' },
          { value: '0640', displayValue: '06:40' },
          { value: '0650', displayValue: '06:50' },
          { value: '0700', displayValue: '07:00' },
          { value: '0710', displayValue: '07:10' },
          { value: '0720', displayValue: '07:20' },
          { value: '0730', displayValue: '07:30' },
          { value: '0740', displayValue: '07:40' },
          { value: '0750', displayValue: '07:50' },
          { value: '0800', displayValue: '08:00' },
          { value: '0810', displayValue: '08:10' },
          { value: '0820', displayValue: '08:20' },
          { value: '0830', displayValue: '08:30' },
          { value: '0840', displayValue: '08:40' },
          { value: '0850', displayValue: '08:50' },
          { value: '0900', displayValue: '09:00' },
          { value: '0910', displayValue: '09:10' },
          { value: '0920', displayValue: '09:20' },
          { value: '0930', displayValue: '09:30' },
          { value: '0940', displayValue: '09:40' },
          { value: '0950', displayValue: '09:50' },
          { value: '1000', displayValue: '10:00' },
          { value: '1010', displayValue: '10:10' },
          { value: '1020', displayValue: '10:20' },
          { value: '1030', displayValue: '10:30' },
          { value: '1040', displayValue: '10:40' },
          { value: '1050', displayValue: '10:50' },
          { value: '1100', displayValue: '11:00' },
          { value: '1110', displayValue: '11:10' },
          { value: '1120', displayValue: '11:20' },
          { value: '1130', displayValue: '11:30' },
          { value: '1140', displayValue: '11:40' },
          { value: '1150', displayValue: '11:50' },
          { value: '1200', displayValue: '12:00' },
          { value: '1210', displayValue: '12:10' },
          { value: '1220', displayValue: '12:20' },
          { value: '1230', displayValue: '12:30' },
          { value: '1240', displayValue: '12:40' },
          { value: '1250', displayValue: '12:50' },
          { value: '1300', displayValue: '13:00' },
          { value: '1310', displayValue: '13:10' },
          { value: '1320', displayValue: '13:20' },
          { value: '1330', displayValue: '13:30' },
          { value: '1340', displayValue: '13:40' },
          { value: '1350', displayValue: '13:50' },
          { value: '1400', displayValue: '14:00' },
          { value: '1410', displayValue: '14:10' },
          { value: '1420', displayValue: '14:20' },
          { value: '1430', displayValue: '14:30' },
          { value: '1440', displayValue: '14:40' },
          { value: '1450', displayValue: '14:50' },
          { value: '1500', displayValue: '15:00' },
          { value: '1510', displayValue: '15:10' },
          { value: '1520', displayValue: '15:20' },
          { value: '1530', displayValue: '15:30' },
          { value: '1540', displayValue: '15:40' },
          { value: '1550', displayValue: '15:50' },
          { value: '1600', displayValue: '16:00' },
          { value: '1610', displayValue: '16:10' },
          { value: '1620', displayValue: '16:20' },
          { value: '1630', displayValue: '16:30' },
          { value: '1640', displayValue: '16:40' },
          { value: '1650', displayValue: '16:50' },
          { value: '1700', displayValue: '17:00' },
          { value: '1710', displayValue: '17:10' },
          { value: '1720', displayValue: '17:20' },
          { value: '1730', displayValue: '17:30' },
          { value: '1740', displayValue: '17:40' },
          { value: '1750', displayValue: '17:50' },
          { value: '1800', displayValue: '18:00' },
          { value: '1810', displayValue: '18:10' },
          { value: '1820', displayValue: '18:20' },
          { value: '1830', displayValue: '18:30' },
          { value: '1840', displayValue: '18:40' },
          { value: '1850', displayValue: '18:50' },
          { value: '1900', displayValue: '19:00' },
          { value: '1910', displayValue: '19:10' },
          { value: '1920', displayValue: '19:20' },
          { value: '1930', displayValue: '19:30' },
          { value: '1940', displayValue: '19:40' },
          { value: '1950', displayValue: '19:50' },
          { value: '2000', displayValue: '20:00' },
          { value: '2010', displayValue: '20:10' },
          { value: '2020', displayValue: '20:20' },
          { value: '2030', displayValue: '20:30' },
          { value: '2040', displayValue: '20:40' },
          { value: '2050', displayValue: '20:50' },
          { value: '2100', displayValue: '21:00' },
          { value: '2110', displayValue: '21:10' },
          { value: '2120', displayValue: '21:20' },
          { value: '2130', displayValue: '21:30' },
          { value: '2140', displayValue: '21:40' },
          { value: '2150', displayValue: '21:50' },
          { value: '2200', displayValue: '22:00' },
          { value: '2210', displayValue: '22:10' },
          { value: '2220', displayValue: '22:20' },
          { value: '2230', displayValue: '22:30' },
          { value: '2240', displayValue: '22:40' },
          { value: '2250', displayValue: '22:50' },
          { value: '2300', displayValue: '23:00' },
          { value: '2310', displayValue: '23:10' },
          { value: '2320', displayValue: '23:20' },
          { value: '2330', displayValue: '23:30' },
          { value: '2340', displayValue: '23:40' },
          { value: '2350', displayValue: '23:50' }
        ]
      case 'hours':
        return [
          { value: '0000', displayValue: '00:00' },
          { value: '0100', displayValue: '01:00' },
          { value: '0200', displayValue: '02:00' },
          { value: '0300', displayValue: '03:00' },
          { value: '0400', displayValue: '04:00' },
          { value: '0500', displayValue: '05:00' },
          { value: '0600', displayValue: '06:00' },
          { value: '0700', displayValue: '07:00' },
          { value: '0800', displayValue: '08:00' },
          { value: '0900', displayValue: '09:00' },
          { value: '1000', displayValue: '10:00' },
          { value: '1100', displayValue: '11:00' },
          { value: '1200', displayValue: '12:00' },
          { value: '1300', displayValue: '13:00' },
          { value: '1400', displayValue: '14:00' },
          { value: '1500', displayValue: '15:00' },
          { value: '1600', displayValue: '16:00' },
          { value: '1700', displayValue: '17:00' },
          { value: '1800', displayValue: '18:00' },
          { value: '1900', displayValue: '19:00' },
          { value: '2000', displayValue: '20:00' },
          { value: '2100', displayValue: '21:00' },
          { value: '2200', displayValue: '22:00' },
          { value: '2300', displayValue: '23:00' }
        ]
      case 'days':
        return [
          { value: '1', displayValue: '1' },
          { value: '2', displayValue: '2' },
          { value: '3', displayValue: '3' },
          { value: '4', displayValue: '4' },
          { value: '5', displayValue: '5' },
          { value: '6', displayValue: '6' },
          { value: '7', displayValue: '7' },
          { value: '8', displayValue: '8' },
          { value: '9', displayValue: '9' },
          { value: '10', displayValue: '10' },
          { value: '11', displayValue: '11' },
          { value: '12', displayValue: '12' },
          { value: '13', displayValue: '13' },
          { value: '14', displayValue: '14' },
          { value: '15', displayValue: '15' },
          { value: '16', displayValue: '16' },
          { value: '17', displayValue: '17' },
          { value: '18', displayValue: '18' },
          { value: '19', displayValue: '19' },
          { value: '20', displayValue: '20' },
          { value: '21', displayValue: '21' },
          { value: '22', displayValue: '22' },
          { value: '23', displayValue: '23' },
          { value: '24', displayValue: '24' },
          { value: '25', displayValue: '25' },
          { value: '26', displayValue: '26' },
          { value: '27', displayValue: '27' },
          { value: '28', displayValue: '28' },
          { value: '29', displayValue: '29' },
          { value: '30', displayValue: '30' },
          { value: '31', displayValue: '31' }
        ]
      case 'weeks':
        return [
          { value: 'monday', displayValue: 'Segunda-Feira' },
          { value: 'tuesday', displayValue: 'Terça-Feira' },
          { value: 'wednesday', displayValue: 'Quarta-Feira' },
          { value: 'thursday', displayValue: 'Quinta-Feira' },
          { value: 'friday', displayValue: 'Sexta-Feira' },
          { value: 'saturday', displayValue: 'Sábado' },
          { value: 'sunday', displayValue: 'Domingo' }
        ]
      case 'months':
        return [
          { value: 'january', displayValue: 'Janeiro' },
          { value: 'february', displayValue: 'Fevereiro' },
          { value: 'march', displayValue: 'Março' },
          { value: 'april', displayValue: 'Abril' },
          { value: 'may', displayValue: 'Maio' },
          { value: 'june', displayValue: 'Junho' },
          { value: 'july', displayValue: 'Julho' },
          { value: 'august', displayValue: 'Agosto' },
          { value: 'september', displayValue: 'Setembro' },
          { value: 'october', displayValue: 'Outubro' },
          { value: 'november', displayValue: 'Novembro' },
          { value: 'december', displayValue: 'Dezembro' }
        ]
      default:
        return []
    }
  }
}

class SpecificTime {
  constructor (type, value, units) {
    this._type = type
    this._value = value
    this._units = units
  }

  get type () {
    return this._type
  }

  get value () {
    return this._value
  }

  get units () {
    return this._units
  }

  static getTypes () {
    return [
      {value: 'hours', displayValue: 'Horas'},
      {value: 'daysOfWeek', displayValue: 'Dias da Semana'},
      {value: 'daysOfMonth', displayValue: 'Dias do Mês'}
    ]
  }

  static getValue (type) {
    switch (type) {
      case 'hours':
        return [
          { value: '0000', displayValue: '00:00' },
          { value: '0100', displayValue: '01:00' },
          { value: '0200', displayValue: '02:00' },
          { value: '0300', displayValue: '03:00' },
          { value: '0400', displayValue: '04:00' },
          { value: '0500', displayValue: '05:00' },
          { value: '0600', displayValue: '06:00' },
          { value: '0700', displayValue: '07:00' },
          { value: '0800', displayValue: '08:00' },
          { value: '0900', displayValue: '09:00' },
          { value: '1000', displayValue: '10:00' },
          { value: '1100', displayValue: '11:00' },
          { value: '1200', displayValue: '12:00' },
          { value: '1300', displayValue: '13:00' },
          { value: '1400', displayValue: '14:00' },
          { value: '1500', displayValue: '15:00' },
          { value: '1600', displayValue: '16:00' },
          { value: '1700', displayValue: '17:00' },
          { value: '1800', displayValue: '18:00' },
          { value: '1900', displayValue: '19:00' },
          { value: '2000', displayValue: '20:00' },
          { value: '2100', displayValue: '21:00' },
          { value: '2200', displayValue: '22:00' },
          { value: '2300', displayValue: '23:00' }
        ]
      case 'daysOfWeek':
        return [
          { value: 'monday', displayValue: 'Segunda-Feira', sorter: '1' },
          { value: 'tuesday', displayValue: 'Terça-Feira', sorter: '2' },
          { value: 'wednesday', displayValue: 'Quarta-Feira', sorter: '3' },
          { value: 'thursday', displayValue: 'Quinta-Feira', sorter: '4' },
          { value: 'friday', displayValue: 'Sexta-Feira', sorter: '5' },
          { value: 'saturday', displayValue: 'Sábado', sorter: '6' },
          { value: 'sunday', displayValue: 'Domingo', sorter: '7' }
        ]
      case 'daysOfMonth':
        return [
          { value: '1', displayValue: '01' },
          { value: '2', displayValue: '02' },
          { value: '3', displayValue: '03' },
          { value: '4', displayValue: '04' },
          { value: '5', displayValue: '05' },
          { value: '6', displayValue: '06' },
          { value: '7', displayValue: '07' },
          { value: '8', displayValue: '08' },
          { value: '9', displayValue: '09' },
          { value: '10', displayValue: '10' },
          { value: '11', displayValue: '11' },
          { value: '12', displayValue: '12' },
          { value: '13', displayValue: '13' },
          { value: '14', displayValue: '14' },
          { value: '15', displayValue: '15' },
          { value: '16', displayValue: '16' },
          { value: '17', displayValue: '17' },
          { value: '18', displayValue: '18' },
          { value: '19', displayValue: '19' },
          { value: '20', displayValue: '20' },
          { value: '21', displayValue: '21' },
          { value: '22', displayValue: '22' },
          { value: '23', displayValue: '23' },
          { value: '24', displayValue: '24' },
          { value: '25', displayValue: '25' },
          { value: '26', displayValue: '26' },
          { value: '27', displayValue: '27' },
          { value: '28', displayValue: '28' },
          { value: '29', displayValue: '29' },
          { value: '30', displayValue: '30' },
          { value: '31', displayValue: '31' }
        ]
      default:
        return []
    }
  }
}

class Weaning {
  constructor (type, interval, reductionPercentage, startDate) {
    this._type = type
    this._interval = interval
    this._reductionPercentage = reductionPercentage
    this._startDate = startDate
  }

  get type () {
    return this._type
  }

  get interval () {
    return this._interval
  }

  get reductionPercentage () {
    return this._reductionPercentage
  }

  get startDate () {
    return this._startDate
  }

  static getTypes () {
    return [
      { value: 'minutes', displayValue: 'Minutos' },
      { value: 'hours', displayValue: 'Horas' },
      { value: 'days', displayValue: 'Dias' },
      { value: 'semanas', displayValue: 'Semanas' },
      { value: 'months', displayValue: 'Meses' }
    ]
  }
}

export { SpecificPeriod, SpecificTime, Meal, Weaning }
