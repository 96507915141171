import v4 from 'uuid/v4'
import GuideEntry from '../models/Guides/GuideEntry'
import Guide from '../models/Guides/Guide'

const periodsData = [
  {
    type: 'meal',
    data: {
      entries: [
        'dinner',
        'lunch'
      ],
      units: '2'
    }
  },
  {
    type: 'meal',
    data: {
      time: 'before',
      entries: [
        'dinner',
        'lunch'
      ],
      units: '2'
    }
  },
  {
    type: 'specificPeriod',
    data: {
      entries: [
        {
          periodType: 'hours',
          interval: '2',
          startAt: '1200'
        }
      ],
      units: '0.5'
    }
  },
  {
    type: 'weaning',
    data: {
      entries: [
        {
          startDate: '2018/06/30',
          minimumUnits: '10',
          periodType: 'hours',
          interval: '2',
          reductionPercentage: '10'
        }
      ]
    }
  },
  {
    type: 'sos',
    data: {
      entries: [
        {
          condition: 'Dor',
          until: 'Dor passar'
        }
      ],
      units: '1'
    }
  }
]
const periodsData2 = [
  {
    type: 'meal',
    data: {
      entries: [
        'lunch'
      ],
      units: '1'
    }
  },
  {
    type: 'meal',
    data: {
      entries: [
        'dinner'
      ],
      units: '2'
    }
  }
]

const entriesData = [
  {
    id: v4(),
    designation: 'Paracetamol 500',
    isGeneric: true,
    dosage: '2x dia',
    start: '01/06/2019',
    duration: {
      value: '5',
      type: 'days'
    },
    unitType: {
      name: 'Comprimido',
      id: 271
    },
    observation: 'observações',
    periods: periodsData
  }
]
const entriesData2 = [
  {
    id: v4(),
    designation: 'Paracetamol 1g',
    isGeneric: true,
    dosage: '3x dia',
    start: '02/06/2019',
    duration: {
      value: '3',
      type: 'days'
    },
    unitType: {
      name: 'Comprimido',
      id: 271
    },
    observation: 'observações 2',
    periods: periodsData2
  }
]

const guides = [
  {
    id: v4(),
    prescriber: 'Ricardo Pimenta-Araújo',
    date: '2019/05/30',
    receiptNumber: '3011000041468888005',
    entries: entriesData
  },
  {
    id: v4(),
    prescriber: 'Ricardo Pimenta-Araújo',
    date: '2019/05/31',
    receiptNumber: '3011000041468888006',
    entries: entriesData2
  }
]

const testGuides = guides.map(guide => {
  const entries = guide.entries.map(GuideEntry.map)
  return Guide.map(guide, entries)
})

export default testGuides
