import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import PatientSearchContainer from './PatientSearchContainer/PatientSearchContainer'
import { patientActions } from '../../../redux/patient'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { medicineDataActions } from '../../../redux/medicineData'

const BigButton = ({text, icon, link}) => {
  return <div className='main-navigation-button-container'>
    <Link to={link}>
      <button className='main-navigation-button'>
        <span className={`fas ${icon} fa-3x responsive-icon`} />
        <div>{text}</div>
      </button>
    </Link>
  </div>
}

class Home extends React.Component {
  handlePatientFound (patientHealthcareNumber) {
    this.props.history.push(`/${patientHealthcareNumber}/prescription/prescribe`)
  }

  componentDidMount () {
    this.props.getDCIS()
    this.props.getPharmaceuticalForms()
    this.props.getAdministrationWays()
    this.props.getProductTypes()
    this.props.getPackagingTypes()
    this.props.getPathologyDiplomas()
  }

  render () {
    const { match, history } = this.props
    return <div className='centered-main-navigation'>
      {/* <div className='greetings'><h2 className='text-center'><p>Bem-Vindo,</p><p>Dr. Ricardo Pimenta-Araújo</p></h2></div> */}
      <div className='jumbotron'>
        <Link to={'/profile'}>
          <div className='doctor-image'>
            <span className='fas fa-user-md fa-6x' />
          </div>
        </Link>
        <div className='greetings'><h2 className='text-center'><p>Bem-Vindo,</p><p>Dr. Ricardo Pimenta-Araújo</p></h2></div>
      </div>
      <div className='col-xs-9 col-sm-6 col-md-4  patient-search-container'>
        <PatientSearchContainer patientFound={this.handlePatientFound.bind(this)} history={history} match={match} />
      </div>
      <div className='main-content'>
        <div className='flex-inner-item'>
          <BigButton text={'Contactos'} icon={'fa-users'} link='/contacts' />
        </div>
        <div className='flex-inner-item'>
          <BigButton text={'Mensagens'} icon={'fa-comments'} link='/messages' />
        </div>
      </div>
    </div>
  }
}

export { BigButton }

function mapStateToProps ({patient, router}) {
  return {
    currentUrl: router.location.pathname,
    loading: patient.fetching,
    error: patient.error,
    patientHealthcareNumber: patient.healthcareNumber,
    patientExists: !!patient.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...patientActions, ...medicineDataActions}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
