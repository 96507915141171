const ALLERGY_TYPES = {
  MEDICATION: 'medicative',
  FOOD: 'food',
  INSECTS: 'insects',
  DOMESTIC_ANIMALS: 'domesticAnimals',
  FUNGI: 'fungi',
  HOME_DUST: 'homeDust',
  POLLEN: 'pollen',
  LATEX: 'latex',
  getDescription: (type) => {
    switch (type) {
      case 'medicative':
        return 'Medicamentosa'
      case 'food':
        return 'Alimentar'
      case 'insects':
        return 'Insectos'
      case 'domesticAnimals':
        return 'Animais Domésticos'
      case 'fungi':
        return 'Fungos'
      case 'homeDust':
        return 'Pó da Casa'
      case 'pollen':
        return 'Pólens'
      case 'latex':
        return 'Latex'
      default:
        return ''
    }
  }
}

const THERAPEUTICS_TYPE = {
  POSITIVE: 'yes',
  NEGATIVE: 'no',
  IRREGULAR: 'irregular',
  getDescription: (type) => {
    switch (type) {
      case 'yes':
        return 'Sim'
      case 'no':
        return 'Não'
      case 'irregular':
        return 'Irregular'
      default:
        return ''
    }
  }
}

const SEVERITY = {
  MILD: 'mild',
  MODERATE: 'moderate',
  SEVERE: 'severe',
  getDescription: (type) => {
    switch (type) {
      case 'mild':
        return 'Ligeira'
      case 'moderate':
        return 'Moderada'
      case 'severe':
        return 'Grave'
      default:
        return ''
    }
  }
}

export { ALLERGY_TYPES, THERAPEUTICS_TYPE, SEVERITY }

export default class Allergy {
  constructor (id, type, discoveryDate, substance, severity, therapeutics) {
    this._id = id
    this._type = type
    this._discoveryDate = discoveryDate
    this._substance = substance
    this._severity = severity
    this._therapeutics = therapeutics
  }

  get id () {
    return this._id
  }

  get type () {
    return this._type
  }

  get discoveryDate () {
    return this._discoveryDate
  }

  get substance () {
    return this._substance
  }

  get severity () {
    return this._severity
  }

  get therapeutics () {
    return this._therapeutics
  }
}
