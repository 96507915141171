import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { basketActions } from '../../../../../../../redux/basket'
import connect from 'react-redux/es/connect/connect'

import commercialIcon from '../../../../../../../resources/images/medicine-icons/icon_commercial.svg'
import genericIcon from '../../../../../../../resources/images/medicine-icons/icon_generic.svg'

import './style.css'
import { DisabledInput } from '../../../../../../common/formComponents/Input'

const Indicator = ({ name, generic, dose, medicineType, packageType, units, administrationType, commercialName }) => {
  return <Fragment>
    <span className='main'>
      {generic
        ? <img className={`custom-icon center-with-text`} src={genericIcon} alt='Generic Medicine' />
        : <img className={`custom-icon center-with-text`} src={commercialIcon} alt='Commercial Medicine' />
      }
      {` ${name} [${commercialName}], ${medicineType.name}`}
    </span>
    {dose && <span className='secondary'>{dose}</span>}
    {packageType.name && <span className='secondary'>{packageType.name}</span>}
    {units && <span className='secondary'>{units} un.</span>}
    {administrationType && <span className='secondary'>{administrationType.name}</span>}
  </Fragment>
}

const ConfirmationMedicineView = ({ editable, generic, medicineType, packageType, activeComponent, commercialName, administrationType, dose, units, quantity, dosage, renewable = 0, changeItem, itemIndex, justification }) => {
  return <div className={`col-xs-12 no-side-padding static-medicine ${!dosage ? 'no-info' : ''}`}>
    <div>
      <div className='col-xs-12 static-medication-info-line no-side-padding'>
        <Indicator
          administrationType={administrationType}
          packageType={packageType}
          generic={generic}
          medicineType={medicineType}
          name={activeComponent}
          commercialName={commercialName}
          dose={dose}
          units={units} />
      </div>
    </div>
    <div className='bottom-line'>
      <div className={` no-side-padding ${!dosage ? 'no-info' : ''}`}>
        <div className='static-medication-info-line'>
          <div className={`form-group ${!dosage ? 'has-warning has-feedback' : ''}`}>
            {!editable
              ? <DisabledInput content={dosage} withWarning={dosage === ''} />
              : <input
                disabled={!editable}
                type='text'
                className='form-control'
                placeholder='Posologia'
                value={dosage}
                onChange={e => changeItem(itemIndex, { dosage: e.target.value })}
                aria-describedby={'inputWarning2Status'} />
            }
            {!dosage && editable
              ? <Fragment>
                <span className='glyphicon glyphicon-remove form-control-feedback' aria-hidden='true' />
                <span id='inputWarning2Status' className='sr-only'>(warning)</span>
              </Fragment>
              : null
            }
          </div>
        </div>
      </div>
      <div className=' no-side-padding badges-container'>
        <div className='pull-right'>
          <span className='badge'>x{quantity}</span>
          <span className='badge'><i className='fas fa-recycle' /> {renewable === 1 ? 'Sim' : 'Não'}</span>
        </div>
      </div>
    </div>
  </div>
}

ConfirmationMedicineView.propTypes = {
  activeComponent: PropTypes.string,
  commercialName: PropTypes.string,
  medicineType: PropTypes.shape({ ID: PropTypes.number, name: PropTypes.string }).isRequired,
  packageType: PropTypes.shape({ ID: PropTypes.number, name: PropTypes.string }).isRequired,
  dose: PropTypes.string,
  administrationType: PropTypes.object,
  units: PropTypes.number,
  dosage: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  renewable: PropTypes.number.isRequired,
  designation: PropTypes.string,
  customMedicine: PropTypes.object,
  generic: PropTypes.bool.isRequired,
  editable: PropTypes.bool
}

ConfirmationMedicineView.defaultProps = {
  renewable: 0,
  editable: true,
  dosage: ''
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ changeItem: basketActions.changeItem }, dispatch)
}

export default connect(null, mapDispatchToProps)(ConfirmationMedicineView)
