import { Button } from '../../../../../../../common/Button/Button'
import React, { Fragment } from 'react'
import { Panel } from '../../../../../../../common/Panel/Panel'
import { UnitsInsertion } from './GuideEntryComponent'
import { SpecificTime } from '../../../../../../../../models/Guides/Periods'
import GuideEntry from '../../../../../../../../models/Guides/GuideEntry'

const SpecificTimePeriodViewer = ({units, entries, timeType, unitsPosition = 'right', removeItem}) => {
  const values = SpecificTime.getValue(timeType)
  const orderedEntries = timeType === 'daysOfWeek'
    ? entries.sort((a, b) => {
      console.log('1')
      const dayA = parseInt(values.filter(item => item.value === a)[0].sorter)
      const dayB = parseInt(values.filter(item => item.value === b)[0].sorter)
      if (dayA < dayB) {
        return -1
      } else if (dayA > dayB) {
        return 1
      } else {
        return 0
      }
    })
    : entries.sort((a, b) => {
      console.log('2')
      return a - b
    })
  console.log(orderedEntries)
  return <div className='col-xs-12 no-side-padding guide-entry-line'>
    <div className='col-sm-11 no-side-padding'>
      {unitsPosition === 'right'
        ? <div className='periods'>
          {orderedEntries.map((item, i) => {
            const description = SpecificTime.getValue(timeType).filter(type => type.value === item)[0]
            return <div className='period-item' key={i}>
              {`${description.displayValue}${timeType === 'daysOfMonth' ? 'º dia do mês' : ''}`}
            </div>
          })}
          <div className='line'><i className='fas fa-long-arrow-alt-right' /></div>
          <div className='units'>{`${GuideEntry.getUnit(units).displayValue} un.`}</div>
        </div>
        : <div className='periods'>
          <div className='units'>{`${GuideEntry.getUnit(units).displayValue} un.`}</div>
          <div className='line'><i className='fas fa-long-arrow-alt-right' /></div>
          {orderedEntries.map((item, i) => {
            const description = SpecificTime.getValue(timeType).filter(type => type.value === item)[0]
            return <div className='period-item' key={i}>
              {`${description.displayValue}${timeType === 'daysOfMonth' ? 'º dia do mês' : ''}`}
            </div>
          })}
        </div>
      }
    </div>
    {removeItem
      ? <div className='col-sm-1 no-side-padding' style={{marginTop: '4px'}}>
        <Button type={'button'} text={<i className='fas fa-trash-alt' />} buttonType={'btn-danger btn-sm pull-right'} onClick={removeItem} />
      </div>
      : null
    }
  </div>
}

class SpecificTimeComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      units: '1',
      entries: []
    }
  }

  handleChange (name, value) {
    this.setState({
      [name]: value,
      entries: []
    })
  }

  handleCheckBoxChange (value, checked) {
    const entries = [...this.state.entries]
    if (checked) {
      entries.push(value)
      this.setState({
        entries
      })
    } else {
      const index = entries.findIndex(item => item === value)
      entries.splice(index, 1)
      this.setState({
        entries
      })
    }
  }

  handleSubmit (e) {
    e.preventDefault()
    const { entries, units, timeType } = this.state
    this.props.handleSubmit({
      type: 'specificTime',
      entries,
      timeType,
      units
    })
  }

  render () {
    const { timeType } = this.state
    const { unitType } = this.props
    return <Fragment>
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Panel heading={'Medicação em Horário Definido'} footer={<Fragment>
          <Button type={'button'} text={<span className='fas fa-trash-alt' />} buttonType={'btn-danger'} onClick={this.props.remove} />
          <Button type={'submit'} disabled={!this.state.entries.length} text={<span className='fas fa-plus' />} buttonType={'btn-success pull-right'} />
        </Fragment>}>
          <Fragment>
            <div className='form-group col-sm-4'>
              <label className='' style={{ textAlign: 'right' }}>Determinar</label>
              <div>
                <select
                  required
                  name='timeType'
                  className='form-control'
                  value={timeType || ''}
                  onChange={({target}) => this.handleChange(target.name, target.value)}>
                  <option value='' hidden />
                  {SpecificTime.getTypes().map(({ value, displayValue }, i) => (
                    <option
                      key={i}
                      value={value}>{displayValue}</option>))}
                </select>
              </div>
            </div>
            {timeType
              ? <div className='form-group col-sm-12'>
                {SpecificTime.getValue(timeType).map((item, i) => {
                  return <div className='checkbox-inline' key={i}>
                    <label className='checkbox-inline'>
                      <input checked={this.state.entries.findIndex(element => element === item.value) >= 0} onChange={e => this.handleCheckBoxChange(e.target.value, e.target.checked)} type='checkbox' value={item.value} />{item.displayValue}
                    </label>
                  </div>
                })}
              </div>
              : null
            }
            {timeType
              ? <UnitsInsertion defaultValue={'1'} handleChange={units => this.setState({ units })} unitType={unitType} />
              : null
            }
          </Fragment>
        </Panel>
      </form>
    </Fragment>
  }
}

export { SpecificTimePeriodViewer, SpecificTimeComponent }
