import React, { Fragment } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton
} from 'react-accessible-accordion'

import './style.css'
import FavoriteItem from './FavoriteItem'
import SectionTitle from '../../../../common/SectionTitle/SectionTitle'
import { Link } from 'react-router-dom'

const FavoritesList = ({favorites, editable, match}) => {
  return <div className='col-sm-10 col-sm-offset-1'>
    <Accordion allowZeroExpanded>
      {favorites.map(fav => {
        return <AccordionItem key={fav.id}>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Fragment>
                <i className='far fa-star' /> {fav.name}
              </Fragment>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className='clearfix'>
              <FavoriteItem editable={editable} currentUrl={match && match.url} favorite={fav} />
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      })}
    </Accordion>
  </div>
}

const FavoritesView = ({ favorites, match, editable }) => {
  return <div className='favorites-view'>
    {editable
      ? <SectionTitle
        rightField={editable ? <Link to={`${match.url}/add`}><button className='btn btn-success btn-sm'><span className='fas fa-plus' /></button></Link> : null}
        smallMargins
        size={'h4'}
        title='Favoritos' />
      : null
    }
    {favorites.length
      ? <FavoritesList editable={editable} favorites={favorites} match={match} />
      : <div className='no-favorites top-margin'>
        <span className='far fa-frown-open fa-3x' />
        <h3>Sem Favoritos.</h3>
      </div>
    }
  </div>
}

export default FavoritesView
