import React from 'react'

import { searchActions } from '../../../../../../../../redux/search'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SearchForm from './SearchForm'

const allSearchTypes = [
  'innAndCommercial',
  'freeForm'
]

class SearchFormContainer extends React.Component {
  render () {
    const {
      currentSearch,
      searchType,
      currentSearchType,
      currentAdminWay,
      formSubmitted,
      currentPharmForm,
      currentDosage,
      handleChange,
      onTypeChange,
      inFocus,
      pharmaceuticalForms,
      administrationWays,
      productTypes,
      dcis,
      packagingTypes
    } = this.props
    return <SearchForm
      dcis={dcis}
      packagingTypes={packagingTypes}
      pharmaceuticalForms={pharmaceuticalForms}
      productTypes={productTypes}
      administrationWays={administrationWays}
      inFocus={inFocus}
      searchTypes={allSearchTypes}
      formSubmitted={formSubmitted}
      currentSearch={currentSearch}
      currentDosage={currentDosage}
      currentSearchType={currentSearchType}
      currentAdminWay={currentAdminWay}
      currentPharmForm={currentPharmForm}
      searchType={searchType}
      handleChange={handleChange}
      onTypeChange={onTypeChange} />
  }
}

function mapStateToProps ({search, medicineData}) {
  const defaultAdminWay = medicineData.administrationWays.filter(item => item.id === search.adminWay)[0]
  const defaultPharmForm = medicineData.pharmaceuticalForms.filter(item => item.id === search.pharmForm)[0]
  return {
    dcis: medicineData.dcis,
    pharmaceuticalForms: medicineData.pharmaceuticalForms,
    productTypes: medicineData.productTypes,
    packagingTypes: medicineData.packagingTypes,
    administrationWays: medicineData.administrationWays,
    currentSearch: search.current || '',
    currentSearchType: search.searchType || '',
    currentDosage: search.dosage || '',
    formSubmitted: search.submitted,
    currentAdminWay: (defaultAdminWay && defaultAdminWay.name) || '',
    currentPharmForm: (defaultPharmForm && defaultPharmForm.name) || '',
    inFocus: search.focus,
    searchType: search.searchType
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(searchActions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFormContainer)
