import React from 'react'

import { bindActionCreators } from 'redux'
import { patientActions } from '../../../../../redux/patient'
import { modalsActions } from '../../../../../redux/modals'
import { connect } from 'react-redux'
import { notify } from 'reapop'
import { Button } from '../../../../common/Button/Button'
import PropTypes from 'prop-types'

class ChoosePatient extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      patientNumber: '',
      redirectToPrescription: false
    }
    this.renderContent = this.renderContent.bind(this)
  }

  handleChange ({target}) {
    this.setState({
      patientNumber: target.value
    })
  }

  handleKeyPress (e) {
    if (e.key === 'Enter') {
      this.handleSubmit(this.state.patientNumber)
    }
  }

  handleSubmit (patientNumber) {
    const { setPatient, notify, patientChoiceSuccess } = this.props
    setPatient(patientNumber)
      .then(() => {
        notify({
          message: `Utente Escolhido`,
          status: 'info',
          dismissible: true,
          dismissAfter: 3000,
          position: 'br',
          closeButton: true,
          allowHTML: false
        })
        patientChoiceSuccess()
      })
      .catch(() => {})
  }

  renderContent (loading, error) {
    return <div className='patient-select form-inline'>
      <div className={`input-group ${error ? 'has-error' : ''}`}>
        <input
          type='text'
          className={`form-control ${error ? 'has-error' : ''}`}
          onChange={this.handleChange.bind(this)}
          placeholder='Nº SNS' />
        <div className='input-group-btn'>
          <Button
            onClick={() => this.handleSubmit(this.state.patientNumber)}
            text={loading ? 'A Carregar...' : 'Copiar Prescrição'}
            buttonType={`btn btn-${error ? 'warning' : 'success'}`}
            type={'button'} />
        </div>
      </div>
    </div>
  }

  render () {
    const { patientError, fetchingPatient, patientExists } = this.props
    return <div>
      {this.renderContent(fetchingPatient, patientError, patientExists)}
    </div>
  }
}

ChoosePatient.propTypes = {
  fetchingPatient: PropTypes.bool.isRequired,
  patientError: PropTypes.object,
  setPatient: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  patientChoiceSuccess: PropTypes.func.isRequired
}

const mapStateToProps = ({patient}) => {
  return {
    fetchingPatient: patient.fetching,
    patientError: patient.error,
    patientExists: patient.data
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...patientActions, ...modalsActions, notify }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePatient)
