import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Panel } from '../../../../../../common/Panel/Panel'
import List from '../List/List'
import SimpleMedicineView from '../../MedicineView/SimpleMedicineView/SimpleMedicineView'

import './style.css'

const currentBasketRepeatedDCIIndexes = (items) => {
  return items.map(({ medicine }, index, array) => {
    if (array.filter(item => item.medicine.activeComponent === medicine.activeComponent).length > 1) {
      return index
    } else {
      return null
    }
  }).filter(item => item !== null)
}

function renderElements (items) {
  const repeatedItemIndexes = currentBasketRepeatedDCIIndexes(items)
  if (items.length > 0) {
    return items.map(({medicine, renewable, quantity, dosage, justification, justificationAdditionalData}, index) => {
      const repeated = repeatedItemIndexes.includes(index)
      return <SimpleMedicineView
        medicine={medicine}
        repeated={repeated}
        medicineId={medicine.id}
        name={medicine.activeComponent}
        commercialName={medicine.commercialName}
        medicineType={medicine.medicineType || {}}
        administrationType={medicine.administrationType || {}}
        packageType={medicine.packageType || {}}
        units={medicine.units}
        generic={medicine.isGeneric}
        dose={medicine.dose}
        renewable={renewable}
        itemIndex={index}
        key={index}
        quantity={quantity}
        dosage={dosage}
        justificationAdditionalData={justificationAdditionalData}
        justification={justification} />
    })
  } else {
    return <div className='empty-basket'>Não existem Itens</div>
  }
}

const BasketContent = ({items}) => {
  return <div className='basket-list'>
    <List>
      {renderElements(items)}
    </List>
  </div>
}

const PanelBasket = ({items, handleSubmitClick, customButtonText}) => {
  return <div className='col-xs-12 pull-right no-side-padding-for-container basket-panel'>
    <Panel
      disableCollapse
      heading='Seleção Atual'
      rightHeader={
        <div className='panel-title pull-right'>
          <span className='badge'>
            {items.length}
          </span>
        </div>}>
      <BasketContent customButtonText={customButtonText} items={items} handleSubmitClick={handleSubmitClick} />
    </Panel>
  </div>
}

const Basket = (props) => {
  return <Fragment>
    <PanelBasket customButtonText={props.customButtonText} items={props.items} handleSubmitClick={props.submit} />
  </Fragment>
}

Basket.propTypes = {
  medicineData: PropTypes.object.isRequired,
  items: PropTypes.array,
  openModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  noConfirmation: PropTypes.bool
}

export default Basket
