import {
  FETCHING_DCIS,
  FETCH_DCIS_SUCCESS,
  FETCH_DCIS_FAILURE,
  FETCHING_PHARMACEUTICAL_FORMS,
  FETCH_PHARMACEUTICAL_FORMS_SUCCESS,
  FETCH_PHARMACEUTICAL_FORMS_FAILURE,
  FETCHING_ADMINISTRATION_WAYS,
  FETCH_ADMINISTRATION_WAYS_SUCCESS,
  FETCH_ADMINISTRATION_WAYS_FAILURE,
  FETCHING_PRODUCT_TYPES,
  FETCH_PRODUCT_TYPES_SUCCESS,
  FETCH_PRODUCT_TYPES_FAILURE,
  FETCHING_PACKAGING_TYPES,
  FETCH_PACKAGING_TYPES_SUCCESS,
  FETCH_PACKAGING_TYPES_FAILURE,
  FETCH_PATHOLOGY_DIPLOMAS_FAILURE,
  FETCH_PATHOLOGY_DIPLOMAS_SUCCESS,
  FETCHING_PATHOLOGY_DIPLOMAS
} from './types'

const reducer = (state = {
  dcis: [],
  packagingTypes: [],
  productTypes: [],
  pharmaceuticalForms: [],
  administrationWays: [],
  pathologyDiplomas: []
}, action) => {
  switch (action.type) {
    case FETCHING_DCIS:
      return Object.assign({}, state, {
        dcis: []
      })
    case FETCH_DCIS_SUCCESS:
      return Object.assign({}, state, {
        dcis: action.payload
      })
    case FETCH_DCIS_FAILURE:
      return Object.assign({}, state, {
        dcis: []
      })
    case FETCHING_PACKAGING_TYPES:
      return Object.assign({}, state, {
        packagingTypes: []
      })
    case FETCH_PACKAGING_TYPES_SUCCESS:
      return Object.assign({}, state, {
        packagingTypes: action.payload
      })
    case FETCH_PACKAGING_TYPES_FAILURE:
      return Object.assign({}, state, {
        packagingTypes: []
      })
    case FETCHING_PRODUCT_TYPES:
      return Object.assign({}, state, {
        productTypes: []
      })
    case FETCH_PRODUCT_TYPES_SUCCESS:
      return Object.assign({}, state, {
        productTypes: action.payload
      })
    case FETCH_PRODUCT_TYPES_FAILURE:
      return Object.assign({}, state, {
        productTypes: []
      })
    case FETCHING_ADMINISTRATION_WAYS:
      return Object.assign({}, state, {
        administrationWays: []
      })
    case FETCH_ADMINISTRATION_WAYS_SUCCESS:
      return Object.assign({}, state, {
        administrationWays: action.payload
      })
    case FETCH_ADMINISTRATION_WAYS_FAILURE:
      return Object.assign({}, state, {
        administrationWays: []
      })
    case FETCHING_PHARMACEUTICAL_FORMS:
      return Object.assign({}, state, {
        pharmaceuticalForms: []
      })
    case FETCH_PHARMACEUTICAL_FORMS_SUCCESS:
      return Object.assign({}, state, {
        pharmaceuticalForms: action.payload
      })
    case FETCH_PHARMACEUTICAL_FORMS_FAILURE:
      return Object.assign({}, state, {
        pathologyDiplomas: []
      })
    case FETCH_PATHOLOGY_DIPLOMAS_SUCCESS:
      return Object.assign({}, state, {
        pathologyDiplomas: action.payload
      })
    case FETCH_PATHOLOGY_DIPLOMAS_FAILURE:
      return Object.assign({}, state, {
        pathologyDiplomas: []
      })
    case FETCHING_PATHOLOGY_DIPLOMAS:
      return Object.assign({}, state, {
        pathologyDiplomas: []
      })
    default:
      return state
  }
}

export default reducer
