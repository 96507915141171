const testUser = {
  id: '11111',
  name: 'Ricardo Pimenta-Araújo',
  birth: '1990-02-05',
  professionalNumber: 'OM:34343',
  profession: 'doctor',
  specialty: 'Medicina Geral e Familiar',
  gender: 'male'
}

export default testUser
