import { manyPatients } from '../../../../../test/testPatient'
import defaultUser from '../../../../../resources/images/user-icon-green-2.svg'
import { getAgeString } from '../../../../../resources/utilities'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button } from '../../../../common/Button/Button'
import React, { Fragment } from 'react'
import ReactTooltip from 'react-tooltip'

import './style.css'
import SectionTitle from '../../../../common/SectionTitle/SectionTitle'
import PatientDataContainer from './PatientData/PatientDataContainer'
import { ReactModal } from '../../../../common/ReactModal/ReactModal'
import PathologiesContainer from './Pathologies/PathologiesContainer'
import Allergies from './Allergies/Allergies'
import Diagnostics from './Diagnostics/Diagnostics'
import testAllergies from '../../../../../test/testAllergies'
import { ALLERGY_TYPES } from '../../../../../models/Allergy'
import { MedicationList } from '../../Patient/MedicationList/MedicationList'
import { Timeline } from '../../../../common/Timeline/Timeline'

// data: PropTypes.arrayOf(PropTypes.shape({
//     date: PropTypes.string.isRequired,
//     text: PropTypes.string.isRequired,
//     state: PropTypes.oneOf(['green', 'red', 'yellow', 'purple', 'orange']).isRequired
//   }))
const testTimelineData = [
  {
    date: moment(new Date()).subtract(3, 'months').format('YYYY-MM-DD'),
    text: 'Receita',
    state: 'green'
  },
  {
    date: moment(new Date()).subtract(5, 'months').format('YYYY-MM-DD'),
    text: 'Alergia Registada',
    state: 'yellow'
  },
  {
    date: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
    text: 'Receita',
    state: 'green'
  },
  {
    date: moment(new Date()).subtract(2, 'months').format('YYYY-MM-DD'),
    text: 'Receita',
    state: 'green'
  },
  {
    date: moment(new Date()).subtract(1, 'year').format('YYYY-MM-DD'),
    text: 'Receita',
    state: 'green'
  },
  {
    date: moment(new Date()).subtract(15, 'months').format('YYYY-MM-DD'),
    text: 'Alergia Registada',
    state: 'yellow'
  },
  {
    date: moment(new Date()).subtract(20, 'months').format('YYYY-MM-DD'),
    text: 'Receita',
    state: 'green'
  },
  {
    date: moment(new Date()).subtract(21, 'months').format('YYYY-MM-DD'),
    text: 'Receita',
    state: 'green'
  }
]

const patientHasMedicalAllergies = (allergies) => {
  return !!allergies.filter(item => item.type === ALLERGY_TYPES.MEDICATION).length
}

const color = 'white'

export default class ContactView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      patientDataModalIsOpen: false,
      pathologiesModalIsOpen: false,
      allergiesModalIsOpen: false,
      medicinesListModalIsOpen: false,
      diagnosticsModalIsOpen: false
    }
  }

  patientDataModalToggle () {
    this.setState({
      patientDataModalIsOpen: !this.state.patientDataModalIsOpen
    })
  }

  diagnosticsModalToggle () {
    this.setState({
      diagnosticsModalIsOpen: !this.state.diagnosticsModalIsOpen
    })
  }

  allergiesModalToggle () {
    this.setState({
      allergiesModalIsOpen: !this.state.allergiesModalIsOpen
    })
  }

  pathologiesModalToggle () {
    this.setState({
      pathologiesModalIsOpen: !this.state.pathologiesModalIsOpen
    })
  }

  medicineListModalToggle () {
    this.setState({
      medicinesListModalIsOpen: !this.state.medicinesListModalIsOpen
    })
  }

  render () {
    const { selectedPatientHealthcareNumber } = this.props
    const patient = manyPatients.find(p => p.healthcareNumber === selectedPatientHealthcareNumber)
    if (patient) {
      const {name, healthcareNumber, birth, photo, gender} = patient
      return <div className='user-container'>
        <div className='id-container'>
          <div className='photo'>
            <img className='profile-photo' src={photo || defaultUser} alt='User Profile' />
          </div>
          <div className='information'>
            <div className='name'>{name}</div>
            <div className='birthDate'>
              <span className={`gender fas fa-${gender === 'male' ? 'mars' : 'venus'}`} />
              <span className='text-muted'>Idade: </span> {getAgeString(birth)} ({moment(new Date(birth)).format('L')})
            </div>
            <div><span className='text-muted'>Nº SNS: </span><span style={{fontWeight: '900'}}>{healthcareNumber}</span>
            </div>
            <Button
              type={'button'}
              buttonType={'btn-info btn-xs'}
              text={'Patologias-RECM'}
              onClick={this.pathologiesModalToggle.bind(this)} />
            <div className='btn btn-success btn-xs disabled'>
              <div><span className='fas fa-user' /> 80%</div>
            </div>
            <div className='hidden-xs hidden-sm pull-right'>
              <Button onClick={this.patientDataModalToggle.bind(this)} type={'button'} buttonType={'btn-info btn-xs'} text={<span className='fas fa-edit' />} />
              <Button
                type={'button'}
                buttonType={'btn-danger btn-xs'}
                text={<span className='fas fa-trash-alt' />}
                onClick={() => window.confirm('Tem a certeza que quer apagar o utente da sua lista?')} />
            </div>
            <div className='visible-xs visible-sm top-margin'>
              <Button onClick={this.patientDataModalToggle.bind(this)} type={'button'} buttonType={'btn-info btn-xs'} text={<span className='fas fa-edit' />} />
              <Button
                type={'button'}
                buttonType={'btn-danger btn-xs'}
                text={<span className='fas fa-trash-alt' />}
                onClick={() => window.confirm('Tem a certeza que quer apagar o utente da sua lista?')} />
            </div>
          </div>
        </div>
        <div className='clinical-data-container'>
          <div className='icon clickable' onClick={() => this.diagnosticsModalToggle()}>
            <span data-tip={'Diagnósticos'} className='fas fa-diagnoses' />
          </div>
          <div className='icon clickable' onClick={() => this.medicineListModalToggle()}>
            <span data-tip={'Medicação'} className='fas fa-pills' />
          </div>
          <div className={`icon clickable ${patientHasMedicalAllergies(testAllergies) ? 'with-allergy' : ''}`} onClick={() => this.allergiesModalToggle()}>
            <span data-tip={'Alergias'} className='fas fa-allergies' />
          </div>
        </div>
        <div className='events-container'>
          <SectionTitle title={'Histórico de Episódios'} size={'h4'} smallMargins />
          <div className='events'>
            <Timeline startDate={moment(new Date()).format('YYYY-MM-DD')} data={testTimelineData} />
          </div>
        </div>
        <div className='actions-container'>
          <div className='buttons'>
            <Link to={`/messages/${selectedPatientHealthcareNumber}`}>
              <div data-tip={'Mensagens'}>
                <Button
                  type={'button'}
                  buttonType={'btn-info'}
                  text={<span style={{color}} className='fas fa-comments fa-2x' />} />
              </div>
            </Link>
            <Link to={`/${selectedPatientHealthcareNumber}/prescription/prescribe`}>
              <div data-tip={'Prescrever'}>
                <Button
                  type={'button'}
                  buttonType={'btn-info'}
                  text={<span style={{color}} className='fas fa-prescription fa-2x' />} />
              </div>
            </Link>
            <Link to={`/${selectedPatientHealthcareNumber}/prescription/history`}>
              <div data-tip={'Histórico de Prescrições'}>
                <Button
                  type={'button'}
                  buttonType={'btn-info'}
                  text={<span style={{color}} className='fas fa-history fa-2x' />} />
              </div>
            </Link>
            <Link onClick={e => e.preventDefault()} disabled to={`/mcdts/${selectedPatientHealthcareNumber}`}>
              <div data-tip={'MCTDs'}>
                <Button
                  disabled
                  type={'button'}
                  buttonType={'btn-info'}
                  text={<span style={{color}} className='fas fa-file-medical-alt fa-2x' />} />
              </div>
            </Link>
          </div>
        </div>
        <ReactModal
          toggleModal={this.patientDataModalToggle.bind(this)}
          name={'Dados do Utente'}
          customClass={'modal-lg'}
          modalIsOpen={this.state.patientDataModalIsOpen}
          footer={<Fragment>
            <div className='pull-left footer-checkbox-container'>
              <div className='form-group'>
                <div className=''>
                  <div className='checkbox'>
                    <label>
                      <input name={'termsAndConditions'} type='checkbox' /> Li e Aceito os Termos e Condições de Utilização da Scribo
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group' style={{marginBottom: '0'}}>
                <div className=''>
                  <div className='checkbox'>
                    <label>
                      <input name={'termsAndConditions'} type='checkbox' /> Li e Aceito a “Política de Segurança e Privacidade” da Kenetto, Lda
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <Button text={'Guardar'} buttonType={'btn-success'} type={'button'} />
          </Fragment>}>
          <PatientDataContainer patientHealthcareNumber={selectedPatientHealthcareNumber} />
        </ReactModal>
        <ReactModal
          name={'Diagnósticos'}
          toggleModal={this.diagnosticsModalToggle.bind(this)}
          modalIsOpen={this.state.diagnosticsModalIsOpen}
          fullWidth>
          <Diagnostics />
        </ReactModal>
        <ReactModal
          name={'Alergias'}
          toggleModal={this.allergiesModalToggle.bind(this)}
          modalIsOpen={this.state.allergiesModalIsOpen}
          fullWidth>
          <Allergies />
        </ReactModal>
        <ReactModal
          name={'Medicação'}
          toggleModal={this.medicineListModalToggle.bind(this)}
          modalIsOpen={this.state.medicinesListModalIsOpen}
          fullWidth>
          <MedicationList />
        </ReactModal>
        <ReactModal
          name={'Patologias-RECM'}
          toggleModal={this.pathologiesModalToggle.bind(this)}
          modalIsOpen={this.state.pathologiesModalIsOpen}
          fullWidth
          footer={<Button text={'Guardar'} buttonType={'btn-success'} type={'button'} />}>
          <PathologiesContainer patientHealthcareNumber={selectedPatientHealthcareNumber} />
        </ReactModal>
        <ReactTooltip delayShow={300} effect='solid' type='light' />
      </div>
    }
    return null
  }
}
