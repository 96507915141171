import React, { Fragment } from 'react'
import { Button } from '../../../../common/Button/Button'

import { modalsActions } from '../../../../../redux/modals'
import { basketActions } from '../../../../../redux/basket'
import { doctorActions } from '../../../../../redux/doctor'
import { notify } from 'reapop'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import ChoosePatient from './ChoosePatient'
import { convertFavoriteToBasket, convertItemsToFavorites } from '../../../../../resources/utilities'
import ConfirmationMedicineView
  from '../../Patient/Prescription/MedicineView/ConfirmationMedicineView/ConfirmationMedicineView'

class FavoriteItem extends React.Component {
  addMedicinesToReceipt (medicines) {
    const { addItems, notify, history, patientHealthcareNumber } = this.props
    addItems(convertFavoriteToBasket(medicines))
    const medicineNames = medicines.map(medicine => medicine.medicine.activeComponent)
    notify({
      title: `Medicamentos adicionados.`,
      message: `${medicineNames.join(', ')}`,
      status: 'info',
      dismissible: true,
      dismissAfter: 5000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
    history.push(`/${patientHealthcareNumber}/prescription/prescribe`)
  }
  addToReceipt (medicines) {
    const { open, patientExists } = this.props
    if (!patientExists) {
      open('choose-patient', 'Procurar Número de Utente', <ChoosePatient patientChoiceSuccess={() => this.addMedicinesToReceipt(medicines)} modalId={'choose-patient'} />)
    } else {
      this.addMedicinesToReceipt(medicines)
    }
  }
  render () {
    const { favorite, removeFavorite, elementIndex, currentUrl, editable } = this.props
    return <div className='col-xs-12 favorite-box'>
      <ul>
        {convertItemsToFavorites(favorite.items).map((item, index) => {
          return <Fragment key={item.medicine.id}>
            <hr className='small-margin' />
            {item
              ? <ConfirmationMedicineView
                editable={false}
                itemIndex={index}
                dose={item.medicine.dose}
                commercialName={item.medicine.commercialName}
                administrationType={item.medicine.administrationType}
                medicineType={item.medicine.medicineType}
                packageType={item.medicine.packageType}
                activeComponent={item.medicine.activeComponent}
                units={item.medicine.units}
                quantity={item.quantity}
                generic={item.medicine.isGeneric}
                renewable={item.renewable}
                dosage={item.dosage} />
              : <ConfirmationMedicineView
                editable={false}
                customMedicine={item.attributes}
                itemIndex={index}
                designation={item.attributes.description}
                quantity={item.quantity}
                renewable={item.renewable}
                dosage={item.dosage} />
            }
          </Fragment>
        })}
      </ul>
      <div className='clearfix favorite-footer'>
        {editable
          ? <Fragment>
            <Link to={`${currentUrl}/edit/${favorite.id}`}>
              <Button type={'button'} text={<span className='fas fa-edit' />} buttonType={'btn-info'} />
            </Link>
            <Button text={<span className='fas fa-trash-alt' />} buttonType={'btn-danger'} type={'button'} onClick={() => { removeFavorite(elementIndex) }} />
          </Fragment>
          : null
        }
        <Button
          text={'Prescrever'}
          buttonType={'btn-success pull-right'}
          type={'button'}
          onClick={() => this.addToReceipt(favorite.items)} />
      </div>
    </div>
  }
}

function mapStateToProps ({ doctor, patient }, {favorite}) {
  return {
    favorites: doctor.favorites,
    elementIndex: doctor.favorites.findIndex(fav => fav.id === favorite.id),
    patientExists: !!patient.data,
    patientHealthcareNumber: patient.patientHealthcareNumber
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({...doctorActions, ...modalsActions, addItems: basketActions.addItems, notify}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FavoriteItem))
