import React, { Component } from 'react'

import QRCode from 'qrcode'

import './style.css'

function renderQRCode (content, element) {
  QRCode.toDataURL(content)
    .then(data => {
      console.log(data)
      element.src = data
    })
    .catch(err => {
      console.error(err)
    })
}

class QRCodeContainer extends Component {
  componentDidMount () {
    renderQRCode('1@hW+W5gp+KXEBZlE02vb1+Oy0w7xGViPIPVn/ZV1H85aIB7Iq66CI0Cbx,D6bkT0rusCo3x7opG+wTJmIWJ7x3Cy14Xz5aH3SVgRY=,iivQ8qUwZicm5XFsiS4xUw==', this.refs.qrCodeImage)
  }

  render () {
    return <div className='container full-height'>
      <div className='qr-container full-height'>
        <div className='qr-code-box'>
          <img src='' alt='QRCODE' ref={'qrCodeImage'} />
        </div>
        <div className='jumbotron'>
          <p>1. Entre na aplicação Scribo</p>
          <p>2. Faça Scan do Código de Barras</p>
        </div>
      </div>
    </div>
  }
}

export default QRCodeContainer
