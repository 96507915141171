import moment from 'moment'
import v4 from 'uuid/v4'
import PropTypes from 'prop-types'
import React from 'react'
import { MealsInsertion, MealsViewer } from './MealPeriod'
import { SpecificTimeComponent, SpecificTimePeriodViewer } from './TimePeriod'
import { SpecificPeriodsComponent, SpecificPeriodViewer } from './Period'
import { UNITS_OPTIONS } from '../../../../../../../../models/Guides/GuideEntry'

import './style.css'
import { SOSComponent, SOSViewer } from './SOS'
import { WeaningComponent, WeaningViewer } from './Weaning'

const UNITS_POSITION = 'left'

const UnitsInsertion = ({unitType, handleChange = () => {}, defaultValue}) => {
  return <div className='form-group col-sm-4'>
    <label className='control-label' style={{ textAlign: 'right' }}>Unidades</label>
    <div className=''>
      <div className='input-group frequence-select'>
        <select
          className='form-control btn-left'
          defaultValue={defaultValue} onChange={e => handleChange(e.target.value)}>
          <optgroup label='Frequentes'>
            {UNITS_OPTIONS.filter(item => item.primary).map(frequency => (
              <option
                key={frequency.value}
                value={frequency.value}>{frequency.displayValue}</option>))}
          </optgroup>
          <optgroup label='Restantes'>
            {UNITS_OPTIONS.filter(item => !item.primary).map(frequency => (
              <option
                key={frequency.value}
                value={frequency.value}>{frequency.displayValue}</option>))}
          </optgroup>
        </select>
        <div className='input-group-addon btn-right frequence-addon'>
          <span>
            {unitType}
          </span>
        </div>
      </div>
    </div>
  </div>
}

const OptionsDropdown = ({alignSide = 'left', onClick}) => {
  const click = (type) => {
    onClick(type)
  }
  return <div className={`dropdown align-${alignSide}`}>
    <button
      className='btn btn-success dropdown-toggle'
      type='button'
      id='dropdownMenu1'
      data-toggle='dropdown'
      aria-haspopup='true'
      aria-expanded='true'>
      <span className='fas fa-plus' /> Esquema
      <span className='caret' />
    </button>
    <ul className='dropdown-menu' aria-labelledby='dropdownMenu1'>
      <li onClick={() => { click('meals') }}><button className='bordered'>Às Refeições</button></li>
      <li onClick={() => { click('specificPeriod') }}><button>Periodicamente</button></li>
      <li onClick={() => { click('specificTime') }}><button>Em Horário</button></li>
      <li onClick={() => { click('sos') }}><button>SOS</button></li>
      <li onClick={() => { click('weaning') }}><button>Desmame</button></li>
    </ul>
  </div>
}

class GuideEntryComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      insertions: [],
      submitted: [],
      entryId: v4()
    }
    this.handleSectionSubmit = this.handleSectionSubmit.bind(this)
  }

  handleSectionSubmit (type, data, i) {
    const submitted = [...this.state.submitted]
    submitted.push(data)
    this.props.handleChange({
      type: 'entry',
      value: {
        type,
        data
      }})
    this.setState({
      submitted
    }, () => {
      this.removeSection(i)
    })
  }

  handleAddition (type) {
    const insertions = [...this.state.insertions]
    insertions.push(type)
    this.setState({
      insertions
    })
  }

  removeItem (i) {
    const submitted = [...this.state.submitted]
    submitted.splice(i, 1)
    this.setState({
      submitted
    })
  }

  removeSection (i) {
    const insertions = [...this.state.insertions]
    insertions.splice(i, 1)
    this.setState({
      insertions
    })
  }

  handleItemChange (name, value, noPush) {
    this.setState({
      [name]: value
    })
    if (!noPush) {
      this.props.handleChange({type: name, value})
    }
  }

  handlePeriodChange (value) {
    this.setState({
      periodType: value
    })
    this.props.handleChange({
      type: 'duration',
      value: {
        type: value,
        value: this.state.durationUnits
      }
    })
  }

  render () {
    const { insertions, submitted, prolongedTreatment, beginningOfTreatment } = this.state
    const { medicineType } = this.props
    const date = moment(new Date()).format('YYYY/MM/DD')
    return <div className='container-fluid no-side-padding-for-container'>
      <div className='clearfix'>
        <div className='form-group col-sm-3'>
          <label className='control-label' style={{ textAlign: 'right' }}><span
            style={{ marginRight: '10px' }}>Início</span></label>
          <div className=''>
            <input
              onChange={(e) => this.handleItemChange('beginningOfTreatment', e.target.value)}
              type='date' min={moment(new Date(date)).format('YYYY-MM-DD')}
              value={beginningOfTreatment ? moment(new Date(beginningOfTreatment)).format('YYYY-MM-DD') : ''}
              className='form-control'
              name='beginningOfTreatment' />
          </div>
        </div>
        <div className='col-sm-3 inline-form-group-checkbox'>
          <div className='checkbox'>
            <label className=''>
              <input
                name={'prolongedTreatment'}
                onChange={(e) => { this.handleItemChange('prolongedTreatment', e.target.checked) }}
                type='checkbox' /> Tratamento Prolongado
            </label>
          </div>
        </div>
        {prolongedTreatment
          ? null
          : <div>
            <div className='form-group col-sm-2'>
              <label
                className='control-label'
                style={{ textAlign: 'right' }}>Duração</label>
              <div className='no-side-padding'>
                <input
                  type='number'
                  min={1}
                  className='form-control'
                  name='durationUnits'
                  onChange={(e) => {
                    this.setState({ periodType: '' })
                    this.handleItemChange('durationUnits', e.target.value, true)
                  }} />
              </div>
            </div>
            <div className='form-group col-sm-2'>
              <label className='control-label' style={{ textAlign: 'right' }}>Período</label>
              <div className='no-side-padding'>
                <select value={this.state.periodType} name='periodType' className='form-control' onChange={(e) => { this.handlePeriodChange(e.target.value) }}>
                  <option value='' hidden />
                  <option value='days'>Dias</option>
                  <option value='weeks'>Semanas</option>
                  <option value='months'>Meses</option>
                </select>
              </div>
            </div>
          </div>
        }
        <div className='col-sm-2 button-inline-form no-side-padding'>
          <div className='pull-right'>
            <OptionsDropdown alignSide={'right'} onClick={this.handleAddition.bind(this)} />
          </div>
        </div>
        {submitted.map((item, i) => {
          if (item.type === 'meal') {
            return <MealsViewer
              removeItem={() => this.removeItem(i)}
              {...item} key={i}
              unitsPosition={UNITS_POSITION} />
          }
          if (item.type === 'specificPeriod') {
            return <SpecificPeriodViewer
              removeItem={() => this.removeItem(i)}
              {...item} key={i}
              unitsPosition={UNITS_POSITION} />
          }
          if (item.type === 'specificTime') {
            return <SpecificTimePeriodViewer
              removeItem={() => this.removeItem(i)}
              {...item} key={i}
              unitsPosition={UNITS_POSITION} />
          }
          if (item.type === 'sos') {
            return <SOSViewer
              removeItem={() => this.removeItem(i)}
              {...item} key={i}
              unitsPosition={UNITS_POSITION} />
          }
          if (item.type === 'weaning') {
            return <WeaningViewer
              removeItem={() => this.removeItem(i)}
              {...item} key={i}
              unitsPosition={UNITS_POSITION} />
          }
          return null
        })}
      </div>
      {insertions.map((entry, i) => {
        if (entry === 'meals') {
          return <MealsInsertion
            unitType={medicineType}
            key={i}
            remove={() => this.removeSection(i)}
            handleSubmit={(data) => this.handleSectionSubmit('meal', data, i)} />
        }
        if (entry === 'specificPeriod') {
          return <SpecificPeriodsComponent
            unitType={medicineType}
            key={i}
            remove={() => this.removeSection(i)}
            handleSubmit={(data) => this.handleSectionSubmit('specificPeriod', data, i)} />
        }
        if (entry === 'specificTime') {
          return <SpecificTimeComponent
            unitType={medicineType}
            key={i}
            remove={() => this.removeSection(i)}
            handleSubmit={(data) => this.handleSectionSubmit('specificTime', data, i)} />
        }
        if (entry === 'sos') {
          return <SOSComponent
            unitType={medicineType}
            key={i}
            remove={() => this.removeSection(i)}
            handleSubmit={(data) => this.handleSectionSubmit('sos', data, i)} />
        }
        if (entry === 'weaning') {
          return <WeaningComponent
            unitType={medicineType}
            key={i}
            remove={() => this.removeSection(i)}
            handleSubmit={(data) => this.handleSectionSubmit('weaning', data, i)} />
        }
        return null
      })}
    </div>
  }
}

GuideEntryComponent.propTypes = {
  medicineType: PropTypes.string.isRequired
}

export { UnitsInsertion, UNITS_POSITION }

export default GuideEntryComponent
