function getAbreviatedTitle (gender, profession) {
  if (gender === 'male') {
    switch (profession) {
      case 'doctor':
        return 'Dr.'
      case 'nurse':
        return 'Enf.'
      default:
        return ''
    }
  } else if (gender === 'female') {
    switch (profession) {
      case 'doctor':
        return 'Dra.'
      case 'nurse':
        return 'Enf.'
      default:
        return ''
    }
  } else {
    return ''
  }
}

function getProfessionString (gender, profession) {
  if (gender === 'male') {
    switch (profession) {
      case 'doctor':
        return 'Médico'
      case 'nurse':
        return 'Enfermeiro'
      default:
        return ''
    }
  } else if (gender === 'female') {
    switch (profession) {
      case 'doctor':
        return 'Médica'
      case 'nurse':
        return 'Enfermeira'
      default:
        return ''
    }
  } else {
    return ''
  }
}

export default class MedicalStaff {
  constructor (id, name, birth, photo, professionalNumber, specialty, gender, profession) {
    this._id = id
    this._name = name
    this._birth = birth
    this._photo = photo
    this._professionalNumber = professionalNumber
    this._specialty = specialty
    this._gender = gender
    this._profession = profession
  }

  get id () {
    return this._id
  }

  get name () {
    return this._name
  }

  get birth () {
    return this._birth
  }

  get photo () {
    return this._photo
  }

  get professionalNumber () {
    return this._professionalNumber
  }

  get specialty () {
    return this._specialty
  }

  get gender () {
    return this._gender
  }

  get profession () {
    return this._profession
  }

  getAbreviatedTitle () {
    return getAbreviatedTitle(this._gender, this._profession)
  }
  getProfessionString () {
    return getProfessionString(this._gender, this._profession)
  }
}
