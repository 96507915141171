const TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  getDescription: (type) => {
    switch (type) {
      case 'primary':
        return 'Principal'
      case 'secondary':
        return 'Secundário'
      default:
        return ''
    }
  }
}

const DISEASES = {
  DIABETES: 'diabetes',
  HYPERTENSION: 'hypertension',
  CARDIOVASCULAR_DISEASE: 'cardiovascularDisease',
  RESPIRATORY_DISEASE: 'respiratoryDisease',
  NEUROLOGICAL_DISEASE: 'neurologicalDisease',
  THYROID_DISEASE: 'thyroidDisease',
  HEPATIC_DISEASE: 'HepaticDisease',
  KIDNEY_DISEASE: 'kidneyDisease',
  SEXUALLY_TRANSMITTED_INFECTION: 'sexuallyTransmittedInfection',
  BLOOD_TRANSFUSSION: 'bloodTransfusion',
  PSYCHIATRIC_DISEASE: 'psychiatricDisease',
  getDescription: (type) => {
    switch (type) {
      case 'diabetes':
        return 'Diabetes'
      case 'hypertension':
        return 'Hipertensão'
      case 'cardiovascularDisease':
        return 'Doença Cardiovascular'
      case 'respiratoryDisease':
        return 'Doença Respiratória'
      case 'neurologicalDisease':
        return 'Doença Neurológica'
      case 'thyroidDisease':
        return 'Doença da Tiróide'
      case 'HepaticDisease':
        return 'Doença Hepática'
      case 'kidneyDisease':
        return 'Doença Renal'
      case 'sexuallyTransmittedInfection':
        return 'Infeções Sexualmente Transmitiveis'
      case 'bloodTransfusion':
        return 'Transfusão Sanguínea'
      case 'psychiatricDisease':
        return 'Doença Psiquiátrica'
      default:
        return ''
    }
  }
}

const LATERALITY = {
  LEFT: 'left',
  RIGHT: 'right',
  NOT_APPLICABLE: 'notApplicable',
  getDescription: (type) => {
    switch (type) {
      case 'left':
        return 'Esquerdo'
      case 'right':
        return 'Direito'
      case 'notApplicable':
        return 'Não Aplicável'
      default:
        return ''
    }
  }
}

const SITUATION = {
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
  SUSPECT: 'suspect',
  PROBABLE: 'probable',
  getDescription: (type) => {
    switch (type) {
      case 'confirmed':
        return 'Confirmado'
      case 'unconfirmed':
        return 'Não Confirmado'
      case 'suspect':
        return 'Suspeito'
      case 'probable':
        return 'Provável'
      default:
        return ''
    }
  }
}

const STATUS = {
  RESOLVED: 'resolved',
  UNRESOLVED: 'unresolved',
  STABLE_DISEASE: 'stableDisease',
  PARTIAL_REMISSION: 'partialRemission',
  DISEASE_PROGRESSION: 'diseaseProgression',
  getDescription: (type) => {
    switch (type) {
      case 'resolved':
        return 'Resolvido'
      case 'unresolved':
        return 'Não Resolvido'
      case 'stableDisease':
        return 'Doença Estável'
      case 'partialRemission':
        return 'Remissão Parcial'
      case 'diseaseProgression':
        return 'Progressão Doença'
      default:
        return ''
    }
  }
}

export { TYPE, LATERALITY, SITUATION, STATUS, DISEASES }

class Diagnosis {
  constructor (id, type, disease, startDate, laterality, situation, status, endDate) {
    this._id = id
    this._type = type
    this._disease = disease
    this._startDate = startDate
    this._laterality = laterality
    this._status = status
    this._endDate = endDate
    this._situation = situation
  }

  get id () {
    return this._id
  }

  get type () {
    return this._type
  }

  get situation () {
    return this._situation
  }

  get disease () {
    return this._disease
  }

  get startDate () {
    return this._startDate
  }

  get laterality () {
    return this._laterality
  }

  get status () {
    return this._status
  }

  get endDate () {
    return this._endDate
  }

  set endDate (value) {
    this._endDate = value
  }

  set situation (value) {
    this._situation = value
  }

  set status (value) {
    this._status = value
  }
}

export default Diagnosis
