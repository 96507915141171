import React from 'react'

const Form = ({editable, person = {}}) => {
  return <div className='margin-bottom'>
    <form>
      <div className='form-group col-sm-12'>
        <label className='control-label' style={{ textAlign: 'right' }}>Nome Clínico</label>
        <div className=''>
          <input disabled={!editable} type='text' className='form-control' defaultValue={person.name} />
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Ordem Profissional</label>
        <div className=''>
          <select disabled={!editable} className='form-control' name={'professionalNumberType'} defaultValue={''}>
            <option value='' hidden disabled />
            <option value='doctor'>Médicos</option>
            <option value='dentist'>Médicos dentistas</option>
            <option value='odontologist'>Odontologistas</option>
          </select>
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Nº Cédula</label>
        <div className=''>
          <input disabled={!editable} type='text' className='form-control' defaultValue={person.professionalNumber} />
        </div>
      </div>
      <div className='form-group col-sm-12'>
        <label className='control-label' style={{ textAlign: 'right' }}>Local de Trabalho</label>
        <div className=''>
          <input disabled={!editable} type='text' className='form-control' name={'locationType'} placeholder={'Hospital, Centro de Saúde, Consultório, Clínica'} />
        </div>
      </div>
      <div className='form-group col-sm-12'>
        <label className='control-label' style={{ textAlign: 'right' }}>Morada Profissional</label>
        <div className=''>
          <input disabled={!editable} type='text' className='form-control' name={'professionalAddress'} placeholder={''} />
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Código Postal</label>
        <div className=''>
          <input disabled={!editable} type='text' name={'postalCode'} className='form-control' />
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Localidade</label>
        <div className=''>
          <input disabled={!editable} type='text' name={'location'} className='form-control' />
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Telefone</label>
        <div className=''>
          <input disabled={!editable} type='text' name={'phone'} className='form-control' />
        </div>
      </div>
      <div className='form-group col-sm-6'>
        <label className='control-label' style={{ textAlign: 'right' }}>Email</label>
        <div className=''>
          <input disabled={!editable} type='text' name={'email'} className='form-control' />
        </div>
      </div>
    </form>
    {/*<FormComponent handleSubmit={() => {}} type={'horizontal'}>*/}
    {/*  <div className='form-group'>*/}
    {/*    <label className='control-label col-sm-3' style={{ textAlign: 'right' }}>Ordem Profissional</label>*/}
    {/*    <div className='col-sm-3'>*/}
    {/*      <select disabled={!editable} className='form-control' name={'professionalNumberType'}>*/}
    {/*        <option value='doctor'>Médicos</option>*/}
    {/*        <option value='dentist'>Médicos dentistas</option>*/}
    {/*        <option value='odontologist'>Odontologistas</option>*/}
    {/*      </select>*/}
    {/*    </div>*/}
    {/*    <label className='control-label col-sm-2' style={{ textAlign: 'right' }}>Nº Cédula</label>*/}
    {/*    <div className='col-sm-4'>*/}
    {/*      <input disabled={!editable} type='text' className='form-control' defaultValue={person.professionalNumber} />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*  <FormElement inputSize={9} type={'text'} label={'Local de Trabalho'} name={'locationType'} placeholder={'Hospital, Centro de Saúde, Consultório, Clínica'} />*/}
    {/*  <FormElement disabled={!editable} inputSize={9} type={'text'} label={'Morada Profissional'} name={'professionalAddress'} />*/}
    {/*  <div className='form-group'>*/}
    {/*    <label className='control-label col-sm-3' style={{ textAlign: 'right' }}>Código Postal</label>*/}
    {/*    <div className='col-sm-3'>*/}
    {/*      <input disabled={!editable} type='text' className='postalCode form-control' />*/}
    {/*    </div>*/}
    {/*    <label className='control-label col-sm-2' style={{ textAlign: 'right' }}>Localidade</label>*/}
    {/*    <div className='col-sm-4'>*/}
    {/*      <input disabled={!editable} type='text' className='location form-control' />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*  <div className='form-group'>*/}
    {/*    <label className='control-label col-sm-3' style={{ textAlign: 'right' }}>Telefone</label>*/}
    {/*    <div className='col-sm-3'>*/}
    {/*      <input disabled={!editable} type='tel' className='form-control' name={'phoneNumber'} />*/}
    {/*    </div>*/}
    {/*    <label className='control-label col-sm-2' style={{ textAlign: 'right' }}>Email</label>*/}
    {/*    <div className='col-sm-4'>*/}
    {/*      <input disabled={!editable} type='email' className='form-control' name={'email'} />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</FormComponent>*/}
  </div>
}

export default Form
