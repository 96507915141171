import React from 'react'
import { ALLERGY_TYPES, SEVERITY, THERAPEUTICS_TYPE } from '../../../../../models/Allergy'
import { Button } from '../../../../common/Button/Button'
import v4 from 'uuid/v4'
import AutoCompleter from '../../../../common/formComponents/AutoCompleter'


const structureListForAutocomplete = (arr = []) => {
  return Object.values(arr)
    .filter(element => element.name)
    .map(element => {
      return {
        value: element.id,
        displayValue: element.name
      }
    })
}

export default class AllergyInsertionForm extends React.Component {
  constructor (props) {
    super(props)
    console.log(props.data)
    const defaultData = {
      id: props.data.id || v4(),
      severity: props.data.severity || '',
      therapeutics: props.data.therapeutics || '',
      substance: props.data.substance || '',
      discoveryDate: props.data.discoveryDate || '',
      type: props.data.type || ''
    }
    this.state = {
      ...defaultData
    }
    this.handleTypeChange = this.handleTypeChange.bind(this)
  }

  handleTypeChange (value) {
    this.setState({
      type: value
    })
  }

  handleChange (name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    this.props.submitAllergy(this.state)
  }

  render () {
    const { submitAllergy, enableDelete, dcis, data } = this.props
    const { severity, therapeutics, id, substance, discoveryDate, type } = this.state
    const { MEDICATION, DOMESTIC_ANIMALS, getDescription: getDescriptionForAllergy, FUNGI, HOME_DUST, INSECTS, LATEX, POLLEN, FOOD } = ALLERGY_TYPES
    const { POSITIVE, IRREGULAR, getDescription: getDescriptionForType, NEGATIVE } = THERAPEUTICS_TYPE
    const { SEVERE, MODERATE, MILD, getDescription: getSeverityDescription } = SEVERITY
    return <div className={`${type === MEDICATION ? 'medication-insertion' : 'other-insertion'} clearfix`}>
      <form onSubmit={this.handleSubmit.bind(this)}>
        <div className='modal-body'>
          <div className='form-group'>
            {data.type === MEDICATION
              ? null
              : <div className='col-sm-12 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Tipo</label>
                <div className=''>
                  <select required className='form-control' name={'type'} value={type} onChange={({target}) => this.handleChange(target.name, target.value)}>
                    <option value={''} hidden disabled />
                    <option value={FOOD}>{getDescriptionForAllergy(FOOD)}</option>
                    <option value={INSECTS}>{getDescriptionForAllergy(INSECTS)}</option>
                    <option value={DOMESTIC_ANIMALS}>{getDescriptionForAllergy(DOMESTIC_ANIMALS)}</option>
                    <option value={FUNGI}>{getDescriptionForAllergy(FUNGI)}</option>
                    <option value={HOME_DUST}>{getDescriptionForAllergy(HOME_DUST)}</option>
                    <option value={POLLEN}>{getDescriptionForAllergy(POLLEN)}</option>
                    <option value={LATEX}>{getDescriptionForAllergy(LATEX)}</option>
                  </select>
                </div>
              </div>
            }
          </div>
          {data.type === MEDICATION
            ? <div className='form-group'>
              <div className='col-sm-12 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Alergénio</label>
                <div className='input-group grouped-item'>
                  <AutoCompleter
                    className={'form-control rounded-left'}
                    defaultValue={substance}
                    placeholder={'DCI'}
                    onSuggestionSelected={({ displayValue }) => this.handleChange('substance', displayValue)}
                    handleChange={(value) => this.handleChange('substance', value)}
                    name={'substance'}
                    options={structureListForAutocomplete(dcis)} />
                  <span className='input-group-addon btn-right'><i className='fa fa-search' /></span>
                </div>
              </div>
            </div>
            : <div className='form-group'>
              <div className='col-sm-12 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Alergénio</label>
                <div className=''>
                  <input
                    required
                    type='text'
                    className='form-control'
                    name={'substance'}
                    value={substance}
                    onChange={({target}) => this.handleChange(target.name, target.value)} />
                </div>
              </div>
            </div>
          }
          <div className='form-group'>
            <div className='col-sm-12 margin-bottom-form-input'>
              <label className='control-label' style={{ textAlign: 'right' }}>Data Diagnóstico</label>
              <div className=''>
                <input required type='date' className='form-control' name={'discoveryDate'} value={discoveryDate} onChange={({target}) => this.handleChange(target.name, target.value)} />
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-12 margin-bottom-form-input'>
              <label className='control-label' style={{ textAlign: 'right' }}>Gravidade</label>
              <div className=''>
                <select required className='form-control' name={'severity'} value={severity} onChange={({target}) => this.handleChange(target.name, target.value)}>
                  <option value={''} hidden disabled />
                  <option value={MILD}>{getSeverityDescription(MILD)}</option>
                  <option value={MODERATE}>{getSeverityDescription(MODERATE)}</option>
                  <option value={SEVERE}>{getSeverityDescription(SEVERE)}</option>
                </select>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-12 margin-bottom-form-input'>
              <label className='control-label' style={{ textAlign: 'right' }}>Terapêutica</label>
              <div className=''>
                <select required className='form-control' name={'therapeutics'} value={therapeutics} onChange={({target}) => this.handleChange(target.name, target.value)}>
                  <option value={''} hidden disabled />
                  <option value={POSITIVE}>{getDescriptionForType(POSITIVE)}</option>
                  <option value={NEGATIVE}>{getDescriptionForType(NEGATIVE)}</option>
                  <option value={IRREGULAR}>{getDescriptionForType(IRREGULAR)}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          {enableDelete ? <Button type={'button'} onClick={() => submitAllergy({id: id}, true)} text={<span className='fas fa-trash-alt' />} buttonType={'btn-danger pull-left'} /> : <div /> }
          <Button type={'submit'} text={'Guardar'} buttonType={'btn-success pull-right'} />
        </div>
      </form>
    </div>
  }
}
