import React from 'react'

import logo from '../../../resources/images/logo.svg'
import { Button } from '../../common/Button/Button'
import { COUNTRIES } from '../../../resources/utilities'
import SectionTitle from '../../common/SectionTitle/SectionTitle'

import './style.css'

const Signup = () => {
  return <div className='container register'>
    <div className='col-xs-12 no-side-padding'>
      <div className='col-md-3 register-left'>
        <div className='container-left'>
          <img src={logo} alt='' />
          <h1>Scribo</h1>
          <Button type={'button'} buttonType={'btn btn-info btn-lg'} text={'Login'} />
        </div>
      </div>
      <div className='col-md-9 register-right no-side-padding'>
        <h3 className='register-heading'>Registo do Profissional de Saúde</h3>
        <div className='register-form'>
          <form onSubmit={(e) => { e.preventDefault() }}>
            <SectionTitle title={'Dados Pessoais'} size={'h5'} smallMargins />
            <div className='form-group'>
              <div className='col-sm-12 margin-bottom-form-input'>
                <label>Nome Completo</label>
                <input type='text' className='form-control' placeholder='' />
              </div>
            </div>
            <div className='form-group radio-form-group'>
              <div className='col-sm-12 margin-bottom-form-input'>
                <label>Sexo</label>
                <div className='form-control' style={{border: 'none', boxShadow: 'none'}}>
                  <div className='radio-inline'>
                    <label className=''>
                      <input type='radio' name='genderSelect' value='male' onChange={() => {}} />
                      Masculino
                    </label>
                  </div>
                  <div className='radio-inline'>
                    <label className=''>
                      <input type='radio' name='genderSelect' value='female' onChange={() => {}} />
                      Feminino
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Data Nascimento</label>
                <div className=''>
                  <input type='date' className='form-control' />
                </div>
              </div>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Estado Civil</label>
                <div className=''>
                  <select name='maritalState' className='form-control'>
                    <option value='single'>Solteiro/a</option>
                    <option value='maried'>Casado/a</option>
                    <option value='divorced'>Divorciado/a</option>
                    <option value='widow'>Viúvo/a</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Naturalidade</label>
                <div className=''>
                  <select className='form-control' defaultValue={'Portugal'} name={'nationality'}>
                    {COUNTRIES.map((country, i) => <option key={i} value={country}>{country}</option>)}
                  </select>
                </div>
              </div>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Nacionalidade</label>
                <div className=''>
                  <select className='form-control' defaultValue={'Portugal'} name={'naturality'}>
                    {COUNTRIES.map((country, i) => <option key={i} value={country}>{country}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-12 margin-bottom-form-input'>
                <label>Morada Pessoal</label>
                <input type='text' className='form-control' placeholder='' />
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Código Postal</label>
                <div className=''>
                  <input type='text' className='form-control' name={'postalCode'} />
                </div>
              </div>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Localidade</label>
                <div className=''>
                  <input type='text' className='form-control' name={'location'} />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label ' style={{ textAlign: 'right' }}>Telefone</label>
                <div className=''>
                  <input type='tel' className='form-control' name={'phoneNumber'} />
                </div>
              </div>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label ' style={{ textAlign: 'right' }}>Email</label>
                <div className=''>
                  <input type='email' className='form-control' name={'email'} />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Nº CC</label>
                <div className=''>
                  <input type='text' className='form-control' name={'identityCard'} />
                </div>
              </div>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>NIF</label>
                <div className=''>
                  <input type='number' className='form-control' name={'vat'} />
                </div>
              </div>
            </div>
            <SectionTitle title={'Dados Profissionais'} size={'h5'} smallMargins />
            <div className='form-group'>
              <div className='col-sm-12 margin-bottom-form-input'>
                <label>Nome Clínico</label>
                <input type='text' className='form-control' placeholder='' />
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Ordem Profissional</label>
                <div className=''>
                  <select className='form-control' name={'professionalNumberType'}>
                    <option value='doctor'>Médicos</option>
                    <option value='dentist'>Médicos dentistas</option>
                    <option value='odontologist'>Odontologistas</option>
                  </select>
                </div>
              </div>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Nº Cédula</label>
                <div className=''>
                  <input type='text' className='form-control' />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-12 margin-bottom-form-input'>
                <label>Nº Local de Prescrição</label>
                <input type='number' className='form-control' placeholder='' />
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-12 margin-bottom-form-input'>
                <label>Local de Trabalho</label>
                <input type='text' className='form-control' placeholder='Hospital, Centro de Saúde, Consultório, Clínica' />
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-12 margin-bottom-form-input'>
                <label>Morada Profissional</label>
                <input type='text' className='form-control' placeholder='' />
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label ' style={{ textAlign: 'right' }}>Código Postal</label>
                <div className=''>
                  <input type='text' className='form-control' />
                </div>
              </div>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Localidade</label>
                <div className=''>
                  <input type='text' className='form-control' />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Telefone</label>
                <div className=''>
                  <input type='tel' className='form-control' name={'phoneNumber'} />
                </div>
              </div>
              <div className='col-sm-6 margin-bottom-form-input'>
                <label className='control-label' style={{ textAlign: 'right' }}>Email</label>
                <div className=''>
                  <input type='email' className='form-control' name={'email'} />
                </div>
              </div>
            </div>
            <div className='col-sm-12 margin-bottom-form-input'>
              <Button type={'submit'} text={'Registar'} buttonType={'btn-success pull-right'} />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}

export default Signup
