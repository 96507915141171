/**
 * Created by valentin on 20/07/2017.
 */
import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Renderer } from '../Renderer/Renderer'
import './style.css'

class ReactModal extends React.Component {
  render () {
    const { toggleModal, fullWidth, innerFooter, disableClosing, customClass = '', customHeaderClass = '', name, modalIsOpen, footer, children } = this.props
    const width = fullWidth ? 'full-width' : null
    return <div className='modal'>
      <Modal
        bodyOpenClassName={'modal-open'}
        overlayClassName='modal fade in'
        onRequestClose={toggleModal}
        shouldReturnFocusAfterClose={false}
        className={`Modal__Bootstrap modal-dialog ${width} ${customClass}`}
        ref='Modal'
        id='Modal'
        style={{
          overlay: {
            display: 'block',
            paddingLeft: '0px'
          }
        }}
        ariaHideApp={false}
        closeTimeoutMS={150}
        isOpen={modalIsOpen}
        contentLabel={name}>
        <div className='modal-content'>
          <div className={`modal-header ${customHeaderClass}`} >
            {!disableClosing
              ? <button type='button' className='close' onClick={toggleModal}>
                <i className='fas fa-times-circle' />
              </button>
              : null
            }
            <h4 className='modal-title'>{name}</h4>
          </div>
          <div className={!innerFooter ? 'modal-body' : ''}>
            {children}
          </div>
          <Renderer data={footer} noMessage>
            <div className='modal-footer'>
              {footer}
            </div>
          </Renderer>
        </div>
      </Modal>
    </div>
  }
}

ReactModal.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toggleModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  footer: PropTypes.object,
  children: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  disableClosing: PropTypes.bool,
  innerFooter: PropTypes.bool,
  customClass: PropTypes.string,
  customHeaderClass: PropTypes.string
}

ReactModal.defaultProps = {
  disableClosing: false,
  fullWidth: false,
  innerFooter: false
}

export { ReactModal }
