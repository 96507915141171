import React from 'react'
import testAllergies from '../../../../../test/testAllergies'
import Allergy, { ALLERGY_TYPES } from '../../../../../models/Allergy'

import './style.css'
import { ReactModal } from '../../../../common/ReactModal/ReactModal'
import DiverseAllergiesPanel from './DiverseAllergiesPanel'
import MedicalAllergiesPanel from './MedicalAllergiesPanel'
import AllergyInsertionForm from './AllergiesInsertionForm'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { notify } from 'reapop'

function getOtherAllergies (allAllergies) {
  return allAllergies.filter(allergy => allergy.type !== ALLERGY_TYPES.MEDICATION)
    .map(({ id, type, discoveryDate, substance, severity, therapeutics }) => (
      new Allergy(id, type, discoveryDate, substance, severity, therapeutics))
    )
}

function getMedicalAllergies (allAllergies) {
  return allAllergies.filter(allergy => allergy.type === ALLERGY_TYPES.MEDICATION)
    .map(({ id, type, discoveryDate, substance, severity, therapeutics }) => (
      new Allergy(id, type, discoveryDate, substance, severity, therapeutics))
    )
}

const OptionsDropdown = ({alignSide = 'left', toggleModal}) => {
  return <div className={`dropdown align-${alignSide}`}>
    <button
      className='btn btn-success dropdown-toggle'
      type='button'
      id='dropdownMenu1'
      data-toggle='dropdown'
      aria-haspopup='true'
      aria-expanded='true'>
      <span className='fas fa-plus' />
      <span className='caret' />
    </button>
    <ul className='dropdown-menu' aria-labelledby='dropdownMenu1'>
      <li onClick={() => toggleModal(ALLERGY_TYPES.MEDICATION)}><button className='bordered'>Medicamentosa</button></li>
      <li onClick={() => toggleModal()}><button className='bordered'>Outra</button></li>
    </ul>
  </div>
}

class Allergies extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      allergies: testAllergies,
      modalIsOpen: false,
      type: undefined,
      editing: false
    }
  }

  toggleModal () {
    this.setState({
      editing: false,
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  openInsertion (item = {}, editing = true) {
    this.toggleModal()
    this.setState({
      editing,
      item
    })
  }

  handleAllergySubmit (values, removeItem) {
    const current = [...this.state.allergies]
    const itemIndex = current.findIndex(item => item.id === values.id)
    if (itemIndex > -1) {
      if (!removeItem) {
        current[itemIndex] = values
        testAllergies[itemIndex] = values
      } else {
        current.splice(itemIndex, 1)
        testAllergies.splice(itemIndex, 1)
      }
    } else {
      current.push(values)
      testAllergies.push(values)
    }
    this.setState({
      allergies: current,
      modalIsOpen: false
    })
    this.props.notify({
      message: removeItem ? 'Alergia Removida.' : this.state.editing ? 'Alterações Guardadas.' : 'Alergia Adicionada.',
      status: removeItem ? 'error' : 'info',
      dismissible: true,
      dismissAfter: 5000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
  }

  openAllergyInsertion (type, editing) {
    const data = {discoveryDate: moment(new Date()).format('YYYY-MM-DD'), editing, type}
    this.openInsertion(data, editing)
  }

  render () {
    const medicalAllergies = getMedicalAllergies(this.state.allergies)
    const otherAllergies = getOtherAllergies(this.state.allergies)
    const { item, editing } = this.state
    return <div className='container-fluid'>
      <div className='col-xs-12 no-side-padding margin-bottom'>
        <div className='pull-right'>
          <OptionsDropdown alignSide={'right'} toggleModal={(type) => this.openAllergyInsertion(type, false)} />
        </div>
      </div>
      <div className='col-xs-12 small-side-padding'>
        <MedicalAllergiesPanel openInsertion={this.openInsertion.bind(this)} allergies={medicalAllergies} />
      </div>
      <div className='col-xs-12 small-side-padding'>
        <DiverseAllergiesPanel openInsertion={this.openInsertion.bind(this)} allergies={otherAllergies} />
      </div>
      <ReactModal innerFooter toggleModal={this.toggleModal.bind(this)} name={editing ? 'Alterar Alergia' : 'Adicionar Alergia'} customClass={'allergies-insertion'} modalIsOpen={this.state.modalIsOpen}>
        <AllergyInsertionForm dcis={this.props.dcis}
          enableDelete={item && item.id}
          submitAllergy={this.handleAllergySubmit.bind(this)}
          data={item} />
      </ReactModal>
    </div>
  }
}

const mapStateToProps = ({medicineData}) => {
  return {
    dcis: medicineData.dcis
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({notify}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Allergies)
