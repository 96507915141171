import React from 'react'

import './style.css'
import MaterializedReceiptGenerator from '../PDFCreators/materializedReceiptGenerator'
import MedicineChangeContainer
  from '../Home/Patient/Prescription/MedicineView/MedicineChangeView/MedicineChangeContainer'

class TestMaterialized extends React.Component {
  renderReceipt () {
    const doc = new MaterializedReceiptGenerator('3011000041468888005', '2019-01-01', 'Valentin Angelov Mirchev', '356047551', '916123221', 239605, 9961, 'H. CRUZ VERMELHA P.', 'R. PIMENTA-ARAÚJO', 217714000, [], 1)
    const blob = doc.getBlobUrl()
    window.open(blob, '_blank')
  }

  render () {
    return <div>
      <button onClick={() => this.renderReceipt()}>OLa</button>
      receita materializada
    </div>
  }
}

class Test extends React.Component {
  render () {
    return <div style={{border: '1px solid red'}}>
      <MedicineChangeContainer dci={'Paracetamol'} />
    </div>
  }
}

export default TestMaterialized
