import React, { Component } from 'react'
import PropTypes from 'prop-types'

import fetch from 'isomorphic-fetch'

import cmdIcon from '../../../../../../../../resources/images/prescription/cmd.svg'

import './style.css'
const FORM_TYPES = {
  PHONE_PIN_FORM: 0,
  CODE_CONFIRMATION_FORM: 1,
  ID_CARD: 2,
  NONE: -1
}

class PhonePinForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      phone: '+351 912161389',
      code: '0261',
      hash: 'Texto exemplo'
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    console.log(this.state)
    // eslint-disable-next-line no-undef
    const formData = new FormData()
    formData.append('phone', this.state.phone)
    formData.append('code', this.state.code)
    formData.append('hash', this.state.hash)
    fetch('http://192.168.2.116:8080/', {
      method: 'POST',
      body: formData
    }).then(resp => {
      if (resp.status > 400) {
        console.error('erro')
      } else {
        resp.text().then(processId => {
          this.props.submit(processId)
        })
      }
    })
  }

  render () {
    return <form data-autocomplete='off' onSubmit={e => this.handleSubmit(e)}>
      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <input
            onChange={(e) => { this.setState({phone: e.target.value}) }}
            name={'phone'}
            type='tel'
            value={this.state.phone}
            className='form-control'
            placeholder='Telefone'
            maxLength={9} />
        </div>
        <div className='col-xs-12 col-sm-6'>
          <input
            onChange={(e) => { this.setState({code: e.target.value}) }}
            className='form-control'
            type='password'
            name={'code'}
            value={this.state.code}
            autoComplete='new-password'
            placeholder='PIN' />
        </div>
        <input type='text' value={this.state.hash} hidden onChange={() => {}} />
        <div className='col-xs-12 margin-top'>
          <button type={'button'} className='btn btn-success btn-block' onClick={this.handleSubmit.bind(this)}>Assinar</button>
        </div>
      </div>
    </form>
  }
}

PhonePinForm.defaultProps = { defaultPhone: '' }

const CardForm = ({ submit }) => {
  return <div>
    <div className='row'>
      <div className='col-xs-12'>
        <button className='btn btn-default btn-block' onClick={submit}>
          <span className='fas fa-spinner fa-pulse' /> A Carregar...
        </button>
      </div>
    </div>
  </div>
}

PhonePinForm.propTypes = {
  submit: PropTypes.func.isRequired,
  defaultPhone: PropTypes.string
}

class CodeConfirmationForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pin: ''
    }
  }

  handleSubmit (e) {
    e.preventDefault()
    const { processId } = this.props
    const { pin } = this.state
    // eslint-disable-next-line no-undef
    const formData = new FormData()
    formData.append('processId', processId)
    formData.append('code', pin)
    console.log('submittind second form')
    fetch('http://192.168.2.116:8080/otp', {
      method: 'POST',
      body: formData
    }).then(resp => {
      if (resp.status > 400) {
        console.error('erro')
      } else {
        resp.text().then(resp => {
          console.log(resp)
          this.props.submit()
        })
      }
    })
  }

  render () {
    return <form onSubmit={this.handleSubmit.bind(this)}>
      <div className='row'>
        <div className='col-xs-6'>
          <input className='form-control' name={'pin'} type='password' placeholder='PIN' onChange={(e) => this.setState({pin: e.target.value})} maxLength={6} value={this.state.pin} />
        </div>
        <div className='col-xs-6'>
          <button type={'submit'} className='btn btn-success btn-block'>Confirmar</button>
        </div>
      </div>
    </form>
  }
}

CodeConfirmationForm.propTypes = {
  processId: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired
}

class ConfirmationBox extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formType: null,
      processId: null
    }
    this.changeFormType = this.changeFormType.bind(this)
  }

  changeFormType (processId) {
    const nextValue = this.state.formType + 1
    this.setState({
      processId,
      formType: nextValue
    })
    if (nextValue === FORM_TYPES.NONE) {
      this.props.confirmReceipt()
    }
  }

  confirmReceipt () {
    this.props.confirmReceipt()
    this.setState({
      formType: null
    })
  }

  render () {
    const { formType } = this.state
    return <div>
      <div className='row'>
        <h5 style={{opacity: formType === FORM_TYPES.NONE ? '0' : '1', textAlign: 'center'}}>Assinar</h5>
        <div className='col-xs-6'>
          <button className='btn btn-info btn-block' onClick={() => { this.setState({ formType: FORM_TYPES.ID_CARD }) }}>
            <i className='fas fa-id-card fa-2x' />
          </button>
        </div>
        <div className='col-xs-6'>
          <button className='btn btn-info btn-block' onClick={() => { this.setState({ formType: FORM_TYPES.PHONE_PIN_FORM }) }}>
            <img src={cmdIcon} alt='CMD icon' style={{height: '28px'}} />
          </button>
        </div>
      </div>
      <br />
      {formType === FORM_TYPES.PHONE_PIN_FORM
        ? <PhonePinForm defaultPhone={this.props.defaultPhone} submit={this.changeFormType} />
        : null
      }
      {formType === FORM_TYPES.ID_CARD
        ? <CardForm submit={this.confirmReceipt.bind(this)} />
        : null
      }
      {formType === FORM_TYPES.CODE_CONFIRMATION_FORM
        ? <CodeConfirmationForm processId={this.state.processId} submit={this.confirmReceipt.bind(this)} />
        : null
      }
    </div>
  }
}
ConfirmationBox.propTypes = {
  confirmReceipt: PropTypes.func.isRequired,
  defaultPhone: PropTypes.string
}

export default ConfirmationBox
