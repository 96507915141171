export default class Medicine {
  constructor (id, commercialName, activeComponent, dose, units, medicineType, packageType, administrationType, price, isGeneric, hasGeneric, copayment) {
    this._id = id
    this._commercialName = commercialName
    this._activeComponent = activeComponent
    this._dose = dose
    this._units = units
    this._medicineType = medicineType
    this._administrationType = administrationType
    this._price = price
    this._isGeneric = isGeneric
    this._hasGeneric = hasGeneric
    this._packageType = packageType
    this._copayment = copayment
  }

  getFullDesignation () {
    let designation = `${this.activeComponent}`
    if (this.dose) {
      designation = `${designation}, ${this.dose}`
    }
    if (this.units) {
      designation = `${designation} - ${this.units} un.`
    }
    return designation
  }

  get id () {
    return this._id
  }

  get commercialName () {
    return this._commercialName
  }

  get activeComponent () {
    return this._activeComponent
  }

  get dose () {
    return this._dose
  }

  get units () {
    return this._units
  }

  get medicineType () {
    return this._medicineType
  }

  set medicineType (value) {
    this._medicineType = value
  }

  get packageType () {
    return this._packageType
  }

  set packageType (value) {
    this._packageType = value
  }

  get administrationType () {
    return this._administrationType
  }

  get price () {
    return this._price
  }

  get isGeneric () {
    return this._isGeneric
  }

  get hasGeneric () {
    return this._hasGeneric
  }

  get copayment () {
    return this._copayment
  }
}
