const ON_CHANGE = 'search//ON_CHANGE'
const FETCH_SUCCESS = 'search//FETCH_SUCCESS'
const FETCHING_RESULTS = 'search//FETCHING_RESULTS'
const ERROR_FETCHING = 'search//ERROR_FETCHING'
const TYPE_CHANGE = 'search//TYPE_CHANGE'
const RESET = 'search//RESET'

export {
  ON_CHANGE,
  FETCH_SUCCESS,
  FETCHING_RESULTS,
  ERROR_FETCHING,
  TYPE_CHANGE,
  RESET
}
