export default class Favorite {
  constructor (id, name, items) {
    this._id = id
    this._name = name
    this._items = items
  }

  get id () {
    return this._id
  }

  get name () {
    return this._name
  }

  get items () {
    return this._items
  }
}
