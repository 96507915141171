import React from 'react'
import { Button } from '../../../../common/Button/Button'
import v4 from 'uuid/v4'
import AutoCompleter from '../../../../common/formComponents/AutoCompleter'

const structureListForAutocomplete = (arr = []) => {
  return Object.values(arr)
    .filter(element => element.name)
    .map(element => {
      return {
        value: element.id,
        displayValue: element.name
      }
    })
}

export default class MedicationInsertionForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      id: v4()
    }
    this.handleTypeChange = this.handleTypeChange.bind(this)
  }

  handleTypeChange (value) {
    this.setState({
      type: value
    })
  }

  handleChange (name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    console.log(this.state)
    this.props.toggleModal()
    window.alert('Cannot yet add medicines.')
  }

  render () {
    const { dcis } = this.props
    const { description, dosage, dose, date, prescriber } = this.state
    // name, dosage, date, prescriber, medType
    return <div>
      <form onSubmit={this.handleSubmit.bind(this)}>
        <div className='modal-body'>
          <div className='form-group'>
            <div className='col-sm-12 margin-bottom-form-input'>
              <label className='control-label' style={{ textAlign: 'right' }}>Medicamento</label>
              <div className='input-group grouped-item'>
                <AutoCompleter
                  className={'form-control rounded-left'}
                  placeholder={'DCI'}
                  required
                  defaultValue={description}
                  onSuggestionSelected={({ displayValue }) => this.handleChange('description', displayValue)}
                  handleChange={(value) => this.handleChange('description', value)}
                  name={'description'}
                  options={structureListForAutocomplete(dcis)} />
                <span className='input-group-addon btn-right'><i className='fa fa-search' /></span>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-12 margin-bottom-form-input'>
              <label className='control-label' style={{ textAlign: 'right' }}>Dosagem</label>
              <div className=''>
                <input
                  required
                  type='text'
                  className='form-control'
                  name={'dose'}
                  value={dose || ''}
                  onChange={({target}) => this.handleChange(target.name, target.value)} />
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-12 margin-bottom-form-input'>
              <label className='control-label' style={{ textAlign: 'right' }}>Posologia</label>
              <div className=''>
                <input
                  required
                  type='text'
                  className='form-control'
                  name={'dosage'}
                  value={dosage || ''}
                  onChange={({target}) => this.handleChange(target.name, target.value)} />
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-12 margin-bottom-form-input'>
              <label className='control-label' style={{ textAlign: 'right' }}>Data Prescrição</label>
              <div className=''>
                <input
                  required
                  type='date'
                  className='form-control'
                  name={'date'}
                  value={date || ''}
                  onChange={({target}) => this.handleChange(target.name, target.value)} />
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-12 margin-bottom-form-input'>
              <label className='control-label' style={{ textAlign: 'right' }}>Prescritor</label>
              <div className=''>
                <input
                  required
                  type='text'
                  className='form-control'
                  name={'prescriber'}
                  value={prescriber || ''}
                  onChange={({target}) => this.handleChange(target.name, target.value)} />
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <Button type={'submit'} text={'Guardar'} buttonType={'btn-success pull-right'} />
        </div>
      </form>
    </div>
  }
}
