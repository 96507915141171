import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { basketActions } from '../../../../../../../redux/basket'
import { modalsActions } from '../../../../../../../redux/modals'
import Basket from './Basket'

class BasketContainer extends React.Component {
  render () {
    const { basketItems, openModal, closeModal, submit, customButtonText, noConfirmation, medicineData } = this.props
    return <Basket
      medicineData={medicineData}
      noConfirmation={noConfirmation}
      closeModal={closeModal}
      customButtonText={customButtonText}
      openModal={openModal}
      submit={submit}
      items={basketItems} />
  }
}

BasketContainer.propTypes = {
  noConfirmation: PropTypes.bool,
  customButtonText: PropTypes.string,
  submit: PropTypes.func.isRequired
}

function mapStateToProps ({basket, medicineData}) {
  return {
    medicineData,
    basketItems: basket.map(item => {
      return item
    })
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({...basketActions, openModal: modalsActions.open, closeModal: modalsActions.close}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketContainer)
