import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PatientHistory from './PatientHistory/PatientHistory'
import PrescribeRouter from './Prescribe/PrescribeRouter'
import { bindActionCreators } from 'redux'
import { patientActions } from '../../../../../redux/patient'
import { connect } from 'react-redux'
import Patient from '../../../../../models/Patient'
import Conversation from '../../Messages/Conversation/Conversation'
import PrescriptionGuideContainer from './Guides/PrescriptionGuideContainer'

const testMessages = [
  {
    senderId: 0,
    dateTime: '2019-02-06T13:00:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet consequat turpis. Praesent tempor commodo ante, sed pellentesque dolor vulputate a. Etiam faucibus arcu ut scelerisque ultrices. Duis et venenatis turpis, id sodales nibh. Maecenas consectetur arcu ac felis aliquam, lobortis viverra purus accumsan. Vestibulum vel tristique felis, sit amet ornare diam. Aenean fringilla ac dolor at lobortis. Aenean rutrum ut nulla at auctor. Etiam mollis dignissim nisl, at molestie justo dictum non. Nulla eleifend eleifend mauris, eu hendrerit nibh. Donec pulvinar et ligula sit amet molestie.'
  },
  {
    senderId: 0,
    dateTime: '2019-02-06T13:10:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet consequat turpis.'
  },
  {
    senderId: 0,
    dateTime: '2019-02-06T13:18:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  {
    senderId: 1,
    dateTime: '2019-02-06T13:19:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet consequat turpis.'
  },
  {
    senderId: 1,
    dateTime: '2019-02-06T13:20:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet consequat turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sit amet consequat turpis.'
  },
  {
    senderId: 0,
    dateTime: '2019-02-06T13:28:00Z',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  {
    senderId: 1,
    dateTime: '2019-02-06T13:30:00Z',
    content: 'Lorem ipsum dolor sit amet'
  }
]

class PrescriptionRouting extends React.Component {
  render () {
    const { match, patient } = this.props
    if (patient) {
      return <Switch>
        <Route
          exact
          path={`${match.url}`}
          render={() => <Redirect to={`${match.url}/prescribe`} replace />} />
        <Route
          path={`${match.url}/history`}
          render={props => <PatientHistory {...props} />} />
        <Route
          path={`${match.url}/messages`}
          render={props => <Conversation messages={testMessages} {...props} />} />
        <Route
          path={`${match.url}/prescribe`}
          render={props => <PrescribeRouter {...props} />} />
        <Route
          path={`${match.url}/guides`}
          render={props => <PrescriptionGuideContainer {...props} />} />
      </Switch>
    } else {
      return <div>Loading</div>
    }
  }
}

// const PatientPrescriptionRouting2 = (props) => {
//   return <SideNav items={navItems(props.match.url)} patient={props.patient}>
//     <Content {...props} />
//   </SideNav>
// }

const mapStateToProps = ({patient}) => {
  let patientObject
  if (patient.data) {
    const { id, healthcareNumber, name, birth, gender, country, contacts, email, photo } = patient.data
    patientObject = new Patient(id, healthcareNumber, name, birth, gender, country, contacts, email, photo)
  }
  return {
    patient: patientObject,
    fetching: patient.fetching,
    error: patient.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...patientActions}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionRouting)
