const RELATIVES = {
  MOTHER: 'mother',
  FATHER: 'father',
  GRAND_MOTHER_MOTHER_LINE: 'grandMotherMotherLine',
  GRAND_MOTHER_FATHER_LINE: 'grandMotherFatherLine',
  GRAND_FATHER_MOTHER_LINE: 'grandFatherMotherLine',
  GRAND_FATHER_FATHER_LINE: 'grandFatherFatherLine',
  UNCLE_MOTHER_LINE: 'uncleMotherLine',
  UNCLE_FATHER_LINE: 'uncleFatherLine',
  AUNT_MOTHER_LINE: 'auntMotherLine',
  AUNT_FATHER_LINE: 'auntFatherLine',
  BROTHER: 'brother',
  SISTER: 'sister',
  COUSIN: 'cousin',
  HALF_BROTHER: 'halfBrother',
  HALF_SISTER: 'halfSister',
  getDescription: (type) => {
    switch (type) {
      case 'mother':
        return 'Mãe'
      case 'father':
        return 'Pai'
      case 'grandMotherMotherLine':
        return 'Avó Materna'
      case 'grandMotherFatherLine':
        return 'Avó Paterna'
      case 'grandFatherMotherLine':
        return 'Avô Materno'
      case 'grandFatherFatherLine':
        return 'Avô Paterno'
      case 'uncleMotherLine':
        return 'Tio Materno'
      case 'uncleFatherLine':
        return 'Tio Paterno'
      case 'auntMotherLine':
        return 'Tia Materna'
      case 'auntFatherLine':
        return 'Tia Paterna'
      case 'brother':
        return 'Irmão'
      case 'sister':
        return 'Irmã'
      case 'cousin':
        return 'Primo/a'
      case 'halfBrother':
        return 'Meio irmão'
      case 'halfSister':
        return 'Meia irmã'
      default:
        return ''
    }
  }
}

export { RELATIVES }

export default class FamilyBackground {
  constructor (id, relative, diagnosis, observations) {
    this._id = id
    this._relative = relative
    this._diagnosis = diagnosis
    this._observations = observations
  }

  get id () {
    return this._id
  }

  get relative () {
    return this._relative
  }

  get diagnosis () {
    return this._diagnosis
  }

  get observations () {
    return this._observations
  }

}
