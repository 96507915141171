import React, { Component } from 'react'
import FormElement from '../../../../../../common/formComponents/FormElement'
import Form from '../../../../../../common/formComponents/Form'
import { connect } from 'react-redux'
import MedicineTypeIcon from '../../MedicineView/MedicineTypeIcon/MedicineTypeIcon'
import PropTypes from 'prop-types'
import { ReactModal } from '../../../../../../common/ReactModal/ReactModal'
import { Button } from '../../../../../../common/Button/Button'
import { convertBasketToPrescribedMedicines } from '../../../../../../../resources/utilities'
import { doctorActions } from '../../../../../../../redux/doctor'
import { basketActions } from '../../../../../../../redux/basket'
import v4 from 'uuid/v4'
import { bindActionCreators } from 'redux'
import { notify } from 'reapop'

const MedicineItem = ({medicine}) => {
  return <div>
    {medicine.activeComponent} | {medicine.medicineType.name} <MedicineTypeIcon type={medicine.medicineType.id} color={'black'} /> |
    {medicine.dose} | {medicine.packageType.name} | {medicine.units} un. | {medicine.administrationType.name}
  </div>
}

class FavoriteCreationContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      favoriteName: ''
    }
  }

  addFavorite () {
    const { items: basketItems } = this.props
    const name = this.state.favoriteName || 'Sem Nome'
    const items = convertBasketToPrescribedMedicines(basketItems)
    this.props.addFavorite({
      id: v4(),
      name,
      items
    })
    this.props.removeAll()
    this.props.notify({
      message: `Favorito criado com sucesso.`,
      status: 'info',
      dismissible: true,
      dismissAfter: 3000,
      position: 'br',
      closeButton: true,
      allowHTML: false
    })
    this.props.toggleModal()
  }

  render () {
    let { items } = this.props
    return <div>
      <ReactModal
        name={'Criar Favorito'}
        footer={<Button type={'button'} text={'Guardar'} buttonType={'btn-success'} onClick={this.addFavorite.bind(this)} />}
        toggleModal={this.props.toggleModal}
        modalIsOpen={this.props.modalIsOpen}>
        <Form handleSubmit={() => {}}>
          <FormElement handleChange={(name, value) => this.setState({favoriteName: value})} type={'text'} inputSize={9} label={'Nome do Favorito'} name={'favoriteName'} />
          <div className='list-group'>
            {items.map((item, i) => {
              return <div key={i} className='list-group-item'>
                <MedicineItem medicine={item.medicine} />
                <div className='text-muted'>{item.dosage}</div>
              </div>
            })}
          </div>
        </Form>
      </ReactModal>
    </div>
  }
}

FavoriteCreationContainer.propTypes = { items: PropTypes.any }

const mapStateToProps = ({basket}) => {
  return {
    items: basket
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({...doctorActions, ...basketActions, notify}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteCreationContainer)
