import v4 from 'uuid/v4'
import { ALLERGY_TYPES, SEVERITY, THERAPEUTICS_TYPE } from '../models/Allergy'

const testAllergies = [
  {
    id: v4(),
    type: ALLERGY_TYPES.MEDICATION,
    discoveryDate: '2019-02-02',
    substance: 'Paracetamol',
    severity: SEVERITY.MODERATE,
    therapeutics: THERAPEUTICS_TYPE.POSITIVE
  },
  {
    id: v4(),
    type: ALLERGY_TYPES.MEDICATION,
    discoveryDate: '2019-02-02',
    substance: 'Bromexina',
    severity: SEVERITY.MODERATE,
    therapeutics: THERAPEUTICS_TYPE.IRREGULAR
  },
  {
    id: v4(),
    type: ALLERGY_TYPES.POLLEN,
    discoveryDate: '2019-02-02',
    severity: SEVERITY.MILD,
    therapeutics: THERAPEUTICS_TYPE.NEGATIVE
  },
  {
    id: v4(),
    type: ALLERGY_TYPES.FOOD,
    discoveryDate: '2019-02-02',
    substance: 'Glúten',
    severity: SEVERITY.SEVERE,
    therapeutics: THERAPEUTICS_TYPE.NEGATIVE
  },
  {
    id: v4(),
    type: ALLERGY_TYPES.DOMESTIC_ANIMALS,
    discoveryDate: '2019-02-02',
    severity: SEVERITY.MILD,
    therapeutics: THERAPEUTICS_TYPE.NEGATIVE
  }
]

export default testAllergies
