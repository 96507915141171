import React, { Component } from 'react'
import { Panel } from '../../../../../common/Panel/Panel'
import { Button } from '../../../../../common/Button/Button'
import PropTypes from 'prop-types'
import ProfessionalForm from './Form'
import { ReactModal } from '../../../../../common/ReactModal/ReactModal'
import { DisabledInput } from '../../../../../common/formComponents/Input'
import ProfileSectionsHeader from '../ProfileSectionsHeader'

import './style.css'
import DocumentAdditionForm from './DocumentAdditionForm'

class View extends Component {
  constructor (props) {
    super(props)
    this.state = {
      editModalIsOpen: false,
      addModalIsOpen: false
    }
  }

  toggleEditModal () {
    this.setState({
      editModalIsOpen: !this.state.editModalIsOpen
    })
  }

  toggleAddModal () {
    this.setState({
      addModalIsOpen: !this.state.addModalIsOpen
    })
  }

  render () {
    let { person } = this.props
    return <div className='container-fluid doctor-profile'>
      <ProfileSectionsHeader person={person} />
      <Panel
        heading={'Documentos'}
        panelType={'panel-default'}
        rightHeader={<div className='horizontal-buttons-container'>
          <Button onClick={this.toggleAddModal.bind(this)} type={'submit'}
            text={<i className='fas fa-plus' />} buttonType={'btn-success btn-xs'} />
          <Button onClick={this.toggleEditModal.bind(this)} type={'submit'}
            text={<i className='fas fa-edit' />} buttonType={'btn-info btn-xs'} />
        </div>}>
        <div className='margin-bottom'>
          <form>
            <div className='form-group col-sm-6'>
              <label className='control-label' style={{ textAlign: 'right' }}>Nº CC</label>
              <div className=''>
                <DisabledInput content={''} />
              </div>
            </div>
            <div className='form-group col-sm-6'>
              <label className='control-label' style={{ textAlign: 'right' }}>NIF</label>
              <div className=''>
                <DisabledInput content={''} />
              </div>
            </div>
            <div className='form-group col-sm-6'>
              <label className='control-label' style={{ textAlign: 'right' }}>Nº Local de Prescrição</label>
              <div className=''>
                <DisabledInput content={''} />
              </div>
            </div>
          </form>
        </div>
      </Panel>
      <ReactModal
        footer={<Button onClick={this.toggleEditModal.bind(this)} type={'submit'} text={'Guardar'} buttonType={'btn-success pull-right'} />}
        name={'Editar Documentos'}
        toggleModal={this.toggleEditModal.bind(this)}
        modalIsOpen={this.state.editModalIsOpen}>
        <ProfessionalForm editable person={person} />
      </ReactModal>
      <ReactModal
        footer={<Button onClick={this.toggleAddModal.bind(this)} type={'submit'} text={'Adicionar'} buttonType={'btn-success pull-right'} />}
        name={'Adicionar Documento'}
        toggleModal={this.toggleAddModal.bind(this)}
        modalIsOpen={this.state.addModalIsOpen}>
        <DocumentAdditionForm />
      </ReactModal>
    </div>
  }
}

View.propTypes = {
  editable: PropTypes.bool,
  person: PropTypes.shape({})
}

View.defaultProps = { person: {} }

export default View
